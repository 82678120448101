/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*@author Sanjana Shende
 * Campaign Specification CampaignSpecificationPublisher
 @version 1.0
 */

import React from "react";
import { Container, Button, lightColors } from "react-floating-action-button";
import { css } from "emotion";
import { Drawer, Comment, Avatar, Input, Form, Table } from "antd"; //kiran-4746-removing console warning-no used
import { MDBBtn, CardBody } from "mdbreact";
import "antd/dist/antd.css";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { CampaignSpecificationLoader } from "../loaders/CampaignSpecificationLoader"; // 3428 - karan - loader
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //snehal-task-3766-specification VAPT api call
const Swal = require("sweetalert2");
const queryString = require("query-string");

var abmFile = [];
var suppressionFile = [];
var execlusionFile = [];
var otherFile = [];
const { TextArea } = Input;

// const CommentList = ({ comments }) => (
// 	<List
// 		dataSource={comments}
// 		header={`${comments.length} ${comments.length > 1 ? "messages" : "message"
// 			}`}
// 		itemLayout="horizontal"
// 		renderItem={(props) => <Comment {...props} />}
// 	/>
// ); //kiran-4746-removing console warning-no used

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <div>
    <Form.Item>
      <TextArea
        rows={4}
        onChange={onChange}
        value={value}
        style={{ resize: "none" }}
      />
    </Form.Item>
    <Form.Item>
      <button
        type="submit"
        class="btn add-button"
        id="cmd"
        loading={submitting}
        onClick={onSubmit}
      >
        Send Message
      </button>
    </Form.Item>
  </div>
);

class campaignSpecificationPublisherDashboardDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      showpacing: "No",
      customizedColumns: [],
      deliveryFormatMappingNew: [],
      display: "none",
      mappingValues: [],
      newsuccess: "",
      fields: {},
      dynamic: [],
      campCount: [],
      gdprPublisher: [],
      // gdprNoPublisher:[],
      otherDetails: [],
      abmDetails: [],
      pendingCampaignCount: "",
      suppresionDetails: [],
      exclusionDetails: [],
      region: "",
      sumOfAllocatedLeads: [],
      assetLinkArray: [],
      campID: " ",
      campIDTemp: " ",
      cancelTab: "",
      parentCampID: "",
      reallocationID: "",
      id: "",
      leadAllocation: 0,
      stateCopy: [
        {
          allocatedLead: "",
          cpl: "",
          campCPL: "",
        },
      ],
      newDynamicArray: [],
      newGDPRPubArray: [],
      gdprStatus: "false",
      campaignName: "",
      requiredLeadPerAsset: "",
      multiTouch: "",
      btnStatus: "",
      // allocatedLead: "",
      pendingLead: 0,
      campaignVisibleTime: "",
      campAcceptReject: "",
      campaignDetailAllocates: [],
      campaignDetailAssign: [],
      campaignDetailID: [],
      liveCampaignList: [],
      onClickAllocatedLead: [],
      onClickAllocatedLeadPubList: [],
      assignDeliveredLead: [],
      errors: {},
      alertDisplay: "none",
      alertBorder: "",
      alertWidth: "",
      alertAlign: "",
      spanSpaceDisplay: "none",
      success: "",
      puberror: "",
      allocerrors: "",
      reerrors: "",
      dateerrors: "",
      agencyID: "",
      show: false,
      campaignBriefDetails: [],
      campaignBriefDeliveryDetails: [],
      campaignBriefCustomQuestionDetails: [],
      campaignBriefSupportingDocumentAssetDetails: [],
      campaignBriefSupportingDocumentAbmDetails: [],
      campaignBriefSupportingDocumentSuppresionDetails: [],
      campaignBriefSupportingDocumentExclusionDetails: [],
      campaignBriefSupportingDocumentOtherDetails: [],
      publisherCampCPL: [],
      mergedCampaignDetailAssign: [],
      mergedGdprPublisher: [],
      assetFileList: [],
      linkListArray: [],
      userID: "",
      isSuppLink: false,
      comments: [
        {
          author: "Sanjana Godbole",
          avatar: "DI.png",
          content: <p>Welcome to chat</p>,
          datetime: moment().calendar(),
        },
      ],
      submitting: false,
      value: "",
      visible: false,
      deliveryURL: "",
      customQuestionURL: "",
      pacingCampaignDetails: [],
      campaignDetails: [],
      pacingUnit: "None",
      agencyCustomizeMapping: [],
      countryWiseAllocationArray: [],
      requiredCountryWiseAllocation: "No",
      key: "mainTab",
      loading: "'whirly-loader ", // Sanjana-3128-Campaign Spec Performance and many time come as blank data-loader added
      totalApiCalls: 13, //3428-karan-loader
      apiCallsCompleted: 0, //3428-karan-loader
      customizeMappingFormat: "", //kiran-4884-declare variable for customize mapping format
      extraAddedFields: "", //Aman-5281 added to store 21-30 Extra fields
      extraAddedFieldsYes: "", //Aman-5281 added to store yes value only of 21-30 Extra fields 
      columnsequencingarray: [],
      columnSequenceCheck: "No",
    };
    this.handleCampaignBriefDetails =
      this.handleCampaignBriefDetails.bind(this);

    this.showMappin = this.showMappin.bind(this);
    this.getMappin = this.getMappin.bind(this);
    // this.mappinHandleChange=this.mappinHandleChange.bind(this);

    this.loadingRef = React.createRef(); //3428-karan-loader handlers
    this.closeLoadingRef = React.createRef(); //3428-karan-loader handlers
  } //end of constructor
  showMappin() {
    this.setState({
      display: "block",
    });
  }
  getMappin() {
    var campID = this.props.greeting;
    // const { isAuthenticated, user } = this.props.auth;//kiran-4746-removing console warning-no used

    let data = {
      deliveryCampID: campID,
      //user: user,//sonali=3945-removing user
      mappingValues: this.state.deliveryFormatMappingNew,
    };

    fetch("/campaign/setDeliveryFormatMapping", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        Swal.fire({
          text: "Customize Mapping Done",
          type: "success",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          preConfirm: () => {
            window.location.reload();
          },
        });
      });
  }
  mappinHandleChange(i, e) {
    const { name, value } = e.target;
    let deliveryFormatMappingNew = [...this.state.deliveryFormatMappingNew];
    deliveryFormatMappingNew[i] = {
      ...deliveryFormatMappingNew[i],
      [name]: value,
    };
    // this.state.deliveryFormatMappingNew = [...deliveryFormatMappingNew];
    this.setState({ deliveryFormatMappingNew: [...deliveryFormatMappingNew] }); //kiran-4746-removing console warning-use setState
  }
  componentDidMount() {
    this.loadingRef.current.click(); //3428=karan-loader
    var campID = this.props.greeting;
    const { user } = this.props.auth; //kiran-4746-removing console warning-no used
    var userID = user.id;
    var parsedData = queryString.parse(this.props.location.search);
    var cancelTab = parsedData.cancelKey;
    let key = parsedData.key;
    this.setState({
      campID: campID,
      userID: userID,
      cancelTab: cancelTab,
      key,
    });
  }

  componentWillMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    } else {
      var campID = this.props.greeting;
      const { user } = this.props.auth; //kiran-4746-removing console warning-no used
      var pID = user.id;

      // var parsed = queryString.parse(this.props.location.search);
      // var agencyID = parsed.agencyID;
      // var cancelTab = parsed.cancelKey;
      // let key = parsed.key;
      //Sanjana Godbole-3728-VAPT--publisher side--Home--URL links And Routes-FE
      var parsed, key, cancelTab, agencyID; //kiran-4746-removing console warning-no used campID, parentCampID, reallocationID,
      if (this.props.location.state !== undefined) {
        parsed = this.props.location.state;
        agencyID = parsed.agencyID;
        cancelTab = parsed.cancelKey;
        key = parsed.key;
      } else {
        parsed = queryString.parse(this.props.location.search);
        agencyID = parsed.agencyID;
        cancelTab = parsed.cancelKey;
        key = parsed.key;
      }

      //Somnath Task-3128, Call handleCampaignBriefDetails function to get campaign Spec
      //snehal-task-3348-STC--cancel tab--campaign specification issue--publisher side
      this.setState({ cancelTab: cancelTab });

      this.handleCampaignBriefDetails(campID, pID);

      this.setState({ agencyID, cancelTab, key });
      //{/* snehal-task-3211-RFP - messaging facility between Agency and Publisher(added a key as dashboardMsg to show drawer) */}
      //kiran-4746-removing console warning-changed == to ===
      if (key === "dashboardMsg") {
        this.setState({ visible: true });
      } else {
        this.setState({ visible: false });
      }

      let data = {
        campID: campID,
        //pID: pID,//Sonali-3945-removing pID and user
        //user: user,
      };

      // alert("data2"+JSON.stringify(data));
      fetch("campaign/deliveryFormatMapping", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((deliveryFormatMapping) => {
          //3428-karan-loader
          this.setState(
            (prev) => ({
              apiCallsCompleted: prev.apiCallsCompleted + 1,
            }),
            () => {
              this.dismissLoader();
            }
          );
          this.setState(
            {
              deliveryFormatMappingNew: deliveryFormatMapping,
              customizedColumns: deliveryFormatMapping,
            },
            function () {}
          );
        })
        .catch(function (err) {
          //3428-karan-loader
          this.setState(
            (prev) => ({
              apiCallsCompleted: prev.apiCallsCompleted + 1,
            }),
            () => {
              this.dismissLoader();
            }
          );
          console.log(err);
        });

      //This API is for change status
      //kiran-4546-commented as this api will not receive broadcast msg to pub
      //kiran-4607-uncommented this api as msg not read out
      fetch("/notification/PublisherSendMessageToAgecncyStatusChanged", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((comments) => {
          this.setState({ comments: comments });

          //This API is for brodcast message history~
          //4546-Nilesh- Below API call in (/notification/PublisherSendMessageToAgecncyStatusChanged").
          fetch(
            "/notification/campaignSpecificationBrodcastMessageForPublisher",
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(data),
            }
          )
            .then((res) => res.json())
            .then((comments) => {
              //3428-karan-loader
              this.setState(
                (prev) => ({
                  apiCallsCompleted: prev.apiCallsCompleted + 1,
                }),
                () => {
                  this.dismissLoader();
                }
              );
              this.setState({ comments: comments });
            })
            .catch(function (err) {
              //3428-karan-loader
              this.setState(
                (prev) => ({
                  apiCallsCompleted: prev.apiCallsCompleted + 1,
                }),
                () => {
                  this.dismissLoader();
                }
              );
              console.log(err);
            });
        })
        .catch(function (err) {
          console.log(err);
        });

      const self = this; //karan-task-3463-setting current instance and changed the value of "this" to "self" in below fetch('publisherDashboard/getCountryWiseLead') then() and catch() block
      // this api for getting countrywise allocation
      //sonali=3945-removing pID from the below link
      fetch(
        "publisherDashboard/getCountryWiseLead?campID=" + campID + "&key=" + key
      )
        .then((res) => res.json())
        .then((countryWiseAllocationArray) => {
          //3428-karan-loader
          self.setState(
            (prev) => ({
              apiCallsCompleted: prev.apiCallsCompleted + 1,
            }),
            () => {
              self.dismissLoader();
            }
          );
          self.setState({
            countryWiseAllocationArray: countryWiseAllocationArray,
          });
        })
        .catch(function (err) {
          //3428-karan-loader
          self.setState(
            //karan-task-3463-using current instance
            (prev) => ({
              apiCallsCompleted: prev.apiCallsCompleted + 1,
            }),
            () => {
              self.dismissLoader(); //karan-task-3463-using current instance
            }
          );
          console.log(err);
        });

        //Chaitanya-5361-fetch call for column mapping data
        fetch("publisher/getColumnSequencing?campID=" + campID)
        .then((res) => res.json())
        .then((response) => {
          //alert("response.columnsSequence"+JSON.stringify(response.columnsSequence))
          this.setState(
            (prev) => ({
              apiCallsCompleted: prev.apiCallsCompleted + 1,
            }),
            () => {
              self.dismissLoader(); //Rutuja 5017 changed this to self as page going crash
            }
          );
          //alert("response.columnSequenceCheck"+JSON.stringify(response.columnSequenceCheck))
          if (response.columnSequenceCheck == "Yes") {
            // If columnsSequence is present in the response
            this.setState({
              columnsequencingarray: response.columnsSequence,
              columnSequenceCheck: response.columnSequenceCheck,
            });
            //alert("columnsequencingarray"+JSON.stringify(this.state.columnsequencingarray))
          } else {
            // If only columnSequenceCheck is present in the response
            this.setState({
              columnSequenceCheck: response.columnSequenceCheck,
            });
          }
        });
    }
  }
  contactAgency = () => {
    window.location.href = "/contactAgency";
  };

  handleSubmit = () => {
    if (!this.state.value) {
      return;
    } else {
      /* @author Narendra Phadke
       * @param  Description private  message
       * @return Description send private message
       */
      const { user } = this.props.auth;
      var campID = this.state.campID;
      let data = {
        campID: campID,
        //	user: user,//sonali-3945-removing user
        message: this.state.value,
      };
      fetch("notification/campaignSpecificationPublisherPrivateMessage", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((message) => {
          this.setState({ submitting: true }); //Somnath Task-3935, Remove message array
        })
        .catch(function (err) {
          console.log(err);
        });
      // this.setState({
      //   submitting: true,
      // });

      var uname = user.firstName + " " + user.lastName;

      var fnamefirst = user.firstName.slice(0, 1);
      var lnamefirst = user.lastName.slice(0, 1);

      var uavatar = fnamefirst + lnamefirst;

      setTimeout(() => {
        this.setState({
          submitting: false,
          value: "",
          comments: [
            {
              author: uname,
              avatar: uavatar,
              content: <p>{this.state.value}</p>,
              datetime: moment().calendar(),
            },
            ...this.state.comments,
          ],
        });
      }, 1000);
    }
  };

  handleChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  showDrawer = () => {
    /**
     * @author Narendra Phadke
     * @param  Description fetch brodcast message history
     * @return Description return all history
     */

    //var parsed = queryString.parse(this.props.location.search);
    var campID = this.state.campID;
    // const { user } = this.props.auth;//kiran-4746-removing console warning-no used
    let data = {
      campID: campID,
      //	user: user,//sonali-3945-removing user
    };

    //This API is for brodcast message history
    fetch("/notification/campaignSpecificationBrodcastMessageForPublisher", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((comments) => {
        this.setState({ comments: comments, visible: true });
        if (this.state.comments.length > 0) {
          this.state.comments.map((comments) => (
            <div>
              comments: [
              {
                //this.state.author = comments.author
                (this.setState({ author: comments.author }),
                // this.state.avatar = comments.avatar
                this.setState({ avatar: comments.avatar }),
                //this.state.content = <p>comments.content</p>
                this.setState({ content: <p>comments.content</p> }),
                //this.state.datetime = comments.datetime
                this.setState({ datetime: comments.datetime })) //kiran-4746-removing console warning-use setState
              }
              ]
            </div>
          ));
        }
      })
      .catch(function (err) {
        console.log(err);
      });

    //This API is for change status
    fetch("/notification/PublisherSendMessageToAgecncyStatusChanged", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((comments) => {
        //Somnath Task-3935 remove comments from setstate
        this.setState({ visible: true });
      })
      .catch(function (err) {
        console.log(err);
      });
    //  this.setState({
    //    visible: true,
    //  });

    //    this.setState({
    //     comments: comments,
    //  });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  /**
   * @author Narendra Phadke
   * @param  Description handle the Campaign Modal Details
   * @return Description return All steps campaign Details
   */

  handleCampaignBriefDetails(campID1, userID1) {
    var campID = campID1;
    // var userID = userID1;
    //var fromCancelTab = this.state.cancelTab;
    //snehal-task-3348-STC--cancel tab--campaign specification issue--publisher side
    //var parsed = queryString.parse(this.props.location.search);
    //sonali-4113-getting cancelKey from this.props
    var fromCancelTab = this.props.cancelKey;
    var key = this.state.key;
    let data = {};
    //sonali-4113-passing parameter on the based of fromCancelTab
    //kiran-4746-removing console warning-changed == to ===
    if (
      fromCancelTab === undefined ||
      fromCancelTab === "undefined" ||
      fromCancelTab == null ||
      fromCancelTab === "null"
    ) {
      data = {
        campID: campID,
        key: key,
      };
    } else {
      data = {
        campID: campID,
        cancelTab: fromCancelTab,
        key: key,
      };
    }

    //Sonali-4113-making post request
    //This API is for campaign details from campaign table
    //sonali-3945-removing user from the api call
    fetch("/publisher/campaignBriefDetails", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((campaignBriefDetails) => {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );
        //Somnath Task-3128, campaignBriefDetails destructering changes done
        if (campaignBriefDetails.length > 0) {
          var {
            parentCampID,
            reallocationID,
            campaignName,
            requiredCountryWiseAllocation,
            requiredLeadPerAsset,
            multiTouch,
          } = campaignBriefDetails[0];
        }
        this.setState({
          campaignBriefDetails,
          requiredLeadPerAsset,
          multiTouch,
          campaignName,
          requiredCountryWiseAllocation,
          loading: "",
        });
        // Sanjana-3128-Campaign Spec Performance and many time come as blank data-loader added

        // if (this.state.campaignBriefDetails && this.state.campaignBriefDetails.length) {
        //   var str = this.state.campaignBriefDetails[0].country;
        //   var str_array = str.split(',');
        //   for (var i = 0; i < str_array.length; i++) {
        //     countryArray.push(str_array[i],' | ');
        //   }
        // }if (this.state.campaignBriefDetails && this.state.campaignBriefDetails.length) {
        //   var str = this.state.campaignBriefDetails[0].jobLevel;
        //   var str_array = str.split(',');
        //   for (var i = 0; i < str_array.length; i++) {
        //     jobLevelArray.push(str_array[i],' | ');
        //   }
        // }if (this.state.campaignBriefDetails && this.state.campaignBriefDetails.length) {
        //   var str = this.state.campaignBriefDetails[0].jobFunction;
        //   var str_array = str.split(',');
        //   for (var i = 0; i < str_array.length; i++) {
        //     jobFunctionArray.push(str_array[i],' | ');
        //   }
        // }if (this.state.campaignBriefDetails && this.state.campaignBriefDetails.length) {
        //   var str = this.state.campaignBriefDetails[0].employeeSize;
        //   var str_array = str.split(',');
        //   for (var i = 0; i < str_array.length; i++) {
        //     employeeSizeArray.push(str_array[i],',');
        //   }
        // }if (this.state.campaignBriefDetails && this.state.campaignBriefDetails.length) {
        //   var str = this.state.campaignBriefDetails[0].companyRevenue;
        //   var str_array = str.split(',');
        //   for (var i = 0; i < str_array.length; i++) {
        //     companyRevenueArray.push(str_array[i],',');
        //   }
        // }

        //This API is for campaign delivery details from delivery format jobLevelArray
        //Task-3128 Somnath, Lead DeliveryURl changes done

        fetch(
          "/publisher/campaignBriefDeliveryDetails?campID=" +
            campID +
            "&parentCampID=" +
            parentCampID +
            "&reallocationID=" +
            reallocationID
        )
          .then((res) => res.json())
          .then((campaignBriefDeliveryDetails) => {
            //Aman-5281-added to get the 21-30 extra fileds value 
            const extraFields = ["extra21", "extra22", "extra23", "extra24", "extra25", "extra26", "extra27", "extra28", "extra29", "extra30"];

            //Aman-5281-Iterate over each object in the array and filter null values
            const extractedDetailsArray = campaignBriefDeliveryDetails.map(details => {
              return Object.fromEntries(
                Object.entries(details)
                  .filter(([key, value]) => extraFields.includes(key) && value !== null)
              );
            }).filter(details => Object.keys(details).length > 0); // Filter out empty objects

            //Aman-5281 -added Function to transform keys extra21 to Extra 21
            const transformKey = (key) => {
              return key.replace(/extra(\d+)/, 'Extra $1');
            };

            //Aman-5281 - Filter the object where the value is "Yes" and transform keys
            const yesDetailsArray = extractedDetailsArray.map(details => {
              return Object.fromEntries(
                Object.entries(details)
                  .filter(([key, value]) => value === "Yes")
                  .map(([key, value]) => [transformKey(key), value]) // Transform the key here
              );
            }).filter(details => Object.keys(details).length > 0); // Filter out empty objects

              this.setState({
                extraAddedFields: extractedDetailsArray,
                extraAddedFieldsYes: yesDetailsArray
              });
            //3428-karan-loader
            this.setState(
              (prev) => ({
                apiCallsCompleted: prev.apiCallsCompleted + 1,
              }),
              () => {
                this.dismissLoader();
              }
            );
            if (campaignBriefDeliveryDetails.length > 0) {
              var { deliveryURL } = campaignBriefDeliveryDetails[0];
            }
            let customizeMappingFormat =
              campaignBriefDeliveryDetails[0].customizeMappingFormat; // kiran-4884-taken value from array and setState
            this.setState({
              campaignBriefDeliveryDetails,
              deliveryURL,
              customizeMappingFormat,
            });
            //* snehal-Task-3171-prod issue---specification customize mapping issue(on priority) *
            if (campaignBriefDeliveryDetails.length > 0) {
              if (campaignBriefDeliveryDetails[0].customizeMapping === "Yes") {
                this.setState({ showpacing: "Yes" });
              }
            }
          })
          .catch(function (err) {
            //3428-karan-loader
            this.setState(
              (prev) => ({
                apiCallsCompleted: prev.apiCallsCompleted + 1,
              }),
              () => {
                this.dismissLoader();
              }
            );
            console.log(err);
          }); // End of Delivery Format
      })
      .catch(function (err) {
        console.log(err);
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );
      });

    /**
     * @author Supriya Gore
     * @param  Description handle the pacing campaign details edit
     * @return Description return pacing campaign details
     */
    // fetch("campaign/pacingDetailsForEdit?campID=" + campID + "")
    // .then(res => res.json())
    // .then(pacingCampaignDetails => {
    //   this.setState({
    //     pacingCampaignDetails: pacingCampaignDetails
    //   });

    // });

    var parsed = queryString.parse(this.props.location.search);
    key = parsed.key;
    // var key="Counter";
    //kiran-4746-removing console warning-changed == to ===
    if (key === "Assign" || key === "Counter" || key === "AcceptedCounter") {
      let data = {
        campID: campID,
        //pID: userID,//sonali-3945-removing pID from the data
        status: key,
      };
      fetch("publisherDashboard/publisherPacingCampaignDetails", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((pacingCampaignDetails) => {
          // const pacingResult = Array.from(
          // 	new Set(pacingCampaignDetails.map((p) => p.campID))
          // ).map((campID) => {
          // 	return {
          // 		campID: campID,
          // 		pacingUnit: pacingCampaignDetails.find((p) => p.campID === campID)
          // 			.pacingUnit,
          // 	};
          // });//kiran-4746-removing console warning-no used

          this.setState({ pacingCampaignDetails: pacingCampaignDetails });
          if (pacingCampaignDetails.length > 0) {
            this.setState({ pacingUnit: pacingCampaignDetails[0].pacingUnit });
          }
          this.state.campaignBriefDetails[0].allocatedLead =
            pacingCampaignDetails[0].allocatedLead;
        });
    } else {
      let data = {
        campID: campID,
        //	pID: userID,//sonali-3945-removing pID from the data
      };

      fetch("publisherDashboard/pacingCampaignDetails", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((pacingCampaignDetails) => {
          //Sonali-4113-added length check to avoid undefined error on FE
          if (pacingCampaignDetails.length > 0) {
            // const pacingResult = Array.from(
            // 	new Set(pacingCampaignDetails.map((p) => p.campID))
            // ).map((campID) => {
            // 	return {
            // 		campID: campID,
            // 		pacingUnit: pacingCampaignDetails.find((p) => p.campID === campID)
            // 			.pacingUnit,
            // 	};
            // }); //kiran-4746-removing console warning-no used

            this.setState({ pacingCampaignDetails: pacingCampaignDetails });
            if (pacingCampaignDetails.length > 0) {
              this.setState({
                pacingUnit: pacingCampaignDetails[0].pacingUnit,
              });
            }
          }
        });
    }

    //custom question come from new custom question
    fetch("/publisher/campaignBriefCustomQuestionDetails?campID=" + campID)
      .then((res) => res.json())
      .then((campaignBriefCustomQuestionDetails) => {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );
        this.setState({
          campaignBriefCustomQuestionDetails:
            campaignBriefCustomQuestionDetails,
        });
        if (this.state.campaignBriefCustomQuestionDetails.length > 0) {
          var cqURL = "";
          for (
            var i = 0;
            i < this.state.campaignBriefCustomQuestionDetails.length;
            i++
          ) {
            var cq =
              "&CQ-" +
              this.state.campaignBriefCustomQuestionDetails[i]
                .customQuestionID +
              '=""';
            cqURL += cq;
          }
          this.setState({ customQuestionURL: cqURL });
        }
      })
      .catch(function (err) {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );
        console.log(err);
      });
    const self = this; //karan-task-3463-setting current instance and changed the value of "this" to "self" in below fetch('/publisher/campaignBriefSupportingDocumentAssetDetails') then() and catch() block
    //This API is for campaign supporting document Asset details from delivery format
    fetch(
      "/publisher/campaignBriefSupportingDocumentAssetDetails?campID=" +
        campID +
        ""
    )
      .then((res) => res.json())
      .then((campaignBriefSupportingDocumentAssetDetails) => {
        //3428-karan-loader
        self.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            self.dismissLoader();
          }
        );
        self.setState({
          campaignBriefSupportingDocumentAssetDetails:
            campaignBriefSupportingDocumentAssetDetails,
        });
        //kiran-4746-removing console warning-changed != to !==
        if (
          self.state.campaignBriefSupportingDocumentAssetDetails !==
            undefined ||
          self.state.campaignBriefSupportingDocumentAssetDetails.length > 0
        ) {
          var allocatedLead = self.state.campaignBriefDetails[0].allocatedLead;
          var assetFileList = [];
          for (
            var i = 0;
            i < self.state.campaignBriefSupportingDocumentAssetDetails.length;
            i++
          ) {
            var leadPercentage =
              self.state.campaignBriefSupportingDocumentAssetDetails[i]
                .leadPercentage;
            var leadPerAsset =
              (parseInt(leadPercentage) * parseInt(allocatedLead)) / 100;
            leadPerAsset = Math.round(leadPerAsset);
            assetFileList.push({
              supportDocID:
                self.state.campaignBriefSupportingDocumentAssetDetails[i]
                  .supportDocID,
              leadPercentage: leadPercentage,
              leadPerAsset: leadPerAsset,
              suppDocName:
                self.state.campaignBriefSupportingDocumentAssetDetails[i]
                  .suppDocName,
              assetStatus:
                self.state.campaignBriefSupportingDocumentAssetDetails[i]
                  .assetStatus,
              multiTouch:
                self.state.campaignBriefSupportingDocumentAssetDetails[i]
                  .multiTouch,
            });
          }
          self.setState({ assetFileList });
        }
      })
      .catch(function (err) {
        //3428-karan-loader
        self.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            self.dismissLoader();
          }
        );
        console.log(err);
      });

    //This API is for campaign supporting document ABM details from delivery format
    fetch(
      "/publisher/campaignBriefSupportingDocumentAbmDetails?campID=" +
        campID +
        ""
    )
      .then((res) => res.json())
      .then((campaignBriefSupportingDocumentAbmDetails) => {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );
        this.setState({
          campaignBriefSupportingDocumentAbmDetails:
            campaignBriefSupportingDocumentAbmDetails,
        });
        //kiran-4746-removing console warning-changed != to !==
        if (
          this.state.campaignBriefSupportingDocumentAbmDetails !== undefined ||
          this.state.campaignBriefSupportingDocumentAbmDetails !== ""
        ) {
          for (
            var i = 0;
            i < this.state.campaignBriefSupportingDocumentAbmDetails.length;
            i++
          ) {
            abmFile.push(
              this.state.campaignBriefSupportingDocumentAbmDetails[i]
                .suppDocName + " | "
            );
          }
        }
      })
      .catch(function (err) {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );
        console.log(err);
      });

    //This API is for campaign supporting document Suppresion details from delivery format
    fetch(
      "/publisher/campaignBriefSupportingDocumentSuppresionDetails?campID=" +
        campID +
        ""
    )
      .then((res) => res.json())
      .then((campaignBriefSupportingDocumentSuppresionDetails) => {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );
        if (campaignBriefSupportingDocumentSuppresionDetails.length > 0) {
          var isSuppLink =
            campaignBriefSupportingDocumentSuppresionDetails[0].isSuppLink;
        }
        this.setState({
          isSuppLink: isSuppLink,
          campaignBriefSupportingDocumentSuppresionDetails:
            campaignBriefSupportingDocumentSuppresionDetails,
        });
        //kiran-4746-removing console warning-changed != to !==
        if (
          this.state.campaignBriefSupportingDocumentSuppresionDetails !==
            undefined ||
          this.state.campaignBriefSupportingDocumentSuppresionDetails !== ""
        ) {
          for (
            var i = 0;
            i <
            this.state.campaignBriefSupportingDocumentSuppresionDetails.length;
            i++
          ) {
            suppressionFile.push(
              this.state.campaignBriefSupportingDocumentSuppresionDetails[i]
                .suppDocName + " | "
            );
          }
        }
      })
      .catch(function (err) {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );
        console.log(err);
      });

    //This API is for campaign supporting document Exclusion details from delivery format
    fetch(
      "/publisher/campaignBriefSupportingDocumentExclusionDetails?campID=" +
        campID +
        ""
    )
      .then((res) => res.json())
      .then((campaignBriefSupportingDocumentExclusionDetails) => {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );
        this.setState({
          campaignBriefSupportingDocumentExclusionDetails:
            campaignBriefSupportingDocumentExclusionDetails,
        });
        //kiran-4746-removing console warning-changed != to !==
        if (
          this.state.campaignBriefSupportingDocumentExclusionDetails !==
            undefined ||
          this.state.campaignBriefSupportingDocumentExclusionDetails !== ""
        ) {
          for (
            var i = 0;
            i <
            this.state.campaignBriefSupportingDocumentExclusionDetails.length;
            i++
          ) {
            execlusionFile.push(
              this.state.campaignBriefSupportingDocumentExclusionDetails[i]
                .suppDocName + " | "
            );
          }
        }
      })
      .catch(function (err) {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );
        console.log(err);
      });

    //This API is for campaign supporting document Other details from delivery format
    fetch(
      "/publisher/campaignBriefSupportingDocumentOtherDetails?campID=" +
        campID +
        ""
    )
      .then((res) => res.json())
      .then((campaignBriefSupportingDocumentOtherDetails) => {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );
        this.setState({
          campaignBriefSupportingDocumentOtherDetails:
            campaignBriefSupportingDocumentOtherDetails,
        });
        //kiran-4746-removing console warning-changed != to !==
        if (
          this.state.campaignBriefSupportingDocumentOtherDetails !==
            undefined ||
          this.state.campaignBriefSupportingDocumentOtherDetails !== ""
        ) {
          for (
            var i = 0;
            i < this.state.campaignBriefSupportingDocumentOtherDetails.length;
            i++
          ) {
            otherFile.push(
              this.state.campaignBriefSupportingDocumentOtherDetails[i]
                .suppDocName + " | "
            );
          }
        }
      })
      .catch(function (err) {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );
        console.log(err);
      });

    /** For getting AssetLink from DB */
    //Nilesh-4546-changed the value of "this" to "self" in below fetch('agency/getAssetLinkInSpec').
    fetch("agency/getAssetLinkInSpec?campID=" + campID)
      .then((res) => res.json())
      .then((assetLinkArray) => {
        //3428-karan-loader
        self.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            self.dismissLoader();
          }
        );
        self.setState({ assetLinkArray: assetLinkArray });
        if (self.state.assetLinkArray.length > 0) {
          var linkListArray = [];
          let allocatedLead = self.state.campaignBriefDetails[0].allocatedLead;
          for (var i = 0; i < assetLinkArray.length; i++) {
            var leadPerAsset =
              (parseInt(allocatedLead) *
                parseInt(assetLinkArray[i].leadPercentage)) /
              100;
            leadPerAsset = Math.round(leadPerAsset);
            linkListArray.push({
              supportDocID: assetLinkArray[i].supportDocID,
              leadPercentage: assetLinkArray[i].leadPercentage,
              leadPerAsset: leadPerAsset,
              suppDocName: assetLinkArray[i].suppDocName,
              assetLink: assetLinkArray[i].assetLink,
              assetStatus: assetLinkArray[i].assetStatus,
              multiTouch: assetLinkArray[i].multiTouch,
            });
          }
          self.setState({ linkListArray });
        }
      })
      .catch(function (err) {
        //3428-karan-loader
        self.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            self.dismissLoader();
          }
        );
        console.log(err);
      });

    fetch("campaign/getAgencyCustomizeMapping?campID=" + campID + "")
      .then((res) => res.json())
      .then((agencyCustomizeMapping) => {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );
        this.setState({ agencyCustomizeMapping: agencyCustomizeMapping });
      })
      // 3428-karan- added missing catch block to handle loader
      .catch((err) => {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            this.dismissLoader();
          }
        );
        console.log(err);
      });

    this.setState({ show: true });
  } // end of handleCampaignBriefDetails

  /**
   * @author Somnath Keswad
   * @param  Description  get multiple Link of Asset
   * @return Description return open Multiple Link when i click on button in campSpec
   */
  //  openAllLink(){
  //   var length=this.state.assetLinkArray.length;
  //   for(var i=0;i<length;i++){
  //       window.open(this.state.assetLinkArray[i].assetLink);
  //   }
  // }
  openAllLink() {
    var length = this.state.assetLinkArray.length;

    for (let i = 0; i < length; i++) {
      let assetLink1 = this.state.assetLinkArray[i].assetLink;
      let assethttps = assetLink1.includes("https://");
      let assethttp = assetLink1.includes("http://");
      if (assethttps === true || assethttp === true) {
        window.open(this.state.assetLinkArray[i].assetLink);
      } else {
        window.open("https://" + this.state.assetLinkArray[i].assetLink);
      }
    }
  }
  /**
   * @author Snehal More
   * @param  Description  get  Link of Asset
   * @return Description return open Link when i click on link in campSpec
   */
  openAssetLink(e) {
    var assetLink = e.target.getAttribute("assetLink");

    let assethttps = assetLink.includes("https://");
    let assethttp = assetLink.includes("http://");
    if (assethttps === true || assethttp === true) {
      window.open(assetLink);
    } else {
      window.open("https://" + assetLink);
    }
  }
  /**
   * @author Somnath Keswad
   * @param  Description  get multiple Link of Suppression
   * @return Description return open Multiple Link when i click on button in campSpec
   */
  openAllSuppLink() {
    var supp = this.state.campaignBriefSupportingDocumentSuppresionDetails;
    var suppData = supp.filter(function (s) {
      return !s.assetLink === "";
    });
    for (var i = 0; i < suppData.length; i++) {
      window.open(suppData[i].assetLink);
    }
  }

  /**
   *
   * @author : Karan Jagtap
   * @description : dismisses the loader
   */
  dismissLoader = () => {
    if (this.state.apiCallsCompleted === this.state.totalApiCalls) {
      this.closeLoadingRef.current.click();
    }
  };

  render() {
    const { submitting, value } = this.state;
    // Chaitanya-5361-added column
    const columns = [
      {
        width: "3%",
        render: (text, record, index) => (
          <div>
            <span style={{ fontSize: "14px" }}>Column {index + 1}</span>
            <br />
          </div>
        ),
      },
      {
        width: "6%",
        render: (text, record) => (
          <div>
            <span style={{ fontSize: "14px", fontWeight: "bold" }}>
              {record.value}
            </span>
            <br />
          </div>
        ),
      },
    ];
    const tableCSS = css({
      backgroundColor: "white",
      borderStyle: "bold",
      "& thead  > tr": {
        backgroundImage: "linear-gradient(to right,#28166f,#007cc3)!important",
        color: "white",
      },
      "& thead > tr >th": {
        border: "1px solid black",
        color: "white",
      },
    });
    return (
      <div>
        {/* Hello Campaign Specification  CampaignSpecificationPublisher {this.props.greeting} */}
        {/* 3428-karan-di loader */}
        <CampaignSpecificationLoader
          loadingRef={this.loadingRef}
          closeLoadingRef={this.closeLoadingRef}
          message="wait a moment while we are loading your data."
        />
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className={this.state.loading}></div>
          {/*Sanjana-3128-Campaign Spec Performance and many time come as blank data-loader added */}
          {/* <div style={{ border: "1px solid #cdcdcd" }}>
                    <div class="table-responsive">
                      <table id="myTable" className=" table table-bordered">
                        <thead>
                          <tr class="info">
                            <th class="table-header">Basic Details</th>
                          </tr>
                        </thead>
                      </table>
                      </div> */}

          {/* /**
           * @author Narendra Phadke
           * @param  Description Display Campaign Details on Modal
           * @return Description return Campaign Details
           */}
          <div style={{ border: "white" }}>
            {/* snehal-task-3561-Reff(3428)----spec and cust mapping format issue(added css for subtitles)  */}
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "20px",
                color: "#193d8f",
                fontWeight: "600",
              }}
            >
              Basic Details :{" "}
            </div>
            <div
              class="card"
              style={{
                backgroundColor: "#f8f8f8",
                borderColor: "#707070",
                borderWidth: "0.3px",
              }}
            >
              <CardBody>
                {this.state.campaignBriefDetails.map((campaignBriefDetails) => (
                  <div class="row">
                    <div class="col-lg-12">
                      <table>
                        <tr>
                          <td style={{ width: "170px" }}>
                            <label id="label">&nbsp;Campaign Name</label>
                          </td>
                          <td>:&nbsp;{campaignBriefDetails.campaignName}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                ))}

                {this.state.campaignBriefDetails.map((campaignBriefDetails) => (
                  <div class="row">
                    <div class="col-lg-4">
                      <table>
                        <tr>
                          <td style={{ width: "170px" }}>
                            <label id="label">&nbsp;Advertiser Name</label>
                          </td>
                          <td>
                            <span>
                              :&nbsp;{campaignBriefDetails.clientName}
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td style={{ width: "170px" }}>
                            <label id="label">&nbsp;Lead Allocation</label>
                          </td>
                          <td>
                            <span>
                              :&nbsp;{campaignBriefDetails.allocatedLead}
                            </span>
                          </td>
                        </tr>

                        <tr>
                          {/* User Story - 3428 - Karan Jagtap - top alignment */}
                          <td style={{ width: "170px", display: "flex" }}>
                            <label id="label">&nbsp;Timezone</label>
                          </td>
                          <td>
                            <span>:&nbsp;{campaignBriefDetails.timezone}</span>
                          </td>
                        </tr>
                      </table>
                    </div>

                    <div class="col-lg-4">
                      <table>
                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">Agency Campaign ID</label>
                          </td>
                          <td>
                            <span>
                              :&nbsp;{campaignBriefDetails.clientCampID}
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label"> Start Date</label>
                          </td>
                          <td>
                            <span>:&nbsp;{campaignBriefDetails.startDate}</span>
                          </td>
                        </tr>

                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">First Lead Delivery Date</label>
                          </td>
                          <td>
                            <span>
                              :&nbsp;
                              {campaignBriefDetails.firstLeadDeliveryDate}
                            </span>
                          </td>
                        </tr>
                      </table>
                    </div>

                    <div class="col-lg-4">
                      <table>
                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">Campaign Status</label>
                          </td>
                          <td>
                            <span>
                              :&nbsp;{campaignBriefDetails.campaignStatus}
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">End Date</label>
                          </td>
                          <td>
                            <span>:&nbsp;{campaignBriefDetails.endDate}</span>
                          </td>
                        </tr>

                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">No Of Lead Per Domain</label>
                          </td>
                          <td>
                            <span>
                              :&nbsp;{campaignBriefDetails.noOfLeadPerDomain}
                            </span>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                ))}
                <br />
              </CardBody>
            </div>

            {/* {this.state.pacingCampaignDetails.map((pacingCampaignDetails,i)=>{return(
 
                       <div class="row">
                       <div class="col-lg-4">
                         <table>
                           <tr>
                     <td style={{width:'170px'}}><label id="label">&nbsp;Pacing Unit{i+1}</label></td>
                             <td><span>:&nbsp;{pacingCampaignDetails.pacingUnit}</span></td>
                             </tr>
                     
                             <tr>
                             <td style={{width:'170px'}}><label id="label">&nbsp;Pacing Month{i+1}</label></td>
                             <td><span>:&nbsp;{pacingCampaignDetails.pacingMonth}</span></td>
                           </tr>
                         </table>
                       </div>

                       <div class="col-lg-4">
                         <table>
                         <tr>
                             <td style={{width:'170px'}}><label id="label">&nbsp;Pacing Percentage{i+1}</label></td>
                             <td><span>:&nbsp;{pacingCampaignDetails.pacingPercentage}</span></td>
                           </tr>

                           <tr>
                             <td style={{width:'160px'}}><label id="label">Pacing Lead Count{i+1}</label></td>
                             <td><span>:&nbsp;{pacingCampaignDetails.pacingLeadCount}</span></td>
                           </tr>

                      
                         </table>
                       </div>


                       <div class="col-lg-4">
                         <table>
                      

                           <tr>
                             <td style={{width:'160px'}}><label id="label"> Pacing End Date{i+1}</label></td>
                             <td><span>:&nbsp;{pacingCampaignDetails.pacingEndDate}</span></td>
                           </tr>

                           <tr>
                             <td style={{width:'160px'}}><label id="label">Pcing Carry Forward{i+1}</label></td>
                             <td><span>:&nbsp;{pacingCampaignDetails.pacingCarryForward}</span></td>
                           </tr>
                          
                         </table>
                       </div>

                       
                       </div>
                     )})}
                      </CardBody>
                      </div> */}

            {/* /*row1 */}
            <br />

            {/* <table id="myTable" className=" table table-bordered">
                        <thead>
                          <tr class="info">
                            <th class="table-header">Delivery Option</th>
                          </tr>
                        </thead>
                      </table> */}
            {/* /**
             * @author Narendra Phadke
             * @param  Description Display Campaign Details on Modal
             * @return Description return Campaign Details
             */}
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "20px",
                color: "#193d8f",
                fontWeight: "600",
              }}
            >
              Delivery Option :
            </div>
            <div
              class="card"
              style={{
                backgroundColor: "#f8f8f8",
                borderColor: "#707070",
                borderWidth: "0.3px",
              }}
            >
              <CardBody>
                {this.state.campaignBriefDetails.map((campaignBriefDetails) => (
                  <div class="row">
                    <div class="col-lg-4">
                      <table>
                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">Campaign Type</label>
                          </td>
                          <td>:&nbsp;{campaignBriefDetails.ABM}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "170px" }}>
                            <label id="label">Campaign Reporting Day</label>
                          </td>
                          <td>
                            :&nbsp;{campaignBriefDetails.campaignReportingDay}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "170px" }}>
                            <label id="label">Creative Approval</label>
                          </td>
                          {campaignBriefDetails.requiredLPApproval === "" ||
                          campaignBriefDetails.requiredLPApproval === null ||
                          campaignBriefDetails.requiredLPApproval ===
                            undefined ? (
                            <td>:&nbsp;No </td>
                          ) : (
                            <td>
                              {" "}
                              :&nbsp;
                              {campaignBriefDetails.requiredLPApproval[0] ===
                              ","
                                ? campaignBriefDetails.requiredLPApproval.slice(
                                    1
                                  )
                                : campaignBriefDetails.requiredLPApproval}
                            </td>
                          )}
                        </tr>
                        <tr>
                          <td style={{ width: "170px" }}>
                            <label id="label">Call Audit</label>
                          </td>
                          <td>
                            :&nbsp;
                            {campaignBriefDetails.callAudit === "undefined" ||
                            campaignBriefDetails.callAudit === "null" ||
                            campaignBriefDetails.callAudit === null ||
                            campaignBriefDetails.callAudit === undefined
                              ? ""
                              : campaignBriefDetails.callAudit}
                          </td>
                        </tr>
                      </table>
                    </div>

                    <div class="col-lg-4">
                      <table>
                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">Marketing Channel</label>
                          </td>
                          <td>
                            :&nbsp;{campaignBriefDetails.marketingChannel}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">Lead Delivery Option</label>
                          </td>
                          <td>
                            :&nbsp;{campaignBriefDetails.leadDeliveryOption}
                          </td>
                        </tr>

                        {campaignBriefDetails.requiredLPApproval === "" ? (
                          ""
                        ) : (
                          <tr>
                            <td style={{ width: "160px" }}>
                              <label id="label">
                                Creative Approval Timeline (Hours)
                              </label>
                            </td>
                            <td>:&nbsp;{campaignBriefDetails.lpTimeline}</td>
                          </tr>
                        )}

                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">Pacing Unit </label>
                          </td>
                          <td>
                            <span>:&nbsp;{this.state.pacingUnit}</span>
                          </td>
                        </tr>
                      </table>
                    </div>

                    <div class="col-lg-4">
                      <table>
                        {/* User Story : 3188 : Karan Jagtap : Sub-Contracting value shown */}
                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">Sub-Contracting</label>
                          </td>
                          {campaignBriefDetails.subContracting === undefined ||
                          campaignBriefDetails.subContracting === null ||
                          campaignBriefDetails.subContracting === "undefined" ||
                          campaignBriefDetails.subContracting === "null" ||
                          campaignBriefDetails.subContracting === "" ? (
                            <td>:</td>
                          ) : (
                            <td>
                              {" "}
                              :&nbsp;
                              {campaignBriefDetails.subContracting}
                            </td>
                          )}
                        </tr>
                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">Lead Interaction Days</label>
                          </td>
                          <td>
                            <span>
                              :&nbsp;
                              {/* kiran-4746-removing console warning-changed == to === */}
                              {campaignBriefDetails.leadInteractionDays ===
                                "" ||
                              campaignBriefDetails.leadInteractionDays ===
                                undefined
                                ? 0
                                : campaignBriefDetails.leadInteractionDays}
                            </span>
                          </td>
                        </tr>
                        {campaignBriefDetails.requiredLPApproval === "" ||
                        campaignBriefDetails.requiredLPApproval === null ||
                        campaignBriefDetails.requiredLPApproval ===
                          undefined ? (
                          ""
                        ) : (
                          <tr>
                            <td style={{ width: "170px" }}>
                              <label id="label">
                                Creative Approval Required For
                              </label>
                            </td>
                            <td>
                              :&nbsp;
                              {campaignBriefDetails.creativeApprovalRequiredFor}
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td style={{ width: "160px" }}>
                            {" "}
                            <label id="label">Pacing</label>
                          </td>
                          <td>:&nbsp;{campaignBriefDetails.pacing}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                ))}
                {this.state.pacingCampaignDetails.length > 0 ? (
                  <div>
                    <br />
                    <table class="table table-bordered">
                      <thead>
                        <tr class="info">
                          <th
                            style={{
                              color: "#707070",
                              backgroundColor: " rgba(126, 127, 129, 0.22)",
                            }}
                          >
                            Pacing Month
                          </th>
                          <th
                            style={{
                              color: "#707070",
                              backgroundColor: " rgba(126, 127, 129, 0.22)",
                            }}
                          >
                            Pacing Percentage
                          </th>
                          <th
                            style={{
                              color: "#707070",
                              backgroundColor: " rgba(126, 127, 129, 0.22)",
                            }}
                          >
                            Pacing Lead Count
                          </th>
                          <th
                            style={{
                              color: "#707070",
                              backgroundColor: " rgba(126, 127, 129, 0.22)",
                            }}
                          >
                            Pacing End Date
                          </th>
                          <th
                            style={{
                              color: "#707070",
                              backgroundColor: " rgba(126, 127, 129, 0.22)",
                            }}
                          >
                            Pacing carry forward date
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {this.state.pacingCampaignDetails.map(
                          (pacingCampaignDetails, i) => {
                            return (
                              <tr>
                                <td>
                                  <span>
                                    {pacingCampaignDetails.pacingMonth}
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {pacingCampaignDetails.pacingPercentage}%
                                  </span>
                                  {/* snehal-task-3598-Akshay sir-Campaign specification changes */}
                                </td>
                                <td>
                                  <span>
                                    {pacingCampaignDetails.pacingLeadCount}
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {pacingCampaignDetails.pacingEndDate}
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {pacingCampaignDetails.pacingCarryForward}
                                  </span>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  ""
                )}
              </CardBody>
            </div>

            {/* /*row 2 */}
            <br />

            {/* <table id="myTable" className=" table table-bordered">
                        <thead>
                          <tr class="info">
                            <th class="table-header">Campaign Specification</th>
                          </tr>
                        </thead>
                      </table> */}
            {/* /**
             * @author Narendra Phadke
             * @param  Description Display Campaign Details on Modal
             * @return Description return Campaign Details
             */}
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "20px",
                color: "#193d8f",
                fontWeight: "600",
              }}
            >
              Campaign Specification :
            </div>
            <div
              class="card"
              style={{
                backgroundColor: "#f8f8f8",
                borderColor: "#707070",
                borderWidth: "0.3px",
              }}
            >
              <CardBody>
                {/* User Story - 3428 - Karan Jagtap - changed layout to fit whole label text in 1 line */}
                <div className="row">
                  <div className="col">
                    <table>
                      {this.state.campaignBriefDetails.map(
                        (campaignBriefDetails) => (
                          <>
                            {/* Chaitanya-4909-added Non-English Campaign field */}
                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex",
                                }}
                              >
                                {/* rutuja task 5012 changed non -english campaign Name to Multilingual Campaign  */}
                                <label id="label">
                                  &nbsp;Multilingual Campaign 
                                </label>
                              </td>
                              <td>
                                <span class="word-wrap">
                                  :&nbsp;
                                  {campaignBriefDetails.nonEnglishCheck ===
                                  "Yes" ? (
                                    <td>Yes</td>
                                  ) : (
                                    <td>No</td>
                                  )}
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                }}
                              >
                                <label id="label">&nbsp;Region</label>
                              </td>
                              <td>:&nbsp;{campaignBriefDetails.region}</td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                }}
                              >
                                <label id="label">&nbsp;Country</label>
                              </td>
                              <td>
                                {/* // shivani-task 3121- added condition to remove error for country null values. */}
                                <span class="word-wrap">
                                  :&nbsp;
                                  {campaignBriefDetails.country === "null" ||
                                  campaignBriefDetails.country === null ||
                                  campaignBriefDetails.country === undefined ||
                                  campaignBriefDetails.country === "undefined"
                                    ? ""
                                    : unescape(campaignBriefDetails.country)}
                                </span>
                              </td>
                            </tr>
                            {/* //snehal-task-3793-geowise lead display none while rfp is geowise  */}
                            {/* kiran-4746-removing console warning-changed == to === */}
                            {campaignBriefDetails.biddingType === "Geowise" &&
                            campaignBriefDetails.requiredCountryWiseAllocation ===
                              "Yes" ? (
                              ""
                            ) : (
                              <tr>
                                <td
                                  style={{
                                    width: "170px",
                                    display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                  }}
                                >
                                  <label id="label">
                                    &nbsp;Lead Allocation by country
                                  </label>
                                </td>
                                <td>
                                  <span class="word-wrap">
                                    :&nbsp;
                                    {campaignBriefDetails.requiredCountryWiseAllocation ===
                                      "null" ||
                                    campaignBriefDetails.requiredCountryWiseAllocation ===
                                      null ||
                                    campaignBriefDetails.requiredCountryWiseAllocation ===
                                      undefined ||
                                    campaignBriefDetails.requiredCountryWiseAllocation ===
                                      "undefined"
                                      ? ""
                                      : unescape(
                                          campaignBriefDetails.requiredCountryWiseAllocation
                                        )}
                                  </span>
                                </td>
                              </tr>
                            )}
                          </>
                        )
                      )}
                    </table>
                    <div className="row">
                      {this.state.campaignBriefDetails.map(
                        (campaignBriefDetails) => (
                          <>
                            {/* snehal-task-3598-Akshay sir-Campaign specification changes(for zip code, city and state) */}
                            {/* snehal-task-3670-STC---agency side---edit camp---zip code, state, city file issue*/}
                            {/* kiran-4746-removing console warning-changed != to !== */}
                            {campaignBriefDetails.stateFileName !== "" &&
                            campaignBriefDetails.state === "Yes" ? (
                              <div className="col-lg-4">
                                {/* User Story - 3428 - Karan Jagtap - top alignment */}
                                <table>
                                  <tr>
                                    <td
                                      style={{
                                        width: "170px",
                                        display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                      }}
                                    >
                                      <label id="label">&nbsp;State</label>
                                    </td>
                                    <td>
                                      <span class="word-wrap">
                                        :&nbsp;
                                        {campaignBriefDetails.stateFileName}
                                      </span>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            ) : (
                              ""
                            )}
                            {campaignBriefDetails.cityFileName !== "" &&
                            campaignBriefDetails.city === "Yes" ? (
                              <div className="col-lg-4">
                                <table>
                                  <tr>
                                    <td
                                      style={{
                                        width: "170px",
                                        display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                      }}
                                    >
                                      <label id="label">&nbsp;City</label>
                                    </td>
                                    <td>
                                      <span class="word-wrap">
                                        :&nbsp;
                                        {campaignBriefDetails.cityFileName}
                                      </span>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            ) : (
                              ""
                            )}
                            {campaignBriefDetails.zipCodeFileName !== "" &&
                            campaignBriefDetails.zipCode === "Yes" ? (
                              <div className="col-lg-4">
                                <table>
                                  <tr>
                                    <td
                                      style={{
                                        width: "170px",
                                        display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                      }}
                                    >
                                      <label id="label">&nbsp;Zip Code</label>
                                    </td>
                                    <td>
                                      <span class="word-wrap">
                                        :&nbsp;
                                        {campaignBriefDetails.zipCodeFileName}
                                      </span>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        )
                      )}
                    </div>
                    <table>
                      {this.state.campaignBriefDetails.map(
                        (campaignBriefDetails) => (
                          <>
                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                }}
                              >
                                <label id="label">&nbsp;Job Level</label>
                              </td>
                              <td>
                                <span class="word-wrap">
                                  :&nbsp;
                                  {campaignBriefDetails.jobLevel === "|"
                                    ? " "
                                    : campaignBriefDetails.jobLevel}
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                }}
                              ></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td style={{ width: "170px" }}>
                                <label id="label">
                                  &nbsp;Job &nbsp;Function
                                </label>
                              </td>
                              <td>
                                <span class="word-wrap">
                                  :&nbsp;
                                  {campaignBriefDetails.jobFunction === "|"
                                    ? ""
                                    : campaignBriefDetails.jobFunction}
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                }}
                              >
                                <label id="label">&nbsp;Job Title</label>
                              </td>
                              <td>
                                <span class="word-wrap">
                                  :&nbsp;{campaignBriefDetails.jobTitle}
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                }}
                              >
                                <label id="label">&nbsp;Industry</label>
                              </td>
                              {/**shivani-task 3081-added code to reflect industry and custome industry values in speciffication.**/}
                              <td>
                                :&nbsp;
                                {/* kiran-4746-removing console warning-changed == to === */}
                                {/* Chaitanya-4878- added condition for if industry is NA */}
                                {(campaignBriefDetails.industry === "" &&
                                  campaignBriefDetails.customIndustry === "") ||
                                campaignBriefDetails.industry === "NA" ? (
                                  <span class="word-wrap">Not Applicable</span>
                                ) : campaignBriefDetails.customIndustry ===
                                  "" ? (
                                  <span class="word-wrap">
                                    {campaignBriefDetails.industry}
                                  </span>
                                ) : campaignBriefDetails.industry === "" ? (
                                  <span class="word-wrap">
                                    {campaignBriefDetails.customIndustry}
                                  </span>
                                ) : (
                                  <span class="word-wrap">
                                    {/* // shivani-task 3121- added condition to remove error for industry and customeindustry null values.                           */}
                                    {
                                      // Chaitanya-4893- Commented condition for custom Industry size is "null"
                                      // campaignBriefDetails.customIndustry ===
                                      //   "null" ||
                                      campaignBriefDetails.customIndustry ===
                                        null ||
                                      campaignBriefDetails.customIndustry ===
                                        undefined ||
                                      campaignBriefDetails.customIndustry ===
                                        "undefined" ||
                                      campaignBriefDetails.industry ===
                                        "null" ||
                                      campaignBriefDetails.industry === null ||
                                      campaignBriefDetails.industry ===
                                        undefined ||
                                      campaignBriefDetails.industry ===
                                        "undefined"
                                        ? ""
                                        : unescape(
                                            campaignBriefDetails.industry
                                          ) +
                                          "|" +
                                          campaignBriefDetails.customIndustry
                                    }
                                  </span>
                                )}
                              </td>
                            </tr>
                            {/* snehal-task-3647-Industry Exclusion on DI - FE */}
                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                }}
                              >
                                <label id="label">
                                  &nbsp;Industry &nbsp;Exclusion
                                </label>
                              </td>
                              <td>
                                :&nbsp;{" "}
                                {/* snehal-task-3667-ref(3647)--Industry file--check box issue (changes in comparison)*/}
                                {campaignBriefDetails.excludedIndustryFlag ===
                                "Yes" ? (
                                  <span className="word-wrap">
                                    {
                                      campaignBriefDetails.excludedIndustryFileName
                                    }
                                  </span>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                }}
                              >
                                <label id="label">
                                  &nbsp;Employee &nbsp;Size
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {/* Chaitanya-4878- added condition for if Employee size is NA */}
                                {(campaignBriefDetails.employeeSize === "" &&
                                  campaignBriefDetails.customEmpSize === "") ||
                                campaignBriefDetails.employeeSize === "NA" ? (
                                  <span class="word-wrap">
                                    Not Applicable
                                    {/* // shivani-task 3121- added condition to remove error for customEmpSize and employeeSize null values. */}
                                  </span>
                                ) : (
                                  <span class="word-wrap">
                                    {
                                      // Chaitanya-4878- Commented condition for custom employee size is "null"
                                      // campaignBriefDetails.customEmpSize ===
                                      // 	"null" ||
                                      campaignBriefDetails.customEmpSize ===
                                        null ||
                                      campaignBriefDetails.customEmpSize ===
                                        undefined ||
                                      campaignBriefDetails.customEmpSize ===
                                        "undefined" ||
                                      campaignBriefDetails.employeeSize ===
                                        "null" ||
                                      campaignBriefDetails.employeeSize ===
                                        null ||
                                      campaignBriefDetails.employeeSize ===
                                        undefined ||
                                      campaignBriefDetails.employeeSize ===
                                        "undefined"
                                        ? ""
                                        : campaignBriefDetails.employeeSize
                                            .length > 0 &&
                                          campaignBriefDetails.customEmpSize
                                        ? unescape(
                                            campaignBriefDetails.employeeSize
                                          ) +
                                          "|" +
                                          campaignBriefDetails.customEmpSize
                                        : unescape(
                                            campaignBriefDetails.employeeSize
                                          ) +
                                          "" +
                                          campaignBriefDetails.customEmpSize
                                    }
                                  </span>
                                  //  snehal-task-3343----edit campaign---employee size, company revenue
                                )}
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                }}
                              >
                                <label id="label">
                                  &nbsp;Company &nbsp;Revenue
                                </label>
                              </td>
                              <td>
                                {/* campaignBriefDetails.companyRevenue+','+campaignBriefDetails.customCompRevenue */}
                                <span class="word-wrap">
                                  :&nbsp;
                                  {campaignBriefDetails.companyRevenue +
                                    campaignBriefDetails.customCompRevenue ===
                                  "|"
                                    ? ""
                                    : campaignBriefDetails.companyRevenue
                                        .length === 0 &&
                                      campaignBriefDetails.customCompRevenue
                                        .length > 0
                                    ? campaignBriefDetails.companyRevenue +
                                      campaignBriefDetails.customCompRevenue
                                    : campaignBriefDetails.companyRevenue
                                        .length > 0 &&
                                      campaignBriefDetails.customCompRevenue
                                        .length > 0
                                    ? campaignBriefDetails.companyRevenue +
                                      "|" +
                                      campaignBriefDetails.customCompRevenue
                                    : campaignBriefDetails.companyRevenue +
                                      campaignBriefDetails.customCompRevenue}
                                </span>
                                {/* //  snehal-task-3343----edit campaign---employee size, company revenue */}
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                }}
                              >
                                <label id="label">&nbsp;Other Specs</label>
                              </td>
                              <td>
                                {/* // shivani-task 3121- added condition to remove error for otherSpecs null values. */}
                                <span class="word-wrap">
                                  :&nbsp;
                                  {campaignBriefDetails.otherSpecs === "null" ||
                                  campaignBriefDetails.otherSpecs === null ||
                                  campaignBriefDetails.otherSpecs ===
                                    undefined ||
                                  campaignBriefDetails.otherSpecs ===
                                    "undefined"
                                    ? ""
                                    : unescape(campaignBriefDetails.otherSpecs)}
                                </span>
                              </td>
                            </tr>
                          </>
                        )
                      )}
                    </table>
                  </div>
                </div>
              </CardBody>
            </div>

            <br />
            {/* //snehal-task-3793-geowise lead display none while rfp is geowise  */}
            {/* kiran-4746-removing console warning-changed != to !== */}
            {this.state.requiredCountryWiseAllocation === "Yes" &&
            this.state.campaignBriefDetails[0].biddingType !== "Geowise" ? (
              <div>
                <div
                  style={{
                    fontFamily: "roboto",
                    fontSize: "20px",
                    color: "#193d8f",
                    fontWeight: "600",
                  }}
                >
                  Lead Allocation by country:
                </div>
                <div
                  class="card"
                  style={{
                    backgroundColor: "#f8f8f8",
                    borderColor: "#707070",
                    borderWidth: "0.3px",
                  }}
                >
                  <CardBody>
                    <table class="col-md-5 col-lg-5 ">
                      <table class="table  table-bordered">
                        <thead>
                          <tr class="info">
                            <th
                              style={{
                                color: "#707070",
                                backgroundColor: " rgba(126, 127, 129, 0.22)",
                              }}
                            >
                              Country Name
                            </th>
                            <th
                              style={{
                                color: "#707070",
                                backgroundColor: " rgba(126, 127, 129, 0.22)",
                              }}
                            >
                              Lead Allocation
                            </th>
                            <th
                              style={{
                                color: "#707070",
                                backgroundColor: " rgba(126, 127, 129, 0.22)",
                              }}
                            >
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.countryWiseAllocationArray.map((item) => (
                            <tr>
                              {item.status === "Removed" ? (
                                <td style={{ color: "red" }}>{item.country}</td>
                              ) : (
                                <td>{item.country}</td>
                              )}
                              {item.status === "Removed" ? (
                                <td style={{ color: "red" }}>{item.lead}</td>
                              ) : (
                                <td>{item.lead}</td>
                              )}
                              {item.status === "Removed" ? (
                                <td style={{ color: "red" }}>{item.status}</td>
                              ) : (
                                <td>{item.status}</td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </table>
                    <br />
                  </CardBody>
                </div>
              </div>
            ) : (
              ""
            )}
            {/* <table id="myTable" className=" table table-bordered">
                           <thead>
                             <tr class="info">
                               <th class="table-header">Supporting Document</th>
                             </tr>
                           </thead>
                         </table>  */}
            {/* /**
             * @author Narendra Phadke
             * @param  Description Display Campaign Supporting Document Asset Details on Modal
             * @return Description return Campaign Details
             */}
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "20px",
                color: "#193d8f",
                fontWeight: "600",
              }}
            >
              Supporting Document :
            </div>
            <div
              class="card"
              style={{
                backgroundColor: "#f8f8f8",
                borderColor: "#707070",
                borderWidth: "0.3px",
              }}
            >
              <CardBody>
                <div class="row">
                  <div class="col-sm-12">
                    {" "}
                    <label id="label" style={{ width: "80px" }}>
                      ABM
                    </label>
                    :&nbsp;
                    {this.state.campaignBriefSupportingDocumentAbmDetails.map(
                      (campaignBriefSupportingDocumentAbmDetails) => (
                        <span className="word-wrap">
                          {
                            campaignBriefSupportingDocumentAbmDetails.suppDocName
                          }
                          |
                        </span>
                      )
                    )}
                  </div>
                  <div class="col-sm-12">
                    <label id="label">Suppression</label>&nbsp;:&nbsp;
                    {/* {this.state.campaignBriefSupportingDocumentSuppresionDetails.length>0?
                                <div class="col-md-12 col-lg-12 ">
                                <table class="table table-responsive table-bordered">
                                  <thead>
                                    <tr class="info">
                                      <th class="table-header">Suppression ID</th>
                                      <th class="table-header" >Suppression Name</th>
                                      {this.state.isSuppLink===true?
                                      <th class="table-header">Suppression Link</th>:''}
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {this.state.campaignBriefSupportingDocumentSuppresionDetails.map(
                              suppressionList => (
                                    <tr>
                                      <td>{suppressionList.supportDocID}</td>
                                      <td>{suppressionList.suppDocName}</td>
                                      {this.state.isSuppLink===true?
                                      <td style={{wordBreak:'break-all'}}><a href= {suppressionList.assetLink} target="_blank" >
                                      {suppressionList.assetLink}
                                  </a></td>:''}
                                    </tr>
                                    ))}
                                  </tbody>
                                </table>
                               </div>
                             :''}{this.state.isSuppLink===true?
                              <div class="float-right"><button type="button" class="btn btn-primary btn-xs" onClick={this.openAllSuppLink.bind(this)}>
                             Open All
                           </button></div>:''} */}
                    {this.state.campaignBriefSupportingDocumentSuppresionDetails.map(
                      (campaignBriefSupportingDocumentSuppresionDetails) => (
                        <span className="word-wrap">
                          {
                            campaignBriefSupportingDocumentSuppresionDetails.suppDocName
                          }
                          |
                        </span>
                      )
                    )}
                  </div>
                  <div class="col-sm-12">
                    <label id="label" style={{ width: "80px" }}>
                      Execlusion
                    </label>
                    :&nbsp;
                    {this.state.campaignBriefSupportingDocumentExclusionDetails.map(
                      (campaignBriefSupportingDocumentExclusionDetails) => (
                        <span className="word-wrap">
                          {
                            campaignBriefSupportingDocumentExclusionDetails.suppDocName
                          }
                          |
                        </span>
                      )
                    )}
                  </div>
                  <div class="col-sm-12">
                    <label id="label" style={{ width: "80px" }}>
                      Other
                    </label>
                    :&nbsp;
                    {this.state.campaignBriefSupportingDocumentOtherDetails.map(
                      (campaignBriefSupportingDocumentOtherDetails) => (
                        <span className="word-wrap">
                          {
                            campaignBriefSupportingDocumentOtherDetails.suppDocName
                          }
                          |
                        </span>
                      )
                    )}
                  </div>
                  {/* snehal-task-3620-Akshay Sir- Add exclude domains like .gov, .mil- Campaign to lead validation --> FE */}
                  {/* kiran-4746-removing console warning-changed != to !== */}
                  {this.state.campaignBriefDetails.excludedDomain !== "" ||
                  this.state.campaignBriefDetails.excludedDomain != null ||
                  this.state.campaignBriefDetails.excludedDomain !== "null" ||
                  this.state.campaignBriefDetails.excludedDomain !==
                    undefined ||
                  this.state.campaignBriefDetails.excludedDomain !==
                    "undefined" ? (
                    <div class="col-sm-12">
                      <label id="label">Exclude Domain Extension :</label>
                      {this.state.campaignBriefDetails.map(
                        (campaignBriefDetails) => (
                          <span className="word-wrap">
                            {campaignBriefDetails.excludedDomain}
                          </span>
                        )
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </CardBody>
            </div>

            {/*row 4 */}
            <br />
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "20px",
                color: "#193d8f",
                fontWeight: "600",
              }}
            >
              Campaign Asset :
            </div>
            <div
              class="card"
              style={{
                backgroundColor: "#f8f8f8",
                borderColor: "#707070",
                borderWidth: "0.3px",
              }}
            >
              {/* <table id="myTable" className=" table table-bordered">
                          <thead>
                            <tr class="info">
                              <th class="table-header">Campaign Asset</th>
                            </tr>
                          </thead>
                        </table> */}
              {/* /**
               * @author Narendra Phadke
               * @param  Description Display Campaign Supporting Document Asset Details on Modal
               * @return Description return Campaign Details
               */}
              <CardBody>
                <div class="row">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <label id="label">&nbsp;&nbsp;&nbsp;Assets File :</label>
                  <div class="col-sm-12">
                    <div class="col-md-12 col-lg-12 ">
                      <table class="table  table-bordered">
                        <thead>
                          <tr class="info">
                            <th
                              style={{
                                color: "#707070",
                                backgroundColor: " rgba(126, 127, 129, 0.22)",
                              }}
                            >
                              Asset ID
                            </th>
                            {this.state.requiredLeadPerAsset === "Yes" ? (
                              <th
                                style={{
                                  color: "#707070",
                                  backgroundColor: " rgba(126, 127, 129, 0.22)",
                                }}
                              >
                                Lead Percentage (%)
                              </th>
                            ) : (
                              ""
                            )}
                            {this.state.requiredLeadPerAsset === "Yes" ? (
                              <th
                                style={{
                                  color: "#707070",
                                  backgroundColor: " rgba(126, 127, 129, 0.22)",
                                }}
                              >
                                Lead Per Asset
                              </th>
                            ) : (
                              ""
                            )}
                            {this.state.multiTouch === "Yes" ? (
                              <th
                                style={{
                                  color: "#707070",
                                  backgroundColor: " rgba(126, 127, 129, 0.22)",
                                }}
                              >
                                Multi Touch
                              </th>
                            ) : (
                              ""
                            )}
                            <th
                              style={{
                                color: "#707070",
                                backgroundColor: " rgba(126, 127, 129, 0.22)",
                              }}
                            >
                              Asset Name
                            </th>
                            {/* <th class="table-header">Asset Name</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.assetFileList.map((assetFileList) =>
                            assetFileList.assetStatus === "Removed" ? (
                              <tr>
                                <td>
                                  <span
                                    style={{
                                      color: "red",
                                      textDecoration: "line-through",
                                    }}
                                  >
                                    {assetFileList.supportDocID}
                                  </span>
                                </td>
                                {this.state.requiredLeadPerAsset === "Yes" ? (
                                  <td>
                                    <span
                                      style={{
                                        color: "red",
                                        textDecoration: "line-through",
                                      }}
                                    >
                                      {assetFileList.leadPercentage}%
                                    </span>
                                    {/* snehal-task-3598-Akshay sir-Campaign specification changes */}
                                  </td>
                                ) : (
                                  ""
                                )}
                                {this.state.requiredLeadPerAsset === "Yes" ? (
                                  <td>
                                    <span
                                      style={{
                                        color: "red",
                                        textDecoration: "line-through",
                                      }}
                                    >
                                      {assetFileList.leadPerAsset}
                                    </span>
                                  </td>
                                ) : (
                                  ""
                                )}
                                {this.state.multiTouch === "Yes" ? (
                                  <td>
                                    <span
                                      style={{
                                        color: "red",
                                        textDecoration: "line-through",
                                      }}
                                    >
                                      {assetFileList.multiTouch}
                                    </span>
                                  </td>
                                ) : (
                                  ""
                                )}
                                <td>
                                  <span
                                    style={{
                                      color: "red",
                                      textDecoration: "line-through",
                                    }}
                                  >
                                    {assetFileList.suppDocName}
                                  </span>
                                </td>
                              </tr>
                            ) : (
                              <tr>
                                <td>{assetFileList.supportDocID}</td>
                                {this.state.requiredLeadPerAsset === "Yes" ? (
                                  <td>
                                    {assetFileList.leadPercentage}%
                                  </td> /* snehal-task-3598-Akshay sir-Campaign specification changes */
                                ) : (
                                  ""
                                )}
                                {this.state.requiredLeadPerAsset === "Yes" ? (
                                  <td>{assetFileList.leadPerAsset}</td>
                                ) : (
                                  ""
                                )}
                                {this.state.multiTouch === "Yes" ? (
                                  <td>{assetFileList.multiTouch}</td>
                                ) : (
                                  ""
                                )}
                                <td>{assetFileList.suppDocName}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>

                    {this.state.assetLinkArray.length > 0 ? (
                      <p>
                        <label id="label">
                          &nbsp;&nbsp;&nbsp;&nbsp;Assets link :
                        </label>
                        <br />

                        <div>
                          <div class="col-md-12 col-lg-12 ">
                            <table class="table  table-bordered">
                              <thead>
                                <tr class="info">
                                  <th
                                    style={{
                                      color: "#707070",
                                      backgroundColor:
                                        " rgba(126, 127, 129, 0.22)",
                                    }}
                                  >
                                    Asset ID
                                  </th>
                                  {/* kiran-4746-removing console warning-changed != to !== */}
                                  {(this.state.requiredLeadPerAsset != null ||
                                    this.state.requiredLeadPerAsset !==
                                      "undefined") &&
                                  this.state.requiredLeadPerAsset === "Yes" ? (
                                    <th
                                      style={{
                                        color: "#707070",
                                        backgroundColor:
                                          " rgba(126, 127, 129, 0.22)",
                                      }}
                                    >
                                      Lead Percentage (%)
                                    </th>
                                  ) : (
                                    ""
                                  )}
                                  {(this.state.requiredLeadPerAsset !== null ||
                                    this.state.requiredLeadPerAsset !==
                                      "undefined") &&
                                  this.state.requiredLeadPerAsset === "Yes" ? (
                                    <th
                                      style={{
                                        color: "#707070",
                                        backgroundColor:
                                          " rgba(126, 127, 129, 0.22)",
                                      }}
                                    >
                                      Lead Per Asset
                                    </th>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.multiTouch !== null &&
                                  this.state.multiTouch === "Yes" ? (
                                    <th
                                      style={{
                                        color: "#707070",
                                        backgroundColor:
                                          " rgba(126, 127, 129, 0.22)",
                                      }}
                                    >
                                      Multi Touch
                                    </th>
                                  ) : (
                                    ""
                                  )}
                                  <th
                                    style={{
                                      color: "#707070",
                                      backgroundColor:
                                        " rgba(126, 127, 129, 0.22)",
                                    }}
                                  >
                                    Asset Name
                                  </th>
                                  <th
                                    style={{
                                      color: "#707070",
                                      backgroundColor:
                                        " rgba(126, 127, 129, 0.22)",
                                    }}
                                  >
                                    Asset Link
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.linkListArray.map((linkListArray) =>
                                  linkListArray.assetStatus === "Removed" ? (
                                    <tr>
                                      <td>
                                        <span
                                          style={{
                                            color: "red",
                                            textDecoration: "line-through",
                                          }}
                                        >
                                          {linkListArray.supportDocID}
                                        </span>
                                      </td>
                                      {this.state.campaignBriefDetails[0]
                                        .requiredLeadPerAsset === "Yes" ? (
                                        <td>
                                          <span
                                            style={{
                                              color: "red",
                                              textDecoration: "line-through",
                                            }}
                                          >
                                            {linkListArray.leadPercentage}%
                                          </span>
                                          {/* snehal-task-3598-Akshay sir-Campaign specification changes */}
                                        </td>
                                      ) : (
                                        ""
                                      )}
                                      {this.state.campaignBriefDetails[0]
                                        .requiredLeadPerAsset === "Yes" ? (
                                        <td>
                                          <span
                                            style={{
                                              color: "red",
                                              textDecoration: "line-through",
                                            }}
                                          >
                                            {linkListArray.leadPerAsset}
                                          </span>
                                        </td>
                                      ) : (
                                        ""
                                      )}
                                      {this.state.campaignBriefDetails[0]
                                        .multiTouch === "Yes" ? (
                                        <td>
                                          <span
                                            style={{
                                              color: "red",
                                              textDecoration: "line-through",
                                            }}
                                          >
                                            {linkListArray.multiTouch}
                                          </span>
                                        </td>
                                      ) : (
                                        ""
                                      )}
                                      <td>
                                        <span
                                          style={{
                                            color: "red",
                                            textDecoration: "line-through",
                                          }}
                                        >
                                          {linkListArray.suppDocName}
                                        </span>
                                      </td>
                                      <td>
                                        {/* kiran-4746-removing console warning-added rel="noopener noreferrer" */}
                                        <a
                                          href={linkListArray.assetLink}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <span
                                            style={{
                                              color: "red",
                                              textDecoration: "line-through",
                                            }}
                                          >
                                            {linkListArray.assetLink}
                                          </span>{" "}
                                        </a>
                                      </td>
                                    </tr>
                                  ) : (
                                    <tr>
                                      <td>{linkListArray.supportDocID}</td>
                                      {this.state.campaignBriefDetails[0]
                                        .requiredLeadPerAsset === "Yes" ? (
                                        <td>
                                          {linkListArray.leadPercentage}%
                                        </td> /* snehal-task-3598-Akshay sir-Campaign specification changes */
                                      ) : (
                                        ""
                                      )}
                                      {this.state.campaignBriefDetails[0]
                                        .requiredLeadPerAsset === "Yes" ? (
                                        <td>{linkListArray.leadPerAsset}</td>
                                      ) : (
                                        ""
                                      )}
                                      {this.state.campaignBriefDetails[0]
                                        .multiTouch === "Yes" ? (
                                        <td>{linkListArray.multiTouch}</td>
                                      ) : (
                                        ""
                                      )}
                                      <td>{linkListArray.suppDocName}</td>
                                      <td>
                                        {/* kiran-4746-removing console warning-added rel="noopener noreferrer" */}
                                        <a
                                          href={linkListArray.assetLink}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          onClick={this.openAssetLink.bind(
                                            this
                                          )}
                                          assetLink={linkListArray.assetLink}
                                        >
                                          {linkListArray.assetLink}
                                        </a>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                            <div class="float-right">
                              <button
                                type="button"
                                class="btn btn-primary btn-xs"
                                onClick={this.openAllLink.bind(this)}
                              >
                                Open All
                              </button>
                            </div>
                          </div>
                        </div>
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </CardBody>
            </div>

            {/*row 4 */}
            <br />

            {/*row 5 */}

            {/* <table id="myTable" className=" table table-bordered">
                          <thead>
                            <tr class="info">
                              <th class="table-header">Lead Delivery Through API</th>
                            </tr>
                          </thead>
                        </table> */}

            <div
              style={{
                fontFamily: "roboto",
                fontSize: "20px",
                color: "#193d8f",
                fontWeight: "600",
              }}
            >
              Lead Delivery Through API :
            </div>
            <div
              class="card"
              style={{
                backgroundColor: "#f8f8f8",
                borderColor: "#707070",
                borderWidth: "0.3px",
              }}
            >
              <CardBody>
                <div class="word-wrap">
                  <label>URL : </label>&nbsp;{this.state.deliveryURL}
                  {this.state.customQuestionURL}
                </div>
                <br />
              </CardBody>
            </div>
            <br />
            {/* 
                        {this.state.campaignBriefDetails.map(
                          campaignBriefDetails => (
                        <table id="myTable" className=" table table-bordered">
                          <thead>
                            <tr class="info">
                              <th class="table-header" style={{width:'1156px'}}>Delivery Format</th>
                            
                            </tr>
                          </thead>
                        </table>
                          ))} */}
            {/* /**
             * @author Narendra Phadke
             * @param  Description Display Campaign Delivery Details on Modal
             * @return Description return Campaign Delivery Details
             */}
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "20px",
                color: "#193d8f",
                fontWeight: "600",
              }}
            >
              Delivery Format :
            </div>

            {/* {this.state.campaignBriefDetails[0].publisherStatus=="Accept"||this.state.campaignBriefDetails[0].publisherStatus=="LP_Pending"||this.state.campaignBriefDetails[0].publisherStatus=="Paused"||this.state.campaignBriefDetails[0].publisherStatus=="Completed"||this.state.campaignBriefDetails[0].publisherStatus=="PausedIncomplete"||this.state.campaignBriefDetails[0].publisherStatus=="Live_Incomplete"? */}
            {/* <div class="table-header add-button btn" style={{float:'right',cursor:'pointer'}} onClick={this.showMappin}>Customized Mapping</div> */}
            {/* :''} */}
            <div
              class="card"
              style={{
                backgroundColor: "#f8f8f8",
                borderColor: "#707070",
                borderWidth: "0.3px",
              }}
            >
              <CardBody>
                {/* User Story - 3428 - Karan Jagtap - Reformating of Delivery format - Start */}
                {/* As this whole block of "Delivery Format" is re-arranged, but the data other than styling is not changed.
								Hence, I've still tried to maintain the comments given by other resources for their respective tasks inside this block  */}
                <div className="row">
                  {this.state.campaignBriefDeliveryDetails.map(
                    (campaignBriefDeliveryDetails) => (
                      <>
                        <div className="col-lg-3">
                          <table>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Publisher ID</label>
                              </td>
                              <td>:&nbsp;{campaignBriefDeliveryDetails.pID}</td>
                            </tr>

                            <tr>
                              <td>
                                <label id="label">
                                  &nbsp;Lead interaction date
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {
                                  campaignBriefDeliveryDetails.leadInteractionDate
                                }
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <label id="label">&nbsp;Work phone</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.workPhone}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Industry</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.industry}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">
                                  &nbsp;LinkedIn job title
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.linkedInJobTitle}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Country</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.country}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Address</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.address}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">
                                  &nbsp;Asset name touch 1
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.assetNameTouch1 ===
                                "Yes"
                                  ? campaignBriefDeliveryDetails.assetNameTouch1
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;LinkedIn</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.linkedIn}
                              </td>
                            </tr>
                            {/* kiran-4746-removing console warning-changed == to === */}
                            {this.state.campaignBriefDetails.length > 0 ? (
                              (this.state.campaignBriefDetails[0]
                                .marketingChannel === "Email/Telemarketing" ||
                                this.state.campaignBriefDetails[0]
                                  .marketingChannel === "TeleMarketing") &&
                              (campaignBriefDeliveryDetails.channel === "yes" ||
                                campaignBriefDeliveryDetails.channel ===
                                  "Yes") ? (
                                <tr>
                                  <td>
                                    <label id="label">&nbsp;Channel</label>
                                  </td>
                                  <td>:&nbsp;Yes</td>
                                </tr>
                              ) : (
                                <tr>
                                  <td>
                                    <label id="label">&nbsp;Channel</label>
                                  </td>
                                  <td>:&nbsp;No</td>
                                </tr>
                              )
                            ) : (
                              ""
                            )}
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 4</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra4}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 8</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra8}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 12</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra12}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 16</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra16}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 20</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra20}
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div className="col-lg-3">
                          <table>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Campaign ID</label>
                              </td>
                              <td>
                                :&nbsp;{campaignBriefDeliveryDetails.campaignID}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;First name</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.firstName}
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <label id="label">
                                  &nbsp;Alternate phone no
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.alternatePhoneNo}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">
                                  &nbsp;Company employee size
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {
                                  campaignBriefDeliveryDetails.companyEmployeeSize
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">
                                  &nbsp;Job title validation
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {
                                  campaignBriefDeliveryDetails.jobTitleValidation
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;State</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.state}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Street</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.street}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">
                                  &nbsp;Asset name touch 2
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.assetNameTouch2 ===
                                "Yes"
                                  ? campaignBriefDeliveryDetails.assetNameTouch2
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Domain</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.domain}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 1</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra1}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 5</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra5}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 9</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra9}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 13</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra13}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 17</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra17}
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div className="col-lg-3">
                          <table>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Campaign name</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.campaignName}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Last name</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.lastName}
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <label id="label">&nbsp;Company name</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.companyName}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Company revenue</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.companyRevenue}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Job level</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.jobLevel}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;City</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.city}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Asset name</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.assetNameTouch1 ===
                                "Yes"
                                  ? "No"
                                  : campaignBriefDeliveryDetails.assetName}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">
                                  &nbsp;Asset name touch 3
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.assetNameTouch3 ===
                                "Yes"
                                  ? campaignBriefDeliveryDetails.assetNameTouch3
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;IP</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.ip}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 2</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra2}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 6</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra6}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 10</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra10}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 14</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra14}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 18</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra18}
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div className="col-lg-3">
                          <table>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Allocation ID</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.reAllocationID}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Email</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.email}
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <label id="label">
                                  &nbsp;LinkedIn company name
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {
                                  campaignBriefDeliveryDetails.linkedInCompanyName
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Job title</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.jobTitle}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Job function</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.jobFunction}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Zip code</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.zipCode}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Asset ID</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.supportDocID}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">
                                  &nbsp;Customize mapping
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.customizeMapping ===
                                "Yes"
                                  ? campaignBriefDeliveryDetails.customizeMapping
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Comments</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.comments}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 3</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra3}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 7</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra7}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 11</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra11}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 15</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra15}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 19</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra19}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </>
                    )
                  )}
                </div>
                  {/* Aman-5281-added to display setected extra filed 21-30  in Delivery Format*/}
                  {this.state.extraAddedFieldsYes.length >= 1 ? (
                  <div style={{marginTop:"20px"}}>
                  <hr style={{borderColor:"#000"}}/>
                  <div
                        style={{
                        fontFamily: "roboto",
                        fontSize: "15px",
                        color: "#193d8f",
                        fontWeight: "600",
                      }}
                  >Additional Fields</div>
                    {this.state.extraAddedFieldsYes.map((details, index) => (
                      <div key={index} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: '20px' }}>
                        {Object.entries(details).map(([key, value]) => (
                          <div key={key} style={{ display: 'flex', marginRight: '80px' }}>
                            <label id="label">&nbsp;{key}</label>
                            <span>:&nbsp;{value}</span>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                ) : ("")} 
                {/* User Story - 3428 - Karan Jagtap - Reformating of Delivery format - End */}

                {/* <div class="col-sm-4">
                                {" "}

                                {(this.state.campaignBriefDetails[0].marketingChannel==='Email/Telemarketing')&&
                                (campaignBriefDeliveryDetails.channel=="Yes"||campaignBriefDeliveryDetails.channel=="yes")? 

                                 <tr>
                                    <td style={{width:'160px'}}>
                                    <label id="label"style={{width:'160px'}}>Channel:</label>
                                    </td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Yes</td>
                                 </tr>:''
                                 }
                                </div>  */}
              </CardBody>
            </div>
            <br />
            {this.state.showpacing === "Yes" ? (
              <div
                style={{
                  fontFamily: "roboto",
                  fontSize: "20px",
                  color: "#193d8f",
                  fontWeight: "600",
                }}
              >
                Customized Column Mapping : &nbsp;
                {this.state.customizeMappingFormat}
                {/* kiran-4884-displaying format */}
              </div>
            ) : (
              ""
            )}
            {this.state.showpacing === "Yes" ? (
              <div
                class="card"
                style={{
                  backgroundColor: "#f8f8f8",
                  borderColor: "#707070",
                  borderWidth: "0.3px",
                }}
              >
                <CardBody>
                  <div
                    class="container-fluid"
                    style={{ paddingBottom: "11px" }}
                  >
                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3"></div>
                      <table
                        class="table table-bordered"
                        id="myTable3"
                        style={{ width: "60%" }}
                      >
                        <thead>
                          <tr style={{ height: "35px" }}>
                            <th
                              style={{
                                color: "#707070",
                                backgroundColor: " rgba(126, 127, 129, 0.22)",
                                width: "450px",
                              }}
                            >
                              System Field{" "}
                            </th>
                            <th
                              style={{
                                color: "#707070",
                                backgroundColor: " rgba(126, 127, 129, 0.22)",
                                width: "900px",
                              }}
                            >
                              Mapping Alias Field{" "}
                            </th>
                            <th
                              style={{
                                color: "#707070",
                                backgroundColor: " rgba(126, 127, 129, 0.22)",
                                width: "200px",
                              }}
                            >
                              Values
                            </th>
                          </tr>
                        </thead>
                        {/* kiran-4746-removing console warning-changed == to === for  campaignBriefDeliveryDetails */}
                        {this.state.campaignBriefDeliveryDetails.map(
                          (campaignBriefDeliveryDetails, i) => {
                            return (
                              <tbody>
                                {campaignBriefDeliveryDetails.pID === "yes" ||
                                campaignBriefDeliveryDetails.pID === "Yes" ? (
                                  <tr>
                                    <td>&nbsp;Publisher ID </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Publisher ID</b>
                                        ) : (
                                          this.state.customizedColumns[0].pID
                                        )}
                                      </b>{" "}
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.campaignID ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.campaignID ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Campaign ID </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Campaign ID</b>
                                        ) : (
                                          this.state.customizedColumns[0].campID
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.reAllocationID ===
                                  "yes" ||
                                campaignBriefDeliveryDetails.reAllocationID ===
                                  "Yes" ? (
                                  <tr>
                                    <td>&nbsp;Allocation ID </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Allocation ID</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .reAllocationID
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.leadInteractionDate ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.leadInteractionDate ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Lead Interaction Date </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Lead Interaction Date</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .leadInteractionDate
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {/* Sandeep-task-3158-added code for campaign name field */}
                                {campaignBriefDeliveryDetails.campaignName ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.campaignName ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Campaign Name </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Campaign Name</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .campaignName
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.firstName ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.firstName ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;First Name </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>First Name</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .firstName
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.lastName ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.lastName ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Last Name </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Last Name</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .lastName
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.companyName ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.companyName ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Company Name </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Company Name</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .companyName
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.linkedInCompanyName ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.linkedInCompanyName ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;LinkedIn Company Name </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>LinkedIn Company Name</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .linkedInCompanyName
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.email === "Yes" ||
                                campaignBriefDeliveryDetails.email === "yes" ? (
                                  <tr>
                                    <td>&nbsp;Email </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Email</b>
                                        ) : (
                                          this.state.customizedColumns[0].email
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.workPhone ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.workPhone ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Work Phone </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Work Phone</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .workPhone
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.workPhone ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.workPhone ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Work Phone Format</td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Work Phone Format</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .workPhoneFormat
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.jobTitle ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.jobTitle ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Job Title </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Job Title</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .jobTitle
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.linkedInJobTitle ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.linkedInJobTitle ===
                                  "yes" ? (
                                  <tr>
                                    {/* Chaitanya-5054-Added linkedInJobTitle */}
                                    <td>&nbsp;LinkedIn Job Title </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>LinkedIn Job Title</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .linkedInJobTitle
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.address ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.address ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Address </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Address</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .address
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.country ===
                                  "yes" ||
                                campaignBriefDeliveryDetails.country ===
                                  "Yes" ? (
                                  <tr>
                                    <td>&nbsp;Country </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Country</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .country
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.city === "Yes" ||
                                campaignBriefDeliveryDetails.city === "yes" ? (
                                  <tr>
                                    <td>&nbsp;City </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>City</b>
                                        ) : (
                                          this.state.customizedColumns[0].city
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.state === "Yes" ||
                                campaignBriefDeliveryDetails.state === "yes" ? (
                                  <tr>
                                    <td>&nbsp;State </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>State</b>
                                        ) : (
                                          this.state.customizedColumns[0].state
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.zipCode ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.zipCode ===
                                  "Yes" ? (
                                  <tr>
                                    {/* Chaitanya-5054-Changed Zip Code mapping to Zip Code */}
                                    <td>&nbsp;Zip Code </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Zip Code</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .zipcode
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.companyEmployeeSize ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.companyEmployeeSize ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Company Employee Size </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Company Employee Size</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .companyEmployeeSize
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.companyRevenue ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.companyRevenue ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Company Revenue </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Company Revenue</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .companyRevenue
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.industry ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.industry ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Industry </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Industry</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .industry
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.assetName ===
                                  "yes" ||
                                campaignBriefDeliveryDetails.assetName ===
                                  "Yes" ? (
                                  campaignBriefDeliveryDetails.assetNameTouch1 ===
                                    "yes" ||
                                  campaignBriefDeliveryDetails.assetNameTouch1 ===
                                    "Yes" ? (
                                    ""
                                  ) : (
                                    <tr>
                                      <td>&nbsp;Asset Name </td>
                                      <td>
                                        &nbsp;Current:{" "}
                                        <b>
                                          &nbsp;
                                          {this.state.customizedColumns
                                            .length === 0 ? (
                                            <b>Asset Name</b>
                                          ) : (
                                            this.state.customizedColumns[0]
                                              .assetName
                                          )}
                                        </b>
                                        <br />{" "}
                                      </td>
                                      <td></td>
                                    </tr>
                                  )
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.assetNameTouch1 ===
                                  "yes" ||
                                campaignBriefDeliveryDetails.assetNameTouch1 ===
                                  "Yes" ? (
                                  <tr>
                                    <td>&nbsp;Asset Name Touch 1 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Asset Name Touch 1</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .assetNameTouch1
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.assetTimestampTouch1 ===
                                  "yes" ||
                                campaignBriefDeliveryDetails.assetTimestampTouch1 ===
                                  "Yes" ? (
                                  <tr>
                                    <td>&nbsp;Asset Timestamp Touch 1 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Asset Timestamp Touch 1</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .assetTimestampTouch1
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.assetNameTouch2 ===
                                  "yes" ||
                                campaignBriefDeliveryDetails.assetNameTouch2 ===
                                  "Yes" ? (
                                  <tr>
                                    <td>&nbsp;Asset Name Touch 2 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Asset Name Touch 2</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .assetNameTouch2
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.assetTimestampTouch2 ===
                                  "yes" ||
                                campaignBriefDeliveryDetails.assetTimestampTouch2 ===
                                  "Yes" ? (
                                  <tr>
                                    <td>&nbsp;Asset Timestamp Touch 2 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Asset Timestamp Touch 2</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .assetTimestampTouch2
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.assetNameTouch3 ===
                                  "yes" ||
                                campaignBriefDeliveryDetails.assetNameTouch3 ===
                                  "Yes" ? (
                                  <tr>
                                    <td>&nbsp;Asset Name Touch 3 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Asset Name Touch 3</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .assetNameTouch3
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.assetTimestampTouch3 ===
                                  "yes" ||
                                campaignBriefDeliveryDetails.assetTimestampTouch3 ===
                                  "Yes" ? (
                                  <tr>
                                    <td>&nbsp;Asset Timestamp Touch 3 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Asset Timestamp Touch 3</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .assetTimestampTouch3
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.street ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.street ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Street </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Street</b>
                                        ) : (
                                          this.state.customizedColumns[0].street
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.ip === "Yes" ||
                                campaignBriefDeliveryDetails.ip === "yes" ? (
                                  <tr>
                                    <td>&nbsp;IP </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>IP</b>
                                        ) : (
                                          this.state.customizedColumns[0].ip
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.supportDocID ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.supportDocID ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Asset ID </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Asset ID</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .supportDocID
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.jobLevel ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.jobLevel ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Job Level </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Job Level</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .jobLevel
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.jobFunction ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.jobFunction ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Job Function </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Job Function</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .JobFunction
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {this.state.campaignBriefDetails[0]
                                  .marketingChannel === "Email/Telemarketing" ||
                                this.state.campaignBriefDetails[0]
                                  .marketingChannel === "TeleMarketing" ? (
                                  campaignBriefDeliveryDetails.channel ===
                                    "Yes" ||
                                  campaignBriefDeliveryDetails.channel ===
                                    "yes" ? (
                                    <tr>
                                      <td>&nbsp;Channel </td>
                                      <td>
                                        &nbsp;Current:{" "}
                                        <b>
                                          &nbsp;
                                          {this.state.customizedColumns
                                            .length === 0 ? (
                                            <b>Channel</b>
                                          ) : (
                                            this.state.customizedColumns[0]
                                              .channel
                                          )}
                                        </b>
                                        <br />
                                      </td>
                                      <td></td>
                                    </tr>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra1 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra1 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 1 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 1</b>
                                        ) : (
                                          this.state.customizedColumns[0].extra1
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra2 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra2 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 2 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 2</b>
                                        ) : (
                                          this.state.customizedColumns[0].extra2
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra3 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra3 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 3</td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 3</b>
                                        ) : (
                                          this.state.customizedColumns[0].extra3
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra4 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra4 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 4 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 4</b>
                                        ) : (
                                          this.state.customizedColumns[0].extra4
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra5 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra5 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 5 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 5</b>
                                        ) : (
                                          this.state.customizedColumns[0].extra5
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {/* Sandeep-task-3158-added code for extra 15 fields from 6 to 20*/}

                                {campaignBriefDeliveryDetails.extra6 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra6 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 6 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 6</b>
                                        ) : (
                                          this.state.customizedColumns[0].extra6
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra7 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra7 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 7 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 7</b>
                                        ) : (
                                          this.state.customizedColumns[0].extra7
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra8 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra8 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 8 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 8</b>
                                        ) : (
                                          this.state.customizedColumns[0].extra8
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra9 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra9 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 9 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 9</b>
                                        ) : (
                                          this.state.customizedColumns[0].extra9
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra10 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra10 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 10 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 10</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra10
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra11 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra11 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 11 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 11</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra11
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra12 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra12 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 12 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 12</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra12
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra13 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra13 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 13 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 13</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra13
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra14 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra14 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 14 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 14</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra14
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra15 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra15 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 15 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 15</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra15
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra16 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra16 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 16 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 16</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra16
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra17 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra17 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 17 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 17</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra17
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra18 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra18 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 18 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 18</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra18
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra19 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra19 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 19 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 19</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra19
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra20 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra20 ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Extra 20 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 20</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra20
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.domain ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.domain ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Domain </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Domain</b>
                                        ) : (
                                          this.state.customizedColumns[0].domain
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.alternatePhoneNo ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.alternatePhoneNo ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Alternate Phone No </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Alternate Phone No</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .alternatePhoneNo
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.comments ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.comments ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Comments </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Comments</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .comments
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.linkedIn ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.linkedIn ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;LinkedIn </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>LinkedIn</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .linkedIn
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {/* Aman-5281-added to display setected extra filed 21-30 in Customized Column Mapping*/}
                                {campaignBriefDeliveryDetails.extra21 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra21 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 21 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 21</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra21
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.extra22 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra22 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 22 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 22</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra22
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.extra23 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra23 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 23 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 23</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra23
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.extra24 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra24 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 24 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 24</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra24
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.extra25 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra25 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 25 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 25</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra25
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.extra26 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra26 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 26 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 26</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra26
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.extra27 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra27 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 27 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 27</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra27
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.extra28 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra28 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 28 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 28</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra28
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.extra29 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra29 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 29 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 29</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra29
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.extra30 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra30 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 30 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 30</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra30
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                              </tbody>
                            );
                          }
                        )}
                      </table>
                    </div>
                  </div>
                </CardBody>
              </div>
            ) : (
              ""
            )}
            {/*row 5 */}
            {/* /**
             * @author Narendra Phadke
             * @param  Description Display Campaign Custom Question Details on Modal
             * @return Description return Campaign Delivery Details
             */}

            {/* <table id="myTable2" className=" table table-bordered" style={{display:this.state.display}}>
                          <thead>
                            <tr class="info" >
                              <th class="table-header"style={{width:'1319px'}}>Customized Column Mapping</th>
                            </tr>
                          </thead>
                        </table> */}
            {/* {this.state.campaignBriefDeliveryDetails.map(
                          campaignBriefDeliveryDetails => (
                            <div>
                           
                            {campaignBriefDeliveryDetails.customizeMapping=='Yes'?'':
                      this.state.campaignBriefDetails.map(campaignBriefDetails=>{return(
                        campaignBriefDetails.publisherStatus=="Accept"||campaignBriefDetails.publisherStatus=="LP_Pending"||campaignBriefDetails.publisherStatus=="Paused"||campaignBriefDetails.publisherStatus=="Completed"||campaignBriefDetails.publisherStatus=="PausedIncomplete"||campaignBriefDetails.publisherStatus=="Live_Incomplete"?
                        <div class="table-header add-button btn" style={{float:'right',cursor:'pointer', height: "26px", paddingTop: "1px"}} onClick={this.showMappin}>Customized Mapping</div>:''
                      ) 
                      }
                      )}
                      </div>
                       ))} */}

            <div
              style={{ display: this.state.display }}
              class="container-fluid"
            >
              <div
                style={{
                  fontFamily: "roboto",
                  fontSize: "20px",
                  color: "#193d8f",
                  fontWeight: "600",
                }}
              >
                Customized Column Mapping :
              </div>

              <div
                class="card"
                style={{
                  backgroundColor: "#f8f8f8",
                  borderColor: "#707070",
                  borderWidth: "0.3px",
                }}
              >
                <CardBody>
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3"></div>
                    <table
                      class="table table-bordered"
                      id="myTable3"
                      style={{ width: "60%" }}
                    >
                      <thead>
                        <tr style={{ height: "35px" }}>
                          <th
                            style={{
                              color: "#707070",
                              backgroundColor: " rgba(126, 127, 129, 0.22)",
                              width: "450px",
                            }}
                          >
                            &nbsp;System Field
                          </th>
                          <th
                            style={{
                              color: "#707070",
                              backgroundColor: " rgba(126, 127, 129, 0.22)",
                              width: "900px",
                            }}
                          >
                            &nbsp;Mapping Alias Field{" "}
                          </th>
                          <th
                            style={{
                              color: "#707070",
                              backgroundColor: " rgba(126, 127, 129, 0.22)",
                              width: "200px",
                            }}
                          >
                            &nbsp;Values
                          </th>
                        </tr>
                      </thead>
                      {/* kiran-4746-removing console warning-changed == to === for campaignBriefDeliveryDetails  */}
                      {this.state.campaignBriefDeliveryDetails.map(
                        (campaignBriefDeliveryDetails, i) => {
                          return (
                            <tbody>
                              {campaignBriefDeliveryDetails.pID === "yes" ||
                              campaignBriefDeliveryDetails.pID === "Yes" ? (
                                <tr>
                                  <td>&nbsp;Publisher ID </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current :
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Publisher ID</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .pID
                                      )}
                                    </b>{" "}
                                    <br />
                                    &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="pID"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}

                              {campaignBriefDeliveryDetails.campaignID ===
                                "Yes" ||
                              campaignBriefDeliveryDetails.campaignID ===
                                "yes" ? (
                                <tr>
                                  <td>&nbsp;Campaign ID </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current :
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Campaign ID</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .campID
                                      )}
                                    </b>
                                    <br />
                                    &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="campID"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />{" "}
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.reAllocationID ===
                                "yes" ||
                              campaignBriefDeliveryDetails.reAllocationID ===
                                "Yes" ? (
                                <tr>
                                  <td>&nbsp;Allocation ID </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current :
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Allocation ID</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .reAllocationID
                                      )}
                                    </b>
                                    <br />
                                    &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="reAllocationID"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.leadInteractionDate ===
                                "Yes" ||
                              campaignBriefDeliveryDetails.leadInteractionDate ===
                                "yes" ? (
                                <tr>
                                  <td>&nbsp;Lead Interaction Date </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current :
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Lead Interaction Date</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .leadInteractionDate
                                      )}
                                    </b>
                                    <br />
                                    &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="leadInteractionDate"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}{" "}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}

                              {campaignBriefDeliveryDetails.campaignName ===
                                "Yes" ||
                              campaignBriefDeliveryDetails.campaignName ===
                                "yes" ? (
                                <tr>
                                  <td>&nbsp;Campaign Name </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current :
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Campaign Name</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .campaignName
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="campaignName"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}

                              {campaignBriefDeliveryDetails.firstName ===
                                "Yes" ||
                              campaignBriefDeliveryDetails.firstName ===
                                "yes" ? (
                                <tr>
                                  <td>&nbsp;First Name </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current :
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>First Name</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .firstName
                                      )}
                                    </b>
                                    <br />
                                    &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="firstName"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.lastName ===
                                "Yes" ||
                              campaignBriefDeliveryDetails.lastName ===
                                "yes" ? (
                                <tr>
                                  <td>&nbsp;Last Name </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current :
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Last Name</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .lastName
                                      )}
                                    </b>
                                    <br />
                                    &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="lastName"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.companyName ===
                                "Yes" ||
                              campaignBriefDeliveryDetails.companyName ===
                                "yes" ? (
                                <tr>
                                  <td>&nbsp;Company Name </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current :
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Company Name</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .companyName
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="companyName"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.linkedInCompanyName ===
                                "Yes" ||
                              campaignBriefDeliveryDetails.linkedInCompanyName ===
                                "yes" ? (
                                <tr>
                                  <td>&nbsp;LinkedIn Company Name </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current :
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>LinkedIn Company Name</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .linkedInCompanyName
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="companyName"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.email === "Yes" ||
                              campaignBriefDeliveryDetails.email === "yes" ? (
                                <tr>
                                  <td>Email </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    Current :
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Email</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .email
                                      )}
                                    </b>
                                    <br />
                                    &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        {" "}
                                        Alias{" "}
                                        <input
                                          name="email"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.workPhone ===
                                "Yes" ||
                              campaignBriefDeliveryDetails.workPhone ===
                                "yes" ? (
                                <tr>
                                  <td>&nbsp;Work Phone </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current :
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Work Phone</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .workPhone
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="workPhone"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.workPhone ===
                                "Yes" ||
                              campaignBriefDeliveryDetails.workPhone ===
                                "yes" ? (
                                <tr>
                                  <td>&nbsp;Work Phone Format</td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current :
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>91-XXXXXXXXXX</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .workPhoneFormat
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="workPhone"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.jobTitle ===
                                "Yes" ||
                              campaignBriefDeliveryDetails.jobTitle ===
                                "yes" ? (
                                <tr>
                                  <td>&nbsp;Job Title </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current :
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Job Title</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .jobTitle
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="jobTitle"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.linkedInJobTitle ===
                                "Yes" ||
                              campaignBriefDeliveryDetails.linkedInJobTitle ===
                                "yes" ? (
                                <tr>
                                  <td>&nbsp;Job Title </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current :
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>LinkedIn Job Title</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .linkedInJobTitle
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="jobTitle"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.address === "Yes" ||
                              campaignBriefDeliveryDetails.address === "yes" ? (
                                <tr>
                                  <td>&nbsp;Address </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current :
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Address</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .address
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="address"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.country === "yes" ||
                              campaignBriefDeliveryDetails.country === "Yes" ? (
                                <tr>
                                  <td>&nbsp;Country </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current :
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Country</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .country
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="country"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.city === "Yes" ||
                              campaignBriefDeliveryDetails.city === "yes" ? (
                                <tr>
                                  <td>&nbsp;City </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current :
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>City</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .city
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="city"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.state === "Yes" ||
                              campaignBriefDeliveryDetails.state === "yes" ? (
                                <tr>
                                  <td>&nbsp;State </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current :
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>State</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .state
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="state"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.zipCode === "Yes" ||
                              campaignBriefDeliveryDetails.zipCode === "Yes" ? (
                                <tr>
                                  <td>&nbsp;Zip Code </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current :
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Zip Code</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .zipcode
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="zipCode"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.companyEmployeeSize ===
                                "Yes" ||
                              campaignBriefDeliveryDetails.companyEmployeeSize ===
                                "yes" ? (
                                <tr>
                                  <td>&nbsp;Company Employee Size </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current :
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Company Employee Size</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .companyEmployeeSize
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="companyEmployeeSize"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.companyRevenue ===
                                "Yes" ||
                              campaignBriefDeliveryDetails.companyRevenue ===
                                "yes" ? (
                                <tr>
                                  <td>&nbsp;Company Revenue </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Company Revenue</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .companyRevenue
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="companyRevenue"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.industry ===
                                "Yes" ||
                              campaignBriefDeliveryDetails.industry ===
                                "yes" ? (
                                <tr>
                                  <td>&nbsp;Industry </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current :
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Industry</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .industry
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="industry"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.assetName ===
                                "yes" ||
                              campaignBriefDeliveryDetails.assetName ===
                                "Yes" ? (
                                campaignBriefDeliveryDetails.assetNameTouch1 ===
                                  "yes" ||
                                campaignBriefDeliveryDetails.assetNameTouch1 ===
                                  "Yes" ? (
                                  ""
                                ) : (
                                  <tr>
                                    <td>&nbsp;Asset Name </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Asset Name</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .assetName
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td></td>
                                  </tr>
                                )
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.assetNameTouch1 ===
                                "yes" ||
                              campaignBriefDeliveryDetails.assetNameTouch1 ===
                                "Yes" ? (
                                <tr>
                                  <td>&nbsp;Asset Name Touch 1 </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Asset Name Touch 1 </b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .assetNameTouch1
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="assetNameTouch1"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.assetTimestampTouch1 ===
                                "yes" ||
                              campaignBriefDeliveryDetails.assetTimestampTouch1 ===
                                "Yes" ? (
                                <tr>
                                  <td>&nbsp;Asset Timestamp Touch 1 </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Asset Timestamp Touch 1 </b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .assetTimestampTouch1
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="assetTimestampTouch1"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}

                              {campaignBriefDeliveryDetails.assetNameTouch2 ===
                                "yes" ||
                              campaignBriefDeliveryDetails.assetNameTouch2 ===
                                "Yes" ? (
                                <tr>
                                  <td>&nbsp;Asset Name Touch 2 </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Asset Name Touch 2 </b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .assetNameTouch2
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="assetNameTouch2"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}

                              {campaignBriefDeliveryDetails.assetTimestampTouch2 ===
                                "yes" ||
                              campaignBriefDeliveryDetails.assetTimestampTouch2 ===
                                "Yes" ? (
                                <tr>
                                  <td>&nbsp;Asset Timestamp Touch 2 </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Asset Timestamp Touch 2 </b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .assetTimestampTouch2
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="assetTimestampTouch2"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}

                              {campaignBriefDeliveryDetails.assetNameTouch3 ===
                                "yes" ||
                              campaignBriefDeliveryDetails.assetNameTouch3 ===
                                "Yes" ? (
                                <tr>
                                  <td>&nbsp;Asset Name Touch 3 </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Asset Name Touch 3 </b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .assetNameTouch2
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="assetNameTouch3"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.assetTimestampTouch3 ===
                                "yes" ||
                              campaignBriefDeliveryDetails.assetTimestampTouch3 ===
                                "Yes" ? (
                                <tr>
                                  <td>&nbsp;Asset Timestamp Touch 3 </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Asset Timestamp Touch 3 </b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .assetTimestampTouch3
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="assetTimestampTouch3"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}

                              {campaignBriefDeliveryDetails.street === "Yes" ||
                              campaignBriefDeliveryDetails.street === "yes" ? (
                                <tr>
                                  <td>&nbsp;Street </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Street</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .street
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="street"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.ip === "Yes" ||
                              campaignBriefDeliveryDetails.ip === "yes" ? (
                                <tr>
                                  <td>&nbsp;IP </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>IP</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .ip
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="ip"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.supportDocID ===
                                "Yes" ||
                              campaignBriefDeliveryDetails.supportDocID ===
                                "yes" ? (
                                <tr>
                                  <td>&nbsp;Asset ID </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Asset ID</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .supportDocID
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="supportDocID"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.jobLevel ===
                                "Yes" ||
                              campaignBriefDeliveryDetails.jobLevel ===
                                "yes" ? (
                                <tr>
                                  <td>&nbsp;Job Level </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Job Level</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .jobLevel
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="jobLevel"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.jobFunction ===
                                "Yes" ||
                              campaignBriefDeliveryDetails.jobFunction ===
                                "yes" ? (
                                <tr>
                                  <td>&nbsp;Job Function </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Job Function</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .JobFunction
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="jobFunction"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {this.state.campaignBriefDetails[0]
                                .marketingChannel === "Email/Telemarketing" ? (
                                campaignBriefDeliveryDetails.channel ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.channel ===
                                  "yes" ? (
                                  <tr>
                                    <td>&nbsp;Channel </td>
                                    <td style={{ paddingBottom: "10px" }}>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.deliveryFormatMappingNew
                                          .length === 0 ? (
                                          <b>Channel</b>
                                        ) : (
                                          this.state.deliveryFormatMappingNew[0]
                                            .channel
                                        )}
                                      </b>
                                      <br /> &nbsp;
                                      {this.state.campaignBriefDetails[0]
                                        .publisherStatus === "Completed" ||
                                      this.state.campaignBriefDetails[0]
                                        .publisherStatus ===
                                        "PausedIncomplete" ||
                                      this.state.campaignBriefDetails[0]
                                        .publisherStatus ===
                                        "Live_Incomplete" ? (
                                        ""
                                      ) : (
                                        <span>
                                          Alias{" "}
                                          <input
                                            name="channel"
                                            onChange={this.mappinHandleChange.bind(
                                              this,
                                              i
                                            )}
                                          />
                                        </span>
                                      )}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}

                              {campaignBriefDeliveryDetails.extra1 === "Yes" ||
                              campaignBriefDeliveryDetails.extra1 === "yes" ? (
                                <tr>
                                  <td>&nbsp;Extra 1 </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Extra 1</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .extra1
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="extra1"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.extra2 === "Yes" ||
                              campaignBriefDeliveryDetails.extra2 === "yes" ? (
                                <tr>
                                  <td>&nbsp;Extra 2 </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Extra 2</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .extra2
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="extra2"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.extra3 === "Yes" ||
                              campaignBriefDeliveryDetails.extra3 === "yes" ? (
                                <tr>
                                  <td>&nbsp;Extra 3</td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Extra 3</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .extra3
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="extra3"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.extra4 === "Yes" ||
                              campaignBriefDeliveryDetails.extra4 === "yes" ? (
                                <tr>
                                  <td>&nbsp;Extra 4 </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Extra 4</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .extra4
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="extra4"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.extra5 === "Yes" ||
                              campaignBriefDeliveryDetails.extra5 === "yes" ? (
                                <tr>
                                  <td>&nbsp;Extra 5 </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Extra 5</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .extra5
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="extra5"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {/* Sandeep-task-3158-added code for extra 15 fields from 6 to 20*/}
                              {campaignBriefDeliveryDetails.extra6 === "Yes" ||
                              campaignBriefDeliveryDetails.extra6 === "yes" ? (
                                <tr>
                                  <td>&nbsp;Extra 6 </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Extra 6</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .extra6
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="extra6"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.extra7 === "Yes" ||
                              campaignBriefDeliveryDetails.extra7 === "yes" ? (
                                <tr>
                                  <td>&nbsp;Extra 7 </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Extra 7</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .extra7
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="extra7"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.extra8 === "Yes" ||
                              campaignBriefDeliveryDetails.extra8 === "yes" ? (
                                <tr>
                                  <td>&nbsp;Extra 8 </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Extra 8</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .extra8
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="extra8"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.extra9 === "Yes" ||
                              campaignBriefDeliveryDetails.extra9 === "yes" ? (
                                <tr>
                                  <td>&nbsp;Extra 9 </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Extra 9</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .extra9
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="extra9"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.extra10 === "Yes" ||
                              campaignBriefDeliveryDetails.extra10 === "yes" ? (
                                <tr>
                                  <td>&nbsp;Extra 10 </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Extra 10</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .extra10
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="extra10"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.extra11 === "Yes" ||
                              campaignBriefDeliveryDetails.extra11 === "yes" ? (
                                <tr>
                                  <td>&nbsp;Extra 11 </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Extra 11</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .extra11
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="extra11"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.extra12 === "Yes" ||
                              campaignBriefDeliveryDetails.extra12 === "yes" ? (
                                <tr>
                                  <td>&nbsp;Extra 12 </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Extra 12</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .extra12
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="extra12"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.extra13 === "Yes" ||
                              campaignBriefDeliveryDetails.extra13 === "yes" ? (
                                <tr>
                                  <td>&nbsp;Extra 13 </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Extra 13</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .extra13
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="extra13"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.extra14 === "Yes" ||
                              campaignBriefDeliveryDetails.extra14 === "yes" ? (
                                <tr>
                                  <td>&nbsp;Extra 14 </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Extra 14</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .extra14
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="extra14"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.extra15 === "Yes" ||
                              campaignBriefDeliveryDetails.extra15 === "yes" ? (
                                <tr>
                                  <td>&nbsp;Extra 15 </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Extra 15</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .extra15
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="extra15"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.extra16 === "Yes" ||
                              campaignBriefDeliveryDetails.extra16 === "yes" ? (
                                <tr>
                                  <td>&nbsp;Extra 16 </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Extra 16</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .extra16
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="extra16"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.extra17 === "Yes" ||
                              campaignBriefDeliveryDetails.extra17 === "yes" ? (
                                <tr>
                                  <td>&nbsp;Extra 17 </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Extra 17</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .extra17
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="extra17"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.extra18 === "Yes" ||
                              campaignBriefDeliveryDetails.extra18 === "yes" ? (
                                <tr>
                                  <td>&nbsp;Extra 18 </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Extra 18</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .extra18
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="extra18"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.extra19 === "Yes" ||
                              campaignBriefDeliveryDetails.extra19 === "yes" ? (
                                <tr>
                                  <td>&nbsp;Extra 19 </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Extra 19</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .extra19
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="extra19"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.extra20 === "Yes" ||
                              campaignBriefDeliveryDetails.extra20 === "yes" ? (
                                <tr>
                                  <td>&nbsp;Extra 20 </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Extra 20</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .extra20
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="extra20"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}

                              {campaignBriefDeliveryDetails.domain === "Yes" ||
                              campaignBriefDeliveryDetails.domain === "yes" ? (
                                <tr>
                                  <td>&nbsp;Domain </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Domain</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .domain
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="domain"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.alternatePhoneNo ===
                                "Yes" ||
                              campaignBriefDeliveryDetails.alternatePhoneNo ===
                                "yes" ? (
                                <tr>
                                  <td>&nbsp;Alternate Phone No </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Alternate Phone No</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .alternatePhoneNo
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="alternatePhoneNo"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.comments ===
                                "Yes" ||
                              campaignBriefDeliveryDetails.comments ===
                                "yes" ? (
                                <tr>
                                  <td>&nbsp;Comments </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>Comments</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .comments
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="comments"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {campaignBriefDeliveryDetails.linkedIn ===
                                "Yes" ||
                              campaignBriefDeliveryDetails.linkedIn ===
                                "yes" ? (
                                <tr>
                                  <td>&nbsp;LinkedIn </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    &nbsp;Current:{" "}
                                    <b>
                                      &nbsp;
                                      {this.state.deliveryFormatMappingNew
                                        .length === 0 ? (
                                        <b>LinkedIn</b>
                                      ) : (
                                        this.state.deliveryFormatMappingNew[0]
                                          .linkedIn
                                      )}
                                    </b>
                                    <br /> &nbsp;
                                    {this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Completed" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "PausedIncomplete" ||
                                    this.state.campaignBriefDetails[0]
                                      .publisherStatus === "Live_Incomplete" ? (
                                      ""
                                    ) : (
                                      <span>
                                        Alias{" "}
                                        <input
                                          name="linkedIn"
                                          onChange={this.mappinHandleChange.bind(
                                            this,
                                            i
                                          )}
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              ) : (
                                ""
                              )}
                            </tbody>
                          );
                        }
                      )}
                    </table>
                  </div>
                  <br />

                  <button
                    class="btn add-button"
                    style={{ float: "right" }}
                    onClick={this.getMappin}
                  >
                    Save Mapping
                  </button>
                  <br />

                  <br />
                </CardBody>
              </div>
            </div>
            <br />
            
            {/* Chaitanya-5361-Showing column sequencing */}
            {this.state.columnSequenceCheck === "Yes" ? (
              <div>
                {" "}
                <div
                  style={{
                    fontFamily: "roboto",
                    fontSize: "20px",
                    color: "#193d8f",
                    fontWeight: "600",
                  }}
                >
                  Column Sequencing :
                </div>
                <div
                  class="card"
                  style={{
                    backgroundColor: "#f8f8f8",
                    borderColor: "#707070",
                    borderWidth: "0.3px",
                  }}
                >
                  <CardBody>
                    <div
                      className="table-container"
                      style={{ width: "80%", paddingLeft: "19%" }}
                    >
                      <Table
                        columns={columns}
                        dataSource={this.state.columnsequencingarray}
                        pagination={false}
                        bordered
                        id="myTable"
                        showHeader={false}
                        size="small"
                        class="ant-table-thead"
                        className={tableCSS}
                        //scroll={{ x: 1250, y: 4000 }}
                      ></Table>
                    </div>
                  </CardBody>
                </div>
              </div>
            ) : (
              ""
            )}

            <br />
            {/*row 6x */}
            {this.state.campaignBriefCustomQuestionDetails.length > 0 ? (
              <div>
                {/* <thead>
                            <tr class="info" > */}
                <div
                  style={{
                    fontFamily: "roboto",
                    fontSize: "20px",
                    color: "#193d8f",
                    fontWeight: "600",
                  }}
                >
                  Custom Questions
                </div>
                {/* </tr>
                          </thead> */}
                {this.state.campaignBriefDetails.map((campaignBriefDetails) => (
                  <div
                    class="card"
                    style={{
                      backgroundColor: "#f8f8f8",
                      borderColor: "#707070",
                      borderWidth: "0.3px",
                    }}
                  >
                    {/* snehal-task-3112--Custom Question Alias */}
                    {campaignBriefDetails.customQuestionAliasName === "No" ? (
                      <CardBody>
                        <table>
                          <tr>
                            <td style={{ width: "160px" }}>
                              <label id="label">Add Question Alias</label>
                            </td>
                            {this.state.campaignBriefDetails.map(
                              (campaignBriefDetails) => (
                                <td>
                                  <span>
                                    :&nbsp;
                                    {
                                      campaignBriefDetails.customQuestionAliasName
                                    }
                                  </span>
                                </td>
                              )
                            )}
                          </tr>
                        </table>
                        {this.state.campaignBriefCustomQuestionDetails.map(
                          (campaignBriefCustomQuestionDetails, i) => (
                            <div class="row">
                              <div class="col-sm-12 col-lg-4">
                                {" "}
                                <label id="label">
                                  &nbsp;Question {i + 1}:
                                </label>
                                &nbsp;
                                <textarea
                                  id="customQuestion1"
                                  class="form-control"
                                  name="customQuestion1"
                                  disabled //bug-4450-disabled textarea
                                  value={unescape(
                                    campaignBriefCustomQuestionDetails.customQuestion
                                  )}
                                  onChange={this.handleChangeCustomQuestion}
                                />
                              </div>
                              <div class="col-sm-12 col-lg-4">
                                {" "}
                                <label id="label">Answer {i + 1}:</label>
                                &nbsp;
                                <textarea
                                  id="answer1"
                                  class="form-control"
                                  name="answer1"
                                  disabled //bug-4450-disabled textarea
                                  value={unescape(
                                    campaignBriefCustomQuestionDetails.answer
                                  )}
                                  onChange={this.handleChangeCustomQuestion}
                                />
                              </div>

                              <div class="col-sm-12 col-lg-4">
                                {" "}
                                <label id="label">
                                  Not Allowed Answer {i + 1}:
                                </label>
                                &nbsp;
                                <textarea
                                  id="nonallowedanswer"
                                  class="form-control"
                                  name="nonallowedanswer"
                                  disabled //bug-4450-disabled textarea
                                  value={unescape(
                                    campaignBriefCustomQuestionDetails.disAllowAnswer
                                  )}
                                  onChange={this.handleChangeCustomQuestion}
                                />
                              </div>
                            </div>
                          )
                        )}
                      </CardBody>
                    ) : (
                      <CardBody>
                        {" "}
                        {/* snehal-task-3112--Custom Question Alias */}
                        <table>
                          <tr>
                            <td style={{ width: "160px" }}>
                              <label id="label">Add Question Alias</label>
                            </td>
                            {this.state.campaignBriefDetails.map(
                              (campaignBriefDetails) => (
                                <td>
                                  <span>
                                    :&nbsp;
                                    {
                                      campaignBriefDetails.customQuestionAliasName
                                    }
                                  </span>
                                </td>
                              )
                            )}
                          </tr>
                        </table>
                        {this.state.campaignBriefCustomQuestionDetails.map(
                          (campaignBriefCustomQuestionDetails, i) => (
                            <div class="row">
                              <div class="col-sm-12 col-lg-3">
                                {" "}
                                <label id="label">&nbsp;Alias {i + 1}:</label>
                                &nbsp;
                                <textarea
                                  id="aliasName1"
                                  class="form-control"
                                  name="aliasName1"
                                  disabled //bug-4450-disabled textarea
                                  value={unescape(
                                    campaignBriefCustomQuestionDetails.aliasName
                                  )}
                                  onChange={this.handleChangeCustomQuestion}
                                />
                              </div>
                              <div class="col-sm-12 col-lg-3">
                                {" "}
                                <label id="label">
                                  &nbsp;Question {i + 1}:
                                </label>
                                &nbsp;
                                <textarea
                                  id="customQuestion1"
                                  class="form-control"
                                  name="customQuestion1"
                                  disabled //bug-4450-disabled textarea
                                  value={unescape(
                                    campaignBriefCustomQuestionDetails.customQuestion
                                  )}
                                  onChange={this.handleChangeCustomQuestion}
                                />
                              </div>
                              <div class="col-sm-12 col-lg-3">
                                {" "}
                                <label id="label">Answer {i + 1}:</label>
                                &nbsp;
                                <textarea
                                  id="answer1"
                                  class="form-control"
                                  name="answer1"
                                  disabled //bug-4450-disabled textarea
                                  value={unescape(
                                    campaignBriefCustomQuestionDetails.answer
                                  )}
                                  onChange={this.handleChangeCustomQuestion}
                                />
                              </div>

                              <div class="col-sm-12 col-lg-3">
                                {" "}
                                <label id="label">
                                  Not Allowed Answer {i + 1}:
                                </label>
                                &nbsp;
                                <textarea
                                  id="nonallowedanswer"
                                  class="form-control"
                                  name="nonallowedanswer"
                                  disabled //bug-4450-disabled textarea
                                  value={unescape(
                                    campaignBriefCustomQuestionDetails.disAllowAnswer
                                  )}
                                  onChange={this.handleChangeCustomQuestion}
                                />
                              </div>
                            </div>
                          )
                        )}
                      </CardBody>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}

            {/* {this.state.campaignBriefCustomQuestionDetails.map(
                          (campaignBriefCustomQuestionDetails,i) => (

                            <div class="row">
                              <div class="col-sm-12 col-lg-4">
                                {" "}
                                <label id="label">&nbsp;Custom Question {i+1}:</label>
                                &nbsp;
                                <textarea
                                  id="customQuestion1"
                                  class="form-control"
                                  name="customQuestion1"
                                  value={unescape(campaignBriefCustomQuestionDetails.customQuestion)
                                  }
                                  onChange={this.handleChangeCustomQuestion}
                                />
                              </div>
                               <div class="col-sm-12 col-lg-4">
                                {" "}
                                <label id="label">Answer {i+1}:</label>
                                &nbsp;
                                <textarea
                                  id="answer1"
                                  class="form-control"
                                  name="answer1"
                                  value={unescape(campaignBriefCustomQuestionDetails.answer)
                                  }
                                  onChange={this.handleChangeCustomQuestion}
                                />
                              </div>

                              
                              <div class="col-sm-12 col-lg-4">
                                    {" "}
                                    <label id="label">Not Allowed Answer {i+1}:</label>
                                    &nbsp;
                                    <textarea
                                      id="nonallowedanswer"
                                      class="form-control"
                                      name="nonallowedanswer"
                                      value={unescape(campaignBriefCustomQuestionDetails.disAllowAnswer)
                                      }
                                      onChange={this.handleChangeCustomQuestion}
                                    />
                                  </div>
                            </div>
                          )
                        )}
                        <br/> */}
          </div>
          {/*border */}
        </div>
        {/*col-lg-12 */}
        {/* {this.state.requiredCountryWiseAllocation==="Yes"?
<div>
  <div style={{ fontSize:"large", color:'#124E9C' ,fontWeight :"bold"}}>Lead Allocation by country :</div>
  <div class="card" style={{backgroundColor:"#f8f8f8",     borderColor: "#707070", borderWidth: "0.3px"}}>
  
<CardBody>                   
<table class="col-md-5 col-lg-5 ">
  <table class="table  table-bordered">
    <thead>
      <tr class="info">
        <th style={{ color: '#707070',backgroundColor:' rgba(126, 127, 129, 0.22)'}}>Country Name</th>
        <th style={{ color: '#707070',backgroundColor:' rgba(126, 127, 129, 0.22)'}}>Lead Allocation</th>
        <th style={{ color: '#707070',backgroundColor:' rgba(126, 127, 129, 0.22)'}}>Status</th>
      </tr>
      </thead>
      <tbody>
        {this.state.countryWiseAllocationArray.map(item=>(
        <tr>
        {item.status==='Removed'?
          <td style={{ color:'red'}}>{item.country}</td>
        : <td>{item.country}</td>}
        {item.status==='Removed'?
          <td style={{ color:'red'}}>{item.lead}</td>
        : <td>{item.lead}</td>}
        {item.status==='Removed'?
          <td style={{ color:'red'}}>{item.status}</td>
        : <td>{item.status}</td>}
      </tr>
        ))}
      </tbody>
    </table>
    </table>
<br/>
</CardBody>
</div>
</div>
:''} */}
        {/* Sandeep-task-3261-added code for marginTop */}
        <Drawer
          style={{ marginTop: "16px" }}
          title="Basic Drawer"
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
          width={350}
        >
          {/* Sandeep-task-3261-added label tag for message  */}
          <label
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              color: "#414141",
              marginLeft: "12px",
            }}
          >
            Messages
          </label>
          <MDBBtn style={{ left: "165px" }} onClick={this.onClose}>
            &times;
          </MDBBtn>

          <p>
            {" "}
            <Comment
              style={{ top: "8px" }}
              // avatar={
              //   <Avatar
              //     src="DI.png"
              //     alt="Demand Integrate"
              //   />
              // }
              content={
                <Editor
                  onChange={this.handleChange}
                  onSubmit={this.handleSubmit}
                  submitting={submitting}
                  value={value}
                />
              }
            />
            <label
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                marginLeft: "13px",
                color: "#414141",
              }}
            >
              History
            </label>
            {/* <p style={{overflowY:"scroll",height:"200px"}}>{comments.length > 0 && <CommentList comments={comments} />}</p> */}
            <p
              style={{
                overflowY: "scroll",
                height: "200px",
                marginBottom: "10px",
              }}
            >
              {this.state.comments && this.state.comments.length > 0
                ? this.state.comments.map((comments) => (
                    <div>
                      <Avatar
                        style={{ color: "#ffffff", backgroundColor: "#144999" }}
                      >
                        {comments.avatar}
                      </Avatar>
                      &nbsp; <b>{comments.author}</b>&nbsp;&nbsp;
                      <span style={{ fontSize: "10px" }}>
                        {comments.datetime}
                      </span>
                      {/* {comments.status} */}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {comments.status === "Unread" ? (
                        <b>
                          <p style={{ textIndent: "50px", color: "#3a1e5f" }}>
                            {comments.content}
                          </p>
                        </b>
                      ) : (
                        <p style={{ textIndent: "50px" }}>{comments.content}</p>
                      )}
                    </div>
                  ))
                : ""}
            </p>
          </p>
        </Drawer>

        <Container>
          {/* <Button
                tooltip="Contact Publisher"
                 styles={{backgroundColor: darkColors.lighterRed, color: lightColors.white}}
                icon="fa fa-user"
                onClick={this.contactAgency}
                
                />
            <Button
                tooltip="BroadCast"
                 styles={{backgroundColor: darkColors.lighterRed, color: lightColors.white}}
                icon="fa fa-rss"
                onClick={this.showDrawer} 
                /> */}
          <Button
            tooltip="Chat With Us!"
            styles={{
              background: "linear-gradient(to right,#28166f,#007cc3)",
              color: lightColors.white,
            }}
            icon="fa fa-comments-o fa-3x"
            //rotate={true}
            onClick={this.showDrawer}
          />
        </Container>
      </div>
    );
  }
} // end of class CampaignSpecificationPublisher

/* @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
campaignSpecificationPublisherDashboardDetails.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
  withRouter(campaignSpecificationPublisherDashboardDetails)
);
