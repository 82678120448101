/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*
@author:Achal Somani
@fileName:campaignList.js
@Creation Date:21-1-2020
@Description:to Dispaly 100 campaigns per page
@Console error warning handled by:Sunita Landge
*/

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import $ from "jquery"; //Sandeep-task-3227-import jquery
import "bootstrap"; //Sandeep-task-3227-import bootstrap
//import Loader from "react-loader-spinner";//Sonali-3232-added new loader

import "datatables.net";
import "antd/dist/antd.css";
import { Table, Input, Button, Icon, Divider, Tooltip } from "antd";
import Highlighter from "react-highlight-words";
import { Modal } from "react-bootstrap";
import { saveAs } from "file-saver";
import "./dashboard.css";
import "./campaignList.css"; //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
import { css } from "emotion";

import configration from "./../../configration/configration";

import Navigation from "../layouts/navPage";
import Footer from "../layouts/footer";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //karan-task-3717-added default header

const xlsx = require("xlsx");
const Swal = require("sweetalert2");

var dateTime = require("node-datetime");
const tableCSS = css({
	backgroundColor: "white",
	borderStyle: "bold",
	"& thead  > tr": {
		backgroundImage: "linear-gradient(to right,#28166f,#007cc3) !important",
		color: "white",
		fontSize: "12px",
		textAlign: "center",
		fontWeight: "500",
	},
	"& thead > tr >th": {
		textAlign: "center",
		color: "white",
	},
});

class CampaignList extends React.PureComponent { // 4568 -(replace - Component with PureComponent to avoid rendering api) Prod Issue- AC/PC/PNC - Camp Progress - Page Unresponsive popup comes
	constructor(props) {
		super(props);
		this.state = {
			fields: {},
			errors: {},
			disabletip: "tooltip-top",
			agencyID: "",
			campaignDetails: [],
			publisherList: [],
			publisherAllocatedLeads: [],
			dashboardCampaignCount: [],
			activeCampaignCount: "",
			pauseCampaignCount: "",
			completedCampaignCount: "",
			cancelledCampaignCount: "",
			clientName: "",
			activeKey: "",
			assignDeliveredLead: [],
			pacing: "All",
			pageSize: 100,
			pageSize1: 5,
			pageSize2: 5,
			pageSize3: 5,
			pageSize4: 5,
			successMsg: "",
			loader_progress: "loader_campaign_list", //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
			stateCopy: [
				{
					allocatedLead: "",
					cpl: "",
				},
			],
			biddingArray: [],
			reviewArray: [],
			tabReview: "creatives",
			linkActive: "",
			linkActive1: "active",
			linkActive2: "",
			linkActive3: "",
			linkActive4: "",
			linkActive5: "",
			key: "",
			textVal: "",
			leadIncDecStatus: "",
			headExample: "head-example",
			headExample1: "head-exampleGeneral1",
			headExample2: "head-example2",
			headExample3: "head-exampleGeneral3",
			headExample4: "head-exampleGeneral4",
			headExample5: "head-exampleGeneral5",
			headExample6: "head-exampleGeneral6",
			tableState: { size: "small" }, //Array for table properties
			tableSearchText: "",
			downloadFiles: [],
			tableSearchText1: "",
			tableSearchText2: "",
			campaignDetailsSearch: [],
			columnUnreadMsgSearch: [],
			columnUnreadAlertsSearch: [],
			show: "none",
			ReviewTab: "",
			RFPBiddingTab: "",
			step: 2,
			alertBorder: "",
			alertDisplay: "none",
			extension: "",
			tab: "",
			tabName: "Active",
			incrementLeads: "",
			decrementLeads: "",
			incrementCampDetails: [],
			onClickAllocatedLeadPublisherDecrement: [],
			campID: "",
			advCampID: "",
			//loading: true, //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
			loading1: true,
			loading2: true,
			parentCampID: "",
			reallocationID: "",
			counterLength: "",
			rejectLength: "",
			creativeLength: "",
			leadsLength: "",
			publisherID: "",
			onClickAllocatedLeadPubList: [],
			endDateCampaign: [],
			reviewTab1: "",
			inputClass: "btn add-button",
			months: [],
			invoiceLength: "",
			monthArrayLength: "",
			unreadMessages: [],
			unreadMessagesLength: 0,
			unreadAlerts: [],
			unreadAlertsLength: 0,
			partialCount: 0,
			panelBlock: "",
			newDate: "",
			rfpBiddingLength: "",
			modalShow: false,
			allocatedLeadForDec: "",
			publisherDetailsForDec: [],
			byPacing: false,
			token: "", // shivani-task 2993-declare token to set token for API authentication to pass in every fetch header.
			label: "All", //sunita-task-2996- using this varibale we are displaying  on front end side the pacing value
			campstatus: "", //sunita-task-2996- this is used when we are coming for dashboard finding the status of campaign
			noChangeDashbordCampaignCount: [], //sunita-task-2996- this array used for storing the actual count of tab.
			tabNameKey: "", //sunita-task-2996- when we are changing the filter value for particular tab  it will store that tab name only.
			toDate: "", //sunita-task-2996- this vaarible holding the value of to date when we are coming from dashboard
			fromDate: "", //sunita-task-2996- this vaarible holding the value of from date when we are coming from dashboard
			fromDownloadExcel: false, //Sonali-Task 2988-fromDownloadExcel is added for BE to identify that we are coming from download excel function
			fromCampaigntoAllocate: false, // sunita-task- 2996- this varibal is considered  when we are coming from operational action campaigns to allocate
			isprogressBtnDisable: false, // sunita-task-3060-added this variable for disabling the progressreport button as per data .
			followUpReport: false, //sunita-task-2913-added this varibal to display the status of send follow up report button(sending or send follow up).
			dashActiveCount: "", //Sonali-3232-added this to show loader
			cloneStartDate: "",
			cloneEndDate: "",
			cloneFirstLeadDate: "",
			actionPopupVisible: false,
			x: 0,
			y: 0,
			actionPopupSelectedRecord: -1,
			marketoClient: "",
			sfClient: "",
			hubspotClient: "",
			client: ""		//4412
		};

		this.campaignLink = this.campaignLink.bind(this);
		this.biddingLink = this.biddingLink.bind(this);
		this.reviewLink = this.reviewLink.bind(this);
		this.handleAllocatedLeads = this.handleAllocatedLeads.bind(this);
		this.displayPopup = this.displayPopup.bind(this);
		this.displayCampaignSpecification =
			this.displayCampaignSpecification.bind(this);
		this.displayCampaignSpecificationMsg =
			this.displayCampaignSpecificationMsg.bind(this);
		this.displayCampaignSetup = this.displayCampaignSetup.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChangePageSize = this.handleChangePageSize.bind(this);
		this.handleChangePageSize1 = this.handleChangePageSize1.bind(this);
		this.handleChangePageSize2 = this.handleChangePageSize2.bind(this);
		this.handleChangePageSize3 = this.handleChangePageSize3.bind(this);
		this.handleChangePageSize4 = this.handleChangePageSize4.bind(this);
		this.handleChangeSearch = this.handleChangeSearch.bind(this);
		this.handleChangeSearch1 = this.handleChangeSearch1.bind(this);
		this.handleChangeSearch2 = this.handleChangeSearch2.bind(this);
		this.pauseCampaign = this.pauseCampaign.bind(this);
		this.resumeCampaign = this.resumeCampaign.bind(this);
		this.allocatePublisher = this.allocatePublisher.bind(this);
		this.handleRefreshData = this.handleRefreshData.bind(this);
		this.creativesReview = this.creativesReview.bind(this);
		this.handleDecrementCampaignLeads =
			this.handleDecrementCampaignLeads.bind(this);
		this.handleEditCampaign = this.handleEditCampaign.bind(this);
		this.allocatedCampaign = this.allocatedCampaign.bind(this);
		this.rFPBiddingTab = this.rFPBiddingTab.bind(this);
		this.leadReview = this.leadReview.bind(this);
		this.handleChangeCompleteCampaign =
			this.handleChangeCompleteCampaign.bind(this);
		this.handleChangeAllocatedLeads =
			this.handleChangeAllocatedLeads.bind(this);
		this.handleRefreshPage = this.handleRefreshPage.bind(this);
		this.handleIncrementLeads = this.handleIncrementLeads.bind(this);
		this.handleChangeDate = this.handleChangeDate.bind(this);
		this.handleIncCampaignLeads = this.handleIncCampaignLeads.bind(this);
		this.getCampID = this.getCampID.bind(this);
		this.reAllocateCampaign = this.reAllocateCampaign.bind(this);
		this.reAllocate = this.reAllocate.bind(this);
		this.handleChangeIncremental = this.handleChangeIncremental.bind(this);
		this.handleEndDate = this.handleEndDate.bind(this);
		this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
		this.handleChangeEndDateCampaign =
			this.handleChangeEndDateCampaign.bind(this);
		this.cplHandleChange = this.cplHandleChange.bind(this);
		this.budgetHandleChange = this.budgetHandleChange.bind(this);
		this.leadAllocationHandleChange =
			this.leadAllocationHandleChange.bind(this);
		this.cloneCampaign = this.cloneCampaign.bind(this);
		this.cloneCompletedCampaign = this.cloneCompletedCampaign.bind(this);
		this.fadeInOut = this.fadeInOut.bind(this);
		this.closeChat = this.closeChat.bind(this);
		this.recordOpen = this.recordOpen.bind(this);
		this.getDetails = this.getDetails.bind(this);
		this.modalHandleClose = this.modalHandleClose.bind(this);
		this.getDecrementalDetails = this.getDecrementalDetails.bind(this);
		this.cancelCampaign = this.cancelCampaign.bind(this);
		this.getAllocatedLeads = this.getAllocatedLeads.bind(this);
		this.handleDecrementLeads = this.handleDecrementLeads.bind(this);
		this.handleChangeReport = this.handleChangeReport.bind(this);
		this.showLeadAssignTOPublisher = this.showLeadAssignTOPublisher.bind(this);
		this.downloadCampaignExcel = this.downloadCampaignExcel.bind(this);
		this.downloadExcel = this.downloadExcel.bind(this); // sunita-task-2996-created seperate function for download excel
		this.leadsTomarketoHandleChange =
			this.leadsTomarketoHandleChange.bind(this); //Sandeep-task-3752-Agency side--Campaign progress-Action--Add one button-Send lead to marketo-FE
		this.leadsTohubspotHandleChange = this.leadsTohubspotHandleChange.bind(this);  //rutuja task 4276-Agency side--Campaign progress-Action--Add one button-Send lead to Hubspot-FE
		this.leadsTosalesforceHandleChange = this.leadsTosalesforceHandleChange.bind(this); //Priyanka-4277-Salesforce - Campaign wise Lead deliver
		this.deliverLeadsHandleChange = this.deliverLeadsHandleChange.bind(this);
	}

	//Kiran-4412
	deliverLeadsHandleChange(e) {
		e.preventDefault();
		var campLeadSend = "All";
		var campID = e.target.id;
		var parentCampID = e.target.getAttribute("parentCampId");
		var reallocationID = e.target.getAttribute("reallocationId");

		var marketoClient = this.state.marketoClient;
		var hubspotClient = this.state.hubspotClient;
		var sfClient = this.state.sfClient;
		if (marketoClient !== null && marketoClient !== "null" && marketoClient !== undefined && marketoClient !== "undefined") {
			this.props.history.push("/leadDeliverMarketo", { campID, campLeadSend, parentCampID, reallocationID, marketoClient })
		} else if (hubspotClient !== null && hubspotClient !== "null" && hubspotClient !== undefined && hubspotClient !== "undefined") {
			this.props.history.push("/leadDeliverHubspot", { campID, campLeadSend, parentCampID, reallocationID, hubspotClient })
		} else if (sfClient !== null && sfClient !== "null" && sfClient !== undefined && sfClient !== "undefined") {

			this.props.history.push("/leadDeliverSf", { campID, campLeadSend, parentCampID, reallocationID, sfClient })
		} else {

		}
	}

	/**
	 * @author Sandeep Dhawale
	 * @param  Description handle leadsTomarketo
	 * @return Description return leads to marketo data
	 */
	leadsTomarketoHandleChange(e) {
		e.preventDefault();
		var campLeadSend = "All";
		var campID = e.target.id;
		var parentCampID = e.target.getAttribute("parentCampId");
		var reallocationID = e.target.getAttribute("reallocationId");
		//Sonali-Task 2690-Download active campaign list in excel


		Swal.fire({
			title:
				"Are you sure you want to send leads to marketo for this campaign?",
			type: "question",
			showCancelButton: true,
			cancelButtonColor: "#d33",
			confirmButtonColor: "#3085d6",
			allowOutsideClick: false,
			confirmButtonText: "Yes",
		}).then((result) => {
			if (result.value) {
				let data = {
					campID: campID,
					campLeadSend: campLeadSend,
					parentCampID: parentCampID,
					reallocationID: reallocationID,
				};
				fetch("/leadMarketo/campaignLeadMarketoIntegration", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				});
				Swal.fire({
					html: "Request submitted to marketo successfully.<br>You will receive mail after lead submitted successfully to marketo.",
					type: "success",
					confirmButtonText: "Ok",
					allowOutsideClick: false,
				});
			}
		});
	}

	/**
	 * @author Priyanka Patil
	 * @param  Description handle leadsTomarketo
	 * @return Description return leads to marketo data
	 */
	leadsTosalesforceHandleChange(e) {
      
		e.preventDefault();
		var campLeadSend = "All";
		var campID = e.target.id;
		var parentCampID = e.target.getAttribute("parentCampId");
		var reallocationID = e.target.getAttribute("reallocationId");
		Swal.fire({
			title:
				"Are you sure you want to send leads to salesforce for this campaign?",
			type: "question",
			showCancelButton: true,
			cancelButtonColor: "#d33",
			confirmButtonColor: "#3085d6",
			allowOutsideClick: false,
			confirmButtonText: "Yes",
		}).then((result) => {
			if (result.value) {
				let data = {
					campID: campID,
					campLeadSend: campLeadSend,
					parentCampID: parentCampID,
					reallocationID: reallocationID,
				};
				fetch("/leadSalesforce/campaignLeadSalesforceIntegration", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				});
				Swal.fire({
					html: "Request submitted to salesforce successfully.<br>You will receive mail after lead submitted successfully to salesforce.",
					type: "success",
					confirmButtonText: "Ok",
					allowOutsideClick: false,
				});
			}
		});
	} //end of leadsTosalesforceHandleChange
	//kiran-4481-uncomment code which is in function call of leadsTosalesforceHandleChange

	/**
	 * @author Shivani Pathak
	 * @description Task-3611 handles when clicked on Action dots (3-dots)
	 */

	onActionClick = (e, record) => {
		e.preventDefault();
		/* Sandeep-task-3752-Agency side--Campaign progress-Action--Add one button-Send lead to marketo-FE */
		let marketoClient = record.marketoClient;
		this.setState({
			marketoClient: marketoClient,
		});
		let sfClient = record.sfClient;//Priyanka-4277-fetching sgClient from record
		//Priyanka-4277-added below condition to enable button when there are accepted leads
		if (this.state.actionPopupSelectedRecord.acceptedLead == null ||
			this.state.actionPopupSelectedRecord.acceptedLead ==="null" || //Rutuja 2978  == and ===
			this.state.actionPopupSelectedRecord.acceptedLead == "" || 
			this.state.actionPopupSelectedRecord.acceptedLead == undefined ||  
			Number(this.state.actionPopupSelectedRecord.acceptedLead) === 0) {  //Rutuja 2978 - added number and == and ===
			this.setState({
				sfClient: null
			});
		}
		else {
			this.setState({
				sfClient: sfClient
			});
		}

		let hubspotClient = record.hubspotClient;
		this.setState({
			hubspotClient: hubspotClient
		});

		//4412-added below code to check client details
		if (marketoClient !== null && marketoClient !== "null" && marketoClient !== undefined && marketoClient !== "undefined") {
			this.setState({client:marketoClient}); //Rutuja 2978 -chnaged state to set state for console wrning 
		} else if (hubspotClient !== null && hubspotClient !== "null" && hubspotClient !== undefined && hubspotClient !== "undefined") {
			this.setState({client:hubspotClient});//Rutuja 2978 -chnaged state to set state for console wrning 
		} else if (sfClient !== null && sfClient !== "null" && sfClient !== undefined && sfClient !== "undefined") {
			this.setState({client:sfClient});//Rutuja 2978 -chnaged state to set state for console wrning 
		} else {
			this.setState({client:null}); //Rutuja 2978 -chnaged state to set state for console wrning 


		}

		if (!this.state.actionPopupVisible) {
			const self = this;
			document.addEventListener(`click`, function onClickOutside() {
				self.setState({
					actionPopupVisible: false,
					actionPopupSelectedRecord: -1,
					marketoClient: marketoClient,
					sfClient: sfClient,
					hubspotClient: hubspotClient,
				});
				document.removeEventListener(`click`, onClickOutside);
			});
		}

		this.setState({
			x: e.clientX,
			y: e.clientY + window.pageYOffset,
			actionPopupVisible: true,
			actionPopupSelectedRecord: record,
			marketoClient: marketoClient,
			sfClient: sfClient,
			hubspotClient: hubspotClient,
		});
	};

	/**
		 * @author Rutuja Jagtap
		 * @param  Description handle leadsToHubspot
		 * @return Description return leads to Hubspot data
		 */
	leadsTohubspotHandleChange(e) {
		e.preventDefault();

		var campLeadSend = "All";
		var campID = e.target.id;
		var parentCampID = e.target.getAttribute("parentCampId");
		var reallocationID = e.target.getAttribute("reallocationId");
		Swal.fire({
			title:
				"Are you sure you want to send leads to Hubspot for this campaign?",
			type: "question",
			showCancelButton: true,
			cancelButtonColor: "#d33",
			confirmButtonColor: "#3085d6",
			allowOutsideClick: false,
			confirmButtonText: "Yes",
		}).then((result) => {
			if (result.value) {
				let data = {
					campID: campID,
					campLeadSend: campLeadSend,
					parentCampID: parentCampID,
					reallocationID: reallocationID,
				};
				fetch("/leadHubspot/campaignLeadHubspotIntegration", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				}).catch((e) => console.log(e));

				Swal.fire({
					html: "Request submitted to Hubspot successfully.<br>You will receive mail after lead submitted successfully to Hubspot.",
					type: "success",
					confirmButtonText: "Ok",
					allowOutsideClick: false,
				});
			}
		});
	} //end of leadsTohubspotHandleChange



	/**
  @author Sunita
  * This seperate function is created and called below to download excel file from active tab and completed tab depending on the filter on FE
  * Dowloaded file will contain campaign details 
 */

	downloadExcel() {
		//Sonali - Task 2988- Below code is added to create excel sheet and download data from downloadfiles in that excel
		let downloadFiles = this.state.downloadFiles;
		const { user } = this.props.auth;
		//       /* make the worksheet */

		if (this.state.downloadFiles.length > 0) {
			var ws = xlsx.utils.json_to_sheet(downloadFiles);
			/* add to workbook */
			var wb = xlsx.utils.book_new();
			xlsx.utils.book_append_sheet(wb, ws, "Lead");

			// /* write workbook */
			var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" }); // generate a nodejs buffer
			//var str = xlsx.write(wb, { bookType: "xlsx", type: "binary" }); //Rutuja 2978
			/* generate a download */
			function s2ab(s) {
				var buf = new ArrayBuffer(s.length);
				var view = new Uint8Array(buf);
				for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;//Rutuja 2978 changed = to == for console warning
				return buf;
			}
			var fileName; //Rutuja 2978 declared fileName out side if else loop
			//sunita-task-2996- as per campaign status it will download the report for active and completed tab
			if (this.state.campstatus === "Completed") {
				 fileName = user.id + "_Completed_campaign_Progress_Report.xlsx";
			} else {
				 fileName = user.id + "_Active_campaign_Progress_Report.xlsx";
			}
			saveAs(
				new Blob([s2ab(buf)], { type: "application/octet-stream" }),
				fileName
			);
			// window.location.reload();Sonali-Putting this reload function in comment because it is giving some issue while downloading file
		} else {
		}
	}

	/**
	 * @author Sonali
	 * This function is added to download excel file from active tab and completed tab depending on the filter on FE
	 * Dowloaded file will contain campaign details
	 */

	downloadCampaignExcel(e) {
		e.preventDefault(); // karan-task-3921-back button issue
		//sunita-task-2996- these all varibles are used in willmount am accessing here as per report requirement for downloading the report and for sending the data to backend
		var parsed = this.props.location.state; //karan-task-3684-replace query params
		var key = parsed.key;
		const { user } = this.props.auth;
		var fromDate = this.state.fromDate;
		var toDate = this.state.toDate;
		var clientName = parsed.clientName;
		var publisherName = parsed.publisherName;
		var from = parsed.from;
		var campstatus = this.state.campstatus;
		var byPublisher = parsed.byPublisher === true ? "true" : "false"; //karan-task-3929-count mismatched
		var tabName = this.state.tabName;
		var agencyID = user.id;
		this.setState({ agencyID: agencyID });
		var isprogressBtnDisable = this.state.isprogressBtnDisable; // sunita-task-3060-added this variable for disabling the progressreport button as per data .

		//sunita-task-2996- according to tabName it will download the campaign progress report excel on campaign list page.
		var data;
		let campaignDetails = [...this.state.campaignDetails];
		if (campaignDetails.length === 0) {
			isprogressBtnDisable = true; //sunita-task-3060-added this code when data is not present in table as per condition it will disbal or enable the download button
		} else {
			isprogressBtnDisable = false; //sunita-task-3060-added this code when data is not present in table as per condition it will disbal or enable the download button
		}
		this.setState({ isprogressBtnDisable }); //sunita-task-3060- gives the updated value isprogressBtnDisable.

		if (tabName === "Completed") {
			//Sonali-Task 2988-fromDownloadExcel is added for BE to identify that we are coming from download excel function

			if (this.state.label === "Ending this month") {
				//sunita-task-2996- this data is used when tabname is completed.
				data = {
					pacing: "Monthly",
					byPacing: true,
					tab: tabName,
					fromDate: fromDate,
					toDate: toDate,
					fromCampaigntoAllocate: false,
					fromDownloadExcel: true,
				}; //karan-task-3796-vapt remove ids
				this.state.label = "Ending this month"; //Rutuja 4810 facing issue while removing console warning
				//this.setState({lable:"Ending this month"});//Rutuja 2978 -commented  this.state and used setstate for console wrning 
				
			} else {
				if (key === "active") {
					//Sonali-Task-2988-When we go on campaignlist page from "Campaigns to allocate" then key is active in the url,in this case following data will be sent to BE
					data = {
						pacing: "All",
						fromCampaigntoAllocate: true,
						fromDownloadExcel: true,
					}; //karan-task-3796-vapt remove ids
				}
				//sunita-task-2996- this data is used when we are changing filter in  completed tab only.
				data = {
					pacing: this.state.pacing,
					byPacing: true,
					fromDate: fromDate,
					toDate: toDate,
					fromCampaigntoAllocate: false,
					fromDownloadExcel: true,
				}; //karan-task-3796-vapt remove ids
			}
		} else {
			//Sonali-Task 2988-fromDownloadExcel is added for BE to identify that we are coming from download excel function
			//sunita-task-2996-this data is used when other tabname will come i.e except completed
			data = {
				pacing: this.state.pacing,
				byPacing: true,
				tab: tabName,
				fromCampaigntoAllocate: this.state.fromCampaigntoAllocate,
				campstatus: tabName,
				fromDownloadExcel: true,
			}; //karan-task-3796-vapt remove ids
		}

		//sunita-task-2996- this below all conditions are used when am coming from dashboard or on campaign list page as per fromdate and to date and accoring to tab name it will get data
		let dashboardData;
		if (fromDate === "" && toDate === "") {
			if (tabName === "Completed") {
				//sunita-task-2996- this below data is sent as per tabName completed on campaign list page to download the report
				if (this.state.label === "Ending this month") {
					//Sonali-Task 2988-fromDownloadExcel is added for BE to identify that we are coming from download excel function
					dashboardData = {
						clientName: clientName,
						pID: publisherName,
						tab: "All",
						// tab:tabName,
						byPacing: true,
						pacing: "Monthly",
						campstatus: tabName,
						fromDownloadExcel: true,
					}; //karan-task-3796-vapt remove ids
				} else {
					//sunita-task-2996-this data is sent as per filter in completed tab on campaign list page to download the report
					//Sonali-Task 2988-fromDownloadExcel is added for BE to identify that we are coming from download excel function
					dashboardData = {
						clientName: clientName,
						pID: publisherName,
						tab: "All",
						byPacing: true,
						pacing: this.state.pacing,
						campstatus: tabName,
						fromDownloadExcel: true,
					}; //karan-task-3796-vapt remove ids
				}
			} else {
				//sunita-task-2996-this data is sent when other tabname is coming other except completed on campaign list page to dowload the report
				//Sonali-Task 2988-fromDownloadExcel is added for BE to identify that we are coming from download excel function
				dashboardData = {
					clientName: clientName,
					pID: publisherName,
					tab: "All",
					// tab:tabName,
					byPacing: true,
					pacing: this.state.pacing,
					campstatus: tabName,
					fromDownloadExcel: true,
				}; //karan-task-3796-vapt remove ids
			}
		} else {
			//sunita-task-2996-this data is sent when we are coming from dashboard (i.e from bargraph) it gets from date and to date for downloading the report.
			//Sonali-Task 2988-fromDownloadExcel is added for BE to identify that we are coming from download excel function
			dashboardData = {
				fromDate: fromDate,
				toDate: toDate,
				clientName: clientName,
				pID: publisherName,
				fromDownloadExcel: true,
			}; //karan-task-3796-vapt remove ids
		}

		//sunita-task-2996- these below all conditions are used when we are coming from dashboard by advertiser or by publisher
		if (byPublisher === "true" && from === "byCampaign") {
			//Sonali-Task 2988-When we come on campaignList page by clicking on publisher tab(on agency dashboard)--->bar chart
			if (campstatus === "Completed") {
				if (fromDate === "" && toDate === "") {
					//sunita-task-2996-download the completedcampaign excel when from date and to date is blank
					fetch("/agencyDashboard/completeCampaignInfo", {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(dashboardData),
					})
						.then((res) => res.json())
						.then((downloadFiles) => {
							this.setState({ downloadFiles: downloadFiles });
							this.downloadExcel();
							this.setState({ campaignDetails });
						})
						.catch(function (err) {
							console.log(err);
						});
				} else {
					//Sonali-Task 2988-when we are on completed tab
					fetch("/agencyDashboard/publisherCompleteCampaign", {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(dashboardData),
					})
						.then((res) => res.json())
						.then((downloadFiles) => {
							this.setState({ downloadFiles: downloadFiles });
							this.downloadExcel();
							this.setState({ campaignDetails });
						})
						.catch(function (err) {
							console.log(err);
						});
				}
			}
			if (campstatus === "Active") {
				if (fromDate === "" && toDate === "") {
					//Sonali-Task 2690-Download active campaign list in excel
					fetch("/reports/dashboardActiveCampaignInExcel", {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(dashboardData),
					})
						.then((res) => res.json())
						.then((downloadFiles) => {
							this.setState({ downloadFiles: downloadFiles });
							this.downloadExcel();
							this.setState({ campaignDetails });
						})
						.catch(function (err) {
							console.log(err);
						});
				} else {
					//Sonali-Task 2690-Download active campaign list in excel
					fetch("/agencyDashboard/publisherActiveCampaign", {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(dashboardData),
					})
						.then((res) => res.json())
						.then((downloadFiles) => {
							this.setState({ downloadFiles: downloadFiles });
							this.downloadExcel();
							this.setState({ campaignDetails });
						})
						.catch(function (err) {
							console.log(err);
						});
				}
			}
		} else if (clientName) {
			//Sonali-Task 2988-When we come on campaign list page by clicking on advertiser tab--->Active campaign on bar chart on dashboard
			if (campstatus === "Active") {
				//Sonali-Task 2690-Download active campaign list in excel
				fetch("/reports/dashboardActiveCampaignInExcel", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(dashboardData),
				})
					.then((res) => res.json())
					.then((downloadFiles) => {
						this.setState({ downloadFiles: downloadFiles });
						this.downloadExcel();
						this.setState({ campaignDetails });
					})
					.catch(function (err) {
						console.log(err);
					});
			} else {
				fetch("/agencyDashboard/completeCampaignInfo", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(dashboardData),
				})
					.then((res) => res.json())
					.then((downloadFiles) => {
						this.setState({ downloadFiles: downloadFiles });
						this.downloadExcel();
						this.setState({ campaignDetails });
					})
					.catch(function (err) {
						console.log(err);
					});
			}
		} else {
			//sunita-task-2996-below conditions are given as per key when we are coming from dashboard and after that we are doing other actions like tab change or filter change on camapign list page it will downlaod report.
			//Sonali-Task 2988-This route in else is added to download completed campaign list in excel file
			if (
				tabName === "Active" &&
				(key === undefined || key === "active" || key === "campaignSetup") &&
				this.state.fromCampaigntoAllocate === false
			) {
				//Sonali-Task 2690-Download active campaign list in excel
				fetch("/reports/dashboardActiveCampaignInExcel", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((downloadFiles) => {
						this.setState({ downloadFiles: downloadFiles });
						this.downloadExcel();
						this.setState({ campaignDetails });
					})
					.catch(function (err) {
						console.log(err);
					});
			} else if (
				key === "active" &&
				this.state.fromCampaigntoAllocate === true
			) {
				//Sonali-Task 2690-Download active which are available for allocation("Campaigns to allocate link on dashboard")campaign list in excel
				fetch("/agencyDashboard/partialCampaignList", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((downloadFiles) => {
						this.setState({ downloadFiles: downloadFiles });
						this.downloadExcel();
						this.setState({ campaignDetails });
					})
					.catch(function (err) {
						console.log(err);
					});
			} else {
				fetch("/agencyDashboard/completeCampaignInfo", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((downloadFiles) => {
						this.setState({ downloadFiles: downloadFiles });
						this.downloadExcel();
						this.setState({ campaignDetails });
					})
					.catch(function (err) {
						console.log(err);
					});
			}
		}
	}

	showLeadAssignTOPublisher(campID) {
		fetch("/publisher/viewPubAssignLead?campID=" + campID + "", {
			method: "GET",
		}) //karan-task-3796-vapt remove ids
			.then((res) => res.json())
			.then((onClickAllocatedLead) => {
				var campaignStatus = onClickAllocatedLead[0].campaignStatus;
				let requiredLeadPerAsset = onClickAllocatedLead[0].requiredLeadPerAsset;
				this.setState({
					onClickAllocatedLead: onClickAllocatedLead,
					campaignStatus: campaignStatus,
					requiredLeadPerAsset: requiredLeadPerAsset,
				});
			});

		fetch("/publisher/liveCampaignDetails?campID=" + campID + "", {
			method: "GET",
		}) //karan-task-3796-vapt remove ids
			.then((res) => res.json())
			.then((onClickAllocatedLeadPubList) => {
				this.setState({
					onClickAllocatedLeadPubList: onClickAllocatedLeadPubList,
					passCampID: campID,
				});
			});

		/**
		 * @author Ram Chander
		 * @param  Description handle the assign delivered lead
		 * @return Description return delivered lead array
		 */

		fetch("/publisher/viewPubAssignDeliveredLead?campID=" + campID + "", {
			method: "GET",
		}) //karan-task-3796-vapt remove ids
			.then((res) => res.json())
			.then((assignDeliveredLead) => {
				this.setState({
					assignDeliveredLead: assignDeliveredLead,
				});
			});
	}

	handleChangeReport() {
		var followUpReport = this.state.followUpReport; //sunita-task-2913-added this varibal to display the status of send follow up report button(sending or send follow up).
		fetch("reports/publisherReports", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
		}) //karan-task-3796-vapt remove ids
			.then((res) => res.json())
			.then((res) => {
				if (res.success === true) {
					followUpReport = true; //sunita-task-2913-as per value it will display the status of send follow up report button(sending or send follow up).
					var successMessage = res.message;
					Swal.fire({
						text: successMessage,
						type: "success",
						confirmButtonText: "Ok",
						allowOutsideClick: false,
						preConfirm: () => {
							//const { user } = this.props.auth; //Rutujs 2978 commented user as never used console warning
							this.props.history.go(0); // karan-task-3714-vapt header and query params
						},
					});
					this.setState({ followUpReport }); //sunita-task-2913-getting updated value
				}
				if (res.failed === true) {
					followUpReport = false; //sunita-task-2913-as per value it will display the status of send follow up report button(sending or send follow up).
					var failMessage = res.message;
					Swal.fire({
						text: failMessage,
						type: "error",
						confirmButtonText: "Ok",
						allowOutsideClick: false,
						preConfirm: () => {
							// const { user } = this.props.auth; //Rutujs 2978 commented user as never used console warning
							this.props.history.go(0); // karan-task-3714-vapt header and query params
						},
					});
					this.setState({ followUpReport }); //sunita-task-2913-getting updated value
				}
			})
			.catch(function (err) {
				console.log(err);
			});
	} //end of handleChangeReport

	getAllocatedLeads(e) {
		var str = e.target.value;
		var string = str.split("+");
		var campID = string[1];
		var pID = string[0];

		let data = {
			campID: campID,
			pID: pID,
		};
		fetch("publisher/getAllocatedLeadsForPub", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((publisherAllocatedLeads) => {
				this.setState({
					allocatedLeadForDec: publisherAllocatedLeads[0].allocatedLead,
					publisherDetailsForDec: publisherAllocatedLeads,
					publisherID: publisherAllocatedLeads[0].pID,
				});
			});
		this.showLeadAssignTOPublisher(campID);
	} //end of getAllocatedLeads

	fadeInOut(e) {
		var id = e.target.id;
		if (id === "messages") {
			this.setState((state) => ({
				panelBlock: "message",
				show: "block",
			}));
		} else {
			this.setState((state) => ({
				panelBlock: "alert",
				show: "block",
			}));
		}
	}
	//Sonali-Task 2988-When we come on campaignList page by clicking on publisher tab(on agency dashboard)--->bar chart

	closeChat() {
		this.setState((state) => ({
			show: "none",
		}));
	}

	leadAllocationHandleChange(e) {
		let errors = {};
		let fields = this.state;
		fields[e.target.name] = e.target.value;
		this.setState({
			fields,
		});
		if (
			this.state.reTotalLeads === undefined ||
			this.state.retotalleads === ""
		) {
			errors["leadAllocation"] = (
				<li>Please fill below required fields leadAllocation</li>
			);
			this.setState({ alertDisplay: "block", alertBorder: "1px soild red" });
		} else if (
			(this.state.reTotalLeads === undefined ||
				this.state.retotalleads === "") &&
			this.state.reBudget !== ""
		) {
			errors["leadAllocation"] = (
				<li>Please fill below required fields leadAllocation</li>
			);
			this.setState({
				alertDisplay: "block",
				alertBorder: "1px soild red",
				reBudget: this.state.reBudget,
				reCpl: "",
			});
		} else if (
			(this.state.reTotalLeads === undefined ||
				this.state.retotalleads === "") &&
			this.state.reCpl !== ""
		) {
			errors["leadAllocation"] = (
				<li>Please fill below required fields leadAllocation</li>
			);
			this.setState({
				alertDisplay: "block",
				alertBorder: "1px soild red",
				reBudget: "",
				reCpl: this.state.reCpl,
			});
		} else {
			var pattern = new RegExp(/^(|[1-9]\d*)$/);
			if (!pattern.test(fields["reTotalLeads"])) {
				errors["leadAllocation"] = (
					<li>Please enter only numerical values for total leads</li>
				); //shivani -task 2712-updated this message
				this.setState({
					errors: errors,
					alertBorder: "1px solid red",
					alertWidth: "1000px",
					alertAlign: "center",
					alertDisplay: "block",
				});
			} else {
				errors["leadAllocation"] = "";
				this.setState({
					errors: "",
					alertBorder: "0px solid white",
					alertWidth: "1000px",
					alertAlign: "center",
					alertDisplay: "block",
				});
				if (this.state.reBudget !== "") {
					var value1 = this.state.reTotalLeads;
					var value2 = this.state.reBudget;
					var name1 = "reCpl";
					var newCPL = value2 / value1;
					var a = isFinite(newCPL);
					var b = isNaN(newCPL);
					if (a === false || b === true) {
						newCPL = "";
						this.setState({
							[name1]: newCPL,
						});
					} else {
						newCPL = newCPL.toFixed(2);
						this.setState({
							[name1]: newCPL,
						});
					}
				} else if (this.state.reCpl !== "") {
					let value2 = this.state.reCpl;

					let name1 = "reBudget";
					let newbudget = value2 * value1;
					let a = isFinite(newbudget);
					let b = isNaN(newbudget);
					if (a === false || b === true) {
						newbudget = "";
						this.setState({
							[name1]: newbudget,
						});
					} else {
						newbudget = newbudget.toFixed(2);
						this.setState({
							[name1]: newbudget,
						});
					}
				}
			}
		}
	}

	budgetHandleChange(e) {
		let errors = {};
		let fields = this.state;
		fields[e.target.name] = e.target.value;
		var value = e.target.value;
		fields[e.target.name] = e.target.value;
		var value1 = this.state.reTotalLeads;
		var name1 = "reCpl";
		var pattern = new RegExp(/^[1-9]\d*(\.\d{1,2})?$/);
		if (!pattern.test(this.state.reBudget)) {
			errors["budget"] = <li>Please enter only numerical values for budget</li>; //shivani-task 2712-updated error message .
			this.setState({
				errors: errors,
				alertBorder: "1px solid red",
				alertWidth: "1000px",
				alertAlign: "center",
				reCpl: "",
			});
		} else {
			this.setState({
				errors: "",
				alertBorder: "0px solid white",
				alertWidth: "1000px",
				alertAlign: "center",
			});
			if (
				(this.state.reCpl === "" || this.state.reCpl === undefined) &&
				(this.state.reTotalLeads === "" ||
					this.state.reTotalLeads === undefined) &&
				fields["reBudget"]
			) {
				this.setState({
					reCpl: "",
				});
			} else if (
				(this.state.reTotalLeads !== "" ||
					this.state.reTotalLeads !== undefined) &&
				fields["reBudget"]
			) {
				var newCPL = value / value1;
				newCPL = newCPL.toFixed(2);
				this.setState({
					fields,
					[name1]: newCPL,
				});
			}
		}
	} //end of budgetHandleChange

	cplHandleChange(e) {
		let errors = {};
		let fields = this.state;
		var value = e.target.value;
		fields[e.target.name] = e.target.value;
		var value1 = this.state.reTotalLeads;
		var name1 = "reBudget";
		var pattern = new RegExp(/^[1-9]\d*(\.\d{1,2})?$/);
		if (!pattern.test(this.state.reCpl)) {
			errors["cpl"] = <li>Please enter only numerical values for cpl</li>; //shivani-task 2712-updated error message.
			this.setState({
				errors: errors,
				alertBorder: "1px solid red",
				alertWidth: "1000px",
				alertAlign: "center",
				reBudget: 0,
			});
		} else {
			this.setState({
				errors: "",
				alertBorder: "0px solid white",
				alertWidth: "1000px",
				alertAlign: "center",
			});
		}

		if (fields["reTotalLeads"] && fields["reCpl"]) {
			var newbudget = value * value1;
			newbudget = newbudget.toFixed(2);
			this.setState({
				fields,
				[name1]: newbudget,
			});
		} else {
			this.setState({
				reBudget: "",
			});
		}
	}

	campaignSetup(e) {
		e.preventDefault();
		var advCampID = e.target.getAttribute("advCampID");
		let agencyAllocationID = e.target.id;
		this.props.history.push("/createcampaign2", {
			agencyAllocationID,
			advCampID,
		}); // karan-task-3767-replace query params
	}

	/**
	 * @author Narendra Phadke
	 * @param  Description handle Cancel campaign
	 * @return Description return campaign moved to cancel tab
	 */
	cancelCampaign(e) {
		e.preventDefault();
		const { user } = this.props.auth;
		var agencyID = user.id;
		this.setState({ agencyID: agencyID });

		var campID = e.target.id;
		var parentCampID = e.target.getAttribute("parentCampId");
		var reallocationID = e.target.getAttribute("reallocationId");
		//Sonali-Task 2690-Download active campaign list in excel

		Swal.fire({
			title: "Are you sure you want to cancel this campaign?",
			type: "question",
			showCancelButton: true,
			cancelButtonColor: "#d33",
			confirmButtonColor: "#3085d6",
			allowOutsideClick: false,
			confirmButtonText: "Yes",
		}).then((result) => {
			if (result.value) {
				let data = {
					campID: campID,
					parentCampID: parentCampID,
					reallocationID: reallocationID,
				}; //karan-task-3796-vapt remove ids
				fetch("/agencyDashboard/agencyCancelCampaign", {
					//insert data in publisher_assignment table
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((response) => {
						var message1 = response.message;
						Swal.fire({
							text: message1,
							type: "success",
							confirmButtonText: "Ok",
							allowOutsideClick: false,
							preConfirm: () => {
								//sunita-task-2996- tabNameKey-when we are changing the filter value for particular tab  it will store that tab name used for BE and bydefault tab
								var data = {
									pacing: this.state.pacing,
									byPacing: true,
									tabNameKey: "All",
									tab: "All",
								}; //karan-task-3796-vapt remove ids

								fetch("/agencyDashboard/agencyDashboardCount", {
									method: "POST",
									headers: { "Content-Type": "application/json" },
									body: JSON.stringify(data),
								})
									.then((res) => res.json())
									.then((dashboardCampaignCount) => {
										//sunita-task-2996-noChangeDashbordCampaignCount-this array used for storing the actual count of tab.
										//sunita-task-2996-when we are getting key as active from dashboard
										if (this.state.key === "active") {
											this.setState({
												partialCount: dashboardCampaignCount[0].activeCount,
											});
										}
										this.setState({
											dashboardCampaignCount: dashboardCampaignCount,
											noChangeDashbordCampaignCount: dashboardCampaignCount,
										});
									})
									.catch(function (err) {
										console.log(err);
									});

								if (this.state.tab === "Active") {
									fetch("/agencyDashboard/dashboardActiveCampaign", {
										method: "POST",
										headers: { "Content-Type": "application/json" },
										body: JSON.stringify(data),
									})
										.then((res) => res.json())
										.then((campaignDetails) => {
											this.setState({
												campaignDetails: campaignDetails,
												loader_progress: "",
											}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
										})
										.catch(function (err) {
											console.log(err);
										});
								} else if (this.state.tab === "All") {
									fetch("/agencyDashboard/dashboardAllCampaign", {
										method: "POST",
										headers: { "Content-Type": "application/json" },
										body: JSON.stringify(data),
									})
										.then((res) => res.json())
										.then((campaignDetails) => {
											this.setState({
												campaignDetails: campaignDetails,
												loader_progress: "",
											}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
										})
										.catch(function (err) {
											console.log(err);
										});

									fetch("/agencyDashboard/agencyDashboardCount", {
										method: "POST",
										headers: { "Content-Type": "application/json" },
										body: JSON.stringify(data),
									})
										.then((res) => res.json())
										.then((dashboardCampaignCount) => {
											this.setState({
												dashboardCampaignCount: dashboardCampaignCount,
											});
										})
										.catch(function (err) {
											console.log(err);
										});
								} else if (this.state.tab === "Pause") {
									fetch("/agencyDashboard/dashboardPausedCampaignNew", {
										method: "POST",
										headers: { "Content-Type": "application/json" },
										body: JSON.stringify(data),
									})
										.then((res) => res.json())
										.then((campaignDetails) => {
											this.setState({
												campaignDetails: campaignDetails,
												loader_progress: "",
											}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
										})
										.catch(function (err) {
											console.log(err);
										});

									fetch("/agencyDashboard/agencyDashboardCount", {
										method: "POST",
										headers: { "Content-Type": "application/json" },
										body: JSON.stringify(data),
									})
										.then((res) => res.json())
										.then((dashboardCampaignCount) => {
											this.setState({
												dashboardCampaignCount: dashboardCampaignCount,
											});
										})
										.catch(function (err) {
											console.log(err);
										});
								} else if (this.state.tab === "Completed") {
									fetch("/agencyDashboard/dashboardArchiveCampaign", {
										method: "POST",
										headers: { "Content-Type": "application/json" },
										body: JSON.stringify(data),
									})
										.then((res) => res.json())
										.then((campaignDetails) => {
											this.setState({
												campaignDetails: campaignDetails,
												loader_progress: "",
											}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
										})
										.catch(function (err) {
											console.log(err);
										});

									fetch("/agencyDashboard/agencyDashboardCount", {
										method: "POST",
										headers: { "Content-Type": "application/json" },
										body: JSON.stringify(data),
									})
										.then((res) => res.json())
										.then((dashboardCampaignCount) => {
											this.setState({
												dashboardCampaignCount: dashboardCampaignCount,
											});
										})
										.catch(function (err) {
											console.log(err);
										});
								}
							},
						});

						this.setState({ inputClass: "disabled" });
					})
					.catch(function (err) {
						console.log(err);
					});
			}
		});
	}
	/**
	 * @author Supriya Gore
	 * @param  Description handle the End Date Of Campaign
	 * @return Description return All End Date Of Campaign
	 */

	validateForm2() {
		let errors = {};
		let formIsValid = true;
		let newDecdate = true;
		var dt = dateTime.create();
		var formatted = dt.format("Y-m-d");

		for (var i = 0; i < this.state.endDateCampaign.length; i++) {
			if (this.state.endDateCampaign[i].endDate < formatted) {
				errors["commonError"] = (
					<li>End Date should be greater than current date</li>
				);
				newDecdate = false;
				this.setState({ alertDisplay: "block", alertBorder: "1px soild red" });
			} else {
			}
		}

		if (this.state.endDate < formatted) {
			errors["commonError"] = (
				<li>End Date should be greater than current date</li>
			);
			newDecdate = false;
			this.setState({ alertDisplay: "block", alertBorder: "1px soild red" });
		} else {
		}
		if (newDecdate === false) {
			formIsValid = false;
		} else {
			formIsValid = true;
			this.setState({ alertBorder: "0px soild white" });
		}

		this.setState({
			errors: errors,
			alertBorder: "1px solid red",
			alertWidth: "1000px",
			alertAlign: "center",
			alertDisplay: "block",
		});

		return formIsValid;
	} //end of validateForm2

	handleChangeEndDateCampaign(e) {
		e.preventDefault();
		e.target.className += " was-validated";
		if (this.validateForm2() === false) {
			$("#editEndDate1").modal("show");
			//Sandeep-task-3227-added code to show modal
			return;
		} else {
			this.setState({
				alertBorder: "0px solid white",
			});
		}
		$("#editEndDate1").modal("hide");
		//Sandeep-task-3227-added code to hide modal
		let data = {
			//sunita-task-2996- added this change to enddatecampaign when we are coming from completed tab in that live action bcz when we are not changing end date then it gives unefined to BE
			endDateCampaign: this.state.endDateCampaign,
			campID: e.target.id,
		}; //karan-task-3796-vapt remove ids

		fetch("/agency/backToLiveCampaign", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((response) => {
				this.setState({ response: response });

				Swal.fire({
					text: "Respective Campaign has moved to Active Tab.",
					type: "success",
					confirmButtonText: "Ok",
					allowOutsideClick: false,
					preConfirm: () => {
						window.location.reload();
					},
				});

				this.setState({ inputClass: "disabled" });
			});
	}

	/**
	 * @author Supriya Gore
	 * @param  Description handle the End Date Of Campaign
	 * @return Description return All End Date Of Campaign
	 */
	handleChangeEndDate(e) {
		var endDate = e.target.value;
		var name = "endDate";
		var endDateCampaign = [...this.state.endDateCampaign];
		endDateCampaign[0] = { ...endDateCampaign[0], [name]: endDate };
		this.setState({ endDateCampaign: endDateCampaign, endDate: endDate });
	}

	/**
	 * @author Supriya Gore
	 * @param  Description handle the End Date Of Campaign
	 * @return Description return All End Date Of Campaign
	 */
	handleEndDate(e) {
		var campID = e.target.id;
		var advCampID = e.target.getAttribute("advCampID");
		let data = {
			campID: advCampID,
		};

		//update the status in publisher_allocation table
		fetch("/agency/endDateCampaign", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((endDateCampaign) => {
				this.setState({
					endDateCampaign: endDateCampaign,
					campID: campID,
					advCampID: advCampID,
				});
			});
	}

	modalHandleClose(e) {
		this.setState({ modalShow: false });
	}

	getDetails(e) {
	e.preventDefault(); //Nilesh-4900 Add preventDefault
		var parentCampID = e.target.getAttribute("parentCampId");
		var reallocationID = e.target.getAttribute("reallocationId");
		this.setState({
			parentCampID: parentCampID,
			reallocationID: reallocationID,
		});

		Swal.fire({
			title: "Are you sure to clone the campaign?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, clone it!",
		}).then((result) => {
			if (result.value) {
				this.setState({ modalShow: true });
			}
		});
	}

	creativesReview(e) {
		const { user } = this.props.auth;
		var agencyID = user.id;
		var campID = e.target.getAttribute("campID");

		var parentCampID = e.target.getAttribute("parentCampId");
		var reallocationID = e.target.getAttribute("reallocationId");
		var backKey = "Dash";
		this.props.history.push("/agencyCreativesReview", {
			campID,
			parentCampID,
			reallocationID,
			agencyID,
			backKey,
		}); //karan-task-3715-replace query params
	}

	leadReview(e) {
		const { user } = this.props.auth;
		var agencyID = user.id;
		var campID = e.target.getAttribute("campID");

		var parentCampID = e.target.getAttribute("parentCampId");
		var reallocationID = e.target.getAttribute("reallocationId");
		this.props.history.push("/agencyReviewLead", {
			campID,
			parentCampID,
			reallocationID,
			agencyID,
		}); //karan-task-3715-replace query params
	}

	handleRefreshPage(e) {
		this.props.history.go(0); //karan-task-3684-replace query params
	}

	getDecrementalDetails(e) {
		var campID = e.target.getAttribute("campID");
		var parentCampID = e.target.getAttribute("parentCampId");
		var reallocationID = e.target.getAttribute("reallocationId");
		var pID = e.target.getAttribute("pID");

		this.setState({ parentCampID, reallocationID, campID });

		let data = {
			campID: campID,
			parentCampID: parentCampID,
			reallocationID: reallocationID,
			pID: pID,
		};
		fetch("publisher/getPublisherList", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((publisherList) => {
				if (publisherList.length > 0) {
					let publisherDetailsForDec = [];
					publisherDetailsForDec.push(publisherList[0]);
					this.setState({
						publisherList: publisherList,
						allocatedLeadForDec: publisherList[0].allocatedLead,
						publisherID: publisherList[0].pID,
						publisherDetailsForDec: publisherDetailsForDec,
					});
				}
			});

		this.showLeadAssignTOPublisher(campID);
	}

	handleAllocatedLeads(e) {
		var campID = e.target.getAttribute("campID");
		var parentCampID = e.target.getAttribute("parentCampId");
		var reallocationID = e.target.getAttribute("reallocationId");
		var pID = e.target.getAttribute("pID");
		this.setState({ parentCampID, reallocationID });

		let data = {
			campID: campID,
			pID: pID,
		};

		fetch("/agencyDashboard/campaignDetails", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((incrementCampDetails) => {
				this.setState({
					incrementCampDetails: incrementCampDetails,
					campID: campID,
				});
			});
	}

	handleDecrementLeads(e) {
		var name = e.target.name;
		var value = parseFloat(e.target.value);
		let errors = {};
		var allocatedLead = e.target.id;
		var decrementLeads = value; // decrement Leads

		if (Number.isNaN(decrementLeads) === true) {
			decrementLeads = 0;
		}
		if (decrementLeads < 0) {
			decrementLeads = 0;
		}
		this.setState({ decrementLeads: decrementLeads });
		allocatedLead = parseInt(allocatedLead);

		if (value == null || value === "" || value === "undefind") {
			errors["wholenumber"] = <li>Please Enter Value For Lead Decremental</li>;
			this.setState({
				errors: errors,
				alertBorder: "1px solid red",
				alertWidth: "1000px",
				alertAlign: "center",
				alertDisplay: "block",
			});
		} else {
			var pattern = new RegExp(/^(|[1-9]\d*)$/);
			if (!pattern.test(value)) {
				errors["wholenumber"] = <li>Please enter only numerical values</li>;
				this.setState({
					errors: errors,
					alertBorder: "1px solid red",
					alertWidth: "1000px",
					alertAlign: "center",
					alertDisplay: "block",
				});
			} else {
				errors["wholenumber"] = "";

				this.setState({
					errors: errors,
					alertBorder: "0px solid white",
					alertWidth: "1000px",
					alertAlign: "center",
					alertDisplay: "block",
				});
			}

			if (value > allocatedLead) {
				errors["commonError"] = <li>Please enter smaller value</li>;
				this.setState({
					errors: errors,
					alertBorder: "1px solid red",
					alertWidth: "1000px",
					alertAlign: "center",
					alertDisplay: "block",
					inputClass: "disabled",
				});
			} else if (value === allocatedLead) {
				errors["commonError"] = (
					<li>Please use cancel operation to decrement all the leads</li>
				);
				this.setState({
					errors: errors,
					alertBorder: "1px solid red",
					alertWidth: "1000px",
					alertAlign: "center",
					alertDisplay: "block",
					inputClass: "disabled",
				});
			} else {
				errors["commonError"] = "";
				this.setState({
					errors: errors,
					alertWidth: "1000px",
					alertAlign: "center",
					alertDisplay: "block",
					inputClass: "btn add-button",
				});
			}
		}

		this.setState({
			leadIncDecStatus: name,
		});

		let data = {
			campID: this.state.campID,
			pID: this.state.publisherID,
		};

		this.setState({
			decrementLeads: value,
		});

		fetch("/publisher/allocatedLeadsToPublisherForDecrement", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		}) //karan-task-3796-vapt remove ids
			.then((res) => res.json())
			.then((onClickAllocatedLeadPublisherDecrement) => {
				this.setState({
					onClickAllocatedLeadPublisherDecrement:
						onClickAllocatedLeadPublisherDecrement,
				});
			});
	}

	handleIncrementLeads(e) {
		var value = e.target.value;
		this.setState({
			incrementLeads: value,
		});
	}

	handleChangeDate(e) {
		const { name, value } = e.target;
		let incrementCampDetails = [...this.state.incrementCampDetails];
		incrementCampDetails[0] = { ...incrementCampDetails[0], [name]: value };
		this.setState({ incrementCampDetails });
	}

	validateForm() {
		let errors = {};
		let incdecleads = true;
		let formIsValid = true;
		let pubEndDate = true; //sunita – Task -2947- Used for validating publisher end date

		var dt = dateTime.create();
		var formatted = dt.format("Y-m-d");

		var currentDate = new Date(
			new Date().getTime() - new Date().getTimezoneOffset() * 60000
		)
			.toISOString()
			.split("T")[0];

		var dd = new Date(currentDate);
		dd.setMonth(dd.getMonth() - 1);

		if (
			this.state.incrementLeads === undefined ||
			this.state.incrementLeads === "" ||
			this.state.incrementLeads === null
		) {
			incdecleads = false;
			errors["commonError"] = <li>Please fill below required fields</li>;
			this.setState({ alertBorder: "1px solid red", display: "block" });
		} else {
			var pattern = new RegExp(/^(|[1-9]\d*)$/);
			if (!pattern.test(this.state.incrementLeads)) {
				errors["commonError"] = <li>Please enter only numerical values</li>;
				incdecleads = false;
				this.setState({ alertBorder: "1px solid red", display: "block" });
			} else if (this.state.incrementCampDetails[0].campEndDate < formatted) {
				incdecleads = false;
			} else {
			}
		}
		if (this.state.incrementCampDetails[0].campEndDate < currentDate) {
			incdecleads = false;
			errors["Currentdate"] = <li>End date can't be less than current date</li>;
			this.setState({ alertBorder: "1px solid red", display: "block" });
		}

		if (
			this.state.incrementCampDetails[0].campEndDate <
			this.state.incrementCampDetails[0].startDate
		) {
			incdecleads = false;
			errors["EnddateValidate"] = (
				<li>Please enter end date greater than start date</li>
			);
			this.setState({ alertBorder: "1px solid red", display: "block" });
		}

		if (incdecleads === false) {
			formIsValid = false;
		} else {
			formIsValid = true;
		}

		//sunita – Task -2947- start of validation for end date as per publisher end date

		if (
			this.state.incrementCampDetails[0].campEndDate <
			this.state.incrementCampDetails[0].publisherEndDate
		) {
			pubEndDate = false;

			errors["pubEnddate"] = (
				<li>End date must be greater than publisher end date</li>
			);
			this.setState({ alertAlign: "1 px solid red", display: "block" });
		}

		if (pubEndDate === false || incdecleads === false) {
			formIsValid = false;
		} else {
			formIsValid = true;
		}

		this.setState({
			errors: errors,
			alertBorder: "1px solid red",
			alertWidth: "1000px",
			alertAlign: "center",
			alertDisplay: "block",
		});

		return formIsValid;
	}

	handleDecrementCampaignLeads(e) {
		e.preventDefault();

		let errors = {};
		let decValidate = true; //shivani-task 2713 - added variables for validations.
		let formIsValid = true; //shivani-task 2713 - added variables for validations.

		if (
			this.state.decrementLeads == null ||
			this.state.decrementLeads === "" ||
			this.state.decrementLeads === "undefined"
		) {
			errors["wholenumber2"] = <li>Please Enter Value For Lead Decremental</li>;
			this.setState({
				errors: errors,
				alertBorder: "1px solid red",
				alertWidth: "1000px",
				alertAlign: "center",
				alertDisplay: "block",
			});
			decValidate = false;
			$("#decrementalModal1").modal("show");
			//Sandeep-task-3227-added code to show modal
		} else {
			//shivani-task 2713 - added this condition to compare value with regEx and display error message accordingly..
			var pattern = new RegExp(/^(|[1-9]\d*)$/);
			if (!pattern.test(this.state.decrementLeads)) {
				errors["wholenumber1"] = <li>Please enter only numerical values</li>;
				decValidate = false;
				this.setState({
					errors: errors,
					alertBorder: "1px solid red",
					alertWidth: "1000px",
					alertAlign: "center",
					alertDisplay: "block",
				});
			} else {
				errors["wholenumber1"] = "";
				this.setState({
					errors: errors,
					alertBorder: "0px solid white",
					alertWidth: "1000px",
					alertAlign: "center",
					alertDisplay: "block",
				});

				let data = {
					decrementLeads: this.state.decrementLeads,
					publisherDetail: this.state.publisherDetailsForDec,
					publisherDec: this.state.onClickAllocatedLeadPublisherDecrement,
				}; //karan-task-3796-vapt remove ids

				$("#decrementalModal1").modal("hide");
				//Sandeep-task-3227-added code to hide modal
				fetch("/publisher/decrementPublisherLeads", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data), //shivani-task 2993- added code to send required data to BE for api call.
				}) //karan-task-3796-vapt remove ids
					.then((res) => res.json())
					.then((response) => {
						if (this.state.leadIncDecStatus === "decrementalLeads") {
							var mesg =
								"Leads has been decremented and Insertion Order document generated.";
							Swal.fire({
								text: mesg,
								type: "success",
								confirmButtonText: "Ok",
								allowOutsideClick: false,
								preConfirm: () => {
									window.location.reload();
								},
							});

							this.setState({
								successMsg: mesg,
								inputClass: "disabled",
								alertBorder: "0px solid white",
							});
						} else {
						}
					});
			}
		}
		if (decValidate === false) {
			formIsValid = false;
		} else {
			formIsValid = true;
		}
		return formIsValid;
	}

	handleIncCampaignLeads(e) {
		e.preventDefault();
		e.target.className += " was-validated";
		if (this.validateForm() === false) {
			$("#incrementalModal1").modal("show");
			//Sandeep-task-3227-added code to show modal
			return;
		} else {
			$("#incrementalModal1").modal("hide");
			//Sandeep-task-3227-added code to hide modal
			this.setState({
				alertBorder: "0px solid white",
			});
		}

		let data = {
			incrementLeads: this.state.incrementLeads,
			campaignDetails: this.state.incrementCampDetails,
		}; //karan-task-3796-vapt remove ids

		fetch("/agencyDashboard/incrementCampaignLeadsDash", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((response) => {
				Swal.fire({
					text: "Campaign Leads has been Incremented.",
					type: "success",
					confirmButtonText: "Ok",
					allowOutsideClick: false,
					preConfirm: () => {
						window.location.reload();
					},
				});
			});
	}

	getCampID(e) {
		var campID = e.target.id;
		this.setState({ campID: campID });
	}

	/**
	 * @author Somnath Keswad
	 * @param  Description handle Refresh table data on event
	 * @return Description return successfully updated data
	 */
	handleRefreshData() {
		// var data = { // sunita-task-3234 commenting this below data as it's giving some pblm..and added it below wid some condition.
		// 	agencyID: user.id,
		// 	pacing: this.state.pacing,
		// 	tabNameKey: "All",
		// 	tab: "All",
		// };

		var data; //sunita-task-3234-same as above data only that data is sent as per tab for below conditions..

		if (this.state.tab === "Completed") {
			data = {
				pacing: "Monthly",
				tabNameKey: "All",
				tab: "All",
			}; //karan-task-3796-vapt remove ids
		} else {
			data = {
				pacing: "All",
				tabNameKey: "All",
				tab: "All",
			}; //karan-task-3796-vapt remove ids
		}
		//sunita-task-2996- here old route called name as dashboardcampaigncount so i replaced as agencyDashboardCount as per new route and noChangeDashbordCampaignCount-array used for storing the actual count.
		fetch("/agencyDashboard/agencyDashboardCount", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((dashboardCampaignCount) => {
				this.setState({
					dashboardCampaignCount: dashboardCampaignCount,
					noChangeDashbordCampaignCount: dashboardCampaignCount,
				});
			})
			.catch(function (err) {
				console.log(err);
			});

		if (this.state.tab === "Active") {
			fetch("/agencyDashboard/dashboardActiveCampaign", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then(
					(campaignDetails) =>
						this.setState({
							campaignDetails: campaignDetails,
							loader_progress: "",
						}) //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
				)
				.catch(function (err) {
					console.log(err);
				});
		} else if (this.state.tab === "All") {
			fetch("/agencyDashboard/dashboardAllCampaign", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then(
					(campaignDetails) =>
						this.setState({
							campaignDetails: campaignDetails,
							loader_progress: "",
						}) //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
				)
				.catch(function (err) {
					console.log(err);
				});
		} else if (this.state.tab === "Pause") {
			fetch("/agencyDashboard/dashboardPausedCampaignNew", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((campaignDetails) => {
					this.setState({
						campaignDetails: campaignDetails,
						loader_progress: "",
					}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
				})
				.catch(function (err) {
					console.log(err);
				});
		} else if (this.state.tab === "Completed") {
			fetch("/agencyDashboard/dashboardArchiveCampaign", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((campaignDetails) => {
					this.setState({
						campaignDetails: campaignDetails,
						loader_progress: "",
					}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
				})
				.catch(function (err) {
					console.log(err);
				});
		} else if (this.state.tab === "cancelCamp") {
			fetch("/agencyDashboard/dashboardCancelCampaign", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((campaignDetails) => {
					this.setState({
						campaignDetails: campaignDetails,
						loader_progress: "",
					}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
				})
				.catch(function (err) {
					console.log(err);
				});
		} else if (this.state.tab === "CampaignSetup") {
			var tab = "CampaignSetup";
			fetch("/agencyDashboard/dashboardCampaignSetup", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((campaignDetails) => {
					this.setState({
						campaignDetails: campaignDetails,
						tab: tab,
						linkActive4: "active",
						linkActive3: "",
						linkActive1: "",
						linkActive2: "",
						linkActive: "",
						linkActive5: "",
						loading: false,
						loader_progress: "",
					}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	}
	/**
	 * @author Supriya Gore
	 * @param  Description handle edit flow of campaign
	 * @return Description return successfully return on edit flow
	 */
	handleEditCampaign(e) {
		e.preventDefault(); // karan-task-3714-vapt header and query params
		var campID = e.target.id;
		var parentCampID = e.target.getAttribute("parentCampID");
		var reallocationID = e.target.getAttribute("reallocationID");

		var rfpCampaignID = e.target.getAttribute("rfpCampaignID");
		if (rfpCampaignID) {
			if (rfpCampaignID === 0) {
				this.props.history.push("/welcomeEdit", {
					campID,
					parentCampID,
					reallocationID,
					step: this.state.step,
				}); // karan-task-3714-vapt header and query params
			} else {
				this.props.history.push("/welcomeEdit", {
					campID,
					parentCampID,
					reallocationID,
					step: this.state.step,
					rfpCampaignID,
				}); // karan-task-3714-vapt header and query params
			}
		} else {
			this.props.history.push("/welcomeEdit", {
				campID,
				parentCampID,
				reallocationID,
				step: this.state.step,
			}); // karan-task-3714-vapt header and query params
		}
	}

	displayPopup(e) {
		Swal.fire({
			text: "Campaign Completed Marked..",
			type: "success",
			confirmButtonText: "Ok",
			allowOutsideClick: false,
			preConfirm: () => {
				window.location.reload();
			},
		});
	}

	/**
	 * @author Somnath Keswad
	 * @param  Description Complete Campaign
	 * @return Description Mark as complete campaign
	 */
	handleChangeCompleteCampaign(e) {
		e.preventDefault();

		var campID = e.target.id;
		let self = this;

		Swal.fire({
			title: "Are you sure you want to mark this campaign as Completed?",

			type: "question",
			showCancelButton: true,
			cancelButtonColor: "#3085d6",
			confirmButtonColor: "#d33",
			allowOutsideClick: false,
			confirmButtonText: "Yes, complete it!",
		}).then((result) => {
			if (result.value) {
				let data = {
					campID: campID,
				}; //karan-task-3796-vapt remove ids
				fetch("/agency/completeCampaign", {
					//insert data in publisher_assignment table
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				});

				Swal.fire({
					text: "Campaign marked completed !",
					type: "success",
					confirmButtonText: "Ok",
					preConfirm: () => {
						self.handleRefreshData();
					},
				});
			}
		});
	} //end of handleChangeCompleteCampaign

	/**
	 * @author Somnath Keswad
	 * @param  Description Get uploaded lead from backend based on lead type
	 * @return generate excel file based on response
	 */
	handledownloadLead(e) {
		e.preventDefault(); //Rutuja task -2978 
		let campID = e.target.id;
		let parentCampID = e.target.getAttribute("parentcampid");
		let leadType = e.target.getAttribute("leadtype");
		let clientCampID = e.target.getAttribute("clientCampID");//nilesh-4435
		let data = { campID, leadType };
		let campaignDetails = [...this.state.campaignDetails];
		fetch("agencyDashboard/agencyDownloadLead", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((downloadFiles) => {
				if (downloadFiles.length > 0) {
					var ws = xlsx.utils.json_to_sheet(downloadFiles);
					var wb = xlsx.utils.book_new();
					xlsx.utils.book_append_sheet(wb, ws, "Lead");
					var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" }); // generate a nodejs buffer
					function s2ab(s) {
						var buf = new ArrayBuffer(s.length);
						var view = new Uint8Array(buf);
						for (let i = 0; i !== s.length; ++i)
							view[i] = s.charCodeAt(i) & 0xff;
						return buf;
					}
					var fileName = leadType + "_" + clientCampID + "_" + parentCampID + ".xlsx";//nilesh-4435
					saveAs(
						new Blob([s2ab(buf)], { type: "application/octet-stream" }),
						fileName
					);
				}
				this.setState({ campaignDetails }); // when user click on lead number table data is empty so i store this data in temperary array and again set back this array
			});
	}

	handleChangeAllocatedLeads(e) {
		e.preventDefault(); // karan-task-3714-vapt header and query params
		var campID = e.target.getAttribute("CampId");
		var parentCampID = e.target.getAttribute("parentCampId");
		var reallocationID = e.target.getAttribute("reallocationId");
		this.props.history.push("/agencyAllocatedLeads", {
			campID,
			parentCampID,
			reallocationID,
			step: this.state.step,
		}); // karan-task-3714-vapt header and query params
	}

	campaignLink(e) {
		e.preventDefault();
		var dashActiveCount;
		//sunita-task-2996-below data is sent as per changing the tabName.
		var data;

		if (e.target.id === "Completed") {
			data = {
				pacing: "All", 
				byPacing: true,
				tab: "All",
				tabNameKey: "All",
			}; //karan-task-3796-vapt remove ids
			this.state.label = "All"; //Rutuja 4810 facing issue while removing console warning
			//this.setState({label : "All"})//Rutuja 2978;//kiran-4489-added pacing All
		} else {
			data = {
				pacing: "All",
				byPacing: true,
				tab: "All",
				tabNameKey: "All",
			}; //karan-task-3796-vapt remove ids
			this.state.label = "All"; //Rutuja 4810 facing issue while removing console warning
			//this.setState({label:"All"});//Rutuja 2978 -commented this.state and changed to this.setState for console warning 
		}

		this.setState({
			tabName: e.target.id,
			key: "",
			pacing: "All",
			label: this.state.label,
			dashboardCampaignCount: this.state.noChangeDashbordCampaignCount,
			fromDate: "",
			toDate: "",
			//loading: true, // User Story : 3193 : Karan Jagtap : Shows loading symbol in table
			tableSearchText: "", //Sandeep-task-3559-added code to blank tableSearchText
			loader_progress: "loader_campaign_list",
		}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
		if (e.target.id === "Active") {
			dashActiveCount = this.state.dashboardCampaignCount[0].activeCount; //Sonali-3232-added this to show loader
			fetch("/agencyDashboard/activeCampaignInfo", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((campaignDetails) => {
					//sunita-task-2996-fromcampaigntoallocate if we are coming from campaigntoallocate operational action
					this.setState({
						campaignDetails: campaignDetails,
						tab: "Active",
						linkActive1: "active",
						linkActive: "",
						linkActive2: "",
						linkActive3: "",
						linkActive4: "",
						linkActive5: "",
						loading: false,
						fromCampaigntoAllocate: false,
						campstatus: "Active",
						isprogressBtnDisable: false,
						tableSearchText: "", //Sandeep-task-3559-added code to blank tableSearchText
						loader_progress: "",
					}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
					//sunita- task-3060-progressBtnDisable its value  will change as per data present in table for disabling the download button.
				})
				.catch(function (err) {
					console.log(err);
				});
		} else if (e.target.id === "All") {
			fetch("/agencyDashboard/dashboardAllCampaign", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((campaignDetails) => {
					this.setState({
						campaignDetails: campaignDetails,
						tab: "All",
						linkActive4: "",
						linkActive: "active",
						linkActive1: "",
						linkActive2: "",
						linkActive3: "",
						linkActive5: "",
						loading: false,
						loader_progress: "",
						tableSearchText: "", //Sandeep-task-3559-added code to blank tableSearchText
					}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
				})
				.catch(function (err) {
					console.log(err);
				});
		} else if (e.target.id === "Pause") {
			dashActiveCount = this.state.dashboardCampaignCount[0].pauseCount; //Sonali-3232-added this to show loader

			fetch("/agencyDashboard/autoCompleteInPause", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
			}); //karan-task-3796-vapt remove ids

			fetch("/agencyDashboard/pausedCampaignInfo", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((campaignDetails) => {
					this.setState({
						campaignDetails: campaignDetails,
						tab: "Pause",
						linkActive2: "active",
						linkActive: "",
						linkActive1: "",
						linkActive3: "",
						linkActive4: "",
						linkActive5: "",
						loading: false,
						loader_progress: "",
						tableSearchText: "", //Sandeep-task-3559-added code to blank tableSearchText
					}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
				})
				.catch(function (err) {
					console.log(err);
				});
		} else if (e.target.id === "Completed") {
			dashActiveCount = this.state.dashboardCampaignCount[0].completedCount; //Sonali-3232-added this to show loader
			fetch("/agencyDashboard/completeCampaignInfo", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((campaignDetails) => {
					this.setState({
						campaignDetails: campaignDetails,
						tab: "Completed",
						linkActive3: "active",
						linkActive1: "",
						linkActive2: "",
						linkActive: "",
						linkActive4: "",
						linkActive5: "",
						loading: false,
						fromCampaigntoAllocate: false,
						campstatus: "Completed",
						isprogressBtnDisable: false,
						tableSearchText: "", //Sandeep-task-3559-added code to blank tableSearchText
						loader_progress: "",
					}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
					//sunita- task-3060-progressBtnDisable its value  will change as per data present in table for disabling the download button.
				})
				.catch(function (err) {
					console.log(err);
				});
		// 4568 -(place the correct flow of cancelCamp and CampaignSetup ) Prod Issue- AC/PC/PNC - Camp Progress - Page Unresponsive popup comes		
		} else if (e.target.id === "cancelCamp") {
			dashActiveCount = this.state.dashboardCampaignCount[0].cancelCampaign; //Sonali-3232-added this to show loader

			fetch("/agencyDashboard/cancelCampaignInfo", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((campaignDetails) => {
					this.setState({
						campaignDetails: campaignDetails,
						tab: "cancelCamp",
						linkActive4: "",
						linkActive3: "",
						linkActive1: "",
						linkActive2: "",
						linkActive: "",
						linkActive5: "active",
						loading: false,
						loader_progress: "",
						tableSearchText: "", //Sandeep-task-3559-added code to blank tableSearchText
					}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
				})
				.catch(function (err) {
					console.log(err);
				});
		} else if (e.target.id === "CampaignSetup") {
			dashActiveCount = this.state.dashboardCampaignCount[0].campSetUpCount; //Sonali-3232-added this to show loader

			fetch("/agencyDashboard/dashboardCampaignSetup", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((campaignDetails) => {
					this.setState({
						campaignDetails: campaignDetails,
						tab: "CampaignSetup",
						linkActive4: "active",
						linkActive3: "",
						linkActive1: "",
						linkActive2: "",
						linkActive: "",
						linkActive5: "",
						loading: false,
						loader_progress: "",
						tableSearchText: "", //Sandeep-task-3559-added code to blank tableSearchText
					}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
				})
				.catch(function (err) {
					console.log(err);
				});
		}

		this.setState({ dashActiveCount: dashActiveCount }); //Sonali-3232-added this to show loader
	}
	biddingLink(e) {
		e.preventDefault();

		if (e.target.id === "reject") {
			//getting Campaign ID which status is New, Counter, Reject
			fetch("agencyDashboard/notifyDashNewCamp") //karan-task-3796-vapt remove ids
				.then((res) => res.json())
				.then((notifyDashNewCamp) => {
					this.setState({
						biddingArray: notifyDashNewCamp[2],
						rejectLength: notifyDashNewCamp[2].length,
						RFPBiddingTab: "",
						headExample1: "head-example1",
						headExample: "head-exampleGeneral",
						headExample6: "head-exampleGeneral6",
						loading1: false,
					});
				})
				.catch(function (err) {
					console.log(err);
				});
		} else if (e.target.id === "RFP") {
			fetch("agencyDashboard/biddingCampIDOnDashBoard") //karan-task-3796-vapt remove ids
				.then((res) => res.json())
				.then((rfpBidding) => {
					this.setState({
						rfpBiddingLength: rfpBidding.length,
						biddingArray: rfpBidding,
						RFPBiddingTab: "RFPBiddingTab",
						headExample1: "head-exampleGeneral1",
						headExample: "head-exampleGeneral",
						headExample6: "head-example6",
						loading1: false,
					});
				})
				.catch(function (err) {
					console.log(err);
				});
		} else {
			//getting Campaign ID which status is New, Counter, Reject
			fetch("agencyDashboard/notifyDashNewCamp") //karan-task-3796-vapt remove ids
				.then((res) => res.json())
				.then((notifyDashNewCamp) => {
					this.setState({
						biddingArray: notifyDashNewCamp[1],
						counterLength: notifyDashNewCamp[1].length,
						headExample: "head-example",
						headExample1: "head-exampleGeneral1",
						headExample6: "head-exampleGeneral6",
						loading1: false,
					});
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	}

	/**
	 * @author Somnath Keswad
	 * @param  Description  Generate the invoice document based on info
	 * @return Description insert the document in Blob
	 */
	generateInvoice(e) {
		fetch("agencyDashboard/autoGenerateInvoice") //karan-task-3796-vapt remove ids
			.then((res) => res.json())
			.then((autoGenerateInvoice) => { })
			.catch(function (err) {
				console.log(err);
			});
	}

	reviewLink(e) {
		e.preventDefault();
		var tabReview = e.target.id;

		this.setState({ tabReview: tabReview });
		if (e.target.id === "creatives") {
			this.setState({
				reviewTab1: "creatives",
			});
			//getting Campaign ID which Delivered The Lead
			var ReviewTab = "ReviewCreatives";
			fetch("agencyDashboard/lpPOCCSReviewNotifyDash") //karan-task-3796-vapt remove ids
				.then((res) => res.json())
				.then((lpPOCCSReviewNotifyDash) => {
					this.setState({
						reviewArray: lpPOCCSReviewNotifyDash,
						creativeLength: lpPOCCSReviewNotifyDash.length,
						ReviewTab: ReviewTab,
						headExample2: "head-example2",
						headExample3: "head-exampleGeneral3",
						headExample4: "head-exampleGeneral4",
						headExample5: "head-exampleGeneral5",
						loading2: false,
					});
				})
				.catch(function (err) {
					console.log(err);
				});
		} else if (e.target.id === "leads") {
			this.setState({
				reviewTab1: "creatives",
			});
			//getting Campaign ID which Delivered The Lead

			fetch("agencyDashboard/leadReviewDashboard") //karan-task-3796-vapt remove ids
				.then((res) => res.json())
				.then((leadReviewDashboard) => {
					this.setState({
						reviewArray: leadReviewDashboard,
						leadsLength: leadReviewDashboard.length,
						ReviewTab: ReviewTab,
						headExample3: "head-example3",
						headExample2: "head-exampleGeneral2",
						headExample4: "head-exampleGeneral4",
						headExample5: "head-exampleGeneral5",
						loading2: false,
					});
				})
				.catch(function (err) {
					console.log(err);
				});
		} else if (e.target.id === "others") {
			this.setState({
				reviewTab1: "others",
			});

			fetch("/campaign/publisherLinkReview", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
			}) //karan-task-3796-vapt remove ids
				.then((res) => res.json())
				.then((publisherLinkReview) => {
					this.setState({
						reviewArray: publisherLinkReview,
						othersLength: publisherLinkReview.length,
						ReviewTab: ReviewTab,
						headExample4: "head-exampleGeneral4",
						headExample3: "head-exampleGeneral3",
						headExample2: "head-exampleGeneral2",
						headExample5: "head-example5",
						loading2: false,
						reviewTab1: ReviewTab,
					});
				})
				.catch(function (err) {
					console.log(err);
				});
		} else {
			fetch("/agencyDashboard/getMonthList", {
				//insert data in publisher_assignment table
				method: "POST",
				headers: { "Content-Type": "application/json" },
			}) //karan-task-3796-vapt remove ids
				.then((res) => res.json())
				.then((monthArray) => {
					let ReviewTab = "invoice";
					this.setState({
						monthArrayLength: monthArray.length,
						reviewArray: monthArray,
						ReviewTab: ReviewTab,
						headExample3: "head-exampleGeneral3",
						headExample2: "head-exampleGeneral2",
						headExample4: "head-example4",
						headExample5: "head-exampleGeneral5",
						loading2: false,
					});
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	}

	allocatePublisher(e) {
		e.preventDefault();//Sonali-4120-added to remove #
		// if (e) e.preventDefault(); // karan-task-3714-vapt header and query params
		var campID = e.target.getAttribute("id");
		var parentCampID = e.target.getAttribute("parentCampId");
		var reallocationID = e.target.getAttribute("reallocationId");
		var rfpProposalID = e.target.getAttribute("rfpProposalID");
		var parsed = this.props.location.state; //karan-task-3684-replace query params
		var key = parsed.key; //shivani-task 2921-added code to parsed key

		if (
			rfpProposalID === "null" ||
			rfpProposalID == null ||
			rfpProposalID === undefined ||
			rfpProposalID === "" ||
			Number(rfpProposalID) === 0 || //Rutuja 2978  added number and changed == to ===
			rfpProposalID.toString() === "0"  //Rutuja 2978  added toString and changed == to ===
		) {
			if (key === "active") {
				//shivani-task 2921- added code to check key is present in link or not
				this.props.history.push("/publisherAllocation", {
					campID,
					parentCampID,
					reallocationID,
					key,
				}); // karan-task-3714-vapt header and query params
			} else {
				this.props.history.push("/publisherAllocation", {
					campID,
					parentCampID,
					reallocationID,
				}); // karan-task-3714-vapt header and query params
			}
		} else {

			this.props.history.push("/rfpCampaignAllocation", {
				campID,
				parentCampID,
				proposalID: rfpProposalID,
			});
		}
	}

	cloneCampaign(e) {
		e.preventDefault();
		const { user } = this.props.auth;
		var agencyID = user.id;
		this.setState({ agencyID: agencyID });
		var campID = e.target.id;
		var parentCampID = e.target.getAttribute("parentCampId");
		var reallocationID = e.target.getAttribute("reallocationId");

		Swal.fire({
			title: "Are you sure you want to create copy of the campaign?",
			type: "question",
			showCancelButton: true,
			cancelButtonColor: "#d33",
			confirmButtonColor: "#3085d6",
			allowOutsideClick: false,
			confirmButtonText: "Yes",
		}).then((result) => {
			if (result.value) {
				let data = {
					campID: campID,
					parentCampID: parentCampID,
					reallocationID: reallocationID,
				}; //karan-task-3796-vapt remove ids
				fetch("/agencyDashboard/agencyCloneCampaign", {
					//insert data in publisher_assignment table
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((response) => {
						//var message1 = response.message; //Rutuja 2978 commented message 1 as never used
						Swal.fire({
							html:
								"New Copy Created Successfully<br>with campaign ID :" +
								response.campID,
							//Sandeep-task-3607-added code for alert msg
							type: "success",
							confirmButtonText: "Ok",
							allowOutsideClick: false,
							preConfirm: () => {
								//sunita-task-2996- tabNameKey-when we are changing the filter value for particular tab  it will store that tab name used for BE and bydefault tab

								let data = {
									pacing: this.state.pacing,
									byPacing: true,
									tabNameKey: "All",
									tab: "All",
								}; //karan-task-3796-vapt remove ids
								fetch("/agencyDashboard/agencyDashboardCount", {
									method: "POST",
									headers: { "Content-Type": "application/json" },
									body: JSON.stringify(data),
								})
									.then((res) => res.json())
									.then((dashboardCampaignCount) => {
										//sunita-task-2996-noChangeDashbordCampaignCount-this array used for storing the actual count of tab.
										//sunita-task-2996-when we are getting key as active from dashboard
										if (this.state.key === "active") {
											this.setState({
												partialCount: dashboardCampaignCount[0].activeCount,
											});
										}
										this.setState({
											dashboardCampaignCount: dashboardCampaignCount,
											noChangeDashbordCampaignCount: dashboardCampaignCount,
										});
									})
									.catch(function (err) {
										console.log(err);
									});
								if (this.state.tab === "Active") {
									fetch("/agencyDashboard/activeCampaignInfo", {
										method: "POST",
										headers: { "Content-Type": "application/json" },
										body: JSON.stringify(data),
									})
										.then((res) => res.json())
										.then((campaignDetails) => {
											this.setState({ campaignDetails: campaignDetails });
										})
										.catch(function (err) {
											console.log(err);
										});
								} else if (this.state.tab === "All") {
									fetch("/agencyDashboard/dashboardAllCampaign", {
										method: "POST",
										headers: { "Content-Type": "application/json" },
										body: JSON.stringify(data),
									})
										.then((res) => res.json())
										.then((campaignDetails) => {
											this.setState({ campaignDetails: campaignDetails });
										})
										.catch(function (err) {
											console.log(err);
										});
								} else if (this.state.tab === "cancelCamp") {
									fetch("/agencyDashboard/cancelCampaignInfo", {
										method: "POST",
										headers: { "Content-Type": "application/json" },
										body: JSON.stringify(data),
									})
										.then((res) => res.json())
										.then((campaignDetails) => {
											this.setState({ campaignDetails: campaignDetails });
										})
										.catch(function (err) {
											console.log(err);
										});
								} else if (this.state.tab === "Pause") {
									fetch("/agencyDashboard/pausedCampaignInfo", {
										method: "POST",
										headers: { "Content-Type": "application/json" },
										body: JSON.stringify(data),
									})
										.then((res) => res.json())
										.then((campaignDetails) => {
											this.setState({ campaignDetails: campaignDetails });
										})
										.catch(function (err) {
											console.log(err);
										});
								} else if (this.state.tab === "Completed") {
									fetch("/agencyDashboard/completeCampaignInfo", {
										method: "POST",
										headers: { "Content-Type": "application/json" },
										body: JSON.stringify(data),
									})
										.then((res) => res.json())
										.then((campaignDetails) => {
											this.setState({ campaignDetails: campaignDetails });
										})
										.catch(function (err) {
											console.log(err);
										});
								}
							},
						});

						this.setState({ inputClass: "disabled" });
					})
					.catch(function (err) {
						console.log(err);
					});
			}
		});
	}

	pauseCampaign(e) {
		e.preventDefault();
		const { user } = this.props.auth;
		var agencyID = user.id;
		this.setState({ agencyID: agencyID });

		var campID = e.target.id;

		Swal.fire({
			title: "Are you sure you want to pause the campaign?",
			html:
				"This will pause the campaign for all allocated publishers.<br/>" +
				"<br/><span style='color:red;'><b>* Reason For Pause</b></span>" +
				"<div><textarea id='pauseReason' rows='2' cols='40'></textarea></div>",
			type: "question",
			showCancelButton: true,
			cancelButtonColor: "#3085d6",
			confirmButtonColor: "#d33",
			allowOutsideClick: false,
			confirmButtonText: "Yes, pause it!",
		}).then((result) => {
			if (result.value && document.getElementById("pauseReason").value !== "") {
				var reason = document.getElementById("pauseReason").value;
				let data = {
					campID: campID,
					reason: reason,
				}; //karan-task-3796-vapt remove ids

				fetch("/agency/agencyPausedCampaign", {
					//insert data in publisher_assignment table
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				});

				Swal.fire({
					text: "Campaign paused !",
					type: "success",
					confirmButtonText: "Ok",
					preConfirm: () => {
						var data = {
							pacing: this.state.pacing,
							byPacing: true,
							tabNameKey: "All",
							tab: "All",
						}; //karan-task-3796-vapt remove ids
						fetch("/agencyDashboard/agencyDashboardCount", {
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(data),
						})
							.then((res) => res.json())
							.then((dashboardCampaignCount) => {
								//sunita-task-2996-when we are getting key as active from dashboard
								if (this.state.key === "active") {
									this.setState({
										partialCount: dashboardCampaignCount[0].activeCount,
									});
								}
								this.setState({
									dashboardCampaignCount: dashboardCampaignCount,
									noChangeDashbordCampaignCount: dashboardCampaignCount,
								});
							})
							.catch(function (err) {
								console.log(err);
							});
						if (this.state.tab === "Active") {
							fetch("/agencyDashboard/dashboardActiveCampaign", {
								method: "POST",
								headers: { "Content-Type": "application/json" },
								body: JSON.stringify(data),
							})
								.then((res) => res.json())
								.then((campaignDetails) => {
									this.setState({ campaignDetails: campaignDetails });
								})
								.catch(function (err) {
									console.log(err);
								});
						} else if (this.state.tab === "All") {
							var data1 = { pacing: this.state.pacing }; //karan-task-3796-vapt remove ids
							fetch("/agencyDashboard/dashboardAllCampaign", {
								method: "POST",
								headers: { "Content-Type": "application/json" },
								body: JSON.stringify(data1),
							})
								.then((res) => res.json())
								.then((campaignDetails) => {
									this.setState({ campaignDetails: campaignDetails });
								})
								.catch(function (err) {
									console.log(err);
								});

							fetch("/agencyDashboard/agencyDashboardCount", {
								method: "POST",
								headers: { "Content-Type": "application/json" },
								body: JSON.stringify(data),
							})
								.then((res) => res.json())
								.then((dashboardCampaignCount) => {
									this.setState({
										dashboardCampaignCount: dashboardCampaignCount,
									});
								})
								.catch(function (err) {
									console.log(err);
								});
						} else if (this.state.tab === "Pause") {
							fetch("/agencyDashboard/dashboardPausedCampaignNew", {
								method: "POST",
								headers: { "Content-Type": "application/json" },
								body: JSON.stringify(data1),
							})
								.then((res) => res.json())
								.then((campaignDetails) => {
									this.setState({ campaignDetails: campaignDetails });
								})
								.catch(function (err) {
									console.log(err);
								});

							fetch("/agencyDashboard/agencyDashboardCount", {
								method: "POST",
								headers: { "Content-Type": "application/json" },
								body: JSON.stringify(data),
							})
								.then((res) => res.json())
								.then((dashboardCampaignCount) => {
									this.setState({
										dashboardCampaignCount: dashboardCampaignCount,
									});
								})
								.catch(function (err) {
									console.log(err);
								});
						} else if (this.state.tab === "Completed") {
							fetch("/agencyDashboard/dashboardArchiveCampaign", {
								method: "POST",
								headers: { "Content-Type": "application/json" },
								body: JSON.stringify(data1),
							})
								.then((res) => res.json())
								.then((campaignDetails) => {
									this.setState({ campaignDetails: campaignDetails });
								})
								.catch(function (err) {
									console.log(err);
								});

							fetch("/agencyDashboard/agencyDashboardCount", {
								method: "POST",
								headers: { "Content-Type": "application/json" },
								body: JSON.stringify(data),
							})
								.then((res) => res.json())
								.then((dashboardCampaignCount) => {
									this.setState({
										dashboardCampaignCount: dashboardCampaignCount,
									});
								})
								.catch(function (err) {
									console.log(err);
								});
						}
					},
				});
			} else {
				if (
					(document.getElementById("pauseReason").value === "" ||
						document.getElementById("pauseReason").value === undefined ||
						document.getElementById("pauseReason").value === null) &&
					result.value === true
				) {
					Swal.fire({
						text: "Please Enter Reason For Pausing the Campaign !",
						type: "error",
						timer: 8000,
						showConfirmButton: false,
					});
				}
			}
		});
	}

	resumeCampaign(e) {
		e.preventDefault();
		const { user } = this.props.auth;
		var agencyID = user.id;
		this.setState({ agencyID: agencyID });

		var campID = e.target.id;

		Swal.fire({
			title: "Are you sure you want to resume the campaign?",
			text: "This will resume the campaign for all allocated publishers.",
			type: "warning",
			showCancelButton: true,
			cancelButtonColor: "#3085d6",
			confirmButtonColor: "#d33",
			allowOutsideClick: false,
			confirmButtonText: "Yes, resume it!",
		}).then((result) => {
			if (result.value) {
				let data = {
					campID: campID,
				}; //karan-task-3796-vapt remove ids
				fetch("/agency/agencyActiveCampaign", {
					//insert data in publisher_assignment table
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				});

				Swal.fire({
					text: "Campaign resumed !",
					type: "success",
					confirmButtonText: "Ok",
					preConfirm: () => {
						//sunita-task- 2996- this api's i have commented bcz here  reloaded the page so this api's are not called.bcz of no use so i have removed.

						window.location.reload();
					},
				});
			}
		});
	}

	/**
	 * @author Somnath Keswad
	 * @param  Description handle Active selected campaign
	 * @return Description return campaign moved to active tab
	 */
	liveCampaign(e) {
		e.preventDefault();
		const { user } = this.props.auth;
		var agencyID = user.id;

		this.setState({ agencyID: agencyID });
		let self = this;
		var campID = e.target.id;
		Swal.fire({
			title: "Are you sure you want to Activate the campaign?",
			text: "This will Activate the campaign for all allocated publishers.",
			type: "warning",
			showCancelButton: true,
			cancelButtonColor: "#3085d6",
			confirmButtonColor: "#d33",
			allowOutsideClick: false,
			confirmButtonText: "Yes, Activate it!",
		}).then((result) => {
			if (result.value) {
				let data = {
					campID: campID,
				}; //karan-task-3796-vapt remove ids

				fetch("/agency/backToLiveCampaign", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				})
					.then(function (response) {
						self.handleRefreshData();
					})
					.catch(function (err) {
						console.log(err);
					});

				Swal.fire({
					text: "Campaign Activated !",
					type: "success",
					confirmButtonText: "Ok",
					preConfirm: () => { },
				});
			}
		});
	}
	displayCampaignSpecification(e) {
		e.preventDefault(); //karan-task-3895-link issue
		var campID = e.target.getAttribute("campID");
		var parentCampID = e.target.getAttribute("parentCampId");
		var reallocationID = e.target.getAttribute("reallocationId");
		this.props.history.push("/campaignSpecificationDashboard", {
			campID,
			parentCampID,
			reallocationID,
		}); //karan-task-3715-replace query params
	}

	displayCampaignSpecificationMsg(e) {
		var campID = e.target.getAttribute("campID");
		var parentCampID = e.target.getAttribute("parentCampId");
		var reallocationID = e.target.getAttribute("reallocationId");
		var publisherID = e.target.getAttribute("pID");
		this.props.history.push("/campaignSpecificationDashboard", {
			campID,
			parentCampID,
			reallocationID,
			pID: publisherID,
		}); //karan-task-3715-replace query params
	}

	displayCampaignSetup(e) {
		e.preventDefault();
		var advCampID = e.target.getAttribute("advCampID");
		var advCampaignName = e.target.getAttribute("advCampaignName");
		var agencyAllocationID = e.target.getAttribute("agencyAllocationID");
		//Somnath Task-4035, Add Url in history Object
		this.props.history.push("campaignsetupSpecification", { advCampID, advCampaignName, agencyAllocationID })
	}

	/**
	 * @author Supriya Gore
	 * @param  Description handle allocated campaign tab campaign
	 * @return Description return successfully return on allocated campaign
	 */
	allocatedCampaign(e) {
		e.preventDefault()
		var campID = e.target.getAttribute("campID");
		window.location.href = "/allocatedCampaignTab?campID=" + campID;
	}

	rFPBiddingTab(e) {
		e.preventDefault()
		var campID = e.target.getAttribute("campID");
		this.props.history.push("/requestProposalAgency", { campID })//Somnath Task-4035, add URL in History Object
	}

	getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		}) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) =>
						setSelectedKeys(e.target.value ? [e.target.value] : [])
					}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
					style={{ width: 188, marginBottom: 8, display: "block" }}
				/>
				<Button
					type="primary"
					onClick={() => this.handleSearch(selectedKeys, confirm)}
					icon="search"
					size="small"
					style={{ width: 90, marginRight: 8 }}>
					Search
				</Button>
				<Button
					onClick={() => this.handleReset(clearFilters)}
					size="small"
					style={{ width: 90 }}>
					Reset
				</Button>
			</div>
		),
		filterIcon: (filtered) => (
			<Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
		),
		onFilter: (value, record) =>
			record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => this.searchInput.select());
			}
		},
		render: (text) => (
			<Highlighter
				highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
				searchWords={[this.state.searchText]}
				autoEscape
				textToHighlight={text.toString()}
			/>
		),
	});

	handleSearch = (selectedKeys, confirm) => {
		confirm();
		this.setState({ searchText: selectedKeys[0] });
	};

	handleReset = (clearFilters) => {
		clearFilters();
		this.setState({ searchText: "" });
	};

	handleChangePageSize(e) {
		var pageSize = e.target.value;
		this.setState({ pageSize: pageSize });
	}

	handleChangePageSize1(e) {
		var pageSize1 = e.target.value;
		this.setState({ pageSize1: pageSize1 });
	}

	handleChangePageSize2(e) {
		var pageSize2 = e.target.value;
		this.setState({ pageSize2: pageSize2 });
	}

	handleChangePageSize3(e) {
		var pageSize3 = e.target.value;
		this.setState({ pageSize3: pageSize3 });
	}

	handleChangePageSize4(e) {
		var pageSize4 = e.target.value;
		this.setState({ pageSize4: pageSize4 });
	}

	handleChange(e) {
		e.preventDefault();
		var pacing = e.target.id;
		var dashActiveCount; //Sonali-3232-added this to show loader
		//sunita-task-2996-this variable added for displaying the pacing value on FE and as per requirement i have setstate the variables
		var label = e.target.getAttribute("label");
		document.getElementById("myText").innerHTML = label; //sunita-task-3132-code change done bcz label value not getting properly.
		this.setState({
			pacing: pacing,
			byPacing: true,
			label: label,
			fromDate: "",
			toDate: "",
			key: "",
			//loading: true, // User Story : 3193 : Karan Jagtap : Shows loading symbol in table
			loader_progress: "loader_campaign_list",
		}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
		var tabName = this.state.tabName;

		//sunita-task-2996-tabnamekey is added for BE when we are changing the filter value for particular tab  it will store that tab name.
		var tabNameKey = tabName;

		//sunita-task-2996-noChangeDashbordCampaignCount-sent to BE data as per requirement
		var data = {
			pacing,
			byPacing: true,
			tab: "All",
			tabNameKey,
			noChangeDashbordCampaignCount: this.state.noChangeDashbordCampaignCount,
		}; //karan-task-3796-vapt remove ids

		fetch("/agencyDashboard/agencyDashboardCount", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((dashboardCampaignCount) => {
				//Sonali-3232-added this to show loader and set dashActiveCount as per the tabName
				if (this.state.tabName.toString() === "Active") { //RUtuja 2978 converted tabname to tostring and changed == to ===
					dashActiveCount = dashboardCampaignCount[0].activeCount;
				}
				if (this.state.tabName.toString() === "Pause") {  //RUtuja 2978 converted tabname to tostring and changed == to ===
					dashActiveCount = dashboardCampaignCount[0].pauseCount;
				}
				if (this.state.tabName.toString() === "Completed") {  //RUtuja 2978 converted tabname to tostring and changed == to ===
					dashActiveCount = dashboardCampaignCount[0].completedCount;
				}
				if (this.state.tabName.toString() === "cancelCamp") { //RUtuja 2978 converted tabname to tostring and changed == to ===
					dashActiveCount = dashboardCampaignCount[0].cancelCampaign;
				}
				if (this.state.tabName.toString() === "CampaignSetup") { //RUtuja 2978 converted tabname to tostring and changed == to ===
					dashActiveCount = dashboardCampaignCount[0].campSetUpCount;
				}

				this.setState(
					{
						dashboardCampaignCount: dashboardCampaignCount,
						dashActiveCount: dashActiveCount, //Sonali-3232-added this to show loader
						//loading: false, // User Story : 3193 : Karan Jagtap : Removed from here because everytime this api's response is returned before getting the data from other async calls
						tabNameKey: tabNameKey,
					},
					function () { }
				);
			})
			.catch(function (err) {
				console.log(err);
			});

		if (tabName === "Active") {
			tabNameKey = "Active";
			fetch("/agencyDashboard/activeCampaignInfo", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((campaignDetails) => {
					this.setState({
						campaignDetails: campaignDetails,
						loading: false,
						tabNameKey: tabNameKey,
						fromCampaigntoAllocate: false,
						isprogressBtnDisable: false,
						loader_progress: "",
					}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
					//sunita- task-3060-progressBtnDisable its value  will change as per data present in table for disabling the download button.
				})
				.catch(function (err) {
					console.log(err);
				});
		} else if (tabName === "All") {
			fetch("/agencyDashboard/dashboardAllCampaign", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((campaignDetails) => {
					this.setState({
						campaignDetails: campaignDetails,
						loading: false,
						loader_progress: "",
					}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
				})
				.catch(function (err) {
					console.log(err);
				});
		}
		//sunita-task-2996-this tabName are getting when we are coming from dashabord bar graph or on campaign list page
		else if (tabName === "Pause" || tabName === "Paused") {
			tabNameKey = "Pause";
			fetch("/agencyDashboard/pausedCampaignInfo", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((campaignDetails) => {
					this.setState({
						campaignDetails: campaignDetails,
						loading: false,
						tabNameKey: tabNameKey,
						loader_progress: "",
					}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
				})
				.catch(function (err) {
					console.log(err);
				});
		} else if (tabName === "Completed") {
			fetch("/agencyDashboard/completeCampaignInfo", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((campaignDetails) => {
					this.setState({
						campaignDetails: campaignDetails,
						loading: false,
						tabNameKey: tabNameKey,
						fromCampaigntoAllocate: false,
						isprogressBtnDisable: false,
						loader_progress: "",
					}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
					//sunita- task-3060-progressBtnDisable its value  will change as per data present in table for disabling the download button.
				})
				.catch(function (err) {
					console.log(err);
				});
		}
		//sunita-task-2996-this tabName are getting when we are coming from dashabord bar graph or on campaign list page
		else if (tabName === "cancelCamp" || tabName === "Cancel") {
			tabNameKey = "cancelCamp";
			fetch("/agencyDashboard/cancelCampaignInfo", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((campaignDetails) => {
					this.setState({
						campaignDetails: campaignDetails,
						loading: false,
						tabNameKey: tabNameKey,
						loader_progress: "",
					}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
				})
				.catch(function (err) {
					console.log(err);
				});
		} else if (tabName === "CampaignSetup") {
			// sunita-task-2996 - api called for campaignsetup filter
			tabNameKey = "CampaignSetup";
			fetch("/agencyDashboard/dashboardCampaignSetup", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((campaignDetails) => {
					this.setState({
						campaignDetails: campaignDetails,
						loading: false,
						tabNameKey: tabNameKey,
						loader_progress: "",
					}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	} //end of handleChange

	recordOpen(parameter1, parameter2) {
		let data = {
			alertID: parameter1,
			clickedcampaignID: parameter2,
		};
		fetch("/notification/AgencyAlertsToAgecncyStatusChanged", {
			//insert data in publisher_assignment table
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		}) //karan-task-3796-vapt remove ids
			.then((res) => res.json())
			.catch(function (err) {
				console.log(err);
			});
	}

	componentDidMount() {
		fetch("/agencyDashboard/getMonthList", {
			//insert data in publisher_assignment table
			method: "POST",
			headers: { "Content-Type": "application/json" },
		}) //karan-task-3796-vapt remove ids
			.then((res) => res.json())
			.then((monthArray) => {
				this.setState({ monthArrayLength: monthArray.length });
			})
			.catch(function (err) {
				console.log(err);
			});

		fetch("/publisher/updateLeadsPermissionTimeFlag", {
			//insert data in publisher_assignment table
			method: "POST",
			headers: { "Content-Type": "application/json" },
		})
			.then((res) => res.json())
			.then((updateLeadsPermissionTimeFlag) => { })
			.catch(function (err) {
				console.log(err);
			});
	}

	cloneCompletedCampaign(e) {
		e.preventDefault();
		let errors = {};
		let formIsValid = true;
		let clonedateValidate = true; //shivani-task 3416-has added this variable for validation.
		// let cloneFirstleaddateValidate = true; //shivani-task 3416-has added this variable for validation.
		let cloneFirstleaddateValidate1 = true; //shivani-task 3416-has added this variable for validation.
		let cloneenddateValidate = true; //shivani-task 3416-has added this variable for validation.

		this.setState({
			alertDisplay: "none",
			alertBorder: "0px solid white",
		});
		if (
			this.state.cloneStartDate === "" ||
			this.state.cloneStartDate === undefined
		) {
			errors["startDate"] = <li>Please enter start date.</li>;
			this.setState({ errors: errors });
			clonedateValidate = false;
		}
		if (
			this.state.cloneEndDate === "" ||
			this.state.cloneEndDate === undefined
		) {
			errors["endDate"] = <li>Please enter end date.</li>;
			this.setState({ errors: errors });
			cloneenddateValidate = false;
		}
		if (
			this.state.cloneFirstLeadDate === "" ||
			this.state.cloneFirstLeadDate === undefined
		) {
			errors["commonError"] = <li>Please enter first lead delivery date.</li>;
			this.setState({ errors: errors });
			cloneFirstleaddateValidate1 = false;
		}
		if (
			Date.parse(this.state.cloneStartDate) >
			Date.parse(this.state.cloneEndDate)
		) {
			//shivani-task 3416-added comparison for clone start date less than clone end date.
			errors["endDate"] = (
				<li>Please enter end date greater than start date.</li>
			);
			this.setState({ errors: errors }); // shivani-task 3416-added this setstate error
			cloneenddateValidate = false; // shivani -task 3416 - added validate false
			// return cloneenddateValidate; // shivani -task 3416 - added - return cloneenddateValidate
		}
		if (
			Date.parse(this.state.cloneFirstLeadDate) <
			Date.parse(this.state.cloneStartDate)
		) {
			// shivani-task 3416- added comparison for first lead delivery date greater than start date.
			errors["commonError"] = (
				<li>Please enter first lead delivery date greater than start date.</li>
			);
			this.setState({ errors: errors }); // shivani-task 3416-added this setstate error
			cloneFirstleaddateValidate1 = false; // shivani -task 3416 - added validate false
			// return cloneFirstleaddateValidate1; // shivani -task 3416 - added - return cloneFirstleaddateValidate1
		}
		if (
			Date.parse(this.state.cloneFirstLeadDate) >
			Date.parse(this.state.cloneEndDate)
		) {
			// shivani-task 3416- added comparison for first lead delivery date greater than start date.
			errors["commonError"] = (
				<li>Please enter first lead delivery date smaller than end date.</li>
			);
			this.setState({ errors: errors }); // shivani-task 3416-added this setstate error
			cloneFirstleaddateValidate1 = false; // shivani -task 3416 - added validate false
			// return cloneFirstleaddateValidate1; // shivani -task 3416 - added - return cloneFirstleaddateValidate1
		}
		//  shivani-Task 3416 -calculated old month time period for validation.
		var today = new Date();
		var date =
			today.getFullYear() +
			"-" +
			(today.getMonth() + 1) +
			"-" +
			today.getDate();
		var month, day, year;
		month = "" + (today.getMonth() + 1);
		day = "" + today.getDate();
		year = today.getFullYear();
		var dt = new Date(date);
		dt.setMonth(dt.getMonth() - 1);
		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;
		var updatedDate = [year, month - 1, day].join("-");
		if (Date.parse(this.state.cloneStartDate) < Date.parse(updatedDate)) {
			// shivani-task 3416-added comparison between cloneStartDate and last month dates .
			errors["startDate"] = (
				<li>Start date can not be older than a month time period.</li>
			);
			// shivani-task 3416-added error message for comparison between cloneStartDate and last month dates .
			this.setState({ errors: errors }); // shivani-task 3416-added this setstate error
			clonedateValidate = false; // shivani -task 3416 - added validate false
			// return clonedateValidate; // shivani -task 3416 - added - return clonedateValidate
		}
		//  else {
		// 	}
		if (
			clonedateValidate === false ||
			cloneenddateValidate === false ||
			cloneFirstleaddateValidate1 === false
		) {
			formIsValid = false;
			this.setState({
				errors: errors,
				alertBorder: "1px solid red",
				alertWidth: "1000px",
				alertAlign: "center",
				alertDisplay: "block",
			});
		} else {
			formIsValid = true;
			this.setState({ alertBorder: "0px soild white" });
			let errors = {};
			errors["endDate"] = "";
			errors["commonError"] = "";
			errors["startDate"] = "";
			this.setState({ errors: errors });

			let data = {
				parentCampID: this.state.parentCampID,
				startDate: this.state.cloneStartDate,
				endDate: this.state.cloneEndDate,
				firstLeadDeliveryDate: this.state.cloneFirstLeadDate,
			}; //karan-task-3796-vapt remove ids
			//Sandeep-task-3227-added code to hide modal
			this.setState({ modalShow: false });
			fetch("/agencyDashboard/cloneCompletedCampaign", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((response) => {
				//	var message1 = response.message; //Rutuja 2978 commented message 1 as never used
					Swal.fire({
						html:
							"New Copy Created Successfully<br>with campaign ID :" +
							response.campID,
						//Sandeep-task-3607-added code for alert msg
						type: "success",
						confirmButtonText: "Ok",
						allowOutsideClick: false,
						preConfirm: () => {
							window.location.reload();
						},
					});

					this.setState({ inputClass: "disabled" });
				})
				.catch(function (err) {
					console.log(err);
				});
		}

		return formIsValid;

	}


	reAllocateCampaign(e) {
		e.preventDefault();
		e.target.className += " was-validated";

		if (this.validateForm1() === false) {
			$("#reallocate").modal("show");
			//Sandeep-task-3227-added code to show modal
			return;
		} else {
			this.setState({
				alertDisplay: "none",
				alertBorder: "0px solid white",
			});
			let data = {
				parentCampID: this.state.parentCampID,
				startDate: this.state.reStartDate,
				endDate: this.state.reEndDate,
				firstLeadDeliveryDate: this.state.reFirstLeadDate,
				budget: this.state.reBudget,
				CPL: this.state.reCpl,
				leadAllocation: this.state.reTotalLeads,
			}; //karan-task-3796-vapt remove ids

			$("#reallocate").modal("hide");
			//Sandeep-task-3227-added code to hide modal
			fetch("/agency/reCreateCampaign", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((response) => {
					var message1 = response.message;

					Swal.fire({
						text: message1,
						type: "success",
						confirmButtonText: "Ok",
						allowOutsideClick: false,
						preConfirm: () => {
							window.location.reload();
						},
					});

					this.setState({ inputClass: "disabled" });
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	}

	validateForm1() {
		let errors = {};
		let formIsValid = true;
		let budgetValidate = true;
		let cplValidate = true;
		let leadValidate = true;
		let dateValidate = true;
		let firstDateValidate = true;

		//Lead validation
		if (
			this.state.reTotalLeads === undefined ||
			this.state.reTotalLeads === "" ||
			this.state.reTotalLeads === null
		) {
			leadValidate = false;
			errors["commonError"] = <li>Please fill below required fields</li>;
			this.setState({ errors: errors });
		} else {
			var pattern = new RegExp(/^(0|[1-9]\d*)$/);
			if (!pattern.test(this.state.reTotalLeads)) {
				leadValidate = false;
				errors["lead"] = (
					<li>Please enter only numerical values for total leads </li>
				); //shivani-task 2712-updated error message.
				this.setState({ errors: errors });
			} else {
				errors["lead"] = "";
				this.setState({ errors: errors });
			}
		}
		//Budget validation
		if (
			this.state.reBudget === undefined ||
			this.state.reBudget === "" ||
			this.state.reBudget === null
		) {
			budgetValidate = false;
			errors["commonError"] = <li>Please fill below required fields</li>;
			this.setState({ errors: errors });
		} else {
			let pattern = new RegExp(/^[1-9]\d*(\.\d{1,2})?$/);
			if (!pattern.test(this.state.reBudget)) {
				budgetValidate = false;
				errors["budget"] = (
					<li>Please enter only numerical values for budget</li>
				); //shivani-task 2712-updated error message.
				this.setState({ errors: errors });
			} else {
				errors["budget"] = "";
				this.setState({ errors: errors });
			}
		}
		//cpl validation
		if (
			this.state.reCpl === undefined ||
			this.state.reCpl === "" ||
			this.state.reCpl === null
		) {
			cplValidate = false;
			errors["commonError"] = <li>Please fill below required fields</li>;
			this.setState({ errors: errors });
		} else {
			let pattern = new RegExp(/^[1-9]\d*(\.\d{1,2})?$/);
			if (!pattern.test(this.state.reCpl)) {
				cplValidate = false;
				errors["cpl"] = <li>Please enter only numerical values for cpl</li>; //shivani-task 2712-updated error message.
				this.setState({ alertBorder: "1px solid red" });
			} else {
				errors["cpl"] = "";
				this.setState({ errors: errors });
			}
		}

		//SD validation
		if (
			this.state.reStartDate === undefined ||
			this.state.reStartDate === "" ||
			this.state.reStartDate === null
		) {
			dateValidate = false;
			errors["commonError"] = <li>Please fill below required fields</li>;
			this.setState({ errors: errors });
		} else {
			var currentDate = new Date(
				new Date().getTime() - new Date().getTimezoneOffset() * 60000
			)
				.toISOString()
				.split("T")[0];
			var dt = new Date(currentDate);
			dt.setMonth(dt.getMonth() - 1);
			var month, day, year;
			month = "" + (dt.getMonth() + 1);
			day = "" + dt.getDate();
			year = dt.getFullYear();

			if (month.length < 2) month = "0" + month;
			if (day.length < 2) day = "0" + day;
			var updatedDate = [year, month, day].join("-");
			//shivani -task 2712 - added validations same as create campaign for start date to display proper error messages for wrong values .
			if (Date.parse(this.state.reStartDate) < Date.parse(updatedDate)) {
				dateValidate = false;
				errors["startDate"] = (
					<li> Start date can not be older than a month time period</li>
				);
			}

			///Start date can not be older than a month time period
		}

		//ED validation
		// shivani -task 2712 - added validations same as create campaign for end date to display proper error messages for wrong values .
		if (
			this.state.reEndDate === undefined ||
			this.state.reEndDate === "" ||
			this.state.reEndDate === null
		) {
			dateValidate = false;
			errors["commonError"] = <li>Please fill below required fields</li>;
			this.setState({ errors: errors });
		} else {
			if (
				Date.parse(this.state.reStartDate) > Date.parse(this.state.reEndDate)
			) {
				dateValidate = false;
				errors["endDate"] = (
					<li> Please enter end date greater than start date</li>
				);
				this.setState({ errors: errors });
			}
		}

		//FLDD validation
		//shivani -task 2712 - added validations same as create campaign for first lead delivery date to display proper error messages for wrong values .
		if (
			this.state.reFirstLeadDate === undefined ||
			this.state.reFirstLeadDate === "" ||
			this.state.reFirstLeadDate === null
		) {
			firstDateValidate = false;
			errors["commonError"] = <li>Please fill below required fields</li>;
			this.setState({ errors: errors });
		} else {
			if (
				Date.parse(this.state.reFirstLeadDate) >
				Date.parse(this.state.reEndDate)
			) {
				firstDateValidate = false;
				errors["firstdeliverydateEnddate"] = (
					<li> Please enter first lead delivery date less than end date</li>
				);
				this.setState({ errors: errors });
			}
			//First Lead Delivery Date Can't Be Greater Than End date
			if (
				Date.parse(this.state.reFirstLeadDate) <
				Date.parse(this.state.reStartDate)
			) {
				firstDateValidate = false;
				errors["firstdeliverydateStartdate"] = (
					<li>Please enter first lead delivery date greater than start date</li>
				);
				this.setState({ errors: errors });
			}
			//First Lead Delivery Date Can't Be Less Than Start Date
			if (Date.parse(this.state.reFirstLeadDate) < Date.parse(currentDate)) {
				firstDateValidate = false;
				errors["firstdeliverydateCurrentDate"] = (
					<li>
						Please enter end date as current date or greater than current date
					</li>
				);

				this.setState({ errors: errors });
			}
			//First Lead Delivery Date Must Be Greater Than Current Date
		}

		if (
			leadValidate === false ||
			budgetValidate === false ||
			cplValidate === false ||
			dateValidate === false ||
			firstDateValidate === false
		) {
			formIsValid = false;
			this.setState({
				errors: errors,
				alertBorder: "1px solid red",
				alertWidth: "1000px",
				alertAlign: "center",
				alertDisplay: "block",
			});
		} else {
			formIsValid = true;
			errors["commonError"] = "";
			this.setState({
				errors: errors,
				alertBorder: "0px solid while",
				alertDisplay: "none",
			});
		}
		return formIsValid;
	}

	componentWillMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			var parsed = this.props.location.state; //karan-task-3684-replace query params
			if (parsed === undefined) {
				this.props.history.replace("/newDashboard");
				return;
			}
			var key = parsed.key;
			const { user } = this.props.auth;
			const token = user.token; //shivani-task 2993- added code to declare token and using this token to authenticate the api.
			var agencyID = user.id;
			var fromDate = parsed.fromDate;
			var toDate = parsed.toDate;
			var clientName = parsed.clientName;
			var publisherName = parsed.publisherName;
			var from = parsed.from;
			var campstatus = parsed.status;
			var byPublisher = parsed.byPublisher === true ? "true" : "false"; //karan-task-3929-count mismatched
			var dashActiveCount;
			//sunita-task-2996-added in setState campstatus,fromdate and todate,key when getting from dashboard to use in other places.
			this.setState({
				clientName: clientName,
				campstatus: campstatus,
				fromDate: fromDate,
				toDate: toDate,
				key: key,
				token,
			}); //shivani-task 2993- added code to setState the token to increase api security.

			let dashboardData = {
				fromDate: fromDate,
				toDate: toDate,
				clientName: clientName,
				pID: publisherName,
			}; //karan-task-3796-vapt remove ids

			const dt = new Date();
			var index = dt.getMonth();
			var userID = user.userID;
			// var userRole = user.role; //Rutuja 2978 commented userRole as never used
			var firstName = user.firstName;
			var lastName = user.lastName;
			this.setState({
				agencyID: agencyID,
				invoiceLength: index,
				userID: userID,
				firstName: firstName,
				lastName: lastName,
			});
			var datetime = new Date();
			var enddate = datetime.toISOString().slice(0, 10);
			this.setState({ enddate: enddate });
			//sunita-task-2996- sending this data as per campstatus geting from dashboard.
			var data;

			if (campstatus === "Completed") {
				 data = { pacing: "Monthly" }; //karan-task-3796-vapt remove ids
				this.state.label = "Ending this month";//Rutuja 4810 facing issue while removed console warning
				//this.setState({label: "Ending this month"});//Rutuja 2978 -commented above this.state.lable and used this.setState for console warning 
				} else {
				data = { pacing: "All" }; //karan-task-3796-vapt remove ids
			}

			let dashboardDataCount = {
				fromDate: fromDate,
				toDate: toDate,
				clientName: clientName,
				tab: campstatus,
				tabNameKey: "All", //sunita-task-2996- bydefault tabNamekey is given as all as per BE requirement
			}; //karan-task-3796-vapt remove ids

			let dashboardDataCountPub = {
				pID: publisherName,
				fromDate: fromDate,
				toDate: toDate,
				tab: campstatus,
				tabNameKey: "All", //sunita-task-2996- bydefault tabNamekey is given as all as per BE requirement
			}; //karan-task-3796-vapt remove ids

			if (byPublisher === "true") {
				fetch("/agencyDashboard/agencyDashboardCountByPublisher", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(dashboardDataCountPub),
				})
					.then((res) => res.json())
					.then((dashboardCampaignCount) => {
						//Sonali-3232-added following if conditions to show loader
						if (campstatus === "Completed") {
							dashActiveCount = dashboardCampaignCount[0].completedCount;
						}
						if (campstatus === "Active") {
							dashActiveCount = dashboardCampaignCount[0].activeCount;
						}
						if (campstatus === "Cancel") {
							dashActiveCount = dashboardCampaignCount[0].cancelCampaign;
						}
						this.setState({
							dashboardCampaignCount: dashboardCampaignCount,
							tabName: campstatus,
							dashActiveCount: dashActiveCount, //Sonali-3232-added this to show loader
						});
					})
					.catch(function (err) {
						console.log(err);
					});

				//sunita-task-2996-default tab count set in array i.e noChangeDashbordCampaignCount array.and this tabNamekey and tab given as per BE requirement for count
				var dataCount = {
					pacing: "All",
					byPacing: true,
					tab: "All",
					tabNameKey: "All",
				}; //karan-task-3796-vapt remove ids
				fetch("/agencyDashboard/agencyDashboardCount", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(dataCount),
				})
					.then((res) => res.json())
					.then((dashboardCampaignCount) => {
						this.setState({
							noChangeDashbordCampaignCount: dashboardCampaignCount,
						});
					})
					.catch(function (err) {
						console.log(err);
					});
			} else if (from === "byCampaign") {
				fetch("/agencyDashboard/agencyDashboardCount", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(dashboardDataCount),
				})
					.then((res) => res.json())
					.then((dashboardCampaignCount) => {
						//Sonali-3232-added following if conditions to show loader
						if (campstatus === "Completed") {
							dashActiveCount = dashboardCampaignCount[0].completedCount;
						}
						if (campstatus === "Active") {
							dashActiveCount = dashboardCampaignCount[0].activeCount;
						}

						this.setState({
							dashboardCampaignCount: dashboardCampaignCount,
							tabName: campstatus,
							dashActiveCount: dashActiveCount, //Sonali-3232-added this to show loader
						});
					})
					.catch(function (err) {
						console.log(err);
					});

				//sunita-task-2996-default tab count set in array i.e noChangeDashbordCampaignCount array.
				let dataCount = {
					pacing: "All",
					byPacing: true,
					tab: "All",
					tabNameKey: "All",
				}; //karan-task-3796-vapt remove ids
				fetch("/agencyDashboard/agencyDashboardCount", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(dataCount),
				})
					.then((res) => res.json())
					.then((dashboardCampaignCount) => {
						this.setState({
							noChangeDashbordCampaignCount: dashboardCampaignCount,
						});
					})
					.catch(function (err) {
						console.log(err);
					});
			} else {
				let data = {
					pacing: this.state.pacing,
					byPacing: true,
					tabNameKey: "All",
					tab: "All",
				}; //karan-task-3796-vapt remove ids
				//sunita-task-2996-default tab count set in array i.e noChangeDashbordCampaignCount array.
				fetch("/agencyDashboard/agencyDashboardCount", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((dashboardCampaignCount) => {
						this.setState({
							dashboardCampaignCount: dashboardCampaignCount,
							noChangeDashbordCampaignCount: dashboardCampaignCount,
							dashActiveCount: dashboardCampaignCount[0].activeCount,
						});
					})
				
					.catch(function (err) {
						console.log(err);
					});
			}

			//api to display campaign data tab wise (by Publisher as well as by Client)&& (by Lead as well as by by Campaign)
			if (byPublisher === "true") {
				if (campstatus === "Active") {
					var tab = "Active";
					fetch("/agencyDashboard/publisherActiveCampaign", {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(dashboardData),
					})
						.then((res) => res.json())
						.then((partialCampaignList) => {
							this.setState({
								campaignDetails: partialCampaignList,
								key: key,
								partialCount: partialCampaignList.length,
								tab: tab,
								loading: false,
								loader_progress: "",
							}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
						})
						.catch(function (err) {
							console.log(err);
						});
				} else if (campstatus === "Cancel") {
					if (from === "byCampaign") {
						let tab = "Cancelled";
						fetch("/agencyDashboard/publisherCancelCampaign", {
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(dashboardData),
						})
							.then((res) => res.json())
							.then((campaignDetails) => {
								this.setState({
									campaignDetails: campaignDetails,
									tab: tab,
									loading: false,
									cancelledCampaignCount: campaignDetails.length,
									linkActive1: "",
									linkActive: "",
									linkActive2: "",
									linkActive3: "",
									linkActive4: "",
									linkActive5: "active",
									loader_progress: "",
								}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
							})
							.catch(function (err) {
								console.log(err);
							});
					} else {
						let tab = "Cancelled";
						fetch("/agencyDashboard/publisherCancelCampaign", {
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(dashboardData),
						})
							.then((res) => res.json())
							.then((campaignDetails) => {
								this.setState({
									campaignDetails: campaignDetails,
									tab: tab,
									loading: false,
									cancelledCampaignCount: campaignDetails.length,
									linkActive1: "",
									linkActive: "",
									linkActive2: "",
									linkActive3: "",
									linkActive4: "",
									linkActive5: "active",
									loader_progress: "",
								}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
							})
							.catch(function (err) {
								console.log(err);
							});
					}
				} else if (campstatus === "Completed") {
					if (from === "byCampaign") {
						let tab = "Complete";
						fetch("/agencyDashboard/publisherCompleteCampaign", {
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(dashboardData),
						})
							.then((res) => res.json())
							.then((campaignDetails) => {
								this.setState({
									campaignDetails: campaignDetails,
									tab: tab,
									loading: false,
									completedCampaignCount: campaignDetails.length,
									linkActive1: "",
									linkActive: "",
									linkActive2: "",
									linkActive3: "active",
									linkActive4: "",
									linkActive5: "",
									loader_progress: "",
								}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
							})
							.catch(function (err) {
								console.log(err);
							});
					} else {
						let tab = "Complete";
						fetch("/agencyDashboard/publisherCompleteCampaign", {
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(dashboardData),
						})
							.then((res) => res.json())
							.then((campaignDetails) => {
								this.setState({
									campaignDetails: campaignDetails,
									tab: tab,
									loading: false,
									completedCampaignCount: campaignDetails.length,
									linkActive1: "",
									linkActive: "",
									linkActive2: "",
									linkActive3: "active",
									linkActive4: "",
									linkActive5: "",
									loader_progress: "",
								}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
							})
							.catch(function (err) {
								console.log(err);
							});
					}
				} else if (campstatus === "Paused") {
					if (from === "byCampaign") {
						let tab = "Paused";
						fetch("/agencyDashboard/publisherPausedCampaign", {
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(dashboardData),
						})
							.then((res) => res.json())
							.then((campaignDetails) => {
								this.setState({
									campaignDetails: campaignDetails,
									tab: tab,
									loading: false,
									pauseCampaignCount: campaignDetails.length,
									linkActive1: "",
									linkActive: "",
									linkActive2: "active",
									linkActive3: "",
									linkActive4: "",
									linkActive5: "",
									loader_progress: "",
								}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
							})
							.catch(function (err) {
								console.log(err);
							});
					} else {
						let tab = "Paused";
						fetch("/agencyDashboard/publisherPausedCampaign", {
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(dashboardData),
						})
							.then((res) => res.json())
							.then((campaignDetails) => {
								this.setState({
									campaignDetails: campaignDetails,
									tab: tab,
									loading: false,
									pauseCampaignCount: campaignDetails.length,
									linkActive1: "",
									linkActive: "",
									linkActive2: "active",
									linkActive3: "",
									linkActive4: "",
									linkActive5: "",
									loader_progress: "",
								}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
							})
							.catch(function (err) {
								console.log(err);
							});
					}
				} else {
					if (from === "byCampaign") {
						let tab = "Active";
						fetch("/agencyDashboard/activeCampaignInfo", {
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(dashboardData),
						})
							.then((res) => res.json())
							.then((campaignDetails) => {
								this.setState({
									campaignDetails: campaignDetails,
									tab: tab,
									loading: false,
									activeCampaignCount: campaignDetails.length,
									linkActive1: "active",
									linkActive: "",
									linkActive2: "",
									linkActive3: "",
									linkActive4: "",
									linkActive5: "",
									loader_progress: "",
								}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
							})
							.catch(function (err) {
								console.log(err);
							});
					} else if (from === "byLead") {
						let tab = "Active";
						fetch("/agencyDashboard/activeCampaignInfo", {
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(dashboardData),
						})
							.then((res) => res.json())
							.then((campaignDetails) => {
								this.setState({
									campaignDetails: campaignDetails,
									tab: tab,
									loading: false,
									activeCampaignCount: campaignDetails.length,
									linkActive1: "active",
									linkActive: "",
									linkActive2: "",
									linkActive3: "",
									linkActive4: "",
									linkActive5: "",
									loader_progress: "",
								}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
							})
							.catch(function (err) {
								console.log(err);
							});
					} else {
						let data = { pacing: this.state.pacing, byPacing: true }; //karan-task-3796-vapt remove ids
						let tab = "Active";
						fetch("/agencyDashboard/activeCampaignInfo", {
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(data),
						})
							.then((res) => res.json())
							.then((campaignDetails) => {
								this.setState({
									campaignDetails: campaignDetails,
									tab: tab,
									loading: false,
									loader_progress: "",
								}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
							})
							.catch(function (err) {
								console.log(err);
							});
					}
				}
			}
			else {
				if (key === "active") {
					let tab = "Active";
					let isprogressBtnDisable = this.state.isprogressBtnDisable;
					fetch("/agencyDashboard/partialCampaignList", {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(data),
					})
						.then((res) => res.json())
						.then((partialCampaignList) => {
							if (partialCampaignList.length === 0) {
								isprogressBtnDisable = true; //sunita-task-3060-added this code when came from dashboard and in table data is not present and downloading the progress report
							} else {
								isprogressBtnDisable = false; //sunita-task-3060-added this code when came from dashboard and in table data is present and downloading the progress report
							}
							//sunita-task-2996-fromCampaigntoAllocate- added when we are coming from campains to allocate from dashboard
							this.setState({
								campaignDetails: partialCampaignList,
								key: key,
								partialCount: partialCampaignList.length,
								tab: tab,
								loading: false,
								tabName: tab,
								fromCampaigntoAllocate: true,
								isprogressBtnDisable,
								loader_progress: "",
							}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
							//sunita- task-3060-progressBtnDisable its value  will change as per data present in table for disabling the download button.
						})
						.catch(function (err) {
							console.log(err);
						});
				} else if (key === "campaignSetup") {
					let tab = "CampaignSetup";
					fetch("/agencyDashboard/dashboardCampaignSetup", {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(data),
					})
						.then((res) => res.json())
						.then((campaignDetails) => {
							this.setState({
								campaignDetails: campaignDetails,
								tab: tab,
								linkActive4: "active",
								linkActive3: "",
								linkActive1: "",
								linkActive2: "",
								linkActive: "",
								linkActive5: "",
								loading: false,
								tabName: tab,
								loader_progress: "",
							}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
						})
						.catch(function (err) {
							console.log(err);
						});
				} else if (campstatus === "Cancel") {
					if (from === "byCampaign") {
						let tab = "Cancelled";
						fetch("/agencyDashboard/cancelCampaignInfo", {
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(dashboardData),
						})
							.then((res) => res.json())
							.then((campaignDetails) => {
								this.setState({
									campaignDetails: campaignDetails,
									tab: tab,
									loading: false,
									cancelledCampaignCount: campaignDetails.length,
									linkActive1: "",
									linkActive: "",
									linkActive2: "",
									linkActive3: "",
									linkActive4: "",
									linkActive5: "active",
									loader_progress: "",
								}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
							})
							.catch(function (err) {
								console.log(err);
							});
					} else {
					}
				} else if (campstatus === "Completed") {
					if (from === "byCampaign") {
						let tab = "Complete";
						fetch("/agencyDashboard/completeCampaignInfo", {
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(dashboardData),
						})
							.then((res) => res.json())
							.then((campaignDetails) => {
								this.setState({
									campaignDetails: campaignDetails,
									tab: tab,
									loading: false,
									completedCampaignCount: campaignDetails.length,
									linkActive1: "",
									linkActive: "",
									linkActive2: "",
									linkActive3: "active",
									linkActive4: "",
									linkActive5: "",
									loader_progress: "",
								}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
							})
							.catch(function (err) {
								console.log(err);
							});
					} else {
					}
				} else if (campstatus === "Paused") {
					if (from === "byCampaign") {
						let tab = "Paused";
						fetch("/agencyDashboard/pausedCampaignInfo", {
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(dashboardData),
						})
							.then((res) => res.json())
							.then((campaignDetails) => {
								this.setState({
									campaignDetails: campaignDetails,
									tab: tab,
									loading: false,
									pauseCampaignCount: campaignDetails.length,
									linkActive1: "",
									linkActive: "",
									linkActive2: "active",
									linkActive3: "",
									linkActive4: "",
									linkActive5: "",
									loader_progress: "",
								}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
							})
							.catch(function (err) {
								console.log(err);
							});
					} else {
					}
				} else {
					if (from === "byCampaign") {
						let tab = "Active";
						fetch("/agencyDashboard/activeCampaignInfo", {
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(dashboardData),
						})
							.then((res) => res.json())
							.then((campaignDetails) => {
								this.setState({
									campaignDetails: campaignDetails,
									tab: tab,
									loading: false,
									activeCampaignCount: campaignDetails.length,
									linkActive1: "active",
									linkActive: "",
									linkActive2: "",
									linkActive3: "",
									linkActive4: "",
									linkActive5: "",
									loader_progress: "",
								}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
							})
							.catch(function (err) {
								console.log(err);
							});
					} else if (from === "byLead") {
						let tab = "Active";
						fetch("/agencyDashboard/activeCampaignInfo", {
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(dashboardData),
						})
							.then((res) => res.json())
							.then((campaignDetails) => {
								this.setState({
									campaignDetails: campaignDetails,
									tab: tab,
									loading: false,
									activeCampaignCount: campaignDetails.length,
									linkActive1: "active",
									linkActive: "",
									linkActive2: "",
									linkActive3: "",
									linkActive4: "",
									linkActive5: "",
									loader_progress: "",
								}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
							})
							.catch(function (err) {
								console.log(err);
							});
					} else {
						let tab = "Active";

						fetch("/agencyDashboard/dashboardActiveCampaign", {
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(data),
						})
							.then((res) => res.json())
							.then((campaignDetails) => {
								this.setState({
									campaignDetails: campaignDetails,
									tab: tab,
									loading: false,
									loader_progress: "",
								}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
							})
							.catch(function (err) {
								console.log(err);
							});
					}
				}
			}

			//getting Campaign ID which status is New, Counter, Reject
			fetch("agencyDashboard/notifyDashNewCamp") //karan-task-3796-vapt remove ids
				.then((res) => res.json())
				.then((notifyDashNewCamp) => {
					this.setState({
						biddingArray: notifyDashNewCamp[1],
						counterLength: notifyDashNewCamp[1].length,
						loading1: false,
					});
				})
				.catch(function (err) {
					console.log(err);
				});

			fetch("agencyDashboard/notifyDashNewCamp") //karan-task-3796-vapt remove ids
				.then((res) => res.json())
				.then((notifyDashNewCamp) => {
					this.setState({ rejectLength: notifyDashNewCamp[2].length });
				})
				.catch(function (err) {
					console.log(err);
				});

			fetch("agencyDashboard/leadReviewDashboard") //karan-task-3796-vapt remove ids
				.then((res) => res.json())
				.then((leadReviewDashboard) => {
					this.setState({ leadsLength: leadReviewDashboard.length });
				})
				.catch(function (err) {
					console.log(err);
				});

			fetch("/campaign/publisherLinkReview", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((publisherLinkReview) => {
					this.setState({ othersLength: publisherLinkReview.length });
				})
				.catch(function (err) {
					console.log(err);
				});

			//getting Campaign ID which Delivered The Lead
			var ReviewTab = "ReviewCreatives";
			fetch("agencyDashboard/lpPOCCSReviewNotifyDash") //karan-task-3796-vapt remove ids
				.then((res) => res.json())
				.then((lpPOCCSReviewNotifyDash) => {
					this.setState({
						reviewArray: lpPOCCSReviewNotifyDash,
						creativeLength: lpPOCCSReviewNotifyDash.length,
						ReviewTab: ReviewTab,
						loading2: false,
					});
				})
				.catch(function (err) {
					console.log(err);
				});
		}

		fetch("agencyDashboard/completeOnEndDate") //karan-task-3796-vapt remove ids
			.then((res) => res.json())
			.catch(function (err) {
				console.log(err);
			});
		// If bidding date is crossed
		fetch("agencyDashboard/checkBiddingDateCrossed") //karan-task-3796-vapt remove ids
			.then((res) => res.json())
			.catch(function (err) {
				console.log(err);
			});
	}

	reAllocate = (e) => {
		var parentCampID = e.currentTarget.getAttribute("parentCampID"); // karan-task-3796-bug-parentcampid was ""
		var reallocationID = e.currentTarget.getAttribute("reallocationID"); // karan-task-3796-bug-parentcampid was ""
		this.setState({
			parentCampID: parentCampID,
			reallocationID: reallocationID,
		});
	};

	/**
	 * @author Narendra
	 * @param  Description  Searching table information
	 */
	handleChangeSearch(e) {
		e.preventDefault();
		let currentList = [];
		// Variable to hold the filtered list before putting into state
		let newList = [];
		let filterData = e.target.value;
		// If the search bar isn't empty
		if (e.target.value === "") {
			newList = this.state.campaignDetails;
		} else {
			// Assign the original list to currentList
			currentList = this.state.campaignDetails;

			newList = currentList.filter((item) => {
				let obj = {};//Somnath Task-3972, Add specific Keys in search criteria
				obj[`campID`] = item.campID;
				obj[`parentCampID`] = item.parentCampID;
				obj[`campaignName`] = item.campaignName;
				obj[`startDate`] = item.startDate;
				obj[`endDate`] = item.endDate;
				obj[`leadAllocation`] = item.leadAllocation;
				obj[`allocatedLead`] = item.allocatedLead;
				obj[`clientCampID`] = item.clientCampID;
				obj[`pendingLead`] = item.pendingLead;
				obj[`deliveredLead`] = item.deliveredLead;
				obj[`qaReviewLead`] = item.qaReviewLead;
				obj[`acceptedLead`] = item.acceptedLead;
				obj[`rejectedLead`] = item.rejectedLead;
				obj[`balancedLead`] = item.balancedLead;
				if (this.state.tabName === "CampaignSetup") {
					obj[`advCampID`] = item.advCampID;
					obj[`advCampaignName`] = item.advCampaignName;
					obj[`startDate`] = item.startDate;
					obj[`endDate`] = item.endDate;
					obj[`advLeadAllocation`] = item.advLeadAllocation;
					obj[`advBudget`] = item.advBudget;
					obj[`currency`] = item.currency;
					obj[`advCPL`] = item.advCPL;
					obj[`advStatus`] = item.advStatus;
				}

				// change current item to lowercase
				const lc = obj;
				// change search term to lowercase
				const filter = e.target.value.toLowerCase();
				// check to see if the current list item includes the search term
				// If it does, it will be added to newList. Using lowercase eliminates
				// issues with capitalization in search terms and search content
				var data = Object.values(lc);
				var dataFinal = data.toString();
				dataFinal = dataFinal.replace(",", " ");
				var dataToLower = dataFinal.toLowerCase();
				return dataToLower.includes(filter);
			});
		}
		// Set the filtered state based on what our rules added to newList
		this.setState({
			campaignDetailsSearch: [...newList],
			tableSearchText: filterData,
		});
	}
	/**
	 * @author Narendra
	 * @param  Description  Searching table information
	 */
	handleChangeSearch1(e) {
		e.preventDefault();
		let currentList = [];
		// Variable to hold the filtered list before putting into state
		let newList = [];
		let filterData = e.target.value;
		// If the search bar isn't empty
		if (e.target.value === "") {
			newList = this.state.unreadMessages;
		} else {
			// Assign the original list to currentList
			currentList = this.state.unreadMessages;
			newList = currentList.filter((item) => {
				// change current item to lowercase
				const lc = item;
				// change search term to lowercase
				const filter = e.target.value.toLowerCase();
				// check to see if the current list item includes the search term
				// If it does, it will be added to newList. Using lowercase eliminates
				// issues with capitalization in search terms and search content
				var data = Object.values(lc);
				var dataFinal = data.toString();
				dataFinal = dataFinal.replace(",", " ");
				var dataToLower = dataFinal.toLowerCase();
				return dataToLower.includes(filter);
			});
		}
		// Set the filtered state based on what our rules added to newList
		this.setState({
			columnUnreadMsgSearch: [...newList],
			tableSearchText1: filterData,
		});
	}

	/**
	 * @author Narendra
	 * @param  Description  Searching table information
	 */
	handleChangeSearch2(e) {
		e.preventDefault();
		let currentList = [];
		// Variable to hold the filtered list before putting into state
		let newList = [];
		let filterData = e.target.value;
		// If the search bar isn't empty
		if (e.target.value === "") {
			newList = this.state.unreadAlerts;
		} else {
			// Assign the original list to currentList
			currentList = this.state.unreadAlerts;
			newList = currentList.filter((item) => {
				// change current item to lowercase
				const lc = item;
				// change search term to lowercase
				const filter = e.target.value.toLowerCase();
				// check to see if the current list item includes the search term
				// If it does, it will be added to newList. Using lowercase eliminates
				// issues with capitalization in search terms and search content
				var data = Object.values(lc);
				var dataFinal = data.toString();
				dataFinal = dataFinal.replace(",", " ");
				var dataToLower = dataFinal.toLowerCase();
				return dataToLower.includes(filter);
			});
		}
		// Set the filtered state based on what our rules added to newList
		this.setState({
			columnUnreadAlertsSearch: [...newList],
			tableSearchText2: filterData,
		});
	}

	handleChangeIncremental(e) {
		let fields = this.state;
		fields[e.target.name] = e.target.value;
		this.setState({
			fields,
		});
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			campaignDetails: nextProps.campaignDetails,
		});
	}

	getInvoiceLink(e) {
		var month = e.target.id;
		var created = e.target.getAttribute("created");
		var yr = new Date(created);
		var year = yr.getFullYear();
		this.props.history.push("/agencyInvoice", { month, Year: year }); //karan-task-3715-replace query params
	}

	getPublisherLink(e) {
		var pID = e.target.id;
		this.props.history.push("/reviewLinkPublisher", { pID }); //karan-task-3715-replace query params
	}

	/**
	 * @author Supriya Gore
	 * @param  Description  handle libyAI url with post method
	 * @return Description return successfully response with url
	 */
	getLIbyAIURL(e) {
		const { user } = this.props.auth;
		var campID = e.target.id;
		let libyAIURL = configration.libyAIURL;
		//Sonali-3771-removing below line from comment
		libyAIURL = libyAIURL + "?campID=" + campID + "&userID=" + user.id;
		let data = {
			user: user,
			campID: campID,
		};

		fetch("/setCookie", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((response) => {
				this.handleRefreshData(e);
				if (response.message === "cookiesSet") {
					window.open(libyAIURL, "_blank");
				}
			});
	}

	render() {
		const asterisk = {
			color: "red",
		};
		const { user } = this.props.auth;

		const columns = [
			{
				title: "Camp ID",
				dataIndex: "campID",
				key: "campID",
				width: "7%",

				align: "center",
				sorter: (a, b) => a.campID - b.campID,
				render: (text, record) => (
					<div style={{ width: "55px" }}>
						<span style={{ fontSize: "12px" }}>
							{record.parentCampID
								? record.parentCampID
								: record.advCampID
									? record.advCampID
									: text}
						</span>
						<br />
					</div>
				),
			},
			// Sandeep-task-3275-added new column for agency camp ID
			{
				title: "Agency Camp ID",
				dataIndex: "agencycampID",
				key: "agencycampID",
				width: "7%",

				align: "center",
				sorter: (a, b) => a.agencycampID - b.agencycampID,
				render: (text, record) => (
					<div style={{ width: "75px" }}>
						<span style={{ fontSize: "12px" }}>
							{record.clientCampID ? record.clientCampID : text}
						</span>
						<br />
					</div>
				),
			},

			{
				title: "Camp Name",
				dataIndex: "campaignName",
				key: "campaignName",
				width: "16%",
				align: "center",
				sorter: (a, b) => {
					const asc = "asc";

					if (a.campaignName.toUpperCase() < b.campaignName.toUpperCase()) {
						return asc ? -1 : 1;
					} else if (
						a.campaignName.toUpperCase() > b.campaignName.toUpperCase()
					) {
						return asc ? 1 : -1;
					} else {
						return 0;
					}
				},
				render: (text, record) => (
					<div class="dot1" style={{ width: "170px" }}>
						<span>
							{record.campaignName ? (
								<a
									href="#/" //karan-task-3895-link issue //Rutuja 2978 chnaged # to #/
									onClick={this.displayCampaignSpecification}
									campID={record.campID}
									campaignName={record.campaignName}
									parentCampID={record.parentCampID}
									reallocationID={record.reallocationID}
									style={{ fontSize: "12px", width: "100px" }}>
									{record.campaignName}
								</a>
							) : (
								<span style={{ fontSize: "12px", width: "100px" }}>
									<a
										href="#/"
										onClick={this.displayCampaignSetup}
										advCampID={record.advCampID}
										agencyAllocationID={record.agencyAllocationID}
										advCampaignName={record.advCampaignName}>
										{record.advCampaignName}
									</a>
								</span>
							)}
						</span>
						<br />
					</div>
				),
			},
			{
				title: "Start Date",
				dataIndex: "startDate",
				key: "startDate",
				width: "7.25%",
				fontSize: "12px",
				align: "center",
				sorter: (a, b) => {
					const asc = "asc";

					if (a.startDate < b.startDate) {
						return asc ? -1 : 1;
					} else if (a.startDate > b.startDate) {
						return asc ? 1 : -1;
					} else {
						return 0;
					}
				},
			},
			{
				title: "End Date",
				dataIndex: "endDate",
				key: "endDate",
				width: "7.25%",
				fontSize: "12px",
				align: "center",
				sorter: (a, b) => {
					const asc = "asc";

					if (a.endDate < b.endDate) {
						return asc ? -1 : 1;
					} else if (a.endDate > b.endDate) {
						return asc ? 1 : -1;
					} else {
						return 0;
					}
				},
			},
			{
				title: "Allocation Status",
				align: "center",
				width: "17%",
				children: [
					{
						title: "Total",
						dataIndex: "leadAllocation",
						key: "leadAllocation",
						align: "center",
						width: "5%",
						sorter: (a, b) =>
							parseInt(a.leadAllocation) - parseInt(b.leadAllocation),
						render(text, record) {
							return {
								props: {
									style: { wordWrap: "break-word", height: "5%" },
								},
								children: (
									<div>
										{record.leadAllocation
											? record.leadAllocation
											: record.advAllocatedLead}
									</div>
								),
							};
						},
					},
					{
						title: "Allocated",
						dataIndex: "allocatedLead",
						key: "allocatedLead",
						align: "center",
						width: "6%",
						sorter: (a, b) => {
							const asc = "asc";

							if (a.allocatedLead < b.allocatedLead) {
								return asc ? -1 : 1;
							} else if (a.allocatedLead > b.allocatedLead) {
								return asc ? 1 : -1;
							} else {
								return 0;
							}
						},
						render: (text, record) => (
							<div style={{ fontSize: "12px" }}>
								{text > 0 ? (
									<a
										href="#/"
										CampId={record.campID}
										parentCampId={record.parentCampID}
										reallocationId={record.reallocationID}
										onClick={this.handleChangeAllocatedLeads}
										style={{ fontSize: "12px" }}>
										{text}
									</a>
								) : (
									<div>0</div>
								)}
							</div>
						),
					},
					{
						title: "Pending",
						dataIndex: "pendingLead",
						key: "pendingLead",
						align: "center",
						width: "6%",
						sorter: (a, b) => {
							const asc = "asc";

							if (a.pendingLead < b.pendingLead) {
								return asc ? -1 : 1;
							} else if (a.pendingLead > b.pendingLead) {
								return asc ? 1 : -1;
							} else {
								return 0;
							}
						},
						render(text, record) {
							return {
								props: {
									style: { wordWrap: "break-word", height: "5%" },
								},
								children: (
									<div style={{ fontSize: "12px" }}>
										{" "}
										{record.pendingLead ? record.pendingLead : 0}
									</div>
								),
							};
						},
					},
				],
			},
			{
				title: "Delivery Status",
				width: "10%",
				children: [
					{
						title: "Delivered",
						dataIndex: "deliveredLead",
						key: "deliveredLead",
						align: "center",
						width: "6%",
						sorter: (a, b) => {
							const asc = "asc";

							if (a.deliveredLead < b.deliveredLead) {
								return asc ? -1 : 1;
							} else if (a.deliveredLead > b.deliveredLead) {
								return asc ? 1 : -1;
							} else {
								return 0;
							}
						},
						render: (text, record) => (
							<div>
								{record.deliveredLead > 0 ? (
									<a
										href="#/"
										id={record.campID}
										parentcampid={record.parentCampID}
										clientCampID={record.clientCampID}//nilesh
										leadtype={"Delivered"}
										onClick={this.handledownloadLead.bind(this)}
										style={{ fontSize: "12px" }}>
										{record.deliveredLead}
									</a>
								) : (
									0
								)}
							</div>
						),
					},
					{
						title: "Review Pending", //Sandeep-task-3377-change in review pending
						dataIndex: "qaReviewLead",
						key: "qaReviewLead",
						align: "center",
						width: "6%",
						sorter: (a, b) => {
							const asc = "asc";

							if (a.qaReviewLead < b.qaReviewLead) {
								return asc ? -1 : 1;
							} else if (a.qaReviewLead > b.qaReviewLead) {
								return asc ? 1 : -1;
							} else {
								return 0;
							}
						},
						render: (text, record) => (
							<div>
								{record.qaReviewLead > 0 ? (
									<a
										href="#/"
										id={record.campID}
										parentcampid={record.parentCampID}
										clientCampID={record.clientCampID}//nilesh
										leadtype={"Review-Pending"}
										onClick={this.handledownloadLead.bind(this)}
										style={{ fontSize: "12px" }}>
										{record.qaReviewLead}
									</a>
								) : (
									0
								)}
							</div>
						),
					},
					{
						title: "Accepted",
						dataIndex: "acceptedLead",
						key: "acceptedLead",
						align: "center",
						width: "5.6%",
						sorter: (a, b) => {
							const asc = "asc";

							if (a.acceptedLead < b.acceptedLead) {
								return asc ? -1 : 1;
							} else if (a.acceptedLead > b.acceptedLead) {
								return asc ? 1 : -1;
							} else {
								return 0;
							}
						},
						render: (text, record) => (
							<div>
								{record.acceptedLead > 0 ? (
									<a
										href="#/"
										id={record.campID}
										parentcampid={record.parentCampID}
										clientCampID={record.clientCampID}//nilesh
										leadtype={"Accepted"}
										onClick={this.handledownloadLead.bind(this)}
										style={{ fontSize: "12px" }}>
										{record.acceptedLead}
									</a>
								) : (
									0
								)}
							</div>
						),
					},
					{
						title: "Rejected",
						dataIndex: "rejectedLead",
						key: "rejectedLead",
						align: "center",
						width: "6%",
						sorter: (a, b) => {
							const asc = "asc";

							if (a.rejectedLead < b.rejectedLead) {
								return asc ? -1 : 1;
							} else if (a.rejectedLead > b.rejectedLead) {
								return asc ? 1 : -1;
							} else {
								return 0;
							}
						},
						render: (text, record) => (
							<div>
								{record.rejectedLead > 0 ? (
									<a
										href="#/"
										id={record.campID}
										parentcampid={record.parentCampID}
										clientCampID={record.clientCampID}//nilesh
										leadtype={"Rejected"}
										onClick={this.handledownloadLead.bind(this)}
										style={{ fontSize: "12px" }}>
										{record.rejectedLead}
									</a>
								) : (
									0
								)}
							</div>
						),
					},
					{
						title: "Balance",
						dataIndex: "balancedLead",
						key: "balancedLead",
						align: "center",
						width: "6%",
						sorter: (a, b) => {
							const asc = "asc";

							if (a.balancedLead < b.balancedLead) {
								return asc ? -1 : 1;
							} else if (a.balancedLead > b.balancedLead) {
								return asc ? 1 : -1;
							} else {
								return 0;
							}
						},
						render(text, record) {
							return {
								props: {
									style: { wordWrap: "break-word", height: "5%", width: "6%" },
								},
								children: (
									<div style={{ fontSize: "12px" }}>
										{" "}
										{record.balancedLead ? record.balancedLead : 0}
									</div>
								),
							};
						},
					},
				],
			},

			{
				title: "Action",
				dataIndex: "",
				key: "",
				width: "6%",
				// shivani -task 3416- added code to adjust width of this column so that all buttons in action column will display properly.
				align: "center",
				//shivani-3611- call onActionClick function to show custom div with option.
				render: (text, record) => (
					<div>
						<div
							className="action_context_menu_icon"
							onClick={(e) => this.onActionClick(e, record)}>
							<span
								style={{
									padding: 5,
									position: "relative",
									top: 15,
									left: 0,
								}}>
								...
							</span>
						</div>
						{/* //shivani-3702-added missing code  */}
						<div>
							<div
								class="modal fade"
								id="editEndDate1"
								role="dialog"
								data-backdrop="static"
								data-keyboard="false"
								style={{ backgroundColor: "rgba(0,0,0,.4)" }}>
								{/* //shivani-task 2712- added css to make backgound blurr. */}
								<div className="endDateModal modal-dialog cust-class">
									<div
										className="modal-content modal-endDate"
										style={{ marginLeft: "-100px" }}>
										<div class="modal-header custom-class">
											<h4 class="modal-title" style={{ color: "#144c9b" }}>
												<font color="white">
													Edit End Date To Make Campaign Live&nbsp;(Campaign ID:
													{this.state.campID})
												</font>
											</h4>

											<button
												type="button"
												class="cancel-button"
												style={{ fontSize: 15, float: "right" }}
												data-dismiss="modal"
												onClick={this.handleRefreshPage}>
												&times;
											</button>
										</div>
										<div class="modal-body">
											<div class="successMessage">{this.state.successMsg}</div>
											<div
												style={{
													border: this.state.alertBorder,
													display: this.state.alertDisplay,
												}}>
												<div class="errorMessage">
													{this.state.errors.commonError}
												</div>
											</div>
											<p>
												{this.state.endDateCampaign.map((endDateCampaign) => (
													<div>
														<div class="row">
															<div
																class="col-6"
																style={{ marginLeft: "-30px" }}>
																<label id="label" style={{ width: "110px" }}>
																	<font color="black">Start Date</font>
																</label>
																<br />
																{endDateCampaign.startDate}
															</div>
															<div class="col-4">
																<label id="label" style={{ width: "110px" }}>
																	<font color="black">End Date</font>
																</label>
																<input
																	type="date"
																	id="endDate"
																	name="endDate"
																	min=""
																	required
																	pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
																	defaultValue={endDateCampaign.endDate}
																	onChange={this.handleChangeEndDate}
																	className="form-control"
																/>
															</div>
														</div>
													</div>
												))}
											</p>
										</div>
										<div class="modal-footer">
											<button
												type="button"
												class="btn btn-default "
												className={this.state.inputClass}
												id={this.state.advCampID}
												onClick={this.handleChangeEndDateCampaign}
												style={{
													backgroundColor: "#144c9b",
													color: "white",
													borderRadius: "3px",
												}}>
												Resume Campaign&nbsp;
												<i class="fa fa-pencil-square-o" aria-hidden="true"></i>
											</button>
										</div>
									</div>
								</div>
							</div>

							<div
								class="modal fade"
								id="incrementalModal1"
								role="dialog"
								data-backdrop="static"
								data-keyboard="false"
								style={{ backgroundColor: "rgba(0,0,0,.4)" }}>
								{/* //shivani-task 2712- added css to make backgound blurr. */}
								<div class="modal-dialog  cust-class">
									<div class="modal-content">
										<div class="modal-header custom-class">
											<h4
												class="modal-title"
												style={{ color: "#144999", paddingRight: "12px" }}>
												<font color="white" style={{ paddingRight: "25px" }}>
													Please Enter Incremental Leads&nbsp;(Campaign ID:
													{this.state.parentCampID})
												</font>
											</h4>
											<button
												type="button"
												class="cancel-button"
												style={{ fontSize: 15 }}
												onClick={this.handleRefreshPage}
												data-dismiss="modal">
												&times;
											</button>
											{/*sunita – Task -2947- handlerefreshpage is alredy present but i have added here for refreshing the page*/}
										</div>

										<div class="modal-body">
											<p>
												<div class="successMessage">
													{this.state.successMsg}
												</div>

												<div>
													<div
														style={{
															border: this.state.alertBorder,
															display: this.state.alertDisplay,
														}}>
														<div class="errorMessage">
															<li>{this.state.errors.commonError}</li>
															{this.state.errors.Currentdate}
															{this.state.errors.EnddateValidate}
															{this.state.errors.leadAllocation}
															{this.state.errors.cpl}
															{this.state.errors.budget}
															{this.state.errors.pubEnddate}{" "}
															{/* sunita – Task -2947- Used for printing the error msg when less end date is given as compared to publisher end date*/}
														</div>
													</div>
												</div>
												<br />
												{this.state.incrementCampDetails.map(
													(incrementCampDetails) => (
														<div>
															{/*sunita – Task -2947- for publisher end date */}

															{incrementCampDetails.publisherEndDate ? (
																<div class="row">
																	<label
																		id="label"
																		style={{
																			paddingRight: "130px",
																			paddingLeft: "20px",
																			color: "#144c9b",
																		}}>
																		Publisher End Date &nbsp;:&nbsp;
																		<span style={{ color: "#555" }}>
																			{incrementCampDetails.publisherEndDate}
																		</span>
																	</label>
																</div>
															) : (
																""
															)}

															<div class="row">
																<div class="col-6">
																	<label
																		id="label"
																		style={{
																			paddingRight: "100px",
																			color: "#144c9b",
																		}}>
																		Increamental Leads:
																		<span style={{ color: "red" }}>*</span>
																	</label>
																	<input
																		style={{
																			width: "150px",
																			fontWeight: "500",
																		}}
																		type="text"
																		className="form-control"
																		name="incrementalLead"
																		onChange={this.handleIncrementLeads}
																		required
																	/>
																</div>
																<div class="col-6">
																	<label
																		id="label"
																		style={{
																			paddingRight: "160px",
																			color: "#144c9b",
																		}}>
																		End Date:{" "}
																	</label>
																	<input
																		type="date"
																		id="endDate"
																		name="campEndDate"
																		min=""
																		required
																		pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
																		defaultValue={
																			incrementCampDetails.campEndDate
																		}
																		onChange={this.handleChangeDate}
																		className="form-control"
																	/>
																</div>
															</div>
														</div>
													)
												)}
											</p>
										</div>

										<div class="modal-footer">
											<center>
												<button
													type="button"
													class="btn btn-default "
													className={this.state.inputClass}
													onClick={this.handleIncCampaignLeads}
													style={{
														backgroundColor: "#144999",
														color: "white",
														borderRadius: "3px",
													}}>
													Increment
												</button>
											</center>
										</div>
									</div>
								</div>
							</div>

							<div
								class="modal fade"
								id="decrementalModal1"
								role="dialog"
								data-backdrop="static"
								data-keyboard="false"
								width="400px"
								style={{ backgroundColor: "rgba(0,0,0,.4)" }}>
								{/* //shivani-task 2712- added css to make backgound blurr. */}
								<div class="modal-dialog ">
									<div class="modal-content">
										<div class="modal-header custom-class">
											<h4
												class="modal-title"
												style={{ color: "#144999", paddingRight: "12px" }}>
												<font color="white" style={{ paddingRight: "25px" }}>
													Decremental Leads&nbsp;(Campaign ID:
													{this.state.parentCampID})
												</font>
											</h4>
											<button
												type="button"
												class="cancel-button"
												style={{ fontSize: 15, marginLeft: "35px" }}
												onClick={this.handleRefreshPage}
												data-dismiss="modal">
												&times;
											</button>
										</div>

										<div class="modal-body">
											<p>
												<div class="successMessage">
													{this.state.successMsg}
												</div>

												<div
													style={{
														display: this.state.alertDisplay,
														border: this.state.alertBorder,
													}}>
													<div class="errorMessage">
														{this.state.errors.commonError}
														{this.state.errors.wholenumber}
														{this.state.errors.wholenumber1}
														{this.state.errors.wholenumber2}
													</div>
												</div>
												<br />
												<div class="row">
													<div class="col-md-4">
														<label
															style={{ marginRight: "200px", width: "100px" }}>
															Publisher Name
														</label>
													</div>
													<div class="col-md-8">
														<select
															style={{
																border: "1px solid #ccc",
																boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
																width: "300px",
																height: "45px",
																borderRadius: "2px",
																borderColor: "#eee",
																marginRight: "100px",
															}}
															onChange={this.getAllocatedLeads}
															name="decrementPopup">
															{this.state.publisherList.map((publisherList) => (
																<option
																	value={
																		publisherList.pID + "+" + this.state.campID
																	}>
																	{publisherList.publisherName}
																</option>
															))}
														</select>
													</div>
												</div>
												<br />

												{this.state.onClickAllocatedLeadPubList.map(
													(onClickAllocatedLeadPubList) => (
														<div>
															{onClickAllocatedLeadPubList.pID ===
																this.state.publisherID ? (
																<table class="table table-bordered">
																	<tr>
																		<th colSpan="1">
																			<label
																				id="label"
																				style={{
																					color: "orange",
																					fontSize: "14px",
																					fontWeight: "bold",
																				}}>
																				Allocation Status
																			</label>
																		</th>
																		<th>
																			{
																				onClickAllocatedLeadPubList.allocatedLead
																			}
																		</th>
																		<th colSpan="2">
																			<label
																				id="label"
																				style={{
																					color: "orange",
																					fontSize: "14px",
																					fontWeight: "bold",
																				}}>
																				Delivery Status
																			</label>{" "}
																		</th>
																	</tr>

																	<tr>
																		<td>
																			{" "}
																			<label id="label">Accept</label>
																		</td>
																		<td>
																			{onClickAllocatedLeadPubList.Accept}
																			<br />
																		</td>
																		<td>
																			{" "}
																			<label id="label">Delivered</label>
																		</td>
																		<td>
																			{onClickAllocatedLeadPubList.deliveredLead
																				? onClickAllocatedLeadPubList.deliveredLead
																				: "0"}{" "}
																			<br />
																		</td>
																	</tr>

																	<tr style={{ backgroundColor: "#fff" }}>
																		<td>
																			<label id="label">Assign</label>
																		</td>
																		<td>
																			{onClickAllocatedLeadPubList.Assign}{" "}
																			<br />
																		</td>
																		<td>
																			<label id="label">Balance</label>
																		</td>
																		<td>
																			{onClickAllocatedLeadPubList.Accept +
																				onClickAllocatedLeadPubList.Assign +
																				onClickAllocatedLeadPubList.Counter +
																				onClickAllocatedLeadPubList.AcceptedCounter -
																				(onClickAllocatedLeadPubList.acceptedLead +
																					onClickAllocatedLeadPubList.agencyInternalReviewLead +
																					onClickAllocatedLeadPubList.qaReviewLead) >
																				0
																				? onClickAllocatedLeadPubList.Accept +
																				onClickAllocatedLeadPubList.Assign +
																				onClickAllocatedLeadPubList.Counter +
																				onClickAllocatedLeadPubList.AcceptedCounter -
																				(onClickAllocatedLeadPubList.acceptedLead +
																					onClickAllocatedLeadPubList.agencyInternalReviewLead +
																					onClickAllocatedLeadPubList.qaReviewLead)
																				: 0}
																			<br />
																		</td>
																	</tr>

																	<tr>
																		<td>
																			{" "}
																			<label id="label">Counter</label>
																		</td>
																		<td>
																			{onClickAllocatedLeadPubList.Counter}
																			<br />
																		</td>
																		<td>
																			{" "}
																			<label id="label">Accepted</label>
																		</td>{" "}
																		<td>
																			<span
																				style={{
																					color: "#20EB10",
																					fontWeight: "600",
																				}}>
																				{onClickAllocatedLeadPubList.acceptedLead ? (
																					this.state.requiredLeadPerAsset ===
																						"Yes" ? (
																						<a
																							href="#/"
																							id={
																								onClickAllocatedLeadPubList.campID
																							}
																							parentCampID={
																								onClickAllocatedLeadPubList.parentCampID
																									? onClickAllocatedLeadPubList.parentCampID
																									: onClickAllocatedLeadPubList.campID
																							}
																							reallocationID={
																								onClickAllocatedLeadPubList.reallocationID
																							}
																							pID={
																								onClickAllocatedLeadPubList.pID
																							}
																							data-toggle="modal"
																							data-target="#showLeadPerAsset"
																							onClick={this.showAssetwiseLeads.bind(
																								this
																							)}>
																							{onClickAllocatedLeadPubList.acceptedLead
																								? onClickAllocatedLeadPubList.acceptedLead
																								: 0}
																						</a>
																					) : onClickAllocatedLeadPubList.acceptedLead ? (
																						onClickAllocatedLeadPubList.acceptedLead
																					) : (
																						0
																					)
																				) : (
																					0
																				)}
																			</span>
																			<br />
																		</td>
																	</tr>

																	<tr style={{ backgroundColor: "#fff" }}>
																		<td>
																			<label id="label">Accepted Counter</label>
																		</td>
																		<td>
																			{
																				onClickAllocatedLeadPubList.AcceptedCounter
																			}
																			<br />
																		</td>
																		<td>
																			{" "}
																			<label id="label">Rejected</label>
																		</td>{" "}
																		<td>
																			<span
																				style={{
																					color: "red",
																					fontWeight: "600",
																				}}>
																				{onClickAllocatedLeadPubList.rejectedLead
																					? onClickAllocatedLeadPubList.rejectedLead
																					: "0"}{" "}
																			</span>
																			<br />
																		</td>
																	</tr>

																	<tr>
																		<td>
																			{" "}
																			<label id="label">Reject</label>
																		</td>
																		<td>
																			{onClickAllocatedLeadPubList.Reject}{" "}
																			<br />
																		</td>
																		<td>
																			{" "}
																			<label id="label">
																				Agency Internal Review
																			</label>
																		</td>
																		<td>
																			{onClickAllocatedLeadPubList.agencyInternalReviewLead >
																				0
																				? onClickAllocatedLeadPubList.agencyInternalReviewLead
																				: 0}
																			<br />
																		</td>
																	</tr>

																	<tr style={{ backgroundColor: "#fff" }}>
																		<td>
																			{" "}
																			<label id="label">Rejected Counter</label>
																		</td>
																		<td>
																			{
																				onClickAllocatedLeadPubList.RejectedCounter
																			}
																			<br />
																		</td>
																	</tr>
																	<tr style={{ backgroundColor: "#fff" }}>
																		<td>
																			{" "}
																			<label
																				id="label"
																				style={{ width: "100px" }}>
																				Cancel After Lead Upload
																			</label>
																		</td>{" "}
																		<td>
																			{onClickAllocatedLeadPubList.cancelALU}
																			<br />
																		</td>
																		{/* sunita- task - 2941- replaced the Cancel ALU - Cancel After Lead Upload */}
																	</tr>
																</table>
															) : (
																""
															)}
														</div>
													)
												)}

												<div class="row">
													<div class="col-md-4">
														<label
															style={{ marginRight: "200px", width: "120px" }}>
															<b>Decrement Leads By:</b>
														</label>
														&nbsp;&nbsp;
													</div>
													<div class="col-md-8">
														<input
															type="text"
															name="decrementalLeads"
															id={this.state.allocatedLeadForDec}
															onChange={this.handleDecrementLeads}
															style={{
																border: "1px solid #ccc",
																boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
																marginRight: "160px",
																width: "100px",
																height: "32px",
																borderRadius: "2px",
																borderColor: "#eee",
															}}></input>
													</div>
												</div>
											</p>
										</div>

										<div class="modal-footer" style={{ marginTop: "-33px" }}>
											<center>
												<button
													type="button"
													class="btn btn-default "
													className={this.state.inputClass}
													onClick={this.handleDecrementCampaignLeads}
													style={{
														backgroundColor: "#144999",
														color: "white",
														borderRadius: "3px",
													}}>
													Decrement
												</button>
											</center>
										</div>
									</div>
								</div>
							</div>

							<div
								class="modal fade"
								id="confirmClone"
								role="dialog"
								data-backdrop="static"
								data-keyboard="false"
								style={{ backgroundColor: "rgba(0,0,0,.4)" }}>
								{/* //shivani-task 2712- added css to make backgound blurr. */}
								<div
									class="modal-dialog modal-sm cust-class"
									style={{ top: "58%", left: "30%" }}>
									<div class="modal-content">
										<div class="modal-header custom-class">
											<h4
												class="modal-title"
												style={{ color: "#144c9b", paddingRight: "190px" }}>
												<font color="white">Confirm</font>
											</h4>

											<button
												type="button"
												class="cancel-button"
												style={{ fontSize: 15 }}
												data-dismiss="modal"
												id="Completed"
												onClick={this.handleRefreshPage}>
												&times;
											</button>
										</div>
										<br />

										<br />

										<div class="modal-body">
											<label>
												Are you sure you want to create copy of this campaign?
											</label>
											<br />
											<button
												type="button"
												class="btn btn-primary"
												data-toggle="modal"
												data-target="#cloneDetails">
												Yes
											</button>
											&nbsp;&nbsp;
											<button
												type="button"
												class="btn btn-primary"
												data-dismiss="modal"
												id="Completed"
												onClick={this.handleRefreshPage}>
												No
											</button>
										</div>
									</div>
								</div>
							</div>

							<Modal
								show={this.state.modalShow}
								dialogClassName="modal-90w"
								aria-labelledby="example-modal-sizes-title-lg"
								centered>
								{/* shivani-task 2711-removed onHide event to see the popUp*/}

								<Modal.Header
									style={{
										backgroundImage:
											"linear-gradient(to right,#28166f,#007cc3)",
										color: "white",
									}}
									closeButton
									onClick={this.handleRefreshPage}>
									{/* shivani-task 2711-added handlerefresh to load the page*/}
									<Modal.Title
										style={{ color: "white" }}
										id="example-modal-sizes-title-lg">
										Clone Detail&nbsp;(Campaign ID:{this.state.parentCampID})
									</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<form
										method="POST"
										name="acceptPub"
										className="needs-validation"
										noValidate>
										<div class="container-fluid">
											<div
												class="errorMessage"
												style={{
													border: this.state.alertBorder,
													display: this.state.alertDisplay,
												}}>
												{this.state.errors.endDate}
												{this.state.errors.startDate}
												{this.state.errors.commonError}
											</div>
										</div>
										<div class="row">
											<div class="col-sm-4">
												<label id="label">
													Start Date<span style={asterisk}>*</span>
													<span style={{ color: "#337ab7", fontSize: "12px" }}>
														(dd-mm-yyyy){" "}
													</span>
												</label>
												<input
													type="date"
													class="form-control"
													id="cloneStartDate"
													name="cloneStartDate"
													value={this.state.cloneStartDate}
													min=""
													required
													pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
													onChange={this.handleChangeIncremental}></input>
											</div>
											<div class="col-sm-4">
												<label id="label">
													End Date<span style={asterisk}>*</span>
													<span style={{ color: "#337ab7", fontSize: "12px" }}>
														(dd-mm-yyyy){" "}
													</span>
												</label>
												<input
													type="date"
													class="form-control"
													id="cloneEndDate"
													name="cloneEndDate"
													value={this.state.cloneEndDate}
													min=""
													required
													onChange={this.handleChangeIncremental}></input>
											</div>
											<div class="col-sm-4">
												<label id="label">
													First Lead Delivery Date
													<span style={asterisk}>*</span>
													<span
														style={{
															color: "#337ab7",
															fontSize: "12px",
														}}></span>
												</label>
												<input
													type="date"
													class="form-control"
													id="cloneFirstLeadDate"
													name="cloneFirstLeadDate"
													value={this.state.cloneFirstLeadDate}
													min=""
													required
													onChange={this.handleChangeIncremental}></input>
											</div>
										</div>
									</form>
								</Modal.Body>

								<Modal.Footer>
									<Button
										variant="primary"
										type="button"
										onClick={this.cloneCompletedCampaign}>
										Save Changes
									</Button>
									<Button variant="secondary" onClick={this.handleRefreshPage}>
										{/* shivani-task 2711-added handlerefresh to load the page*/}
										Close
									</Button>
								</Modal.Footer>
							</Modal>

							<div
								class="modal fade"
								id="reallocate"
								role="dialog"
								data-backdrop="static"
								data-keyboard="false"
								style={{ backgroundColor: "rgba(0,0,0,.4)" }}>
								{/* //shivani-task 2712- added css to make backgound blurr. */}
								<div
									class="modal-dialog modal-lg cust-class"
									style={{ top: "58%", left: "15%" }}>
									<div class="modal-content">
										<form
											method="POST"
											name="acceptPub"
											onSubmit={this.reAllocateCampaign}
											className="needs-validation"
											noValidate>
											<div class="modal-header custom-class">
												<h4
													class="modal-title"
													style={{ color: "#144c9b", paddingRight: "410px" }}>
													<font color="white">
														Re-Allocation Detail&nbsp;(Campaign ID:
														{this.state.parentCampID})
													</font>
												</h4>

												<button
													type="button"
													class="cancel-button"
													style={{ fontSize: 15 }}
													data-dismiss="modal"
													id="Completed"
													onClick={this.handleRefreshPage}>
													&times;
												</button>
											</div>
											<br />

											<div class="container-fluid">
												<div
													class="errorMessage"
													style={{
														border: this.state.alertBorder,
														display: this.state.alertDisplay,
													}}>
													{this.state.errors.commonError}
													{this.state.errors.commonCpl}
													{this.state.errors.endDate}
													{this.state.errors.startDate}
													{this.state.errors.leadsvalidate}
													{this.state.errors.lead}{" "}
													{/* //shivani-task 2712-added this line to set the error message. */}
													{this.state.errors.cplBudget1}
													{this.state.errors.advCPL}
													{this.state.errors.cpl}
													{this.state.errors.budget}
													{this.state.errors.advBudget}
													{this.state.errors.firstdeliverydateEnddate}
													{this.state.errors.firstdeliverydateStartdate}
													{this.state.errors.leadAllocation}
													{this.state.errors.firstdeliverydateCurrentDate}
												</div>
											</div>
											<br />

											<div class="modal-body">
												<div class="row">
													<div class="col-sm-4">
														<label id="label">
															Start Date<span style={asterisk}>*</span>
															<span
																style={{ color: "#337ab7", fontSize: "12px" }}>
																(dd-mm-yyyy){" "}
															</span>
														</label>
														<input
															type="date"
															class="form-control"
															id="reStartDate"
															name="reStartDate"
															value={this.state.reStartDate}
															min=""
															required
															pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
															onChange={this.handleChangeIncremental}></input>
													</div>
													<div class="col-sm-4">
														<label id="label">
															End Date<span style={asterisk}>*</span>
															<span
																style={{ color: "#337ab7", fontSize: "12px" }}>
																(dd-mm-yyyy){" "}
															</span>
														</label>
														<input
															type="date"
															class="form-control"
															id="reEndDate"
															name="reEndDate"
															value={this.state.reEndDate}
															min=""
															required
															onChange={this.handleChangeIncremental}></input>
													</div>
													<div class="col-sm-4">
														<label id="label">
															First Lead Delivery Date
															<span style={asterisk}>*</span>
															<span
																style={{
																	color: "#337ab7",
																	fontSize: "12px",
																}}></span>
														</label>
														<input
															type="date"
															class="form-control"
															id="reFirstLeadDate"
															name="reFirstLeadDate"
															value={this.state.reFirstLeadDate}
															min=""
															required
															onChange={this.handleChangeIncremental}></input>
													</div>
												</div>
												<br />
												<div class="row">
													<div class="col-sm-4">
														<label id="label">
															Total Leads<span style={asterisk}>*</span>
														</label>
														<input
															type="textbox"
															class="form-control"
															id="reTotalLeads"
															name="reTotalLeads"
															value={this.state.reTotalLeads}
															onChange={this.leadAllocationHandleChange}
															required></input>
													</div>
													<div class="col-sm-4">
														<label id="label">
															Budget<span style={asterisk}>*</span>
														</label>
														<input
															type="textbox"
															class="form-control"
															id="reBudget"
															name="reBudget"
															value={this.state.reBudget}
															required
															onChange={this.budgetHandleChange}></input>
													</div>
													<div class="col-sm-4">
														<label id="label">
															CPL<span style={asterisk}>*</span>
														</label>
														<input
															type="textbox"
															class="form-control"
															id="reCpl"
															name="reCpl"
															value={this.state.reCpl}
															required
															onChange={this.cplHandleChange}></input>
													</div>
												</div>
											</div>
											<div class="modal-footer">
												<button
													id="reallocateModal"
													type="button"
													class="btn btn-primary"
													onClick={this.reAllocateCampaign}
													className={this.state.inputClass}
													style={{
														backgroundColor: "#144999",
														color: "white",
														borderRadius: "3px",
													}}>
													Re-allocate
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				),
			},
		];

		//Sonali-3232-loader changed for loaded and no data image issue
		// let locale = {
		// 	emptyText: "Please wait a moment while we fetch the data",
		// }; //Rutuja 2978 commented locale as never used
		const columnsAQA = [
			{
				title: "Camp ID",
				dataIndex: "campID",
				key: "campID",
				width: "7.5%",

				align: "center",
				sorter: (a, b) => a.campID - b.campID,
				render: (text, record) => (
					<div style={{ width: "75px" }}>
						<span style={{ fontSize: "12px" }}>
							{record.parentCampID
								? record.parentCampID
								: record.advCampID
									? record.advCampID
									: text}
						</span>
						<br />
					</div>
				),
			},

			// Sandeep-task-3275-added new column for agency camp ID
			{
				title: "Agency Camp ID",
				dataIndex: "agencycampID",
				key: "agencycampID",
				width: "8%",

				align: "center",
				sorter: (a, b) => a.agencycampID - b.agencycampID,
				render: (text, record) => (
					<div style={{ width: "75px" }}>
						<span style={{ fontSize: "12px" }}>
							{record.clientCampID ? record.clientCampID : text}
						</span>
						<br />
					</div>
				),
			},

			{
				title: "Camp Name",
				dataIndex: "campaignName",
				key: "campaignName",
				width: "18.5%",
				align: "center",
				sorter: (a, b) => {
					const asc = "asc";

					if (a.campaignName.toUpperCase() < b.campaignName.toUpperCase()) {
						return asc ? -1 : 1;
					} else if (
						a.campaignName.toUpperCase() > b.campaignName.toUpperCase()
					) {
						return asc ? 1 : -1;
					} else {
						return 0;
					}
				},
				render: (text, record) => (
					<div class="dot1" style={{ width: "170px" }}>
						<span>
							{record.campaignName ? (
								<a
									href="#/" //karan-task-3895-link issue //Rutuja 2978 chnaged # to page name
									onClick={this.displayCampaignSpecification}
									campID={record.campID}
									campaignName={record.campaignName}
									parentCampID={record.parentCampID}
									reallocationID={record.reallocationID}
									style={{ fontSize: "12px", width: "100px" }}>
									{record.campaignName}
								</a>
							) : (
								<span style={{ fontSize: "12px", width: "100px" }}>
									<a
										href="#/"
										onClick={this.displayCampaignSetup}
										advCampID={record.advCampID}
										agencyAllocationID={record.agencyAllocationID}
										advCampaignName={record.advCampaignName}>
										{record.advCampaignName}
									</a>
								</span>
							)}
						</span>
						<br />
					</div>
				),
			},
			{
				title: "Start Date",
				dataIndex: "startDate",
				key: "startDate",
				width: "7.25%",
				fontSize: "12px",
				align: "center",
				sorter: (a, b) => {
					const asc = "asc";

					if (a.startDate < b.startDate) {
						return asc ? -1 : 1;
					} else if (a.startDate > b.startDate) {
						return asc ? 1 : -1;
					} else {
						return 0;
					}
				},
			},
			{
				title: "End Date",
				dataIndex: "endDate",
				key: "endDate",
				width: "7.25%",
				fontSize: "12px",
				align: "center",
				sorter: (a, b) => {
					const asc = "asc";

					if (a.endDate < b.endDate) {
						return asc ? -1 : 1;
					} else if (a.endDate > b.endDate) {
						return asc ? 1 : -1;
					} else {
						return 0;
					}
				},
			},
			{
				title: "Allocation Status",
				align: "center",
				width: "17%",
				children: [
					{
						title: "Total",
						dataIndex: "leadAllocation",
						key: "leadAllocation",
						align: "center",
						width: "5%",
						sorter: (a, b) =>
							parseInt(a.leadAllocation) - parseInt(b.leadAllocation),
						render(text, record) {
							return {
								props: {
									style: { wordWrap: "break-word", height: "5%" },
								},
								children: (
									<div>
										{record.leadAllocation
											? record.leadAllocation
											: record.advAllocatedLead}
									</div>
								),
							};
						},
					},
					{
						title: "Allocated",
						dataIndex: "allocatedLead",
						key: "allocatedLead",
						align: "center",
						width: "6%",
						sorter: (a, b) => {
							const asc = "asc";

							if (a.allocatedLead < b.allocatedLead) {
								return asc ? -1 : 1;
							} else if (a.allocatedLead > b.allocatedLead) {
								return asc ? 1 : -1;
							} else {
								return 0;
							}
						},
						render: (text, record) => (
							<div style={{ fontSize: "12px" }}>
								{text > 0 ? (
									<a
										href="#/"
										CampId={record.campID}
										parentCampId={record.parentCampID}
										reallocationId={record.reallocationID}
										onClick={this.handleChangeAllocatedLeads}
										style={{ fontSize: "12px" }}>
										{text}
									</a>
								) : (
									<div>0</div>
								)}
							</div>
						),
					},
					{
						title: "Pending",
						dataIndex: "pendingLead",
						key: "pendingLead",
						align: "center",
						width: "6%",
						sorter: (a, b) => {
							const asc = "asc";

							if (a.pendingLead < b.pendingLead) {
								return asc ? -1 : 1;
							} else if (a.pendingLead > b.pendingLead) {
								return asc ? 1 : -1;
							} else {
								return 0;
							}
						},
						render(text, record) {
							return {
								props: {
									style: { wordWrap: "break-word", height: "5%" },
								},
								children: (
									<div style={{ fontSize: "12px" }}>
										{" "}
										{record.pendingLead ? record.pendingLead : 0}
									</div>
								),
							};
						},
					},
				],
			},
			{
				title: "Delivery Status",
				width: "10%",
				children: [
					{
						title: "Delivered",
						dataIndex: "deliveredLead",
						key: "deliveredLead",
						align: "center",
						width: "6%",
						sorter: (a, b) => {
							const asc = "asc";

							if (a.deliveredLead < b.deliveredLead) {
								return asc ? -1 : 1;
							} else if (a.deliveredLead > b.deliveredLead) {
								return asc ? 1 : -1;
							} else {
								return 0;
							}
						},
						render: (text, record) => (
							<div>
								{record.deliveredLead > 0 ? (
									<a
										href="#/"
										id={record.campID}
										parentcampid={record.parentCampID}
										clientCampID={record.clientCampID}//nilesh
										leadtype={"Delivered"}
										onClick={this.handledownloadLead.bind(this)}
										style={{ fontSize: "12px" }}>
										{record.deliveredLead}
									</a>
								) : (
									0
								)}
							</div>
						),
					},
					{
						title: "Review Pending", //Sandeep-task-3377=change in review pending title
						dataIndex: "qaReviewLead",
						key: "qaReviewLead",
						align: "center",
						width: "6%",
						sorter: (a, b) => {
							const asc = "asc";

							if (a.qaReviewLead < b.qaReviewLead) {
								return asc ? -1 : 1;
							} else if (a.qaReviewLead > b.qaReviewLead) {
								return asc ? 1 : -1;
							} else {
								return 0;
							}
						},
						render: (text, record) => (
							<div>
								{record.qaReviewLead > 0 ? (
									<a
										href="#/"
										id={record.campID}
										parentcampid={record.parentCampID}
										clientCampID={record.clientCampID}//nilesh
										leadtype={"Review-Pending"}
										onClick={this.handledownloadLead.bind(this)}
										style={{ fontSize: "12px" }}>
										{record.qaReviewLead}
									</a>
								) : (
									0
								)}
							</div>
						),
					},
					{
						title: "Accepted",
						dataIndex: "acceptedLead",
						key: "acceptedLead",
						align: "center",
						width: "5.6%",
						sorter: (a, b) => {
							const asc = "asc";

							if (a.acceptedLead < b.acceptedLead) {
								return asc ? -1 : 1;
							} else if (a.acceptedLead > b.acceptedLead) {
								return asc ? 1 : -1;
							} else {
								return 0;
							}
						},
						render: (text, record) => (
							<div>
								{record.acceptedLead > 0 ? (
									<a
										href="#/"
										id={record.campID}
										parentcampid={record.parentCampID}
										clientCampID={record.clientCampID}//nilesh
										leadtype={"Accepted"}
										onClick={this.handledownloadLead.bind(this)}
										style={{ fontSize: "12px" }}>
										{record.acceptedLead}
									</a>
								) : (
									0
								)}
							</div>
						),
					},
					{
						title: "Rejected",
						dataIndex: "rejectedLead",
						key: "rejectedLead",
						align: "center",
						width: "6%",
						sorter: (a, b) => {
							const asc = "asc";

							if (a.rejectedLead < b.rejectedLead) {
								return asc ? -1 : 1;
							} else if (a.rejectedLead > b.rejectedLead) {
								return asc ? 1 : -1;
							} else {
								return 0;
							}
						},
						render: (text, record) => (
							<div>
								{record.rejectedLead > 0 ? (
									<a
										href="#/"
										id={record.campID}
										parentcampid={record.parentCampID}
										clientCampID={record.clientCampID}//nilesh
										leadtype={"Rejected"}
										onClick={this.handledownloadLead.bind(this)}
										style={{ fontSize: "12px" }}>
										{record.rejectedLead}
									</a>
								) : (
									0
								)}
							</div>
						),
					},
					{
						title: "Balance",
						dataIndex: "balancedLead",
						key: "balancedLead",
						align: "center",
						width: "6%",
						sorter: (a, b) => {
							const asc = "asc";

							if (a.balancedLead < b.balancedLead) {
								return asc ? -1 : 1;
							} else if (a.balancedLead > b.balancedLead) {
								return asc ? 1 : -1;
							} else {
								return 0;
							}
						},
						render(text, record) {
							return {
								props: {
									style: { wordWrap: "break-word", height: "5%", width: "6%" },
								},
								children: (
									<div style={{ fontSize: "12px" }}>
										{" "}
										{record.balancedLead ? record.balancedLead : 0}
									</div>
								),
							};
						},
					},
				],
			},
		];

		function onChange(pagination, filters, sorter) {
			console.log("params", pagination, filters, sorter);
		}

		this.state.onClickAllocatedLeadPubList =  
			this.state.onClickAllocatedLeadPubList.map((e) =>
				Object.assign(
					{},
					e,
					this.state.assignDeliveredLead.find((e1) => e1.pID === e.pID) || {}
				)
			);

		return (
			<div>
				<Navigation />
				<div
					class="container-fluid"
					style={{ paddingTop: "85px", paddingBottom: "150px" }}>
					{/*Priyanka-4277-Changed css to view dropdown properly */}
					<div
						style={{
							backgroundColor: "rgb(241, 241, 241)",
							paddingTop: "20px",
							borderBottom: "1px solid #a9a9a97d",
						}}>
						<div class="row">
							<div class=" col-xs-12 col-sm-12 col-md-6 col-lg-8 col-xl-9">
								<span>
									{/* Sandeep-task-3217-label change from campaign overview to campaign Progress */}
									<p
										className="campaignOverview"
										style={{
											fontFamily: "roboto",
											color: "black",
											fontSize: "26px",
											fontWeight: "500",
											marginBottom: "12px",
										}}>
										Campaign Progress &nbsp;&nbsp;
										{/* sunita-task-2996-create campaign image code removed from this place and added below */}
									</p>
								</span>
							</div>

							<div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
								{/* sunita-task- 2996- campaign progress report code removed */}
								{/* sunita-task-2996- added above create campaign image code here */}
								<div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
									{user.role === "ANC" ? (
										""
									) : user.role === "AQA" ? (
										""
									) : (
										// <Tooltip placement="left" title="New Campaign">
										//Sandeep-task-3227-removed tootip
										<span
											className="createCampButton"
											style={{ width: "200px" }}>
											<a
												href="welcomePage"
												// karan-task-3933-vapt header and query params
												onClick={(e) => {
													e.preventDefault();
													this.props.history.push("/welcomePage");
												}}>
												<label
													className="createCampLabel"
													style={{
														backgroundColor: "#ffaf42",
														fontSize: "15px",
														color: "white",
														fontWeight: "inherit",
														width: "190px",
														height: "30px",
														paddingTop: "2px",
														borderRadius: "3px",
													}}>
													{/* //Sandeep-task-3227-added new title as Create New Campaign */}
													&nbsp;Create New Campaign&nbsp;
													<img
														src="createCampaign.png"
														alt=""
														style={{
															display: "inline-block",
															height: "22px",
															width: "29px",
														}}></img>
												</label>
											</a>
										</span>
									)}
								</div>

								<div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
									{/* sunita-task-2996- i have removed the code bcz here is image for follow up report and as per new screen they want button.so in as it is condtion instead of followup report image am using button. */}

									{user.role === "AQA" ? (
										""
									) : user.role === "AC" &&
										this.state.followUpReport === true ? ( //sunita-task-2913-as per condition it will show below button
										<span
											type="button"
											className="btn btn-outline-primary btn-sm sendFollowupBtn"
											onClick={this.handleChangeReport}
											id="TDRreport">
											Sending...
										</span>
									) : (
										<span
											type="button"
											className="btn btn-outline-primary btn-sm sendFollowupBtn"
											onClick={this.handleChangeReport}
											id="TDRreport">
											Send Follow up
										</span>
									)}
								</div>
							</div>

							{/* sunita-task-2996-filter code is removed from this place and added below as per UI screen done tha changes*/}
						</div>

						<div class="row">
							<div class="col-xs-7 col-sm-12 col-md-12 col-lg-10 col-xl-10">
								{" "}
								{/*sunita-task-3054-changed the column as per need for setting the alignment */}
								{this.state.dashboardCampaignCount.map(
									(dashboardCampaignCount) => (
										<div
											class="float-center topnav divTab"
											style={{ height: "37px" }}>
											{/*sunita-task-3054-added classname to apply css */}
											{/* sunita-task-2996-added css as per requirement */}
											<a
												href="#/"
												className={this.state.linkActive1 + " tabLink"}
												onClick={this.campaignLink}
												id="Active"
												style={{ color: "black" }}>
												Active&nbsp;
												<span
													style={{
														paddingLeft: "5px",
														paddingRight: "5px",
														height: "22px",
														backgroundColor: "green",
														borderRadius: "18%",
														fontSize: "16px",
														color: "white",
														textAlign: "center",
														display: "inline-block",
													}}>
													{this.state.key === "active"
														? this.state.clientName
															? this.state.activeCampaignCount
															: this.state.partialCount
														: dashboardCampaignCount.activeCount}
												</span>
											</a>
											{/* &nbsp;&nbsp;&nbsp; */}
											<a
												href="#/"
												className={
													this.state.linkActive2 + " tabLink pauseTab" //Rutuja 2978 changed  " tabLink " + " pauseTab" 
												}
												onClick={this.campaignLink}
												id="Pause"
												style={{ color: "black" }}>
												Paused&nbsp;
												<span
													style={{
														paddingLeft: "5px",
														paddingRight: "5px",
														height: "22px",
														backgroundColor: "#ffaf42",
														borderRadius: "18%",
														fontSize: "16px",
														color: "white",
														textAlign: "center",
														display: "inline-block",
													}}>
													{ }
													{dashboardCampaignCount.pauseCount}
												</span>
											</a>
											{/*sunita-task-3054-added the classname for setting the alignement of pause tab  */}
											&nbsp;&nbsp;&nbsp;
											<a
												href="#/"
												className={this.state.linkActive3 + " tabLink"}
												onClick={this.campaignLink}
												id="Completed"
												style={{ color: "black" }}>
												Completed&nbsp;
												<span
													style={{
														paddingLeft: "5px",
														paddingRight: "5px",
														height: "22px",
														backgroundColor: "blue",
														borderRadius: "18%",
														fontSize: "16px",
														color: "white",
														textAlign: "center",
														display: "inline-block",
													}}>
													{dashboardCampaignCount.completedCount}
												</span>
											</a>
											&nbsp;&nbsp;
											{/* sunita-task-2996-removed the code as all tab is not present as per new UI screen */}
											{/* sunita-task-2996-to setup tab code removed from this place and added below */}
											<a
												href="#/"
												className={this.state.linkActive5 + " tabLink"}
												onClick={this.campaignLink}
												id="cancelCamp"
												style={{ color: "black" }}>
												Cancelled&nbsp;
												<span
													style={{
														paddingLeft: "5px",
														paddingRight: "5px",
														height: "22px",
														backgroundColor: "red",
														borderRadius: "18%",
														fontSize: "16px",
														color: "white",
														textAlign: "center",
														display: "inline-block",
													}}>
													{dashboardCampaignCount.cancelCampaign}
												</span>
											</a>
											&nbsp; &nbsp;&nbsp;&nbsp;
											{/* sunita-task-2996-added to setup tab code from above place chnaged the position as per new UI screen */}
											{user.role === "AC" ? (
												<a
													href="#/"
													className={this.state.linkActive4 + " tabLink"}
													onClick={this.campaignLink}
													id="CampaignSetup"
													style={{ color: "black" }}>
													{" "}
													To Setup&nbsp;
													<span
														style={{
															paddingLeft: "5px",
															paddingRight: "5px",
															height: "22px",
															backgroundColor: "#bdbdb3",
															borderRadius: "18%",
															fontSize: "16px",
															color: "white",
															textAlign: "center",
															display: "inline-block",
														}}>
														{dashboardCampaignCount.campSetUpCount}
													</span>
												</a>
											) : (
												""
											)}
											&nbsp;&nbsp;&nbsp;&nbsp;
										</div>
									)
								)}
							</div>

							<div class="col-xs-12 col-sm-12 col-md-12  col-lg-2 col-xl-2">
								<input
									id="search-bar1"
									type="text"
									onkeyup="myFunction()"
									style={{
										backgroundImage: "url(Group_294.png)",
										backgroundPosition: "15px 6px",
										backgroundSize: "15px 15px",
										backgroundRepeat: "no-repeat",
										width: "100%",
										height: "20px",
										fontSize: "10px",
										border: "1px solid #ddd",
										padding: "12px 20px 12px 34px",
										borderRadius: "25px",
										backgroundColor: "rgb(221, 221, 221)",
									}}
									onChange={this.handleChangeSearch}
									onPressEnter={this.handleChangeSearch}
									placeholder="Search..."
									title="Type in a name"
								/>
							</div>
						</div>
					</div>

					{/* sunita-task-2996- abveve removed filter code added in this place as per UI screen */}
					<div class="row" style={{ marginTop: "9px" }}>
						{
							this.state.tabName === "Active" ||
								this.state.tabName === "Completed" ? (
								<div
									class="col-xs-12 col-sm-12  col-md-8  col-lg-9 col-xl-10"
									style={{ marginRight: "-4px" }}>
									<div class="col-xs-12 col-sm-12  col-md-8  col-lg-8 col-xl-8">
										<label style={{ color: "#71717199", fontFamily: "Roboto" }}>
											Showing results for &nbsp;{" "}
										</label>{" "}
										<span
											style={{
												color: "#000",
												fontFamily: "Roboto",
												fontWeight: "500",
											}}>
											{" "}
											"{this.state.label}"
										</span>
									</div>

									{/* kiran-bug 4255-changed classname to dropdownAgency for resolved conflicts for responsiveness */}
									<div class="col-xs-12 col-sm-12  col-md-4  col-lg-4 col-xl-4">
										<div
											class="float-right dropdownAgency"
											style={{
												border: "1px solid #d9d9d9",
												backgroundColor: "white",
												padding: "2px 6px",
											}}>
											<div
												className="float-left"
												style={{
													whiteSpace: "nowrap",
													overflow: "hidden",
													textOverflow: "ellipsis",
													width: "82px",
												}}>
												{" "}
												<span id="myText">{this.state.label}</span>
											</div>
											<img
												style={{ marginTop: "4px" }}
												alt=""
												className="filterImage float-right"
												src="Filter.png"
												height="14px"
												width="14px"></img>
											{/* Sandeep-task-2911 to handle filter size increment issue  */}
											<div class="dropdown-contentAgency">
												<a
													href="#/"
													value="All"
													name="All"
													onClick={this.handleChange}
													id="All"
													label="All">
													{" "}
													All
												</a>
												<a
													href="#/"
													value="Weekly"
													name="Weekly"
													onClick={this.handleChange}
													id="Weekly"
													label="Ending This Week">
													Ending This Week
												</a>
												<a
													href="#/"
													value="Monthly"
													name="Monthly"
													onClick={this.handleChange}
													id="Monthly"
													label="Ending This Month">
													Ending This Month
												</a>
												<a
													href="#/"
													value="Quarterly"
													onClick={this.handleChange}
													name="Quarterly"
													id="Quarterly"
													label="Ending This Quarter">
													Ending This Quarter
												</a>
												<a
													href="#/"
													value="Yearly"
													onClick={this.handleChange}
													name="Yearly"
													id="Yearly"
													label="Ending This Year">
													Ending This Year
												</a>
												<a
													href="#/"
													value="PreMonth"
													onClick={this.handleChange}
													name="PreMonth"
													id="PreMonth"
													label="Ending Previous Month">
													Ending Previous Month
												</a>
												<a
													href="#/"
													value="PreQuarter"
													onClick={this.handleChange}
													name="PreQuarter"
													id="PreQuarter"
													label="Ending Previous Quarter">
													Ending Previous Quarter
												</a>
												<a
													href="#/"
													value="PreYear"
													onClick={this.handleChange}
													name="PreYear"
													id="PreYear"
													label="Ending Previous Year">
													Ending Previous Year
												</a>
											</div>
										</div>
									</div>

								</div>
							) : (
								<div class="col-xs-12 col-sm-12  col-md-12  col-lg-12 col-xl-12">
									<div class="col-xs-12 col-sm-12  col-md-6  col-lg-6 col-xl-6">
										<label style={{ color: "#71717199", fontFamily: "Roboto" }}>
											Showing results for &nbsp;{" "}
										</label>{" "}
										<span
											style={{
												color: "#000",
												fontFamily: "Roboto",
												fontWeight: "500",
											}}>
											{" "}
											"{this.state.label}"
										</span>
									</div>
									{/* kiran-bug 4255-changed classname to dropdownAgency for resolved conflicts for responsiveness */}
									<div
										class="col-xs-12 col-sm-12  col-md-6  col-lg-6 col-xl-6"
										style={{ paddingRight: "2px" }}>
										<div
											class="float-right dropdownAgency"
											style={{
												border: "1px solid #d9d9d9",
												backgroundColor: "white",
												padding: "2px 6px",
											}}>
											<div
												className="float-left"
												style={{
													whiteSpace: "nowrap",
													overflow: "hidden",
													textOverflow: "ellipsis",
													width: "82px",
												}}>
												{" "}
												<span id="myText">{this.state.label}</span>
											</div>
											<img
												style={{ marginTop: "4px" }}
												alt=""
												className="filterImage float-right"
												src="Filter.png"
												height="14px"
												width="14px"></img>
											{/* Sandeep-task-2911 to handle filter size increment issue  */}
											<div class="dropdown-contentAgency">
												<a
													href="#/"
													value="All"
													name="All"
													onClick={this.handleChange}
													id="All"
													label="All">
													{" "}
													All
												</a>
												<a
													href="#/"
													value="Weekly"
													name="Weekly"
													onClick={this.handleChange}
													id="Weekly"
													label="Ending This Week">
													Ending This Week
												</a>
												<a
													href="#/"
													value="Monthly"
													name="Monthly"
													onClick={this.handleChange}
													id="Monthly"
													label="Ending This Month">
													Ending This Month
												</a>
												<a
													href="#/"
													value="Quarterly"
													onClick={this.handleChange}
													name="Quarterly"
													id="Quarterly"
													label="Ending This Quarter">
													Ending This Quarter
												</a>
												<a
													href="#/"
													value="Yearly"
													onClick={this.handleChange}
													name="Yearly"
													id="Yearly"
													label="Ending This Year">
													Ending This Year
												</a>
												<a
													href="#/"
													value="PreMonth"
													onClick={this.handleChange}
													name="PreMonth"
													id="PreMonth"
													label="Ending Previous Month">
													Ending Previous Month
												</a>
												<a
													href="#/"
													value="PreQuarter"
													onClick={this.handleChange}
													name="PreQuarter"
													id="PreQuarter"
													label="Ending Previous Quarter">
													Ending Previous Quarter
												</a>
												<a
													href="#/"
													value="PreYear"
													onClick={this.handleChange}
													name="PreYear"
													id="PreYear"
													label="Ending Previous Year">
													Ending Previous Year
												</a>
											</div>
										</div>
									</div>

								</div>
							)
						}

						<div class="col-xs-12 col-sm-12  col-md-4  col-lg-3 col-xl-2 progressResport">
							{this.state.tabName === "Active" ||
								this.state.tabName === "Completed" ? ( // sunita- task- 2996- for displaying Campaign Progress Report for active and completed tab only.
								<label style={{ marginLeft: "1px", color: "#1890ff" }}>
									Campaign Progress Report
									<span
										style={{
											float: "right",
											marginTop: "0px",
											marginLeft: "4px",
										}}>
										{this.state.isprogressBtnDisable === false ? ( // sunita-task-3060-as per value get in isprogressBtnDisable it will disable or enable the download button
											<a href="#/">
												<i
													class="fa fa-download"
													onClick={this.downloadCampaignExcel}></i>
											</a>
										) : (
											<a href="#/">
												<i
													class="fa fa-download"
													disabled={this.state.isprogressBtnDisable}></i>
												{/*sunita-task-3060-this code added when data is not available in table will disable the prgs button*/}
											</a>
										)}
									</span>
								</label>
							) : (
								""
							)}
						</div>
					</div>
					{/* //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page */}
					<div align="center" id={this.state.loader_progress}></div>
					<div class="row">
						<div
							class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
							style={{ paddingTop: "5px" }}>
							{this.state.tableSearchText === "" ? (
								//Sonali-3232-added this to show loader other wise if data is not present show table in else part which do not have loader
								this.state.dashActiveCount > 0 ? (
									<Table
										{...this.state.tableState}
										id="myTable"
										bordered
										dataSource={this.state.campaignDetails}
										//locale={locale}//Sonali-3232-loader changed for loaded and no data image issue

										onChange={onChange}
										// Sandeep-task-3228-added code to hide Action column from AQA
										columns={user.role !== "AQA" ? columns : columnsAQA} //RUtuja 2978 changed = to ==
										loading={this.state.loading}
										//loading={{ indicator:loaderIcon,spinning: this.state.loading }}//Sonali-3232-loader changed for loaded and no data image issue
										// 4568 -(comment the console warning duplication error ) Prod Issue- AC/PC/PNC - Camp Progress - Page Unresponsive popup comes
										//className="ant-table-thead" className={tableCSS}
										className={`${tableCSS} "ant-table-thead"`}
										scroll={{ x: 1280, y: 4000 }}
										expandedRowRender={(record) => (
											<div>
												<div class="container-fluid">
													<table
														border="1"
														style={{
															tableLayout: "auto",
															borderCollapse: "collapse",
														}}>
														<tr>
															{/* Sandeep-task-3607-Clone campaign-Need to Identify- FE */}
															{record.clonedCampaign === "Yes" ? (
																<th style={{ fontWeight: "400", width: "10%" }}>
																	&nbsp;Cloned From:&nbsp;
																</th>
															) : (
																""
															)}
															{record.clonedCampaign === "Yes" ? (
																<td>
																	<b>&nbsp;{record.clonedFromCampID}</b>
																</td>
															) : (
																<td rowSpan="2">
																	&nbsp; Agency CampaignID:&nbsp;&nbsp;
																	<b>
																		{record.clientCampID
																			? record.clientCampID
																			: ""}
																	</b>
																</td>
															)}
															<th style={{ fontWeight: "400", width: "10%" }}>
																&nbsp;Campaign Name:&nbsp;
															</th>
															<td>
																<b>
																	&nbsp;
																	{record.campaignName
																		? record.campaignName
																		: record.advCampaignName}
																</b>
															</td>
															<th style={{ fontWeight: "400", width: "8%" }}>
																&nbsp;Start Date:&nbsp;
															</th>
															<td style={{ width: "8%" }}>
																<b>
																	&nbsp;
																	{record.startDate
																		? record.startDate
																		: record.advstartDate}
																</b>
															</td>
															<th style={{ fontWeight: "400", width: "8%" }}>
																&nbsp;End Date:&nbsp;
															</th>
															<td style={{ width: "8%" }}>
																<b>
																	&nbsp;
																	{record.endDate
																		? record.endDate
																		: record.advendDate}
																</b>
															</td>

															<th style={{ fontWeight: "400", width: "12%" }}>
																&nbsp;QA Review:&nbsp;
															</th>
															<td>
																<b>
																	&nbsp;
																	{record.onlyQALeads ? record.onlyQALeads : 0}
																</b>
															</td>
															{/* Sandeep-task-3228-added code for AQA */}
															{/* Nilesh-5145- Comment insight section code */}
															{/* {this.state.tab === "CampaignSetup" ||
																user.role === "AQA" ? (
																""
															) : (
																<td rowSpan="2">
																	&nbsp; Insight:&nbsp;&nbsp;
																	<a 
																	    disabled  //Nilesh-5145- disabled this link
																		href="#/"
																		id={record.campID}
																		onClick={this.getLIbyAIURL.bind(this)}>
																		<img
																			style={{ display: "inherit" }}
																			alt=""
																			src="1.png"
																			id={record.campID}
																			width="40px"
																			height="20px"></img>
																	</a>
																</td>
															)} */}
														</tr>
														<tr>
															{/* Sandeep-task-3607-Clone campaign-Need to Identify- FE */}
															{record.clonedCampaign === "Yes" ? (
																<th style={{ fontWeight: "400", width: "11%" }}>
																	&nbsp;Agency CampaignID:&nbsp;
																</th>
															) : (
																""
															)}
															{record.clonedCampaign === "Yes" ? (
																<td>
																	<b>
																		&nbsp;
																		{record.clientCampID
																			? record.clientCampID
																			: ""}
																	</b>
																</td>
															) : (
																""
															)}

															<th style={{ fontWeight: "400", width: "10%" }}>
																&nbsp;Campaign Status:&nbsp;
															</th>
															<td>
																<b>
																	&nbsp;
																	{record.campaignStatus === "Active" ? (
																		<span
																			style={{
																				color: "#5fba7d",
																				fontSize: "12px",
																			}}>
																			{" "}
																			{record.campaignStatus}
																		</span>
																	) : record.campaignStatus ===
																		"Live_Incomplete" ? (
																		<span
																			style={{
																				color: "orange",
																				fontSize: "12px",
																			}}>
																			{" "}
																			{record.campaignStatus}
																		</span>
																	) : record.campaignStatus === "Paused" ? (
																		<span
																			style={{
																				color: "#ffaf42",
																				fontSize: "12px",
																			}}>
																			{" "}
																			{record.campaignStatus}
																		</span>
																	) : record.campaignStatus === "Completed" ? (
																		<span
																			style={{
																				color: "skyblue",
																				fontSize: "12px",
																			}}>
																			{" "}
																			{record.campaignStatus}
																		</span>
																	) : record.advStatus === "PendingSetup" ? (
																		<span
																			style={{
																				color: "#abab93",
																				fontSize: "12px",
																			}}>
																			{" "}
																			{record.advStatus}
																		</span>
																	) : record.campaignStatus ===
																		"PausedIncomplete" ? (
																		<span
																			style={{
																				color: "orange",
																				fontSize: "12px",
																			}}>
																			{record.campaignStatus}
																		</span>
																	) : record.campaignStatus ===
																		"Cancel Campaign" ? (
																		<span
																			style={{
																				color: "red",
																				fontSize: "12px",
																			}}>
																			{record.campaignStatus}
																		</span>
																	) : (
																		""
																	)}
																</b>
															</td>

															{user.role === "AC" ? (
																<th style={{ fontWeight: "400", width: "8%" }}>
																	&nbsp;CPL:
																</th>
															) : (
																""
															)}
															{user.role === "AC" ? (
																<td>
																	<b>
																		&nbsp;
																		{record.CPL
																			? record.CPL
																			: record.advCPL}{" "}
																		&nbsp;{record.currency}
																	</b>
																</td>
															) : (
																""
															)}
															{user.role === "AC" ? (
																<th style={{ fontWeight: "400", width: "8%" }}>
																	&nbsp;Budget:
																</th>
															) : (
																""
															)}
															{user.role === "AC" ? (
																<td style={{ width: "8%" }}>
																	<b>
																		&nbsp;
																		{record.budget
																			? record.budget
																			: record.advBudget}
																		&nbsp;{record.currency}
																	</b>
																</td>
															) : (
																""
															)}

															<th style={{ fontWeight: "400", width: "14%" }}>
																&nbsp;Agency Internal Review:&nbsp;
															</th>
															<td>
																<b>
																	&nbsp;
																	{record.agencyInternalReviewLead
																		? record.agencyInternalReviewLead
																		: 0}
																</b>
															</td>
														</tr>
													</table>
												</div>
											</div>
										)}
										pagination={{
											pageSize: this.state.pageSize,
											position: "bottom",
										}}></Table>
								) : (
									<Table
										{...this.state.tableState}
										id="myTable"
										bordered
										dataSource={this.state.campaignDetails}
										onChange={onChange}
										// Sandeep-task-3228-added code to hide Action column from AQA
										columns={user.role !== "AQA" ? columns : columnsAQA} //RUtuja 2978  changed = to ==
										class="ant-table-thead"
										className={tableCSS}
										scroll={{ x: 1280, y: 4000 }}
										expandedRowRender={(record) => (
											<div>
												<div class="container-fluid">
													<table
														border="1"
														style={{
															tableLayout: "auto",
															borderCollapse: "collapse",
														}}>
														<tr>
															{/* Sandeep-task-3607-Clone campaign-Need to Identify- FE */}
															{record.clonedCampaign === "Yes" ? (
																<th style={{ fontWeight: "400", width: "10%" }}>
																	&nbsp;Cloned From:&nbsp;
																</th>
															) : (
																""
															)}
															{record.clonedCampaign === "Yes" ? (
																<td>
																	<b>&nbsp;{record.clonedFromCampID}</b>
																</td>
															) : (
																<td rowSpan="2">
																	&nbsp; Agency CampaignID:&nbsp;&nbsp;
																	<b>
																		{record.clientCampID
																			? record.clientCampID
																			: ""}
																	</b>
																</td>
															)}
															<th style={{ fontWeight: "400", width: "10%" }}>
																&nbsp;Campaign Name:&nbsp;
															</th>
															<td>
																<b>
																	&nbsp;
																	{record.campaignName
																		? record.campaignName
																		: record.advCampaignName}
																</b>
															</td>
															<th style={{ fontWeight: "400", width: "8%" }}>
																&nbsp;Start Date:&nbsp;
															</th>
															<td style={{ width: "8%" }}>
																<b>
																	&nbsp;
																	{record.startDate
																		? record.startDate
																		: record.advstartDate}
																</b>
															</td>
															<th style={{ fontWeight: "400", width: "8%" }}>
																&nbsp;End Date:&nbsp;
															</th>
															<td style={{ width: "8%" }}>
																<b>
																	&nbsp;
																	{record.endDate
																		? record.endDate
																		: record.advendDate}
																</b>
															</td>

															<th style={{ fontWeight: "400", width: "12%" }}>
																&nbsp;QA Review:&nbsp;
															</th>
															<td>
																<b>
																	&nbsp;
																	{record.onlyQALeads ? record.onlyQALeads : 0}
																</b>
															</td>
															{/* Sandeep-task-3228-added code for AQA */}
															{/* Nilesh-5145- Comment insight section code */}
															{/* {this.state.tab === "CampaignSetup" ||
																user.role === "AQA" ? (
																""
															) : (
																<td rowSpan="2">
																	&nbsp; Insight:&nbsp;&nbsp;
																	<a
																	    disabled   //Nilesh-5145- disabled this link
																		href="#/"
																		id={record.campID}
																		onClick={this.getLIbyAIURL.bind(this)}>
																		<img
																			style={{ display: "inherit" }}
																			alt=""
																			src="1.png"
																			id={record.campID}
																			width="40px"
																			height="20px"></img>
																	</a>
																</td>
															)} */}
														</tr>
														<tr>
															{/* Sandeep-task-3607-Clone campaign-Need to Identify- FE */}
															{record.clonedCampaign === "Yes" ? (
																<th style={{ fontWeight: "400", width: "11%" }}>
																	&nbsp;Agency CampaignID:&nbsp;
																</th>
															) : (
																""
															)}
															{record.clonedCampaign === "Yes" ? (
																<td>
																	<b>
																		&nbsp;
																		{record.clientCampID
																			? record.clientCampID
																			: ""}
																	</b>
																</td>
															) : (
																""
															)}

															<th style={{ fontWeight: "400", width: "10%" }}>
																&nbsp;Campaign Status:&nbsp;
															</th>
															<td>
																<b>
																	&nbsp;
																	{record.campaignStatus === "Active" ? (
																		<span
																			style={{
																				color: "#5fba7d",
																				fontSize: "12px",
																			}}>
																			{" "}
																			{record.campaignStatus}
																		</span>
																	) : record.campaignStatus ===
																		"Live_Incomplete" ? (
																		<span
																			style={{
																				color: "orange",
																				fontSize: "12px",
																			}}>
																			{" "}
																			{record.campaignStatus}
																		</span>
																	) : record.campaignStatus === "Paused" ? (
																		<span
																			style={{
																				color: "#ffaf42",
																				fontSize: "12px",
																			}}>
																			{" "}
																			{record.campaignStatus}
																		</span>
																	) : record.campaignStatus === "Completed" ? (
																		<span
																			style={{
																				color: "skyblue",
																				fontSize: "12px",
																			}}>
																			{" "}
																			{record.campaignStatus}
																		</span>
																	) : record.advStatus === "PendingSetup" ? (
																		<span
																			style={{
																				color: "#abab93",
																				fontSize: "12px",
																			}}>
																			{" "}
																			{record.advStatus}
																		</span>
																	) : record.campaignStatus ===
																		"PausedIncomplete" ? (
																		<span
																			style={{
																				color: "orange",
																				fontSize: "12px",
																			}}>
																			{record.campaignStatus}
																		</span>
																	) : record.campaignStatus ===
																		"Cancel Campaign" ? (
																		<span
																			style={{
																				color: "red",
																				fontSize: "12px",
																			}}>
																			{record.campaignStatus}
																		</span>
																	) : (
																		""
																	)}
																</b>
															</td>

															{user.role === "AC" ? (
																<th style={{ fontWeight: "400", width: "8%" }}>
																	&nbsp;CPL:
																</th>
															) : (
																""
															)}
															{user.role === "AC" ? (
																<td>
																	<b>
																		&nbsp;
																		{record.CPL
																			? record.CPL
																			: record.advCPL}{" "}
																		&nbsp;{record.currency}
																	</b>
																</td>
															) : (
																""
															)}
															{user.role === "AC" ? (
																<th style={{ fontWeight: "400", width: "8%" }}>
																	&nbsp;Budget:
																</th>
															) : (
																""
															)}
															{user.role === "AC" ? (
																<td style={{ width: "8%" }}>
																	<b>
																		&nbsp;
																		{record.budget
																			? record.budget
																			: record.advBudget}
																		&nbsp;{record.currency}
																	</b>
																</td>
															) : (
																""
															)}

															<th style={{ fontWeight: "400", width: "14%" }}>
																&nbsp;Agency Internal Review:&nbsp;
															</th>
															<td>
																<b>
																	&nbsp;
																	{record.agencyInternalReviewLead
																		? record.agencyInternalReviewLead
																		: 0}
																</b>
															</td>
														</tr>
													</table>
												</div>
											</div>
										)}
										pagination={{
											pageSize: this.state.pageSize,
											position: "bottom",
										}}></Table>
								)
							) : (
								<Table
									{...this.state.tableState}
									id="myTable"
									bordered
									dataSource={this.state.campaignDetailsSearch}
									onChange={onChange}
									columns={user.role !== "AQA" ? columns : columnsAQA} //RUtuja 2978  changed = to ==
									// 4568 -(comment the  console warning duplication error ) Prod Issue- AC/PC/PNC - Camp Progress - Page Unresponsive popup comes
									// className="ant-table-thead" className={tableCSS}
									className={`${tableCSS} "ant-table-thead"`}
									loading={this.state.loading}
									scroll={{ x: 300, y: 240 }}
									expandedRowRender={(record) => (
										<div class="container-fluid">
											<table
												border="1"
												style={{
													tableLayout: "auto",
													borderCollapse: "collapse",
												}}>
												<tr>
													{/* Sandeep-task-3607-Clone campaign-Need to Identify- FE */}
													{record.clonedCampaign === "Yes" ? (
														<th style={{ fontWeight: "400", width: "10%" }}>
															&nbsp;Cloned From:&nbsp;
														</th>
													) : (
														""
													)}
													{record.clonedCampaign === "Yes" ? (
														<td>
															<b>&nbsp;{record.clonedFromCampID}</b>
														</td>
													) : (
														<td rowSpan="2">
															&nbsp; Agency CampaignID:&nbsp;&nbsp;
															<b>
																{record.clientCampID ? record.clientCampID : ""}
															</b>
														</td>
													)}
													<th style={{ fontWeight: "400", width: "10%" }}>
														&nbsp;Campaign Name:&nbsp;
													</th>
													<td>
														<b>&nbsp;{record.campaignName}</b>
													</td>
													<th style={{ fontWeight: "400", width: "8%" }}>
														&nbsp;Start Date:&nbsp;
													</th>
													<td>
														<b>&nbsp;{record.startDate}</b>
													</td>
													<th style={{ fontWeight: "400", width: "8%" }}>
														&nbsp;End Date:&nbsp;
													</th>
													<td>
														<b>&nbsp;{record.endDate}</b>
													</td>
													<th style={{ fontWeight: "400", width: "12%" }}>
														&nbsp;QA Review:&nbsp;
													</th>
													<td>
														<b>
															&nbsp;
															{record.onlyQALeads ? record.onlyQALeads : 0}
														</b>
													</td>
                                                     {/* Nilesh-5145- Comment insight section code */}
													{/* {user.role === "AC" ? (
														<td rowSpan="2">
															&nbsp; Insight:&nbsp;&nbsp;
															<a
															    disabled  //Nilesh-5145- disabled this link
																href="#/"
																id={record.campID}
																onClick={this.getLIbyAIURL.bind(this)}>
																<img
																	style={{ display: "inherit" }}
																	alt=""
																	src="1.png"
																	width="40px"
																	height="20px"></img>
															</a>
														</td>
													) : (
														""
													)} */}
												</tr>
												<tr>
													{/* Sandeep-task-3607-Clone campaign-Need to Identify- FE */}
													{record.clonedCampaign === "Yes" ? (
														<th style={{ fontWeight: "400", width: "11%" }}>
															&nbsp;Agency CampaignID:&nbsp;
														</th>
													) : (
														""
													)}
													{record.clonedCampaign === "Yes" ? (
														<td>
															<b>
																&nbsp;
																{record.clientCampID ? record.clientCampID : ""}
															</b>
														</td>
													) : (
														""
													)}
													<th style={{ fontWeight: "400", width: "10%" }}>
														&nbsp;Campaign Status:&nbsp;
													</th>
													<td>
														<b>
															&nbsp;
															{record.campaignStatus === "Active" ? (
																<span
																	style={{
																		color: "#5fba7d",
																		fontSize: "12px",
																	}}>
																	{" "}
																	{record.campaignStatus}
																</span>
															) : record.campaignStatus ===
																"Live_Incomplete" ? (
																<span
																	style={{ color: "orange", fontSize: "12px" }}>
																	{" "}
																	{record.campaignStatus}
																</span>
															) : record.campaignStatus === "Paused" ? (
																<span
																	style={{
																		color: "#ffaf42",
																		fontSize: "12px",
																	}}>
																	{" "}
																	{record.campaignStatus}
																</span>
															) : record.campaignStatus === "Completed" ? (
																<span
																	style={{
																		color: "skyblue",
																		fontSize: "12px",
																	}}>
																	{" "}
																	{record.campaignStatus}
																</span>
															) : record.advStatus === "PendingSetup" ? (
																<span
																	style={{
																		color: "#abab93",
																		fontSize: "12px",
																	}}>
																	{" "}
																	{record.advStatus}
																</span>
															) : record.campaignStatus ===
																"PausedIncomplete" ? (
																<span
																	style={{ color: "orange", fontSize: "12px" }}>
																	{record.campaignStatus}
																</span>
															) : record.campaignStatus ===
																"Cancel Campaign" ? (
																<span
																	style={{ color: "red", fontSize: "12px" }}>
																	{record.campaignStatus}
																</span>
															) : (
																""
															)}
														</b>
													</td>
													{user.role === "AC" ? (
														<th style={{ fontWeight: "400", width: "8%" }}>
															&nbsp;CPL:&nbsp;
														</th>
													) : (
														""
													)}
													{user.role === "AC" ? (
														<td>
															<b>
																&nbsp;{record.CPL} &nbsp;{record.currency}
															</b>
														</td>
													) : (
														""
													)}
													{user.role === "AC" ? (
														<th style={{ fontWeight: "400", width: "8%" }}>
															&nbsp;Budget:&nbsp;
														</th>
													) : (
														""
													)}
													{user.role === "AC" ? (
														<td>
															<b>
																&nbsp;{record.budget}&nbsp;{record.currency}
															</b>
														</td>
													) : (
														""
													)}

													<th style={{ fontWeight: "400", width: "12%" }}>
														&nbsp;Agency Internal Review:&nbsp;
													</th>
													<td>
														<b>
															&nbsp;
															{record.agencyInternalReviewLead
																? record.agencyInternalReviewLead
																: 0}
														</b>
													</td>
												</tr>
											</table>
										</div>
									)}
									pagination={{
										pageSize: this.state.pageSize,
										position: "bottom",
									}}></Table>
							)}
						</div>
					</div>
					<br />
					<div class="row">
						<div
							class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
							style={{ marginTop: "-75px", marginLeft: "5px" }}>
							&nbsp;Records per page:&nbsp;
							<select
								defaultValue={this.state.pageSize}
								onChange={this.handleChangePageSize}
								id="pacing1"
								class="input-small"
								className="form-control"
								name="pacing"
								style={{ width: "71px", height: "30px", display: "initial" }}>
								<option value="100" selected>
									100
								</option>
								<option value="200">200</option>
								<option value="300">300</option>
							</select>
						</div>
					</div>
					{/* Shivani- task 3611 - for custome div */}
					{/* Shivani-3702-added links to label */}
					{this.state.actionPopupVisible && (
						<div
							className="popup-campaign-progressAgency"
							style={{
								right: `${window.innerWidth - this.state.x}px`,
								top: `${this.state.y}px`,
								padding: 7,
								width: "215px",
							}}>
							{this.state.actionPopupSelectedRecord.campaignStatus ===
								"Cancel Campaign" ? (
								<span>
									<span style={{ float: "left", paddingBottom: "10px" }}>
										<Tooltip placement="top" title="Allocate">
											<img
												src="Allocate_Disable.jpg "
												alt=""
												width="15"
												height="15"
												style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
										</Tooltip>
										{/* kiran- 4310- adjusted margin to display aCtion highlighter properly */}
										<div>
											<span style={{ marginLeft: "29px", }}>
												Allocate Campaign
											</span>
										</div>
									</span>
									<Divider type="vertical" style={{ float: "left" }} />
									<span style={{ float: "left", paddingBottom: "10px" }}>
										<Tooltip placement="top" title="Edit">
											<img
												src="Edit_Disable.png"
												alt=""
												width="15"
												height="15"
												style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
										</Tooltip>
										<div>
											<span style={{ marginLeft: "29px", }}>
												Edit Campaign
											</span>
										</div>
									</span>
									<Divider type="vertical" style={{ float: "left" }} />
									<span style={{ float: "left", paddingBottom: "10px" }}>
										<Tooltip placement="top" title="Lead Increment">
											<img
												src="Increment_Disable.jpg"
												alt=""
												width="15"
												height="15"
												style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
										</Tooltip>
										<div>
											<span style={{ marginLeft: "29px", }}>
												Lead Increment
											</span>
										</div>
									</span>
									<Divider type="vertical" style={{ float: "left" }} />
									<span style={{ float: "left", paddingBottom: "10px" }}>
										<Tooltip placement="top" title="Pause">
											<img
												src="Paused_Disable.jpg"
												alt=""
												id={this.state.actionPopupSelectedRecord.campID}
												width="15"
												height="15"
												style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
										</Tooltip>
										<div>
											<span style={{ marginLeft: "29px", }}>
												Pause Campaign
											</span>
										</div>
									</span>
									<Divider type="vertical" style={{ float: "left" }} />
									<span style={{ float: "left", paddingBottom: "10px" }}>
										<Tooltip
											placement="topRight"
											title="Campaign can not be marked completed as leads are pending"
											overlayStyle={{
												color: "white",
												backgroundColor: "orange",
											}}>
											<img
												src="Complete_Disable.jpg"
												alt=""
												id={this.state.actionPopupSelectedRecord.campID}
												width="15"
												height="15"
												style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
										</Tooltip>
										<div>
											<span style={{ marginLeft: "29px", }}>
												Completed Campaign
											</span>
										</div>
									</span>

									<Divider type="vertical" style={{ float: "left" }} />
									<span style={{ float: "left", paddingBottom: "10px" }}>
										<Tooltip placement="top" title="Clone">
											<img
												src="clone_disable.png "
												alt=""
												width="15"
												height="15"
												style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
										</Tooltip>
										<div>
											<span style={{ marginLeft: "29px", }}>
												Clone Campaign
											</span>
										</div>
									</span>
									<Divider type="vertical" style={{ float: "left" }} />
									<span style={{ float: "left", paddingBottom: "10px" }}>
										<Tooltip placement="top" title="Cancel">
											<img
												src="Reject_Disabled.png "
												alt=""
												width="15"
												height="15"
												style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
										</Tooltip>
										<div>
											<span style={{ marginLeft: "29px", }}>
												Cancel Campaign
											</span>
										</div>
									</span>
								</span>
							) : this.state.actionPopupSelectedRecord.campaignStatus ===
								"Active" ? (
								<span>
									{user.role === "AC" ? (
										this.state.linkActive5 === "active" ? (
											<span style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip placement="top" title="Allocate">
													<img
														src="Allocate_Disable.jpg "
														alt=""
														width="15"
														height="15"
														style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "29px", }}>
														Allocate Campaign
													</span>
												</div>
											</span>
										) : (
											<a
												href="#/" //Rutuja 2978 - changed # to #/
												id={this.state.actionPopupSelectedRecord.campID}
												style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip placement="top" title="Allocate">
													<img
														src="allocate.jpg"
														alt=""
														id={this.state.actionPopupSelectedRecord.campID}
														onClick={this.allocatePublisher}
														rfpProposalID={
															this.state.actionPopupSelectedRecord.rfpProposalID
														}
														parentCampID={
															this.state.actionPopupSelectedRecord.parentCampID
														}
														reallocationID={
															this.state.actionPopupSelectedRecord
																.reallocationID
														}
														width="15"
														height="15"
														style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "29px", }}
														id={this.state.actionPopupSelectedRecord.campID}
														onClick={this.allocatePublisher}
														rfpProposalID={
															this.state.actionPopupSelectedRecord.rfpProposalID
														}
														parentCampID={
															this.state.actionPopupSelectedRecord.parentCampID
														}
														reallocationID={
															this.state.actionPopupSelectedRecord
																.reallocationID
														}>
														Allocate Campaign
													</span>
												</div>
											</a>
										)
									) : (
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip placement="top" title="Allocate">
												<img
													src="Allocate_Disable.jpg "
													alt=""
													width="15"
													height="15"
													style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "29px", }}>
													Allocate Campaign
												</span>
											</div>
										</span>
									)}

									<Divider type="vertical" style={{ float: "left" }} />
									{user.role === "AC" ? (
										this.state.linkActive5 === "active" ? (
											<span style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip placement="top" title="Edit">
													<img
														src="Edit_Disable.png"
														alt=""
														width="15"
														height="15"
														style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "29px", }}>
														Edit Campaign
													</span>
												</div>
											</span>
										) : (
											<a
												href="#/"
												id={this.state.actionPopupSelectedRecord.campID}
												style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip placement="top" title="Edit">
													<img
														src="Edit.png"
														alt=""
														id={this.state.actionPopupSelectedRecord.campID}
														parentCampId={
															this.state.actionPopupSelectedRecord.parentCampID
																? this.state.actionPopupSelectedRecord
																	.parentCampID
																: this.state.actionPopupSelectedRecord.campID
														}
														reallocationId={
															this.state.actionPopupSelectedRecord
																.reallocationID
														}
														onClick={this.handleEditCampaign}
														width="15"
														height="15"
														style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "29px", }}
														id={this.state.actionPopupSelectedRecord.campID}
														parentCampId={
															this.state.actionPopupSelectedRecord.parentCampID
																? this.state.actionPopupSelectedRecord
																	.parentCampID
																: this.state.actionPopupSelectedRecord.campID
														}
														reallocationId={
															this.state.actionPopupSelectedRecord
																.reallocationID
														}
														onClick={this.handleEditCampaign}>
														Edit Campaign
													</span>
												</div>
											</a>
										)
									) : (
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip placement="top" title="Edit">
												<img
													src="Edit_Disable.png"
													alt=""
													width="15"
													height="15"
													style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "29px", }}>
													Edit Campaign
												</span>
											</div>
										</span>
									)}
									<Divider type="vertical" style={{ float: "left" }} />
									{user.role === "AC" || user.role === "ANC" ? (
										this.state.linkActive5 === "active" ? (
											<span style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip placement="top" title="Lead Increment">
													<img
														src="Increment_Disable.jpg"
														alt=""
														width="15"
														height="15"
														style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "29px", }}>
														Lead Increment
													</span>
												</div>
											</span>
										) : (
											<a
												href="#/"
												id={this.state.actionPopupSelectedRecord.campID}
												data-toggle="modal"
												data-target="#incrementalModal1"
												style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip placement="top" title="Lead Increment">
													<img
														src="incremental.jpg"
														alt=""
														id={this.state.actionPopupSelectedRecord.campID}
														campID={this.state.actionPopupSelectedRecord.campID}
														pID={this.state.actionPopupSelectedRecord.pID}
														campaignName={
															this.state.actionPopupSelectedRecord.campaignName
														}
														parentCampID={
															this.state.actionPopupSelectedRecord.parentCampID
														}
														reallocationID={
															this.state.actionPopupSelectedRecord
																.reallocationID
														}
														onClick={this.handleAllocatedLeads}
														width="15"
														height="15"
														style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "29px", }}
														data-toggle="modal"
														data-target="#incrementalModal1"
														id={this.state.actionPopupSelectedRecord.campID}
														campID={this.state.actionPopupSelectedRecord.campID}
														pID={this.state.actionPopupSelectedRecord.pID}
														campaignName={
															this.state.actionPopupSelectedRecord.campaignName
														}
														parentCampID={
															this.state.actionPopupSelectedRecord.parentCampID
														}
														reallocationID={
															this.state.actionPopupSelectedRecord
																.reallocationID
														}
														onClick={this.handleAllocatedLeads}>
														Lead Increment
													</span>
												</div>
											</a>
										)
									) : (
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip placement="top" title="Lead Increment">
												<img
													src="Increment_Disable.jpg"
													alt=""
													width="15"
													height="15"
													style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "29px", }}>
													Lead Increment
												</span>
											</div>
										</span>
									)}
									<Divider type="vertical" style={{ float: "left" }} />
									{user.role === "AC" || user.role === "ANC" ? (
										this.state.actionPopupSelectedRecord.flagForDecrement ===
											"undefined" ||
											this.state.actionPopupSelectedRecord.flagForDecrement ===
											undefined ||
											this.state.actionPopupSelectedRecord.flagForDecrement ===
											"null" ||
											this.state.actionPopupSelectedRecord.flagForDecrement ===
											null ||
											this.state.actionPopupSelectedRecord.flagForDecrement ===
											"" ? (
											this.state.actionPopupSelectedRecord.allocatedLead ===
												0 ? (
												<span style={{ float: "left", paddingBottom: "10px" }}>
													<Tooltip
														placement="topRight"
														title="Leads can not be decremented as allocation is not done."
														overlayStyle={{
															color: "white",
															backgroundColor: "orange",
														}}>
														<img
															src="Decrement_Disable.png"
															alt=""
															width="15"
															height="15"
															style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
													</Tooltip>
													<div>
														<span
															style={{
																marginLeft: "29px",

															}}>
															Lead Decrement
														</span>
													</div>
												</span>
											) : (
												<span style={{ float: "left", paddingBottom: "10px" }}>
													<Tooltip
														placement="topRight"
														title="Leads can not be decremented as allocation accept process not done."
														overlayStyle={{
															color: "white",
															backgroundColor: "orange",
														}}>
														<img
															src="Decrement_Disable.png"
															alt=""
															width="15"
															height="15"
															style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
													</Tooltip>
													<div>
														<span
															style={{
																marginLeft: "29px",

															}}>
															Lead Decrement
														</span>
													</div>
												</span>
											)
										) : (
											<a
												href="#/"
												id={this.state.actionPopupSelectedRecord.campID}
												data-toggle="modal"
												data-target="#decrementalModal1"
												style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip placement="top" title="Lead Decrement">
													<img
														src="Decrement.png"
														alt=""
														id={this.state.actionPopupSelectedRecord.campID}
														campID={this.state.actionPopupSelectedRecord.campID}
														pID={this.state.actionPopupSelectedRecord.pID}
														campaignName={
															this.state.actionPopupSelectedRecord.campaignName
														}
														parentCampID={
															this.state.actionPopupSelectedRecord.parentCampID
														}
														reallocationID={
															this.state.actionPopupSelectedRecord
																.reallocationID
														}
														onClick={this.getDecrementalDetails}
														width="15"
														height="15"
														style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "29px", }}
														data-toggle="modal"
														data-target="#decrementalModal1"
														id={this.state.actionPopupSelectedRecord.campID}
														campID={this.state.actionPopupSelectedRecord.campID}
														pID={this.state.actionPopupSelectedRecord.pID}
														campaignName={
															this.state.actionPopupSelectedRecord.campaignName
														}
														parentCampID={
															this.state.actionPopupSelectedRecord.parentCampID
														}
														reallocationID={
															this.state.actionPopupSelectedRecord
																.reallocationID
														}
														onClick={this.getDecrementalDetails}>
														Lead Decrement
													</span>
												</div>
											</a>
										)
									) : (
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip
												placement="topRight"
												title="Lead Decrement"
												overlayStyle={{
													color: "white",
													backgroundColor: "orange",
												}}>
												<img
													src="Decrement_Disable.png"
													alt=""
													width="15"
													height="15"
													style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "29px", }}>
													Lead Decrement
												</span>
											</div>
										</span>
									)}

									<Divider type="vertical" style={{ float: "left" }} />
									{user.role === "AC" ? (
										this.state.linkActive5 === "active" ? (
											<span style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip placement="top" title="Pause">
													<img
														src="Paused_Disable.jpg"
														alt=""
														id={this.state.actionPopupSelectedRecord.campID}
														width="15"
														height="15"
														style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "29px", }}>
														Pause Campaign
													</span>
												</div>
											</span>
										) : (
											<a
												href="#/"
												id={this.state.actionPopupSelectedRecord.campID}
												onClick={this.pauseCampaign}
												style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip placement="top" title="Pause">
													<img
														src="paused.jpg"
														alt=""
														id={this.state.actionPopupSelectedRecord.campID}
														width="15"
														height="15"
														style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "29px", }}
														id={this.state.actionPopupSelectedRecord.campID}
														onClick={this.pauseCampaign}>
														Pause Campaign
													</span>
												</div>
											</a>
										)
									) : (
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip placement="top" title="Pause">
												<img
													src="Paused_Disable.jpg"
													alt=""
													id={this.state.actionPopupSelectedRecord.campID}
													width="15"
													height="15"
													style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "29px", }}>
													Pause Campaign
												</span>
											</div>
										</span>
									)}

									<Divider type="vertical" style={{ float: "left" }} />

									{user.role === "AC" ? (
										this.state.actionPopupSelectedRecord
											.requiredLeadPerAsset === "Yes" ? (
											this.state.actionPopupSelectedRecord.completeStatus ===
												true &&
												parseInt(
													this.state.actionPopupSelectedRecord.leadAllocation
												) ===
												parseInt(
													this.state.actionPopupSelectedRecord.allocatedLead
												) ? (
												<a
													href="#/"
													id={this.state.actionPopupSelectedRecord.campID}
													onClick={this.handleChangeCompleteCampaign}
													style={{ float: "left", paddingBottom: "10px" }}>
													<Tooltip placement="top" title="Complete">
														<img
															src="completed.jpg"
															alt=""
															id={this.state.actionPopupSelectedRecord.campID}
															width="15"
															height="15"
															style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
													</Tooltip>
													<div>
														<span
															style={{ marginLeft: "29px", }}
															id={this.state.actionPopupSelectedRecord.campID}
															onClick={this.handleChangeCompleteCampaign}>
															Completed Campaign
														</span>
													</div>
												</a>
											) : (
												<span style={{ float: "left", paddingBottom: "10px" }}>
													<Tooltip
														placement="topRight"
														title="Campaign can not be marked completed as leads are pending"
														overlayStyle={{
															color: "white",
															backgroundColor: "orange",
														}}>
														<img
															src="Complete_Disable.jpg"
															alt=""
															id={this.state.actionPopupSelectedRecord.campID}
															width="15"
															height="15"
															style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
													</Tooltip>
													<div>
														<span
															style={{
																marginLeft: "29px",
															}}>
															Completed Campaign
														</span>
													</div>
												</span>
											)
										) : parseInt(
											this.state.actionPopupSelectedRecord.leadAllocation
										) ===
											parseInt(
												this.state.actionPopupSelectedRecord.allocatedLead
											) &&
											parseInt(
												this.state.actionPopupSelectedRecord.acceptedLead
											) >=
											parseInt(
												this.state.actionPopupSelectedRecord.leadAllocation
											) ? (
											<a
												href="#/"
												id={this.state.actionPopupSelectedRecord.campID}
												onClick={this.handleChangeCompleteCampaign}
												style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip placement="top" title="Complete">
													<img
														src="completed.jpg"
														alt=""
														id={this.state.actionPopupSelectedRecord.campID}
														width="15"
														height="15"
														style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "29px", }}
														id={this.state.actionPopupSelectedRecord.campID}
														onClick={this.handleChangeCompleteCampaign}>
														Completed Campaign
													</span>
												</div>
											</a>
										) : (
											<span style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip
													placement="topRight"
													title="Campaign can not be marked completed as leads are pending"
													overlayStyle={{
														color: "white",
														backgroundColor: "orange",
													}}>
													<img
														src="Complete_Disable.jpg"
														alt=""
														id={this.state.actionPopupSelectedRecord.campID}
														width="15"
														height="15"
														style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "29px", }}>
														Completed Campaign
													</span>
												</div>
											</span>
										)
									) : (
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip placement="top" title="Complete">
												<img
													src="Complete_Disable.jpg"
													alt=""
													id={this.state.actionPopupSelectedRecord.campID}
													width="15"
													height="15"
													style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "29px", }}>
													Completed Campaign
												</span>
											</div>
										</span>
									)}

									<Divider type="vertical" style={{ float: "left" }} />
									{user.role === "AC" ? (
										this.state.linkActive5 === "active" ? (
											<span style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip placement="top" title="Clone">
													<img
														src="clone_disable.png "
														alt=""
														width="15"
														height="15"
														style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "29px", }}>
														Clone Campaign
													</span>
												</div>
											</span>
										) : (
											<a
												href="#/"
												id={this.state.actionPopupSelectedRecord.campID}
												style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip placement="top" title="Clone">
													<img
														src="clone.png"
														alt=""
														id={this.state.actionPopupSelectedRecord.campID}
														onClick={this.cloneCampaign}
														parentCampID={
															this.state.actionPopupSelectedRecord.parentCampID
														}
														reallocationID={
															this.state.actionPopupSelectedRecord
																.reallocationID
														}
														width="15"
														height="15"
														style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "29px", }}
														id={this.state.actionPopupSelectedRecord.campID}
														onClick={this.cloneCampaign}
														parentCampID={
															this.state.actionPopupSelectedRecord.parentCampID
														}
														reallocationID={
															this.state.actionPopupSelectedRecord
																.reallocationID
														}>
														Clone Campaign
													</span>
												</div>
											</a>
										)
									) : (
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip placement="top" title="Clone">
												<img
													src="clone_disable.png "
													alt=""
													width="15"
													height="15"
													style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "29px", }}>
													Clone Campaign
												</span>
											</div>
										</span>
									)}

									<Divider type="vertical" style={{ float: "left" }} />
									{user.role === "AC" ? (
										<a
											href="#/"
											id={this.state.actionPopupSelectedRecord.campID}
											style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip placement="top" title="Cancel">
												<img
													src="Reject.png"
													alt=""
													id={this.state.actionPopupSelectedRecord.campID}
													onClick={this.cancelCampaign}
													parentCampID={
														this.state.actionPopupSelectedRecord.parentCampID
													}
													reallocationID={
														this.state.actionPopupSelectedRecord.reallocationID
													}
													width="15"
													height="15"
													style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "29px", }}
													id={this.state.actionPopupSelectedRecord.campID}
													onClick={this.cancelCampaign}
													parentCampID={
														this.state.actionPopupSelectedRecord.parentCampID
													}
													reallocationID={
														this.state.actionPopupSelectedRecord.reallocationID
													}>
													Cancel Campaign
												</span>
											</div>
										</a>
									) : (
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip placement="top" title="Cancel">
												<img
													src="Reject_Disabled.png "
													alt=""
													width="15"
													height="15"
													style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "29px", }}>
													Cancel Campaign
												</span>
											</div>
										</span>
									)}
									{/* Sandeep-task-3752-Agency side--Campaign progress-Action--Add one button-Send lead to marketo-FE */}
									{/* Chaitanya-task-4500-Marketo--Disable old workflow from Camp progress */}
									{/* <Divider type="vertical" style={{ float: "left" }} />
									{user.role === "AC" ? (

										this.state.marketoClient == null ||
											this.state.marketoClient == "null" ||
											this.state.marketoClient == "" ||
											this.state.marketoClient == undefined ? (

											<span style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip placement="top" title="Send Leads To Marketo">
													<img
														src="marketo_disabled.png "
														alt=""
														width="15"
														height="15"
														style={{ marginRight: "160px", marginBottom: "-17px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "26px", }}>
														Send Leads To Marketo
													</span>
												</div>
											</span>
										) : (
											<a
												href="#/"
												id={this.state.actionPopupSelectedRecord.campID}
												style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip placement="top" title="Send Leads To Marketo">
													<img
														src="marketo_enable.png"
														alt=""
														id={this.state.actionPopupSelectedRecord.campID}
														onClick={this.leadsTomarketoHandleChange}
														parentCampID={
															this.state.actionPopupSelectedRecord.parentCampID
														}
														reallocationID={
															this.state.actionPopupSelectedRecord
																.reallocationID
														}
														width="15"
														height="15"
														style={{ marginRight: "160px", marginBottom: "-17px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "26px", }}
														id={this.state.actionPopupSelectedRecord.campID}
														onClick={this.leadsTomarketoHandleChange}
														parentCampID={
															this.state.actionPopupSelectedRecord.parentCampID
														}
														reallocationID={
															this.state.actionPopupSelectedRecord
																.reallocationID
														}>
														Send Leads To Marketo
													</span>
												</div>
											</a>
										)
									) : (
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip placement="top" title="Send Leads To Marketo">
												<img
													src="marketo_disabled.png "
													alt=""
													width="15"
													height="15"
													style={{ marginRight: "160px", marginBottom: "-17px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "26px", }}>
													Send Leads To Marketo
												</span>
											</div>
										</span>
									)} */}
									{/* rutuja 4276-Agency side--Campaign progress-Action--Add one button-Send lead to Hubspot-FE */}
									{/* Chaitanya-task-4500-Hubspot--Disable old workflow from Camp progress */}
									{/* <Divider type="vertical" style={{ float: "left" }} />
									{user.role === "AC" ? (
										this.state.hubspotClient == null ||
											this.state.hubspotClient == "null" ||
											this.state.hubspotClient == "" ||
											this.state.hubspotClient == undefined ||
											this.state.actionPopupSelectedRecord.acceptedLead == 0 ? (
											<span style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip placement="top" title="Send Leads To Hubspot">
													<img
														src="marketo_disabled.png "
														alt=""
														width="15"
														height="15"
														style={{ marginRight: "160px", marginBottom: "-17px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "26px", }}>
														Send Leads To Hubspot
													</span>
												</div>
											</span>
										) : (
											<a
												href="#/"
												id={this.state.actionPopupSelectedRecord.campID}
												style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip placement="top" title="Send Leads To Hubspot">
													<img
														src="marketo_enable.png"
														alt=""
														id={this.state.actionPopupSelectedRecord.campID}
														onClick={this.leadsTohubspotHandleChange}
														parentCampID={
															this.state.actionPopupSelectedRecord.parentCampID
														}
														reallocationID={
															this.state.actionPopupSelectedRecord
																.reallocationID
														}
														width="15"
														height="15"
														style={{ marginRight: "160px", marginBottom: "-17px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "26px", }}
														id={this.state.actionPopupSelectedRecord.campID}
														onClick={this.leadsTohubspotHandleChange}
														parentCampID={
															this.state.actionPopupSelectedRecord.parentCampID
														}
														reallocationID={
															this.state.actionPopupSelectedRecord
																.reallocationID
														}>
														Send Leads To Hubspot
													</span>
												</div>
											</a>
										)
									) : (
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip placement="top" title="Send Leads To Hubspot">
												<img
													src="marketo_enable.png"
													alt=""
													width="15"
													height="15"
													style={{ marginRight: "160px", marginBottom: "-17px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "26px", }}>
													Send Leads To Hubspot
												</span>
											</div>
										</span>
									)} */}
									{/* Priyanka-4277-Salesforce - Campaign wise Lead deliver */}
									
									{/* 4543-Nilesh Disable Send leads to Saleforce button*/}
									{/* <Divider type="vertical" style={{ float: "left" }} /> 
									{user.role === "AC" ? (
										// 
										this.state.sfClient == null ||
											this.state.sfClient == "null" ||
											this.state.sfClient == "" ||
											this.state.sfClient == undefined ||
											this.state.actionPopupSelectedRecord.acceptedLead == 0 ? (
											<span style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip placement="top" title="Send Leads To SalesForce">
													<img
														src="marketo_disabled.png "
														alt=""
														width="15"
														height="15"
														style={{ marginRight: "175px", marginBottom: "-17px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "26px", }}>
														Send Leads To Salesforce
													</span>
												</div>
											</span>
										) : (
											<a
												href="#/"
												id={this.state.actionPopupSelectedRecord.campID}
												style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip placement="top" title="Send Leads To Salesforce">
													<img
														src="marketo_enable.png"
														alt=""
														id={this.state.actionPopupSelectedRecord.campID}
														onClick={this.leadsTosalesforceHandleChange}
														parentCampID={
															this.state.actionPopupSelectedRecord.parentCampID
														}
														reallocationID={
															this.state.actionPopupSelectedRecord
																.reallocationID
														}
														width="15"
														height="15"
														style={{ marginRight: "175px", marginBottom: "-17px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "26px", }}
														id={this.state.actionPopupSelectedRecord.campID}
														onClick={this.leadsTosalesforceHandleChange}
														parentCampID={
															this.state.actionPopupSelectedRecord.parentCampID
														}
														reallocationID={
															this.state.actionPopupSelectedRecord
																.reallocationID
														}>
														Send Leads To Salesforce
													</span>
												</div>
											</a>
										)
									) : (
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip placement="top" title="Send Leads To Salesforce">
												<img
													src="marketo_disabled.png "
													alt=""
													width="15"
													height="15"
													style={{ marginRight: "175px", marginBottom: "-17px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "26px", }}>
													Send Leads To Salesforce
												</span>
											</div>
										</span>
									)} */}


									{/* 4412--added below code to check client details and enable button */}
									<Divider type="vertical" style={{ float: "left" }} />
									{user.role === "AC" ? (
										// 
										this.state.client !== null &&
											this.state.client !== "null" &&
											this.state.client !== undefined &&
											this.state.client !== "undefined" &&
											this.state.actionPopupSelectedRecord.acceptedLead !== null &&
											this.state.actionPopupSelectedRecord.acceptedLead !== "null" &&
											this.state.actionPopupSelectedRecord.acceptedLead !== "" &&
											this.state.actionPopupSelectedRecord.acceptedLead !== undefined &&
											this.state.actionPopupSelectedRecord.acceptedLead !== 0 ? (
											<a
												href="#/"
												id={this.state.actionPopupSelectedRecord.campID}
												style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip placement="top" title="Send Leads To CRM">
													<img
														src="marketo_enable.png"
														alt=""
														id={this.state.actionPopupSelectedRecord.campID}
														onClick={this.deliverLeadsHandleChange}
														parentCampID={
															this.state.actionPopupSelectedRecord.parentCampID
														}
														reallocationID={
															this.state.actionPopupSelectedRecord
																.reallocationID
														}
														width="15"
														height="15"
														style={{ marginRight: "175px", marginBottom: "-17px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "26px", }}
														id={this.state.actionPopupSelectedRecord.campID}
														onClick={this.deliverLeadsHandleChange}
														parentCampID={
															this.state.actionPopupSelectedRecord.parentCampID
														}
														reallocationID={
															this.state.actionPopupSelectedRecord
																.reallocationID
														}>
														Send Leads To CRM
													</span>
												</div>
											</a>

										) : (


											<span style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip placement="top" title="Send Leads To CRM">
													<img
														src="marketo_disabled.png "
														alt=""
														width="15"
														height="15"
														style={{ marginRight: "175px", marginBottom: "-17px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "26px", }}>
														Send Leads To CRM
														{/* 4412-nilesh */}
													</span>
												</div>
											</span>
										)
									) : (
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip placement="top" title="Send Leads To CRM">
												<img
													src="marketo_disabled.png "
													alt=""
													width="15"
													height="15"
													style={{ marginRight: "175px", marginBottom: "-17px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "26px", }}>
													Send Leads To CRM
												</span>
											</div>
										</span>
									)}

                                      {/* Nilesh-5351- Add a new Map Column Sequence option in menu */}
									  <span style={{ float: "left", paddingBottom: "10px" }}
									>           
												<Tooltip placement="top" 
												title="Map Column Sequence" 
												>
													<img
														src="Sequencing.png"
														alt=""
														width="15"
														height="15"
														style={{ marginRight: "185px", marginBottom: "-17px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "25px", }}
														 id={this.state.actionPopupSelectedRecord.campID}
														 onClick={(e) => {
															e.preventDefault();
																var campID = e.target.id;
																var campaignStatus = this.state.actionPopupSelectedRecord.campaignStatus
																
																this.props.history.push({
																	pathname: "/columnSequencing",
																	state: { campID, campaignStatus },
																  });
																}}
														>
														Map Column Sequence
													</span>
												</div>
											</span>

								</span>
							) : this.state.actionPopupSelectedRecord.campaignStatus ===
								"Paused" ? (
								<span>
									{user.role === "AC" ||
										user.role === "ANC" ||
										user.role === "AQA" ? (
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip placement="top" title="Allocate">
												<img
													src="Allocate_Disable.jpg"
													alt=""
													id={this.state.actionPopupSelectedRecord.campID}
													width="15"
													height="15"
													style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "29px" }}>
													Allocate Campaign
												</span>
											</div>
										</span>
									) : (
										""
									)}

									<Divider type="vertical" style={{ float: "left" }} />
									{user.role === "AC" ||
										user.role === "ANC" ||
										user.role === "AQA" ? (
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip placement="top" title="Edit">
												<img
													src="Edit_Disable.png"
													alt=""
													id={this.state.actionPopupSelectedRecord.campID}
													width="15"
													height="15"
													style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "29px", }}>
													Edit Campaign
												</span>
											</div>
										</span>
									) : (
										""
									)}

									<Divider type="vertical" style={{ float: "left" }} />
									{user.role === "AC" ||
										user.role === "ANC" ||
										user.role === "AQA" ? (
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip placement="top" title="Lead Increment">
												<img
													src="Increment_Disable.jpg"
													alt=""
													id={this.state.actionPopupSelectedRecord.campID}
													width="15"
													height="15"
													style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "29px", }}>
													Lead Increment
												</span>
											</div>
										</span>
									) : (
										""
									)}

									<Divider type="vertical" style={{ float: "left" }} />
									{user.role === "AC" ? (
										<a
											href="#/"
											id={this.state.actionPopupSelectedRecord.campID}
											onClick={this.resumeCampaign}
											style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip placement="top" title="Resume">
												<img
													src="Continue.png"
													alt=""
													id={this.state.actionPopupSelectedRecord.campID}
													width="15"
													height="15"
													style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "29px", }}
													id={this.state.actionPopupSelectedRecord.campID}
													onClick={this.resumeCampaign}>
													Live Campaign
												</span>
											</div>
										</a>
									) : (
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip placement="top" title="Resume">
												<img
													src="Continue_Disable.png"
													alt=""
													id={this.state.actionPopupSelectedRecord.campID}
													width="15"
													height="15"
													style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "29px", }}>
													Live Campaign
												</span>
											</div>
										</span>
									)}

									<Divider type="vertical" style={{ float: "left" }} />
									{user.role === "AC" ||
										user.role === "ANC" ||
										user.role === "AQA" ? (
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip placement="top" title="Complete">
												<img
													src="Complete_Disable.jpg"
													alt=""
													id={this.state.actionPopupSelectedRecord.campID}
													width="15"
													height="15"
													style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "29px", }}>
													Completed Campaign
												</span>
											</div>
										</span>
									) : (
										""
									)}
								</span>
							) : this.state.actionPopupSelectedRecord.campaignStatus ===
								"Completed" ? (
								<span>
									{user.role === "AC" ? (
										<span>
											<span style={{ float: "left" }}>
												<a
													href="#/"
													id={this.state.actionPopupSelectedRecord.campID}
													data-toggle="modal"
													data-target="#editEndDate1"
													style={{ float: "left", paddingBottom: "10px" }}>
													<Tooltip placement="top" title="LIVE">
														<img
															src="Continue.png"
															alt=""
															id={this.state.actionPopupSelectedRecord.campID}
															advCampID={
																this.state.actionPopupSelectedRecord
																	.parentCampID
																	? this.state.actionPopupSelectedRecord
																		.parentCampID
																	: this.state.actionPopupSelectedRecord.campID
																		? this.state.actionPopupSelectedRecord.campID
																		: this.state.text
															}
															onClick={this.handleEndDate}
															width="15"
															height="15"
															style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
													</Tooltip>
													<div>
														<span
															style={{ marginLeft: "29px", }}
															data-toggle="modal"
															data-target="#editEndDate1"
															id={this.state.actionPopupSelectedRecord.campID}
															advCampID={
																this.state.actionPopupSelectedRecord
																	.parentCampID
																	? this.state.actionPopupSelectedRecord
																		.parentCampID
																	: this.state.actionPopupSelectedRecord.campID
																		? this.state.actionPopupSelectedRecord.campID
																		: this.state.text
															}
															onClick={this.handleEndDate}>
															Live Campaign
														</span>
													</div>
												</a>
											</span>
										</span>
									) : (
										""
									)}

									<Divider type="vertical" style={{ float: "left" }} />
									{user.role === "AC" ? (
										<a
											href="#/"
											id={this.state.actionPopupSelectedRecord.campID}
											data-toggle="modal"
											// data-target="#confirmClone" //Nilesh-4900 Comment this line
											onClick={this.getDetails}
											parentCampId={
												this.state.actionPopupSelectedRecord.parentCampID
											}
											reallocationId={
												this.state.actionPopupSelectedRecord.reallocationID
											}
											style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip placement="top" title="Clone">
												<img
													src="clone.png"
													alt=""
													id={this.state.actionPopupSelectedRecord.campID}
													parentCampId={
														this.state.actionPopupSelectedRecord.parentCampID
													}
													reallocationId={
														this.state.actionPopupSelectedRecord.reallocationID
													}
													width="15"
													height="15"
													style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "29px", }}
													id={this.state.actionPopupSelectedRecord.campID}
													parentCampId={
														this.state.actionPopupSelectedRecord.parentCampID
													}
													reallocationId={
														this.state.actionPopupSelectedRecord.reallocationID
													}>
													Clone Campaign
												</span>
											</div>
										</a>
									) : (
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip placement="top" title="Clone">
												<img
													src="clone_disable.png "
													width="15"
													alt=""
													height="15"
													style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "29px", }}>
													Clone Campaign
												</span>
											</div>
										</span>
									)}
									<Divider type="vertical" style={{ float: "left" }} />
									{user.role === "AC" ? (
										this.state.actionPopupSelectedRecord.reAllocationExist ===
											"false" ? (
											<a
												href="#/"
												id={this.state.actionPopupSelectedRecord.campID}
												data-toggle="modal"
												data-target="#reallocate"
												style={{ float: "left", paddingBottom: "10px" }}
												// karan-task-3796-bug-parentcampid was ""
												parentCampID={
													this.state.actionPopupSelectedRecord.parentCampID
														? this.state.actionPopupSelectedRecord.parentCampID
														: this.state.actionPopupSelectedRecord.campID
												}
												reallocationID={
													this.state.actionPopupSelectedRecord.reallocationID
												}
												onClick={this.reAllocate}>
												<Tooltip placement="top" title="Reallocate">
													<img
														src="Reallocation.png"
														alt=""
														width="15"
														height="15"
														style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "29px", }}>
														Reallocate Campaign
													</span>
												</div>
											</a>
										) : (
											<span style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip placement="top" title="Reallocate">
													<img
														src="Reallocation_Disable.png"
														alt=""
														width="15"
														height="15"
														style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "29px", }}>
														Reallocate Campaign
													</span>
												</div>
											</span>
										)
									) : (
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip placement="top" title="Reallocate">
												<img
													src="Reallocation_Disable.png"
													alt=""
													width="15"
													height="15"
													style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "29px", }}>
													Reallocate Campaign
												</span>
											</div>
										</span>
									)}
								</span>
							) : this.state.actionPopupSelectedRecord.campaignStatus ===
								"Live_Incomplete" ? (
								<span>
									{user.role === "AC" ? (
										<span>
											<span style={{ float: "left" }}>
												<a
													href="#/"
													id={this.state.actionPopupSelectedRecord.campID}
													data-toggle="modal"
													data-target="#editEndDate1"
													style={{ float: "left", paddingBottom: "10px" }}>
													<Tooltip placement="top" title="LIVE">
														<img
															src="Continue.png"
															alt=""
															id={this.state.actionPopupSelectedRecord.campID}
															advCampID={
																this.state.actionPopupSelectedRecord
																	.parentCampID
																	? this.state.actionPopupSelectedRecord
																		.parentCampID
																	: this.state.actionPopupSelectedRecord
																		.advCampID
																		? this.state.actionPopupSelectedRecord
																			.advCampID
																		: this.state.text
															}
															onClick={this.handleEndDate}
															width="15"
															height="15"
															style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
													</Tooltip>
													<div>
														<span
															style={{ marginLeft: "29px", }}
															data-toggle="modal"
															data-target="#editEndDate1"
															id={this.state.actionPopupSelectedRecord.campID}
															advCampID={
																this.state.actionPopupSelectedRecord
																	.parentCampID
																	? this.state.actionPopupSelectedRecord
																		.parentCampID
																	: this.state.actionPopupSelectedRecord
																		.advCampID
																		? this.state.actionPopupSelectedRecord
																			.advCampID
																		: this.state.text
															}
															onClick={this.handleEndDate}>
															Live Campaign
														</span>
													</div>
												</a>
											</span>
										</span>
									) : (
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip
												placement="top"
												overlayStyle={{
													color: "white",
													backgroundColor: "orange",
												}}
												title="Edit end date to make campaign live.">
												<img
													src="Continue_Disable.png"
													alt=""
													id={this.state.actionPopupSelectedRecord.campID}
													width="15"
													height="15"
													style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "29px", }}>
													Live Campaign
												</span>
											</div>
										</span>
									)}
									<Divider type="vertical" style={{ float: "left" }} />
									{user.role === "AC" ? (
										<a
											href="#/"
											id={this.state.actionPopupSelectedRecord.campID}
											data-toggle="modal"
											onClick={this.getDetails}
											parentCampId={
												this.state.actionPopupSelectedRecord.parentCampID
											}
											reallocationId={
												this.state.actionPopupSelectedRecord.reallocationID
											}
											style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip placement="top" title="Clone">
												<img
													src="clone.png"
													alt=""
													id={this.state.actionPopupSelectedRecord.campID}
													parentCampID={
														this.state.actionPopupSelectedRecord.parentCampID
													}
													reallocationID={
														this.state.actionPopupSelectedRecord.reallocationID
													}
													width="15"
													height="15"
													style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "29px", }}
													id={this.state.actionPopupSelectedRecord.campID}
													parentCampID={
														this.state.actionPopupSelectedRecord.parentCampID
													}
													reallocationID={
														this.state.actionPopupSelectedRecord.reallocationID
													}>
													Clone Campaign
												</span>
											</div>
										</a>
									) : (
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip placement="top" title="Clone">
												<img
													src="clone_disable.png "
													alt=""
													width="15"
													height="15"
													style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "29px", }}>
													Clone Campaign
												</span>
											</div>
										</span>
									)}

									<Divider type="vertical" style={{ float: "left" }} />
									{user.role === "AC" ? (
										this.state.actionPopupSelectedRecord.reAllocationExist ===
											"false" ? (
											<a
												href="#/"
												id={this.state.actionPopupSelectedRecord.campID}
												data-toggle="modal"
												data-target="#reallocate"
												style={{ float: "left", paddingBottom: "10px" }}
												// karan-task-3796-bug-parentcampid was ""
												parentCampID={
													this.state.actionPopupSelectedRecord.parentCampID
														? this.state.actionPopupSelectedRecord.parentCampID
														: this.state.actionPopupSelectedRecord.campID
												}
												reallocationID={
													this.state.actionPopupSelectedRecord.reallocationID
												}
												onClick={this.reAllocate}>
												<Tooltip placement="top" title="Reallocate">
													<img
														src="Reallocation.png"
														alt=""
														width="15"
														height="15"
														style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "29px", }}>
														Reallocate Campaign
													</span>
												</div>
											</a>
										) : (
											<span style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip placement="top" title="Reallocate">
													<img
														src="Reallocation_Disable.png"
														alt=""
														width="15"
														height="15"
														style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "29px", }}>
														Reallocate Campaign
													</span>
												</div>
											</span>
										)
									) : (
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip placement="top" title="Reallocate">
												<img
													src="Reallocation_Disable.png"
													alt=""
													width="15"
													height="15"
													style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "29px", }}>
													Reallocate Campaign
												</span>
											</div>
										</span>
									)}
								</span>
							) : this.state.actionPopupSelectedRecord.advStatus ===
								"PendingSetup" ? (
								user.role === "ANC" || user.role === "AQA" ? (
									<span>
										<a
											href="#/"
											id={this.state.actionPopupSelectedRecord.campID}
											advCampID={this.state.actionPopupSelectedRecord.advCampID}
											disabled
											onClick={this.campaignSetup.bind(this)}
											style={{ float: "left" }}>
											<Tooltip placement="top" title="Campaign Setup">
												<img
													src="camp_setup_Disable.png"
													alt=""
													advCampID={
														this.state.actionPopupSelectedRecord.advCampID
													}
													id={
														this.state.actionPopupSelectedRecord
															.agencyAllocationID
													}
													width="20"
													height="20"
													style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "29px", }}>
													Campaign Setup
												</span>
											</div>
										</a>
									</span>
								) : (
									<span>
										<a
											href="#/" //Rutuja 2978 added #/
											id={this.state.actionPopupSelectedRecord.campID}
											advCampID={this.state.actionPopupSelectedRecord.advCampID}
											onClick={this.campaignSetup.bind(this)}
											style={{ float: "left" }}>
											<Tooltip placement="top" title="Campaign Setup">
												<img
													src="camp_setup_Enable.png"
													alt=""
													advCampID={
														this.state.actionPopupSelectedRecord.advCampID
													}
													id={
														this.state.actionPopupSelectedRecord
															.agencyAllocationID
													}
													width="20"
													height="20"
													style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "29px", }}
													advCampID={
														this.state.actionPopupSelectedRecord.advCampID
													}
													id={
														this.state.actionPopupSelectedRecord
															.agencyAllocationID
													}>
													Campaign Setup
												</span>
											</div>
										</a>
									</span>
								)
							) : this.state.actionPopupSelectedRecord.campaignStatus ===
								"PausedIncomplete" ? (
								<span>
									{user.role === "AC" ? (
										<span>
											<span style={{ float: "left" }}>
												<a
													href="#/"
													id={this.state.actionPopupSelectedRecord.campID}
													data-toggle="modal"
													data-target="#editEndDate1"
													style={{ float: "left", paddingBottom: "10px" }}>
													<Tooltip placement="top" title="LIVE">
														<img
															src="Continue.png"
															alt=""
															id={this.state.actionPopupSelectedRecord.campID}
															advCampID={
																this.state.actionPopupSelectedRecord
																	.parentCampID
																	? this.state.actionPopupSelectedRecord
																		.parentCampID
																	: this.state.actionPopupSelectedRecord
																		.advCampID
																		? this.state.actionPopupSelectedRecord
																			.advCampID
																		: this.state.text
															}
															onClick={this.handleEndDate}
															width="15"
															height="15"
															style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
													</Tooltip>
													<div>
														<span
															style={{ marginLeft: "29px", }}
															data-toggle="modal"
															data-target="#editEndDate1"
															id={this.state.actionPopupSelectedRecord.campID}
															advCampID={
																this.state.actionPopupSelectedRecord
																	.parentCampID
																	? this.state.actionPopupSelectedRecord
																		.parentCampID
																	: this.state.actionPopupSelectedRecord
																		.advCampID
																		? this.state.actionPopupSelectedRecord
																			.advCampID
																		: this.state.text
															}
															onClick={this.handleEndDate}>
															Live Campaign{" "}
														</span>
													</div>
												</a>
											</span>
											{/* } */}
										</span>
									) : (
										""
									)}
									<Divider type="vertical" style={{ float: "left" }} />
									{user.role === "AC" ? (
										<a
											href="#/"
											id={this.state.actionPopupSelectedRecord.campID}
											data-toggle="modal"
										    // data-target="#confirmClone"  //Nilesh-4900 Comment this line 
										    onClick={this.getDetails}
											parentCampId={
												this.state.actionPopupSelectedRecord.parentCampID
											}
											reallocationId={
												this.state.actionPopupSelectedRecord.reallocationID
											}
											style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip placement="top" title="Clone">
												<img
													src="clone.png"
													alt=""
													id={this.state.actionPopupSelectedRecord.campID}
													parentCampID={
														this.state.actionPopupSelectedRecord.parentCampID
													}
													reallocationID={
														this.state.actionPopupSelectedRecord.reallocationID
													}
													width="15"
													height="15"
													style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "29px", }}
													id={this.state.actionPopupSelectedRecord.campID}
													parentCampID={
														this.state.actionPopupSelectedRecord.parentCampID
													}
													reallocationID={
														this.state.actionPopupSelectedRecord.reallocationID
													}>
													Clone Campaign
												</span>
											</div>
										</a>
									) : (
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip placement="top" title="Clone">
												<img
													src="clone_disable.png "
													alt=""
													width="15"
													height="15"
													style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "29px", }}>
													Clone Campaign
												</span>
											</div>
										</span>
									)}

									<Divider type="vertical" style={{ float: "left" }} />
									{user.role === "AC" ? (
										this.state.actionPopupSelectedRecord.reAllocationExist ===
											"false" ? (
											<a
												href="#/"
												id={this.state.actionPopupSelectedRecord.campID}
												data-toggle="modal"
												data-target="#reallocate"
												style={{ float: "left", paddingBottom: "10px" }}
												// karan-task-3796-bug-parentcampid was ""
												parentCampID={
													this.state.actionPopupSelectedRecord.parentCampID
														? this.state.actionPopupSelectedRecord.parentCampID
														: this.state.actionPopupSelectedRecord.campID
												}
												reallocationID={
													this.state.actionPopupSelectedRecord.reallocationID
												}
												onClick={this.reAllocate}>
												<Tooltip placement="top" title="Reallocate">
													<img
														src="Reallocation.png"
														alt=""
														width="15"
														height="15"
														style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "29px", }}>
														Reallocate Campaign
													</span>
												</div>
											</a>
										) : (
											<span style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip placement="top" title="Reallocate">
													<img
														src="Reallocation_Disable.png"
														alt=""
														width="15"
														height="15"
														style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "29px", }}>
														Reallocate Campaign
													</span>
												</div>
											</span>
										)
									) : (
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip placement="top" title="Reallocate">
												<img
													src="Reallocation_Disable.png"
													alt=""
													width="15"
													height="15"
													style={{ marginRight: "150px", marginBottom: "-17px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "29px", }}>
													Reallocate Campaign
												</span>
											</div>
										</span>
									)}
								</span>
							) : (
								""
							)}
						</div>
					)}
				</div>
				{/* Sandeep-task-3957-In action lead send lead to marketo button issue---agency side */}
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				{<Footer />}
			</div>
		); //end of return
	} // end of render
} //end of class

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */

CampaignList.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(CampaignList)
);
