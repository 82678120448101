/* Copyright(c) 2024 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/**
 * @author : Nilesh Zinzurao
 * @description : It is page use for column sequence mapping
 */
import React from "react";
import * as $ from "jquery";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import Footer from "../layouts/footer";
import Navigation from "../layouts/navPage";
import { Tooltip, Card } from "antd";
import { FaArrowAltCircleLeft } from "react-icons/fa";

import "./supportingDocumentNew.css";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //snehal-task-3766-specification VAPT api call
import { VscTriangleDown, VscTriangleUp } from "react-icons/vsc"; //Nilesh-5351- Use for up and down navigation arroe

// to display alerts
const Swal = require("sweetalert2");

class columnSequencing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // sequencingArray: [], //Nilesh-5351- This array use for store all available fields
      selectedValue: [], //Nilesh-5351- This array use for store all selected fields
      clickedItems: [], // Initialize clickedItems as an empty array
      selectedKey: null,
      selectedArray: [],
      newArray: {},
      campID: this.props.location.state.campID,
      campaignStatus: "Active", //Chaitanya-5349-REPORT-Campaign Tracing-status showing Incorrect value
      customQuestionDetails: [], //Nilesh-5351- storing custom questions
      customizedColumns: [], //Nilesh-5351- This array use for store all available fields
      existingValue: [],
      existingColumnsSequence: [], //5353-storing existing Columns Sequence
      newAvailableFields: [], //5353-storing New Available Fields
      loader_progress: "", //5353-for loader
      draggingIndex: null, //Chaitanya-5354-added Index of the item being dragged
      dragOverIndex: null, //Chaitanya-5354-added Index of the item being dragged over
      draggingList: null, //Chaitanya-5354-added to track draggingList
    };
    this.clearData = this.clearData.bind(this);
    this.loadingRef = React.createRef();
    this.closeLoadingRef = React.createRef();
    this.clearExistingSequence = this.clearExistingSequence.bind(this);
    this.fetchColumns = this.fetchColumns.bind(this);
  }
  componentWillMount() {
    this.fetchQuestionDetails();
    var campID = this.state.campID;
    var parsed = this.props.location.state;
    var pID = parsed.pID;

    let data = {
      campID: campID,
      pID: pID,
    };

    this.fetchColumns(data);
  }

  // Chaitanya-5353-funtion for fetch columns data
  fetchColumns = (data) => {
    //this.loadingRef.current.click();
    this.setState({ loader_progress: "loader_campaign_list" });
    this.fetchQuestionDetails();
    //Nilesh-5351- It is route use fetching all selected fildes data
    fetch("/columnMapping/columnSequenceMapping", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((customizedColumns) => {
        if (customizedColumns.length > 0) {
          //this.closeLoadingRef.current.click();
          // Assuming customQuestionDetails is an array of custom question objects
          const customQuestions = this.state.customQuestionDetails.map(
            (item) => item.customQuestion
          );
          // Create an array of custom question objects
          //5210-Added space before - for below CQ as faced issue for download specs at PC side
          const customQuestionObjects = customQuestions.map(
            (question, index) => ({
              [`customQuestion${index + 1}`]: `CQ -${question}`,
            })
          );
          // Create an array of alias question objects
          var customQuestionAliasObjects;
          let updatedSequenceArray = [];
          //alert(JSON.stringify(this.state.customQuestionDetails))
          if (this.state.customQuestionDetails.length > 0) {
            if (this.state.customQuestionDetails[0].aliasName !== "") {
              const customQuestionsAlias = this.state.customQuestionDetails.map(
                (item) => item.aliasName
              );
              customQuestionAliasObjects = customQuestionsAlias.map(
                (questionAlias, index) => ({
                  [`aliasName${index + 1}`]: questionAlias,
                })
              );
            } else {
              customQuestionAliasObjects = [];
            }

            if (customQuestionAliasObjects.length > 0) {
              // Add customQuestions to each object in customizedColumns array
              const updatedSequencingArray = customizedColumns.map(
                (item, index) => {
                  const customQuestionsForItem =
                    customQuestionAliasObjects.reduce((acc, question) => {
                      return { ...acc, ...question };
                    }, {});
                  return { ...item, ...customQuestionsForItem };
                }
              );

              updatedSequenceArray = updatedSequencingArray;
            } else {
              // Add customQuestions to each object in customizedColumns array
              const updatedSequencingArray = customizedColumns.map(
                (item, index) => {
                  const customQuestionsForItem = customQuestionObjects.reduce(
                    (acc, question) => {
                      return { ...acc, ...question };
                    },
                    {}
                  );
                  return { ...item, ...customQuestionsForItem };
                }
              );

              updatedSequenceArray = updatedSequencingArray;
            }
          } else {
            updatedSequenceArray = customizedColumns;
          }
          // Chaitanya-5353-fetch call to read blob data
          fetch("/columnMapping/getBlob", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
          })
            .then((res) => res.json())
            .then((existingColumnsSequence) => {
              this.setState({ loader_progress: "" });
              if (existingColumnsSequence.length > 0) {
                // Extract keys from existingValue
                const existingKeys = existingColumnsSequence.map(
                  (item) => item.key
                );

                //Chaitanya-5336-Added condition for updatedSequenceArray
                const updatedKeys =
                  updatedSequenceArray.length > 0
                    ? Object.keys(updatedSequenceArray[0])
                    : [];
                // Filter out values in customizedColumns that are not in existingKeys
                const newAvailableFields = updatedKeys
                  .filter((key) => !existingKeys.includes(key))
                  .reduce((arr, key) => {
                    arr.push({ [key]: updatedSequenceArray[0][key] });
                    return arr;
                  }, []);

                if (newAvailableFields.length > 0) {
                  //updatedSequenceArray.length = 0;    //Chaitanya-5336-commented updatedSequenceArray
                  //alert(updatedSequenceArray.length)
                  this.setState({
                    newAvailableFields: newAvailableFields,
                    customizedColumns: updatedSequenceArray,
                    existingValue: existingColumnsSequence,
                  });
                } else {
                  //newAvailableFields.length = 0;      //Chaitanya-5336-commented newAvailableFields
                  //updatedSequenceArray.length = 0;    //Chaitanya-5336-commented updatedSequenceArray
                  this.setState({
                    newAvailableFields: [], //Chaitanya-5336-newAvailableFields set blank
                    customizedColumns: [], //Chaitanya-5336-customizedColumns set blank
                    existingValue: existingColumnsSequence,
                  });
                }
              } else {
                let emptyArray = [];
                // let existingValue = this.state.existingValue;
                // existingValue.length = 0;
                this.setState({
                  customizedColumns: updatedSequenceArray,
                  existingValue: emptyArray, //Chaitanya-5337-Passing emptyArray to existingValue
                  selectedValue: emptyArray, //Chaitanya-5337-Passing emptyArray to selectedValue
                  newAvailableFields: emptyArray, //Chaitanya-5337-Passing emptyArray to newAvailableFields
                });
                //alert(JSON.stringify(this.state.customizedColumns))
              }
            });
        } else {
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  //Nilesh-5351- Used for fetching questions details
  fetchQuestionDetails = () => {
    let data = {
      campID: this.state.campID,
    };
    // alert("data -->" + JSON.stringify(data))
    //Nilesh-5351- Add routes for fetching custom question details
    fetch("campaignNew/questionDetailsForEdit", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((customQuestionDetails) => {
        // Handle the response data
        this.setState({
          customQuestionDetails: customQuestionDetails,
        });
        // alert("customQuestionDetails --->" + JSON.stringify(this.state.customQuestionDetails))
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.error("Error:", error);
      });
  };

  //Nilesh-5351- Click on arrow and move all fields
  handleImageClick = (key, value) => {
    // Chaitanya-5353-added condition to add New Available Fields
    if (this.state.newAvailableFields.length > 0) {
      const NewExistingValues = [];

      this.state.newAvailableFields.forEach((item) => {
        Object.entries(item).forEach(([key, value]) => {
          if (value) {
            NewExistingValues.push({ key, value });
          }
        });
      });
      let existingValue = this.state.existingValue;

      const mergedValues = [...existingValue, ...NewExistingValues];

      this.setState(
        {
          existingValue: mergedValues,
          clickedItems: NewExistingValues.map((item) => item.value), // Update the clickedItems state to include all values
        },
        () => {}
      );
    } else {
      const selectedValues = [];
      this.state.customizedColumns.forEach((item) => {
        Object.entries(item).forEach(([key, value]) => {
          if (value) {
            selectedValues.push({ key, value });
          }
        });
      });
      this.setState(
        {
          selectedValue: selectedValues,
          clickedItems: selectedValues.map((item) => item.value), // Update the clickedItems state to include all values
        },
        () => {}
      );
    }
  };

  //Nilesh-5351- Clear all data from selected array
  clearData = (value) => {
    // alert("this.state.clickedItems.includes(value)--->"+ JSON.stringify(this.state.clickedItems.includes(value)))
    Swal.fire({
      // title: "Supporting Document Details Saved",
      text: "Do you want to clear selection?", //Nilesh-5377- Change text message
      icon: "Warning",
      type: "warning",
      //showCancelButton: true,
      confirmButtonColor: "#193D8F",
      cancelButtonColor: "#193D8F",
      confirmButtonText: "Ok",
      //cancelButtonText: "Cancle",
      showCloseButton: true,
      allowOutsideClick: false,
    }).then((result) => {
      // alert("result -->" + JSON.stringify(result))
      if (result.value) {
        // alert("hiiiii clearData")
        // User clicked "Ok"
        this.setState({
          selectedValue: [],
          clickedItems: [], // Clear the clickedItems array
        });
        // alert("this.state.clickedItems.includes(value) >>>"+ JSON.stringify(this.state.clickedItems.includes(value)))
      } else if (result.dismiss === "cancel") {
        // User clicked "Cancel" or dismissed the modal
        console.log("Reset selection cancelled");
      }
    });
  };

  //Chaitanya-5353-fuction for delete existing column sequence
  clearExistingSequence = (value) => {
    // alert("this.state.clickedItems.includes(value)--->"+ JSON.stringify(this.state.clickedItems.includes(value)))
    //this.loadingRef.current.click();
    let data = {
      campID: this.state.campID,
      campaignStatus: this.state.campaignStatus,
    };
    Swal.fire({
      // title: "Supporting Document Details Saved",
      text: "Do you want to Delete Existing Column Sequence?", //Nilesh-5377- Change text message
      icon: "Warning",
      type: "warning",
      //showCancelButton: true,
      confirmButtonColor: "#193D8F",
      cancelButtonColor: "#193D8F",
      confirmButtonText: "Ok",
      //cancelButtonText: "Cancle",
      showCloseButton: true,
      allowOutsideClick: false,
    }).then((result) => {
      //alert("result -->" + JSON.stringify(result))
      if (result.value) {
        this.setState({ loader_progress: "loader_campaign_list" });
        // User clicked "Ok"
        // Make the fetch call to delete the blob
        fetch("columnMapping/deleteBlob", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((res) => {
            if (!res.ok) {
              throw new Error(`HTTP error! status: ${res.status}`);
            }
            return res.json();
          })
          .then((data) => {
            console.log("Delete Blob Response:", data);
            if (data.message) {
              //this.closeLoadingRef.current.click();
              this.setState({ loader_progress: "" });
              //alert('Blob deleted successfully!');
              let campID = this.state.campID;
              const { user } = this.props.auth;
              let data = {
                campID: campID,
                pID: user.id,
              };
              this.setState({
                existingValue: [],
                clickedItems: [], // Clear the clickedItems array
              });
              this.fetchColumns(data);
            }
          })
          .catch((err) => {
            console.error("Error deleting blob:", err);
            //alert('Error deleting blob: ' + err.message);
          });
        // alert("this.state.clickedItems.includes(value) >>>"+ JSON.stringify(this.state.clickedItems.includes(value)))
      } else if (result.dismiss === "cancel") {
        this.setState({ loader_progress: "" });
        // User clicked "Cancel" or dismissed the modal
        console.log("Reset selection cancelled");
      }
    });
  };

  //Nilesh- 5351- Use for selected value move Up
  moveItemUp = (index) => {
    if (index === 0) return; // Cannot move the first item up
    const newSelectedValue = [...this.state.selectedValue];
    const temp = newSelectedValue[index];
    newSelectedValue[index] = newSelectedValue[index - 1];
    newSelectedValue[index - 1] = temp;
    this.setState({ selectedValue: newSelectedValue });
  };

  //Nilesh- 5351- Use for selected value move down
  moveItemDown = (index) => {
    if (index === this.state.selectedValue.length - 1) return; // Cannot move the last item down
    const newSelectedValue = [...this.state.selectedValue];
    const temp = newSelectedValue[index];
    newSelectedValue[index] = newSelectedValue[index + 1];
    newSelectedValue[index + 1] = temp;
    this.setState({ selectedValue: newSelectedValue });
  };

  //Chaitanya-5353- Use for selected value move Up
  moveExistingItemUp = (index) => {
    if (index === 0) return; // Cannot move the first item up
    const newSelectedValue = [...this.state.existingValue];
    const temp = newSelectedValue[index];
    newSelectedValue[index] = newSelectedValue[index - 1];
    newSelectedValue[index - 1] = temp;
    this.setState({ existingValue: newSelectedValue });
  };

  //Chaitanya-5353- Use for selected value move down
  moveExistingItemDown = (index) => {
    if (index === this.state.existingValue.length - 1) return; // Cannot move the last item down
    const newSelectedValue = [...this.state.existingValue];
    const temp = newSelectedValue[index];
    newSelectedValue[index] = newSelectedValue[index + 1];
    newSelectedValue[index + 1] = temp;
    this.setState({ existingValue: newSelectedValue });
  };

  //Chaitanya-5354-added Method to handle drag start
  handleDragStart = (e, index, listType) => {
    //Chaitanya-5354- Reset the color of the previously dropped item when starting a new drag
    if (this.droppedItem) {
      this.droppedItem.style.backgroundColor = "#0777cb"; // Reset to original color
      this.droppedItem = null;
    }

    this.setState({ draggingIndex: index, draggingList: listType });
    const draggedItem =
      listType === "existing"
        ? this.state.existingValue[index]
        : this.state.selectedValue[index];
    e.dataTransfer.setData("text/plain", index);
    e.dataTransfer.effectAllowed = "move";

    const transparentImage = document.createElement("img");
    transparentImage.src =
      "data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACwAAAAAAQABAEACAkQBADs=";
    e.dataTransfer.setDragImage(transparentImage, 0, 0);

    const dragPreview = document.createElement("div");
    dragPreview.textContent = draggedItem.value;

    //Chaitanya-5354- Apply styles to limit the size and add ellipsis
    dragPreview.style.maxWidth = "200px";
    dragPreview.style.whiteSpace = "nowrap";
    dragPreview.style.overflow = "hidden";
    dragPreview.style.textOverflow = "ellipsis";
    dragPreview.style.backgroundColor = "#0777cb";
    dragPreview.style.color = "#fff";
    dragPreview.style.padding = "10px";
    dragPreview.style.borderRadius = "5px";
    dragPreview.style.position = "fixed";
    dragPreview.style.pointerEvents = "none";
    dragPreview.style.zIndex = "1000";
    dragPreview.style.left = `${e.clientX}px`;
    dragPreview.style.top = `${e.clientY}px`;

    document.body.appendChild(dragPreview);
    this.dragPreview = dragPreview;
  };

  //Chaitanya-5354-added Method to handle drag over
  handleDragOver = (e, targetIndex) => {
    e.preventDefault();
    const draggingOverItem = e.currentTarget;
    const boundingRect = draggingOverItem.getBoundingClientRect();
    const offset = e.clientY - boundingRect.top;

    //Chaitanya-5354- Determine if the mouse is in the top or bottom half of the target item
    const shouldInsertAfter = offset > boundingRect.height / 2;
    const newTargetIndex = shouldInsertAfter ? targetIndex + 1 : targetIndex;

    this.setState({ dragOverIndex: newTargetIndex });

    this.dragPreview.style.left = `${e.clientX}px`;
    this.dragPreview.style.top = `${e.clientY}px`;
    this.dragPreview.style.transform = "translate(-50%, -50%)";
  };

  //Chaitanya-5354-added Method to handle drag end
  handleDragEnd = () => {
    if (this.dragPreview) {
      document.body.removeChild(this.dragPreview);
      this.dragPreview = null;
    }
    this.setState({
      draggingIndex: null,
      dragOverIndex: null,
      draggingList: null,
    });
  };

  //Chaitanya-5354-added Method to handle drop
  handleDrop = (e, targetIndex, listType) => {
    e.preventDefault();
    const dragIndex = parseInt(e.dataTransfer.getData("text/plain"), 10);

    if (dragIndex !== targetIndex) {
      // If dragging within the same list
      if (listType === "existing" && this.state.draggingList === "existing") {
        this.moveItem(dragIndex, targetIndex, "existing");
      } else if (
        listType === "selected" &&
        this.state.draggingList === "selected"
      ) {
        this.moveItem(dragIndex, targetIndex, "selected");
      }
    }

    //Chaitanya-5354- Change the background color of the dropped item to grey temporarily
    const droppedItem = e.currentTarget;
    droppedItem.style.backgroundColor = "#B0B0B0";
    this.droppedItem = droppedItem;

    this.setState({
      draggingIndex: null,
      dragOverIndex: null,
      draggingList: null,
    });
  };

  //Chaitanya-5354-added Method to handle drag enter
  handleDragEnter = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
    e.target.classList.add("drag-over"); //Chaitanya-5354-Add visual feedback
  };

  //Chaitanya-5354-added Method to handle drag leave
  handleDragLeave = (e) => {
    e.preventDefault();
    e.target.classList.remove("drag-over"); //Chaitanya-5354-added to Remove drag-over class
  };

  //Chaitanya-5354-added Method to swap items
  moveItem = (fromIndex, toIndex, listType) => {
    const newItems =
      listType === "existing"
        ? [...this.state.existingValue]
        : [...this.state.selectedValue];
    const [movedItem] = newItems.splice(fromIndex, 1); //Chaitanya-5354-added to Remove the item from the original position
    newItems.splice(toIndex, 0, movedItem); //Chaitanya-5354-added to Insert the item at the new position

    if (listType === "existing") {
      this.setState({ existingValue: newItems });
    } else {
      this.setState({ selectedValue: newItems });
    }
  };

  //Nilesh-5351- Used for updating(i.e. inserting) blob in the delivery_format_mapping table
  handleApplySequencing = () => {
    //Chaitanya-5354- Reset the color of the previously dropped item when starting a new drag
    if (this.droppedItem) {
      this.droppedItem.style.backgroundColor = "#0777cb";
      this.droppedItem = null;
    }

    this.setState({ loader_progress: "loader_campaign_list" });

    let data2 = {
      campID: this.state.campID,
      campaignStatus: this.state.campaignStatus,
      columnSequenceCheck: "Yes",
    };
    // alert("data2 --------------->" + JSON.stringify(data2));

    const saveAsBlob = async (data) => {
      try {
        const jsonString = JSON.stringify(data);
        // alert("jsonString -->" + JSON.stringify(jsonString))
        // const dataArray = JSON.parse(jsonString); //5353
        // alert("dataArray -->" + JSON.stringify(dataArray))
        const blob = new Blob([jsonString], { type: "application/json" });
        const formData = new FormData();
        formData.append("file", blob);
        formData.append("campID", data2.campID);
        formData.append("campaignStatus", data2.campaignStatus);
        formData.append("columnSequenceCheck", data2.columnSequenceCheck);

        const response = await fetch("/columnMapping/saveBlob", {
          method: "POST",
          body: formData,
          headers: {
            Accept: "application/json",
          },
        });

        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
          throw new TypeError("Received non-JSON response");
        }

        this.setState({ loader_progress: "" });

        //const responseData = await response.json();
        Swal.fire({
          text: "Column Sequence Changes Saved Successfully!", //Nilesh-5377- Change text message
          type: "success",
          icon: "success",
          confirmButtonText: "Ok",
          heightAuto: false,
          allowOutsideClick: false,
        }).then(() => {
          // Refresh the columnSequencing page
          let campID = this.state.campID;
          const { user } = this.props.auth;
          let data = {
            campID: campID,
            pID: user.id,
          };
          this.fetchColumns(data);
        });
      } catch (error) {
        Swal.fire({
          text: "Error saving sequencing. Please try again.",
          type: "error",
          icon: "error",
          confirmButtonText: "Ok",
          heightAuto: false,
          allowOutsideClick: false,
        });
      }
    };

    if (this.state.existingValue.length === 0) {
      if (this.state.selectedValue.length === 0) {
        this.setState({ loader_progress: "" });
        Swal.fire({
          text: "Fields are not selected. Please select all fields.", //Nilesh-5352- change text
          type: "error",
          icon: "error",
          confirmButtonText: "Ok",
          heightAuto: false,
          allowOutsideClick: false,
        });
      } else {
        saveAsBlob(this.state.selectedValue);
      }
    } else {
      saveAsBlob(this.state.existingValue);
    }
  };

  render() {
    return (
      <div>
        {/*Nilesh-5351- Start - Navigation */}
        <Navigation />
        <div className="card-navigation" style={{ paddingTop: "100px" }}>
          {/* //Chaitanya-5354-added  paddingTop*/}
          <a
            href="/campaignList"
            onClick={() =>
              this.props.history.push("/campaignList", {
                agencyID: this.state.agencyID,
              })
            }
          >
            <FaArrowAltCircleLeft
              size={25}
              style={{
                float: "left",
                marginRight: "20px",
                marginBottom: "5px",
                color: "#056eb8",
              }}
              title="Back to Campaign Progress"
            />
          </a>

          <div class="col-md-12 text-center">
            <label
              id="campallocation"
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "#056eb8",
              }}
            >
              Column Sequence Mapping <br />
              Campaign ID: {this.state.campID}
            </label>
          </div>
        </div>

        <Card
          className="card-addon"
          bordered={false}
          style={{ marginTop: "10px", borderRadius: "10px" }}
        >
          {/* End - Navigation */}
          {/*Nilesh-5351- Start - Available fields and Selected fields Box */}
          <div
            className="row my-2 mx-0"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              className="col-lg-5 col-md-12 padding-left-col md-screen-padding"
              style={{
                paddingLeft: "180px",
                paddingRight: "25px",
              }}
            >
              <h4 style={{ textAlign: "center" }}>Available Fields</h4>
              {this.state.newAvailableFields.length > 0 ? (
                <div
                  className="card"
                  style={{
                    border: "0.7px solid #CCCCCC",
                    paddingLeft: 15,
                    paddingRight: 10,
                    height: "40rem",
                    overflowY: "auto", // Add overflowY: "auto" to enable vertical scrolling
                  }}
                >
                  <ul>
                    {this.state.newAvailableFields.map((item, index) => (
                      <div key={index}>
                        {Object.entries(item).map(([key, value]) => (
                          <p
                            key={key}
                            value={value}
                            style={{
                              margin: "5px",
                              padding: "5px",
                              borderRadius: "5px",
                              fontWeight: "bold",
                              color: "#fff",
                              backgroundColor: this.state.clickedItems.includes(
                                value
                              )
                                ? "#8d93a0"
                                : "#0777cb",
                              cursor: this.state.clickedItems.includes(value)
                                ? "default"
                                : "pointer",
                            }}
                          >
                            {value}
                          </p>
                        ))}
                      </div>
                    ))}
                  </ul>
                </div>
              ) : (
                <div
                  className="card"
                  style={{
                    border: "0.7px solid #CCCCCC",
                    paddingLeft: 15,
                    paddingRight: 10,
                    height: "40rem",
                    overflowY: "auto", // Add overflowY: "auto" to enable vertical scrolling
                  }}
                >
                  <ul>
                    {this.state.customizedColumns.map((item, index) => (
                      <div key={index}>
                        {Object.entries(item).map(([key, value]) => (
                          <p
                            key={key}
                            value={value}
                            style={{
                              margin: "5px",
                              padding: "5px",
                              borderRadius: "5px",
                              fontWeight: "bold",
                              color: "#fff",
                              backgroundColor: this.state.clickedItems.includes(
                                value
                              )
                                ? "#8d93a0"
                                : "#0777cb",
                              cursor: this.state.clickedItems.includes(value)
                                ? "default"
                                : "pointer",
                            }}
                          >
                            {value}
                          </p>
                        ))}
                      </div>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            <div
              align="center"
              style={{ marginBottom: "10%" }}
              id={this.state.loader_progress}
            ></div>

            <div className="col-lg-2 justify-content-center">
              <Tooltip
                title={
                  this.state.clickedItems.length === 0
                    ? "Move All Fields"
                    : "User Already Moved All Fields"
                }
              >
                <img
                  id="arrow-image" //Chaitanya-5354-added id to access in Css
                  src="right-arrow.png"
                  alt="Arrow"
                  // style={{ cursor: "pointer", width: "100px" }}
                  style={{
                    cursor:
                      this.state.clickedItems.length === 0
                        ? "pointer"
                        : "not-allowed",
                    width: "100px",
                  }}
                  // onClick={this.handleImageClick}
                  //Nilesh-5377- Add handleImageClick disabled condition
                  onClick={
                    this.state.clickedItems.length === 0
                      ? this.handleImageClick
                      : null
                  }
                />
              </Tooltip>
            </div>

            {/* Chaitanya-5353-Shown existing column blob value */}
            {this.state.existingValue.length > 0 ? (
              <div
                className="col-lg-5 col-md-12 padding-left-col md-screen-padding"
                style={{
                  paddingLeft: "25px", //50px
                  paddingRight: "180px",
                }}
              >
                <h4 style={{ textAlign: "center" }}>Selected Fields</h4>
                <div
                  className="card"
                  style={{
                    border: "0.7px solid #CCCCCC",
                    paddingLeft: 10,
                    paddingRight: 15,
                    height: "40rem",
                    overflowY: "auto", // Add overflowY: "auto" to enable vertical scrolling
                    position: "relative",
                  }}
                >
                  <ul style={{ padding: 0, margin: 0 }}>
                    {this.state.existingValue.map((item, index) => (
                      <React.Fragment key={index}>
                        {this.state.dragOverIndex === index && (
                          <div
                            style={{
                              height: "2px",
                              backgroundColor: "#007BFF",
                              margin: "5px 0",
                              transition: "height 0.3s ease",
                            }}
                          />
                        )}
                        <b
                          draggable
                          onDragStart={(e) =>
                            this.handleDragStart(e, index, "existing")
                          }
                          onDragEnd={this.handleDragEnd}
                          onDragOver={(e) => this.handleDragOver(e, index)}
                          onDrop={(e) => this.handleDrop(e, index, "existing")}
                          onDragEnter={this.handleDragEnter}
                          onDragLeave={this.handleDragLeave}
                          id={`no-select ${
                            this.state.draggingIndex === index ? "dragging" : ""
                          }`}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            backgroundColor:
                              this.state.draggingIndex === index
                                ? "#0558a3"
                                : "#0777cb",
                            margin: "5px",
                            padding: "5px",
                            borderRadius: " 5px",
                            fontSize: "bold",
                            color: "#fff",
                            cursor: "grab",
                            opacity:
                              this.state.draggingIndex === index ? 0.5 : 1,
                            transition:
                              "background-color 0.3s ease, transform 0.3s ease",
                            transform:
                              this.state.draggingIndex === index
                                ? "scale(1.05)"
                                : "scale(1)",
                            zIndex:
                              this.state.draggingIndex === index ? 10 : "auto",
                          }}
                        >
                          <div>
                            <VscTriangleUp
                              style={{
                                float: "right",
                                fontSize: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() => this.moveExistingItemUp(index)}
                            />
                          </div>
                          <div>{item.value}</div>
                          <div>
                            <VscTriangleDown
                              style={{
                                float: "right",
                                fontSize: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() => this.moveExistingItemDown(index)}
                            />
                          </div>
                        </b>
                      </React.Fragment>
                    ))}
                  </ul>
                </div>
              </div>
            ) : (
              <div
                className="col-lg-5 col-md-12 padding-left-col md-screen-padding"
                style={{
                  paddingLeft: "25px", //50px
                  paddingRight: "180px",
                }}
              >
                <h4 style={{ textAlign: "center" }}>Selected Fields</h4>
                <div
                  className="card"
                  style={{
                    border: "0.7px solid #CCCCCC",
                    paddingLeft: 10,
                    paddingRight: 15,
                    height: "40rem",
                    overflowY: "auto",
                    position: "relative",
                  }}
                >
                  <ul style={{ padding: 0, margin: 0 }}>
                    {this.state.selectedValue.map((item, index) => (
                      <React.Fragment key={index}>
                        {this.state.dragOverIndex === index && (
                          <div
                            style={{
                              height: "2px",
                              backgroundColor: "#007BFF",
                              margin: "5px 0",
                              transition: "height 0.3s ease",
                            }}
                          />
                        )}
                        <b
                          //Chaitanya-5354-added On function to acess drag and drop method
                          draggable
                          onDragStart={(e) =>
                            this.handleDragStart(e, index, "selected")
                          }
                          onDragEnd={this.handleDragEnd}
                          onDragOver={(e) => this.handleDragOver(e, index)}
                          onDrop={(e) => this.handleDrop(e, index, "selected")}
                          onDragEnter={this.handleDragEnter}
                          onDragLeave={this.handleDragLeave}
                          id={`no-select ${
                            this.state.draggingIndex === index ? "dragging" : ""
                          }`}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            backgroundColor:
                              this.state.draggingIndex === index
                                ? "#0558a3"
                                : "#0777cb", //Chaitanya-5354-added css to change color
                            margin: "5px",
                            padding: "5px",
                            borderRadius: " 5px",
                            fontSize: "bold",
                            color: "#fff",
                            //Chaitanya-5354-added css to item look like drag and drop
                            cursor: "grab",
                            opacity:
                              this.state.draggingIndex === index ? 0.5 : 1,
                            transition:
                              "background-color 0.3s ease, transform 0.3s ease",
                            transform:
                              this.state.draggingIndex === index
                                ? "scale(1.05)"
                                : "scale(1)",
                            zIndex:
                              this.state.draggingIndex === index ? 10 : "auto",
                          }}
                        >
                          <div>
                            <VscTriangleUp
                              style={{
                                float: "right",
                                fontSize: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() => this.moveItemUp(index)}
                            />
                          </div>
                          <div>{item.value}</div>
                          <div>
                            <VscTriangleDown
                              style={{
                                float: "right",
                                fontSize: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() => this.moveItemDown(index)}
                            />
                          </div>
                        </b>
                      </React.Fragment>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
          <br></br>
          <div>
            {/* //Chaitanya-5354-added padding below  also move button position*/}
            {/* Nilesh-5355- Add instruction below  */}
            {this.state.clickedItems.length > 0 ||
            this.state.existingValue.length > 0 ? (
              <b
                style={{
                  padding: "5px 15px",
                  marginTop: "10px",
                  color: "red",
                  textAlign: "center",
                }}
              >
                Please click on "Apply/Update" button to save the Selected
                Fields changes.
              </b>
            ) : (
              ""
            )}
            <div style={{ float: "right" }}>
              <button
                className="btn-save-and-next"
                disabled={this.state.existingValue.length > 0 ? false : true}
                style={{
                  padding: "5px 15px",
                  cursor:
                    this.state.existingValue.length > 0
                      ? "pointer"
                      : "not-allowed",
                  opacity: this.state.existingValue.length > 0 ? 1 : 0.5,
                  marginRight: "25px",
                }}
                title="Delete Existing Column Sequence"
                onClick={this.clearExistingSequence}
              >
                Delete
              </button>
            </div>

            <div style={{ float: "right" }}>
              <button
                title="Clear Selected Fields"
                className="btn-save-and-next"
                //Nilesh-5377- Add clear button disabled condition
                disabled={
                  this.state.existingValue.length === 0
                    ? (this.state.selectedValue.length > 0
                      ? false
                      : true)
                    : true
                }
                style={{
                  padding: "5px 15px", //Chaitanya-5354-added padding
                  cursor:
                    this.state.existingValue.length === 0
                      ? this.state.selectedValue.length > 0
                        ? "pointer"
                        : "not-allowed"
                      : "not-allowed",
                  opacity:
                    this.state.existingValue.length === 0
                      ? this.state.selectedValue.length > 0
                        ? 1
                        : 0.5
                      : 0.5,
                  marginRight: "25px", //Chaitanya-5354-added margin
                }}
                onClick={this.clearData}
              >
                Clear
              </button>
            </div>

            {/* Nilesh-5351- Start - Apply Button */}
            {/* Chaitanya-5353-Change Label of 'Apply' button to 'Update' when there is no field is listed in 'Available Fields' */}
            <div style={{ float: "right" }}>
              {this.state.customizedColumns.length > 0 ||
              this.state.newAvailableFields.length > 0 ? (
                <button
                  type="button"
                  class="btn-save-and-next"
                  style={{ marginRight: "25px", padding: "5px 15px" }}
                  onClick={this.handleApplySequencing}
                >
                  Apply
                </button>
              ) : (
                <button
                  type="button"
                  class="btn-save-and-next"
                  style={{ marginRight: "25px", padding: "5px 15px" }}
                  onClick={this.handleApplySequencing}
                >
                  Update
                </button>
              )}
            </div>
          </div>
          {/* End - Button Save and Next */}
          <Footer />
        </Card>
        <br />
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
  withRouter(columnSequencing)
);
