/* Copyright(c) 2021 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/**
 * @author : Karan Jagtap
 * @description : Redesigned Step2 supporting document
 */
import React from "react";
import * as $ from "jquery";
import { form } from "mdbreact";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import Footer from "../layouts/footer";
import Navigation from "../layouts/navPage";
import { MDBCol, MDBRow } from "mdbreact";
import { Input, Tooltip, Icon, Switch, Steps, Card, Checkbox } from "antd";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FaArrowAltCircleLeft } from "react-icons/fa";

import "./supportingDocumentNew.css";
import obj from "../../configration/configration";
import { fa } from "stopword";
import AgencyCustomizeMappingModal from "./AgencyCustomizeMappingModal";
import Loader from "./Loader";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //snehal-task-3766-specification VAPT api call

// to display alerts
const Swal = require("sweetalert2");

// used to access url values

// stepper content
const StepTabs = [
	// step 2.1
	{
		title: "Supporting Documents",
		icon: (
			<img className="step-icon" src="supporting_documents_step2.png"></img>
		),
	},

	// step 2.2
	{
		title: "LN QA & Delivery Format", //Aman-5161 Leads-Nexus-Edit Campaign-Replace to LNQA
		icon: <img className="step-icon" src="di_qa_delivery_format.png"></img>,
	},
];

const dateTime = require("node-datetime");
const dt = dateTime.create();
// required by BE
const editButtonTime = dt.format("Y-m-d H:M:S");

/**
 * @author : Karan Jagtap
 * @description : EditSupportingDocument component
 */
class EditSupportingDocument extends React.Component {
	constructor() {
		super();
		this.state = {
			// to handle the step navigation
			currentStep: 0,
			displayStep1: "flex",
			displayStep2: "none",
			isDataEdited: false,

			// general details which do not change
			campID: undefined,
			parentCampID: undefined,
			reallocationID: undefined,
			// general details which change
			// to control the error messages block should be displayed or not
			showErrorBlock: false,
			// to disable the button 'Save and Next' until response arrives from async calls
			submitStep1ButtonDisabled: false,
			// to disable the button 'Save and Finish' until response arrives from async calls
			submitStep2ButtonDisabled: false,

			// this is for maintaining the UI for Asset files table
			// success count
			assetFilesUploadedSuccessCount: 0,
			// failure count
			assetFilesUploadedFailedCount: 0,
			// all assset files goes in this array
			assetFilesSelectedDocuments: [],
			// to control visibility of asset files table
			displayAssetFilesTable: "none",
			// to keep track of already selected asset files and avoid duplicate file uploads
			duplicateAssetFiles: [],

			// this is for maintaining assets link part
			// all asset links goes in this array
			assetLinks: [],

			// this is for maintaining the UI for ABM files
			// all abm files goes in this array
			abmFilesSelectedDocuments: [],
			// to show allowed extension files error
			abmFilesExtensionError: false,
			// to show valid header error
			abmValidHeaderError: false,

			// this is for maintaining the UI for Suppression files
			// all suppression files goes in this array
			suppressionFilesSelectedDocuments: [],
			// to show allowed extension files error
			suppressionFilesExtensionError: false,
			// to show valid header error
			suppressionValidHeaderError: false,

			// this is for maintaining the UI for Exclusion files
			// all exclusion files goes in this array
			exclusionFilesSelectedDocuments: [],
			// to show allowed extension files error
			exclusionFilesExtensionError: false,
			// to show valid header error
			exclusionValidHeaderError: false,

			// this is for maintaining the UI for Other files
			// all other files goes in this array
			otherFilesSelectedDocuments: [],

			// Step 2
			// agency mapping related details
			agencyCustomizeMapping: [],
			agencyCustomizeMappingModal: [],
			// delivery format related details
			editDeliveryFormat: [],
			excludeText: "", //snehal-task3620-Add exclude domains
			step: "Edit", //snehal-task3620-Add exclude domains
			columnSequenceCheck: false, //Nilesh-5355-to set column sequence check value
			campaignStatus: "Active",//Chaitanya-5349-REPORT-Campaign Tracing-status showing Incorrect value
		};

		// to show loading modal
		this.loadingRef = React.createRef(); // bug - 3405 - karan jagtap - removed default loader and implemented custom loader
		this.closeLoadingRef = React.createRef(); // bug - 3405 - karan jagtap - removed default loader and implemented custom loader
		this.excludeTextChange = this.excludeTextChange.bind(this); //snehal-task3620-Add exclude domains
		this.checkColumnSequence = this.checkColumnSequence.bind(this); //Nilesh-5355
	}

	//Aman-4841-Prod Issue-(Added to call this route first) AC - Edit campaign -  Supporting Document - Total leads takes time to load
	componentDidMount() {
		//Aman-4841-This code will be executed after the component is rendered.
		this.fetchCampaignDetails();
	  }
	/**
	 * @author : Karan Jagtap
	 * @description : prepares the UI with already present data in database
	 */
	componentWillMount() {
		// if user is not logged in then redirect to login page
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			// karan-task-3933-vapt header and query params
			let parsed = this.props.location.state;
			if (parsed !== undefined) {
				let campID, parentCampID;
				if (parsed.hasOwnProperty("campID")) {
					campID = parsed.campID;
				}
				if (parsed.hasOwnProperty("parentCampID")) {
					parentCampID = parsed.parentCampID;
				}
				this.setState(
					{
						campID: parsed.campID,
						parentCampID: parsed.parentCampID,
					},
					() => {
						// calling all the api endpoints and setting the state and UI
						// this should display same content as saved before from create campaign or edit campaign
						this.fetchSwitchValue();
						// this.fetchSupportingDocumentForEdit();
						// this.fetchAssetLink();
						// this.fetchAgencyCustomizeMapping();
						// this.fetchDeliveryFormatForEdit();
						//kiran-4640-calling function one from another for getting response
					}
				);
				this.checkColumnSequence(campID); //Nilesh-5355- Passed campID
			} else {
				this.props.history.replace("/newDashboard");
			} // karan-task-3933-vapt header and query params
		}
	}

	/**
	 * @author : Karan Jagtap
	 * @description : getting all the details required of current campaign
	 * @route : '/campaign/CampaignDetails'
	 */
	fetchCampaignDetails = () => {
		fetch("campaign/CampaignDetails?campID=" + this.state.campID + "")
			.then((res) => res.json())
			.then((detailsOfCampaign) => {
				// created a json object instead of an array because every array returned in response
				// only has 1 object inside it
				detailsOfCampaign = detailsOfCampaign[0];

				// creating below local variables because .setState() should be only called once per async call
				// all this variables are set to state at the end of this scope
				let multiTouchChk = "No";
				let leadPerAssetChk = "No";
				let jobLevelDelivery = "Yes";
				let jobFunctionDelivery = "Yes";
				let companyRevenue = "Yes";
				let companyEmployeeSize = "Yes";
				let industry = "Yes";
				let jobTitleValidation = "Yes";

				// setting all checkboxes to their respective values
				// multitouch
				if (detailsOfCampaign.multiTouch === "Yes") {
					multiTouchChk = "Yes";
				}

				// lead per asset
				if (detailsOfCampaign.requiredLeadPerAsset === "Yes") {
					leadPerAssetChk = "Yes";
				}

				// Note : to why so many conditions are used
				// from campaignNew.js, it may return 5 types of values,
				// anything from below values could be returned to indicate that the db has no value present in it:
				// 1. ""
				// 2. null
				// 3. "null"
				// 4. undefined
				// 5. "undefined"
				// below there are 2 variables to check with hence, the conditional combination goes as follows:
				// point 1 with point 2,3,4,5 and point 2 with point 1,3,4,5 and so on.. = 4points*4points*2variables=32 conditions
				// this cannot be done in reverse order because value of jobLevel can be 1 of 6 values (eg, 1-50, 50-100) and
				// value of customJobLevel is custom hence it cannot be predicted.
				// Therefore needs to use this approach
				// This same logic goes for the following checkboxes:
				// 1. jobLevel
				// 2. jobFunction
				// 3. companyRevenue
				// 4. employeeSize
				// 5. industry

				// job level, custom job level
				if (
					(detailsOfCampaign.jobLevel === "" &&
						detailsOfCampaign.customJobLevel === "") ||
					(detailsOfCampaign.jobLevel === null &&
						detailsOfCampaign.customJobLevel === null) ||
					(detailsOfCampaign.jobLevel === undefined &&
						detailsOfCampaign.customJobLevel === undefined) ||
					(detailsOfCampaign.jobLevel === "null" &&
						detailsOfCampaign.customJobLevel === null) ||
					(detailsOfCampaign.jobLevel === "undefined" &&
						detailsOfCampaign.customJobLevel === undefined) ||
					(detailsOfCampaign.jobLevel === null &&
						detailsOfCampaign.customJobLevel === "null") ||
					(detailsOfCampaign.jobLevel === undefined &&
						detailsOfCampaign.customJobLevel === "undefined") ||
					(detailsOfCampaign.jobLevel === "null" &&
						detailsOfCampaign.customJobLevel === "null") ||
					(detailsOfCampaign.jobLevel === "undefined" &&
						detailsOfCampaign.customJobLevel === "undefined") ||
					(detailsOfCampaign.jobLevel === "" &&
						detailsOfCampaign.customJobLevel === null) ||
					(detailsOfCampaign.jobLevel === "" &&
						detailsOfCampaign.customJobLevel === undefined) ||
					(detailsOfCampaign.jobLevel === "" &&
						detailsOfCampaign.customJobLevel === "null") ||
					(detailsOfCampaign.jobLevel === "" &&
						detailsOfCampaign.customJobLevel === "undefined") ||
					(detailsOfCampaign.customJobLevel === "" &&
						detailsOfCampaign.jobLevel === null) ||
					(detailsOfCampaign.customJobLevel === "" &&
						detailsOfCampaign.jobLevel === undefined) ||
					(detailsOfCampaign.customJobLevel === "" &&
						detailsOfCampaign.jobLevel === "null") ||
					(detailsOfCampaign.customJobLevel === "" &&
						detailsOfCampaign.jobLevel === "undefined")
				) {
					jobLevelDelivery = "No";
				}

				// job function, custom job function
				if (
					(detailsOfCampaign.jobFunction === "" &&
						detailsOfCampaign.customJobFunction === "") ||
					(detailsOfCampaign.jobFunction === null &&
						detailsOfCampaign.customJobFunction === null) ||
					(detailsOfCampaign.jobFunction === undefined &&
						detailsOfCampaign.customJobFunction === undefined) ||
					(detailsOfCampaign.jobFunction === "null" &&
						detailsOfCampaign.customJobFunction === null) ||
					(detailsOfCampaign.jobFunction === "undefined" &&
						detailsOfCampaign.customJobFunction === undefined) ||
					(detailsOfCampaign.jobFunction === null &&
						detailsOfCampaign.customJobFunction === "null") ||
					(detailsOfCampaign.jobFunction === undefined &&
						detailsOfCampaign.customJobFunction === "undefined") ||
					(detailsOfCampaign.jobFunction === "null" &&
						detailsOfCampaign.customJobFunction === "null") ||
					(detailsOfCampaign.jobFunction === "undefined" &&
						detailsOfCampaign.customJobFunction === "undefined") ||
					(detailsOfCampaign.jobFunction === "" &&
						detailsOfCampaign.customJobFunction === null) ||
					(detailsOfCampaign.jobFunction === "" &&
						detailsOfCampaign.customJobFunction === undefined) ||
					(detailsOfCampaign.jobFunction === "" &&
						detailsOfCampaign.customJobFunction === "null") ||
					(detailsOfCampaign.jobFunction === "" &&
						detailsOfCampaign.customJobFunction === "undefined") ||
					(detailsOfCampaign.customJobFunction === "" &&
						detailsOfCampaign.jobFunction === null) ||
					(detailsOfCampaign.customJobFunction === "" &&
						detailsOfCampaign.jobFunction === undefined) ||
					(detailsOfCampaign.customJobFunction === "" &&
						detailsOfCampaign.jobFunction === "null") ||
					(detailsOfCampaign.customJobFunction === "" &&
						detailsOfCampaign.jobFunction === "undefined")
				) {
					jobFunctionDelivery = "No";
				}

				// company revenue
				if (
					(detailsOfCampaign.companyRevenue === "" &&
						detailsOfCampaign.customCompRevenue === "") ||
					(detailsOfCampaign.companyRevenue === null &&
						detailsOfCampaign.customCompRevenue === null) ||
					(detailsOfCampaign.companyRevenue === undefined &&
						detailsOfCampaign.customCompRevenue === undefined) ||
					(detailsOfCampaign.companyRevenue === "null" &&
						detailsOfCampaign.customCompRevenue === null) ||
					(detailsOfCampaign.companyRevenue === "undefined" &&
						detailsOfCampaign.customCompRevenue === undefined) ||
					(detailsOfCampaign.companyRevenue === null &&
						detailsOfCampaign.customCompRevenue === "null") ||
					(detailsOfCampaign.companyRevenue === undefined &&
						detailsOfCampaign.customCompRevenue === "undefined") ||
					(detailsOfCampaign.companyRevenue === "null" &&
						detailsOfCampaign.customCompRevenue === "null") ||
					(detailsOfCampaign.companyRevenue === "undefined" &&
						detailsOfCampaign.customCompRevenue === "undefined") ||
					(detailsOfCampaign.companyRevenue === "" &&
						detailsOfCampaign.customCompRevenue === null) ||
					(detailsOfCampaign.companyRevenue === "" &&
						detailsOfCampaign.customCompRevenue === undefined) ||
					(detailsOfCampaign.companyRevenue === "" &&
						detailsOfCampaign.customCompRevenue === "null") ||
					(detailsOfCampaign.companyRevenue === "" &&
						detailsOfCampaign.customCompRevenue === "undefined") ||
					(detailsOfCampaign.customCompRevenue === "" &&
						detailsOfCampaign.companyRevenue === null) ||
					(detailsOfCampaign.customCompRevenue === "" &&
						detailsOfCampaign.companyRevenue === undefined) ||
					(detailsOfCampaign.customCompRevenue === "" &&
						detailsOfCampaign.companyRevenue === "null") ||
					(detailsOfCampaign.customCompRevenue === "" &&
						detailsOfCampaign.companyRevenue === "undefined")
				) {
					companyRevenue = "No";
				}

				// employee size
				if (
					(detailsOfCampaign.employeeSize === "" &&
						detailsOfCampaign.customEmpSize === "") ||
					(detailsOfCampaign.employeeSize === null &&
						detailsOfCampaign.customEmpSize === null) ||
					(detailsOfCampaign.employeeSize === undefined &&
						detailsOfCampaign.customEmpSize === undefined) ||
					(detailsOfCampaign.employeeSize === "null" &&
						detailsOfCampaign.customEmpSize === null) ||
					(detailsOfCampaign.employeeSize === "undefined" &&
						detailsOfCampaign.customEmpSize === undefined) ||
					(detailsOfCampaign.employeeSize === null &&
						detailsOfCampaign.customEmpSize === "null") ||
					(detailsOfCampaign.employeeSize === undefined &&
						detailsOfCampaign.customEmpSize === "undefined") ||
					(detailsOfCampaign.employeeSize === "null" &&
						detailsOfCampaign.customEmpSize === "null") ||
					(detailsOfCampaign.employeeSize === "undefined" &&
						detailsOfCampaign.customEmpSize === "undefined") ||
					(detailsOfCampaign.employeeSize === "" &&
						detailsOfCampaign.customEmpSize === null) ||
					(detailsOfCampaign.employeeSize === "" &&
						detailsOfCampaign.customEmpSize === undefined) ||
					(detailsOfCampaign.employeeSize === "" &&
						detailsOfCampaign.customEmpSize === "null") ||
					(detailsOfCampaign.employeeSize === "" &&
						detailsOfCampaign.customEmpSize === "undefined") ||
					(detailsOfCampaign.customEmpSize === "" &&
						detailsOfCampaign.employeeSize === null) ||
					(detailsOfCampaign.customEmpSize === "" &&
						detailsOfCampaign.employeeSize === undefined) ||
					(detailsOfCampaign.customEmpSize === "" &&
						detailsOfCampaign.employeeSize === "null") ||
					(detailsOfCampaign.customEmpSize === "" &&
						detailsOfCampaign.employeeSize === "undefined")
				) {
					companyEmployeeSize = "No";
				}

				// industry, custom industry
				if (
					(detailsOfCampaign.industry === "" &&
						detailsOfCampaign.customIndustry === "") ||
					(detailsOfCampaign.industry === null &&
						detailsOfCampaign.customIndustry === null) ||
					(detailsOfCampaign.industry === undefined &&
						detailsOfCampaign.customIndustry === undefined) ||
					(detailsOfCampaign.industry === "null" &&
						detailsOfCampaign.customIndustry === null) ||
					(detailsOfCampaign.industry === "undefined" &&
						detailsOfCampaign.customIndustry === undefined) ||
					(detailsOfCampaign.industry === null &&
						detailsOfCampaign.customIndustry === "null") ||
					(detailsOfCampaign.industry === undefined &&
						detailsOfCampaign.customIndustry === "undefined") ||
					(detailsOfCampaign.industry === "null" &&
						detailsOfCampaign.customIndustry === "null") ||
					(detailsOfCampaign.industry === "undefined" &&
						detailsOfCampaign.customIndustry === "undefined") ||
					(detailsOfCampaign.industry === "" &&
						detailsOfCampaign.customIndustry === null) ||
					(detailsOfCampaign.industry === "" &&
						detailsOfCampaign.customIndustry === undefined) ||
					(detailsOfCampaign.industry === "" &&
						detailsOfCampaign.customIndustry === "null") ||
					(detailsOfCampaign.industry === "" &&
						detailsOfCampaign.customIndustry === "undefined") ||
					(detailsOfCampaign.customIndustry === "" &&
						detailsOfCampaign.industry === null) ||
					(detailsOfCampaign.customIndustry === "" &&
						detailsOfCampaign.industry === undefined) ||
					(detailsOfCampaign.customIndustry === "" &&
						detailsOfCampaign.industry === "null") ||
					(detailsOfCampaign.customIndustry === "" &&
						detailsOfCampaign.industry === "undefined")
				) {
					industry = "No";
				}

				// job title validation
				if (detailsOfCampaign.jobTitleValidation === "No") {
					jobTitleValidation = "No";
				}

				// setting the state with respect to above changes
				let editDeliveryFormat = { ...this.state.editDeliveryFormat };
				editDeliveryFormat.jobLevelDelivery = jobLevelDelivery;
				editDeliveryFormat.jobFunctionDelivery = jobFunctionDelivery;
				editDeliveryFormat.companyRevenue = companyRevenue;
				editDeliveryFormat.companyEmployeeSize = companyEmployeeSize;
				editDeliveryFormat.industry = industry;
				editDeliveryFormat.jobTitleValidation = jobTitleValidation;

				// Note :
				// where ever you find a state set like this
				// {name: name} instead of {name}
				// it is done on purpose to use the find functionality of this editor
				// it helps tracking where the state of a particular object is changed
				this.setState({
					detailsOfCampaign: detailsOfCampaign,
					totalLeadAllocation: detailsOfCampaign.leadAllocation,
					requiredLeadPerAsset: detailsOfCampaign.requiredLeadPerAsset,
					requiredLeadPerAssetOld: detailsOfCampaign.requiredLeadPerAsset,
					campaignType: detailsOfCampaign.ABM,
					reallocationID: detailsOfCampaign.reallocationID,
					multiTouchChk: multiTouchChk,
					leadPerAssetChk: leadPerAssetChk,
					editDeliveryFormat: editDeliveryFormat,
				});
			});
	};
	/**
	 * @author Snehal More
	 * @param  Description handle change of exclude domain (//snehal-task3620-Add exclude domains)
	 * @return Description to set tha values of exclude domain
	 */
	excludeTextChange(e) {
		const { name, value } = e.target;
		this.setState({
			excludeText: value,
		});
	}
	/**
	 * @author : Karan Jagtap
	 * @description : getting the LN QA switch value of current campaign
	 * @route : '/diQARole/getSwitchValueForEdit'
	 */
	fetchSwitchValue = () => {
		fetch("/diQARole/getSwitchValueForEdit?campID=" + this.state.campID + "")
			.then((res) => res.json())
			.then((switchDetails) => {
				if (switchDetails.length > 0) {
					if (switchDetails[0].campaignLevelSwitch == "") {
						switchDetails[0].campaignLevelSwitch = "OFF";
					}
					this.setState({
						switchValue: switchDetails[0].campaignLevelSwitch,
						switchDetails: switchDetails,
					});
					this.fetchSupportingDocumentForEdit();
					//kiran-4640-calling function
				}
			});
	};

	/**
	 * @author : Karan Jagtap
	 * @description : getting asset, abm, exclusion, suppression and other files of current campaign
	 * @route : '/campaign/supportingDocumentForEdit'
	 * @params : showMessage - true/false
	 */
	fetchSupportingDocumentForEdit = (showMessage = false) => {
		fetch("campaign/supportingDocumentForEdit?campID=" + this.state.campID + "")
			.then((res) => res.json())
			.then((sdEditDetail) => {
				this.setState({
					sdEditDetail: sdEditDetail,
					sdEditDetailOld: sdEditDetail, // required by BE
					assetNoChangeArray: sdEditDetail, // required by BE
					campaignStatus: sdEditDetail[0].campaignStatus,
					excludeText: sdEditDetail[0].excludedDomain,
					noChangeExcludedDomain: sdEditDetail[0].excludedDomain,
				}); //Sonali-3257-addedd campaignStatus
				//snehal-task3620-Add exclude domains(exclude domain)

				if (
					sdEditDetail != undefined ||
					sdEditDetail != "" ||
					sdEditDetail != null ||
					sdEditDetail.length > 0
				) {
					// just separating the files as per their document type and maintaing it in separate arrays
					// for better management
					let assetFilesSelectedDocumentsTemp = [];
					let abmFilesSelectedDocumentsTemp = [];
					let suppressionFilesSelectedDocumentsTemp = [];
					let exclusionFilesSelectedDocumentsTemp = [];
					let otherFilesSelectedDocumentsTemp = [];
					for (var i = 0; i < this.state.sdEditDetail.length; i++) {
						if (sdEditDetail[i].typeOfSuppDoc == "Asset") {
							assetFilesSelectedDocumentsTemp = [
								...assetFilesSelectedDocumentsTemp,
								{
									...sdEditDetail[i],
									success: true,
									assetFileName: sdEditDetail[i].suppDocName,
									assetName_touch: sdEditDetail[i].multiTouch, // Bug - 3399 - Karan Jagtap - by default this property should be present
								},
							];
						} else if (sdEditDetail[i].typeOfSuppDoc == "ABM") {
							abmFilesSelectedDocumentsTemp = [
								...abmFilesSelectedDocumentsTemp,
								{ ...sdEditDetail[i], success: true },
							];
						} else if (sdEditDetail[i].typeOfSuppDoc == "Suppression") {
							suppressionFilesSelectedDocumentsTemp = [
								...suppressionFilesSelectedDocumentsTemp,
								{ ...sdEditDetail[i], success: true },
							];
						} else if (sdEditDetail[i].typeOfSuppDoc == "Exclusion") {
							exclusionFilesSelectedDocumentsTemp = [
								...exclusionFilesSelectedDocumentsTemp,
								{ ...sdEditDetail[i], success: true },
							];
						} else if (sdEditDetail[i].typeOfSuppDoc == "Other") {
							otherFilesSelectedDocumentsTemp = [
								...otherFilesSelectedDocumentsTemp,
								{ ...sdEditDetail[i], success: true },
							];
						}
					}
					if (assetFilesSelectedDocumentsTemp.length > 0) {
						this.setState({ displayAssetFilesTable: "block" });
					}
					this.setState({
						assetFilesSelectedDocuments: assetFilesSelectedDocumentsTemp,
						abmFilesSelectedDocuments: abmFilesSelectedDocumentsTemp,
						suppressionFilesSelectedDocuments:
							suppressionFilesSelectedDocumentsTemp,
						exclusionFilesSelectedDocuments:
							exclusionFilesSelectedDocumentsTemp,
						otherFilesSelectedDocuments: otherFilesSelectedDocumentsTemp,
						assetFilesUploadedSuccessCount:
							assetFilesSelectedDocumentsTemp.length,
						assetFilesUploadedFailedCount: 0,
						assetFilesSelectedDocumentsOld: assetFilesSelectedDocumentsTemp,
						isDataEdited: showMessage,
					});
				}

				// as this route is not just called from componentWillMount() but other places too,
				// hence, showing the message if called from outside of componentWillMount()
				if (showMessage) {
					let message = "This asset is no longer used";
					Swal.fire({
						text: message,
						type: "success",
						confirmButtonText: "Ok",
						allowOutsideClick: false,
						heightAuto: false,
					});
				}
			})
			.catch((err) => {});
			this.fetchAssetLink();
			//kiran-4640-calling function
	};

	/**
	 * @author : Karan Jagtap
	 * @description : getting asset links of current campaign
	 * @route : '/campaign/getAssetLink'
	 * @params : assetLinkNew - array of new links which is not yet saved in database
	 */
	fetchAssetLink = (assetLinksNew = []) => {
		fetch("campaign/getAssetLink?campID=" + this.state.campID)
			.then((res) => res.json())
			.then((dynamicInput) => {
				// Bug - 3399 - Karan Jagtap - Start
				let dynamicInputTemp = [];
				for (let i = 0; i < dynamicInput.length; i++) {
					dynamicInputTemp.push({
						...dynamicInput[i],
						assetName_touch: dynamicInput[i].multiTouch, // Bug - 3399 - Karan Jagtap - by default this property should be present
					});
				}
				// Bug - 3399 - Karan Jagtap - End
				this.setState({
					assetLinks: [...dynamicInputTemp, ...assetLinksNew],
					dynamicInputOldArray: dynamicInput, // require by BE
				});
			})
			.catch(function (err) {});
			this.fetchAgencyCustomizeMapping();
			//kiran-4640-calling function
	};

	/**
	 * @author : Karan Jagtap
	 * @description : getting customized mapping details of current campaign
	 * @route : '/campaign/getAgencyCustomizeMapping'
	 */
	fetchAgencyCustomizeMapping = () => {
		fetch("campaign/getAgencyCustomizeMapping?campID=" + this.state.campID + "")
			.then((res) => res.json())
			.then((agencyCustomizeMapping) => {
				this.setState({ agencyCustomizeMapping: agencyCustomizeMapping });
				this.fetchDeliveryFormatForEdit();
				//kiran-4640-calling function
			});
	};

	/**
	 * @author : Karan Jagtap
	 * @description : getting all delivery format details of current campaign
	 * @route : '/campaign/deliveryFormatEdit'
	 */
	fetchDeliveryFormatForEdit = () => {
		fetch("campaign/deliveryFormatEdit?campID=" + this.state.campID + "")
			.then((res) => res.json())
			.then((editDeliveryFormat) => {
				editDeliveryFormat = editDeliveryFormat[0];
				// if no format returned from BE, it means it has Format 1
				if (
					editDeliveryFormat.customizeMappingFormat === "" ||
					editDeliveryFormat.customizeMappingFormat === null ||
					editDeliveryFormat.customizeMappingFormat === undefined
				) {
					editDeliveryFormat.customizeMappingFormat = "Format 1";
				}
				this.setState(
					{
						editDeliveryFormat: editDeliveryFormat,
						editDeliveryFormatNoChange: editDeliveryFormat, // required by BE
					},
					() => {
						// called here because the below function needs values of editDeliveryFormat for managing the checkboxes
						// this.fetchCampaignDetails(); //Aman-4841-(commented this and calling it at on componentDidMount to reduce time to call)
						// karan-task-3059-fire handler if it is checked, doing this because programmatically we need to fire this handler anyhow at initialization to make sure agencyCustomizeMappingModal state is not empty
						if (this.state.editDeliveryFormat.customizeMapping === "Yes") {
							this.customizeMappingFormatHandleChange();
						}
					}
				);
			});
	};

	/**
	 * @author : Karan Jagtap
	 * @description : all navigation related functions
	 */
	// start - navigatory functions
	handleBackButton = (e) => {
		// when user on step2.1
		if (this.state.currentStep === 0) {
			let canGoBack = true;
			let assetFileAndAssetLinkEmpty = false;
			let abmFilesEmpty = false;
			let abmFilesInvalid = false;
			let assetNameEmpty = false;
			let showErrorBlock = false;
			let assetLinksTemp = this.state.assetLinks.filter(
				(obj) => obj.status !== "Removed"
			);
			let assetFilesSelectedDocumentsTemp =
				this.state.assetFilesSelectedDocuments.filter(
					(obj) => obj.status !== "Removed"
				);

			// 1. asset files and asset links both are empty
			if (
				assetLinksTemp.length === 0 &&
				assetFilesSelectedDocumentsTemp.length === 0
			) {
				canGoBack = false;
				assetFileAndAssetLinkEmpty = true;
				showErrorBlock = true;
			}

			if (this.state.campaignType === "ABM") {
				// 2. abm file is compulsary to upload but found empty
				if (this.state.abmFilesSelectedDocuments.length === 0) {
					canGoBack = false;
					abmFilesEmpty = true;
					showErrorBlock = true;
				} else {
					let abmFilesSelectedDocumentsTemp =
						this.state.abmFilesSelectedDocuments.filter(
							(obj) => obj.success === true
						);
					// 3. abm file is uploaded but all are invalid headers
					if (abmFilesSelectedDocumentsTemp.length === 0) {
						canGoBack = false;
						abmFilesInvalid = true;
						showErrorBlock = true;
					}
				}
			}

			// 4. asset links added but no data filled inside its fields
			if (assetLinksTemp.length > 0) {
				for (let i = 0; i < assetLinksTemp.length; i++) {
					if (
						assetLinksTemp[i].linkAssetFileName === null ||
						assetLinksTemp[i].linkAssetFileName === undefined ||
						assetLinksTemp[i].linkAssetFileName === ""
					) {
						canGoBack = false;
						assetNameEmpty = true;
						showErrorBlock = true;
					}
				}
			}

			// if any change is done on step2.1 then show this warning and dont allow user to go back
			// unless clicked on save and next
			if (this.state.isDataEdited) {
				canGoBack = false;
				Swal.fire({
					title: "Warning",
					text: "Please click on save and next button because you have changed some data.",
					icon: "warning",
					type: "warning",
					confirmButtonColor: "#193D8F",
					confirmButtonText: "Okay",
					allowOutsideClick: false,
				});
			}

			if (canGoBack) {
				this.props.history.push("/welcomeEdit", {
					campID: this.state.campID,
					parentCampID: this.state.parentCampID,
				}); // karan-task-3714-vapt header and query params
			} else {
				this.setState({
					showErrorBlock: showErrorBlock,
					assetFileAndAssetLinkEmpty: assetFileAndAssetLinkEmpty,
					abmFilesEmpty: abmFilesEmpty,
					abmFilesInvalid: abmFilesInvalid,
					assetNameEmpty: assetNameEmpty,
				});
			}
		} else {
			// called here once again because when asset file name is changed, it does not reflect when came back from
			// step2.2 to step2.1
			this.fetchSupportingDocumentForEdit();
			this.fetchAssetLink(); //snehal-task-3617(STC-added function call for asset link while i came back from step2)
			this.onHandleStepChange(0);
		}
	};
	// this is for handling the steppers according to current step value
	onHandleStepChange = (currentStep) => {
		this.setState({ currentStep });
		if (currentStep === 0) {
			this.setState({
				displayStep1: "flex",
				displayStep2: "none",
			});
		} else {
			this.setState({
				displayStep1: "none",
				displayStep2: "flex",
			});
		}
	};
	// this is for handling the steppers when clicked on step heading
	onHandleStepChangeFromStepper = () => {
		if (this.state.currentStep === 0) {
			if (this.state.isDataEdited) {
				Swal.fire({
					title: "Warning",
					text: "Please click on save and next button because you have changed some data.",
					icon: "warning",
					type: "warning",
					confirmButtonColor: "#193D8F",
					confirmButtonText: "Okay",
					allowOutsideClick: false,
				});
			} else {
				this.setState({ isDataEdited: false });
				this.onHandleStepChange(1);
			}
		} else {
			this.onHandleStepChange(0);
		}
	};
	// "Save and Next" button handler
	submitStep1 = () => {
		if (this.validationStep1()) {
			this.loadingRef.current.click(); // bug - 3405 - karan jagtap - removed default loader and implemented custom loader
			this.setState({ submitStep1ButtonDisabled: true });
			let key = 1;
			let assetFileName = [];
			let assetvizLeadsEdited = "";
			let multiTouchEdited = "";
			const dt = dateTime.create();
			const saveButtonTime = dt.format("Y-m-d H:M:S");
			const { user } = this.props.auth;
			// creating array 'assetFileName' as per the format required by BE
			for (let i = 0; i < this.state.assetFilesSelectedDocuments.length; i++) {
				let singleObj = this.state.assetFilesSelectedDocuments[i];
				let obj = {
					...singleObj,
					key: key,
					sdEditDetailOld: this.state.sdEditDetailOld,
				};
				assetFileName.push(obj);
				key++;
			}

			// if the value of lead per asset checkbox is changed from (yes to no) or (no to yes)
			if (
				this.state.detailsOfCampaign.requiredLeadPerAsset !==
				this.state.leadPerAssetChk
			) {
				assetvizLeadsEdited = true;
			}

			// if the value of multitouch checkbox is changed from (yes to no) or (no to yes)
			if (
				this.state.detailsOfCampaign.multiTouch !== this.state.multiTouchChk
			) {
				multiTouchEdited = true;
			}

			// data for 'campaign/editSupportingDocument'
			let data = {
				assetFileName: assetFileName,
				assetNoChangeArray: this.state.sdEditDetailOld,
				assetsFile: assetFileName,
				assetvizLeadsEdited: assetvizLeadsEdited,
				campID: this.state.campID,
				campaignStatus: this.state.campaignStatus,
				dynamicInput: this.state.assetLinks,
				dynamicInputOldArray: this.state.dynamicInputOldArray,
				editButtonTime: editButtonTime,
				multiTouchEdit: this.state.multiTouchChk,
				multiTouchEdited: multiTouchEdited,
				parentCampID: this.state.parentCampID,
				requiredLeadPerAsset: this.state.leadPerAssetChk,
				requiredLeadPerAssetOld:
					this.state.detailsOfCampaign.requiredLeadPerAsset,
				saveButtonTime: saveButtonTime,
				sdEditDetailOld: this.state.sdEditDetailOld,
				excludedDomain: this.state.excludeText,
				noChangeExcludedDomain: this.state.noChangeExcludedDomain,
				step: this.state.step,
			}; //snehal-task3620-Add exclude domains(for exclude) // karan-task-3719-vapt header and query params

			let self = this;
			fetch("campaign/editSupportingDocument", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then(function (response) {
					if (response.status >= 400) {
						throw new Error("Bad response from server");
					}
					return response.json();
				})
				.then(function (response) {
					if (response.success) {
						let key2 = 1;
						// creating array 'multiTouchEditArray' as per the format required by BE
						let multiTouchEditArray = [];
						for (
							let i = 0;
							i < self.state.assetFilesSelectedDocuments.length;
							i++
						) {
							let singleObj = self.state.assetFilesSelectedDocuments[i];
							let obj = {
								...singleObj,
								key: key2,
							};
							multiTouchEditArray.push(obj);
							key2++;
						}

						// data for 'campaign/editDeliveryFormatFormultiTouch'
						let data2 = {
							campID: self.state.campID,
							campaignStatus: self.state.campaignStatus,
							multiTouchEdit: self.state.multiTouchChk,
							multiTouchEditArray: multiTouchEditArray,
							multiTouchEditArrayForLink: self.state.assetLinks,
						}; // karan-task-3933-vapt header and query params
						fetch("campaign/editDeliveryFormatFormultiTouch", {
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(data2),
						})
							.then((res) => res.json())
							.then((response) => {
								if (response.success) {
									self.closeLoadingRef.current.click(); // bug - 3405 - karan jagtap - removed default loader and implemented custom loader
									self.setState({
										submitStep1ButtonDisabled: false,
										isDataEdited: false,
									});
									Swal.fire({
										title: "Supporting Document Details Saved",
										text: "Do you want to edit further details?",
										icon: "Success",
										type: "success",
										showCancelButton: true,
										confirmButtonColor: "#193D8F",
										cancelButtonColor: "#193D8F",
										confirmButtonText: "Yes, Move Further!",
										cancelButtonText: "Back To Main",
										showCloseButton: true,
										allowOutsideClick: false,
									}).then((result) => {
										if (result.value) {
											self.onHandleStepChange(1);
										} else if (result.dismiss === "cancel") {
											self.props.history.push("/welcomeEdit", {
												campID: self.state.campID,
												parentCampID: self.state.parentCampID,
											}); // karan-task-3714-vapt header and query params1078
										}

										//Nilesh-5355-Add Swal fire when column sequence check value is Yes
										if(self.state.columnSequenceCheck === 'Yes'){
											//Nilesh-5355-Add Swal fire when blob is present
											Swal.fire({
											   title: "Column Sequence Map",
											   text:"Column Sequence is Mapped for the Campaign. Do You want to Update Column Sequence?",
											   icon: "warning",
											   type: "warning",
											   confirmButtonText: "Continue",
											   // showCloseButton: true,
											   cancelButtonColor: "#d33",
											   showCancelButton: true,
											   cancelButtonText: "Cancel",
											   allowOutsideClick: false,
											 }).then((result) => {
											   if(result.value){
												   var campID = self.state.campID;
												   self.props.history.push("/columnSequencing", {
													 campID,
												   });
												 }
											 });
									   }
									});
								} else {
									self.closeLoadingRef.current.click(); // bug - 3405 - karan jagtap - removed default loader and implemented custom loader
									self.setState({ submitStep1ButtonDisabled: false });
									self.showSwalErrorMessage();
								}
							})
							.catch((err) => {
								self.closeLoadingRef.current.click(); // bug - 3405 - karan jagtap - removed default loader and implemented custom loader
								self.setState({ submitStep1ButtonDisabled: false });
								self.showSwalErrorMessage();
							});
					} else {
						self.closeLoadingRef.current.click(); // bug - 3405 - karan jagtap - removed default loader and implemented custom loader
						self.setState({ submitStep1ButtonDisabled: false });
						self.showSwalErrorMessage();
					}
				})
				.catch(function (err) {
					self.closeLoadingRef.current.click(); // bug - 3405 - karan jagtap - removed default loader and implemented custom loader
					self.setState({ submitStep1ButtonDisabled: false });
					self.showSwalErrorMessage();
				});
		}
	};
	// "Save and Finish" button handler
	submitStep2 = () => {
		this.loadingRef.current.click(); // bug - 3405 - karan jagtap - removed default loader and implemented custom loader
		this.setState({ submitStep2ButtonDisabled: true });
		const { user } = this.props.auth;
		let isEdited = "";

		// if the value of LN QA switch is changed
		// original != current
		if (
			this.state.switchDetails[0].campaignLevelSwitch !== this.state.switchValue
		) {
			isEdited = true;
		}

		// data for 'campaign/updateSwitchValue'
		let data2 = {
			campID: this.state.campID,
			campaignStatus: this.state.campaignStatus,
			fromEdit: true,
			isEdited: isEdited,
			switchValue: this.state.switchValue,
		}; // karan-task-3719-vapt header and query params

		// data for 'campaign/editDeliveryFormat'
		let data = {
			campaignStatus: this.state.campaignStatus,
			editButtonTime: editButtonTime,
			editDeliveryFormat: this.state.editDeliveryFormat,
			editDeliveryFormatNoChange: this.state.editDeliveryFormatNoChange,
			parentCampID: this.state.parentCampID,
			reallocationID: this.state.reallocationID,
			CustomizeMappingData: this.state.agencyCustomizeMappingModal //Nilesh-5279 - Added to get setected  agencies mapping details
		}; // karan-task-3933-vapt header and query params

		let self = this;
		fetch("/campaign/editDeliveryFormat", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then(function (response) {
				if (response.status >= 400) {
					throw new Error("Bad response from server");
				}
				return response.json();
			})
			.then(function (response) {
				if (response.success) {
					fetch("campaign/updateSwitchValue", {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(data2),
					})
						.then((res) => res.json())
						.then((response) => {
							if (response.success) {
								self.closeLoadingRef.current.click(); // bug - 3405 - karan jagtap - removed default loader and implemented custom loader
								self.setState({ submitStep2ButtonDisabled: false });
								 //Nilesh-5355-Add Swal fire when column sequence check value is Yes
								 if(self.state.columnSequenceCheck === 'Yes'){
									return Swal.fire({
										title: "Column Sequence Map",
										text:"Campaign Edited Successfully. Column Sequence is Mapped for the Campaign. Do You want to Update Column Sequence?",
										icon: "warning",
										type: "warning",
										confirmButtonText: "Continue",
										// showCloseButton: true,
										cancelButtonColor: "#d33",
										showCancelButton: true,
										cancelButtonText: "Cancel",
										allowOutsideClick: false,
									  }).then((result) => {
										if (result.value) {
											var campID = self.state.campID;
											self.props.history.push("/columnSequencing", {
											  campID,
											});
										} 
										else {
											self.props.history.push("/welcomeEdit", {
												campID: self.state.campID,
												parentCampID: self.state.parentCampID,
												step: 1,
											});
										}
									  });
								}
								else{
									Swal.fire({
										text: "Campaign Edited Successfully", // Bug - 3405 - Karan Jagtap - message changed
										heightAuto: false,
										type: "success",
										confirmButtonText: "Ok",
										allowOutsideClick: false,
										preConfirm: () => {
											self.props.history.push("/welcomeEdit", {
												campID: self.state.campID,
												parentCampID: self.state.parentCampID,
												step: 1,
											}); // karan-task-3933-vapt header and query params
										},
									});
								}
							} else {
								self.closeLoadingRef.current.click(); // bug - 3405 - karan jagtap - removed default loader and implemented custom loader
								self.setState({ submitStep2ButtonDisabled: false });
								self.showSwalErrorMessage();
							}
						})
						.catch(function (err) {
							self.closeLoadingRef.current.click(); // bug - 3405 - karan jagtap - removed default loader and implemented custom loader
							self.setState({ submitStep2ButtonDisabled: false });
							self.showSwalErrorMessage();
						});
				} else {
					self.closeLoadingRef.current.click(); // bug - 3405 - karan jagtap - removed default loader and implemented custom loader
					self.setState({ submitStep2ButtonDisabled: false });
					self.showSwalErrorMessage();
				}
			})
			.catch(function (err) {
				self.closeLoadingRef.current.click(); // bug - 3405 - karan jagtap - removed default loader and implemented custom loader
				self.setState({ submitStep2ButtonDisabled: false });
				self.showSwalErrorMessage();
			});
	};
	// end - navigatory functions

	/**
	 * @author : Karan Jagtap
	 * @description : all checkbox and switch related functions
	 */
	// start - checkbox and switch changes
	handleMultiTouchCheckbox = (e) => {
		if (e.target.checked) {
			let multiTouchChk = "Yes";

			// for asset files table
			let assetFilesSelectedDocumentsTemp = [];
			if (this.state.assetFilesSelectedDocuments.length > 0) {
				for (
					let i = 0;
					i < this.state.assetFilesSelectedDocuments.length;
					i++
				) {
					let obj = this.state.assetFilesSelectedDocuments[i];
					if (obj.hasOwnProperty("multiTouch")) {
						if (obj.multiTouch === "") {
							assetFilesSelectedDocumentsTemp.push({
								...obj,
								multiTouch: "1st Touch",
								assetTimestamp_touch: "Yes", // Bug - 3399 - property was missing
								assetName_touch: "1st Touch", // Bug - 3399 - property was missing
							});
						} else {
							assetFilesSelectedDocumentsTemp.push(obj);
						}
					} else {
						assetFilesSelectedDocumentsTemp.push({
							...obj,
							multiTouch: "1st Touch",
							assetTimestamp_touch: "Yes", // Bug - 3399 - property was missing
							assetName_touch: "1st Touch", // Bug - 3399 - property was missing
						});
					}
				}
			}

			// for asset links table
			let assetLinksTemp = [];
			for (let i = 0; i < this.state.assetLinks.length; i++) {
				let obj = this.state.assetLinks[i];
				if (obj.hasOwnProperty("multiTouch")) {
					if (obj.multiTouch === "") {
						assetLinksTemp.push({
							...obj,
							multiTouch: "1st Touch",
							assetTimestamp_touch: "Yes", // Bug - 3399 - property was missing
							assetName_touch: "1st Touch", // Bug - 3399 - property was missing
						});
					} else {
						assetLinksTemp.push(obj);
					}
				} else {
					assetLinksTemp.push({
						...obj,
						multiTouch: "1st Touch",
						assetTimestamp_touch: "Yes", // Bug - 3399 - property was missing
						assetName_touch: "1st Touch", // Bug - 3399 - property was missing
					});
				}
			}

			this.setState({
				multiTouchChk: multiTouchChk,
				assetFilesSelectedDocuments: assetFilesSelectedDocumentsTemp,
				assetLinks: assetLinksTemp,
				isDataEdited: true,
			});
		} else {
			// Bug - 3399 - Karan Jagtap - Start - precautionary change
			// for asset files table
			let assetFilesSelectedDocumentsTemp = [];
			if (this.state.assetFilesSelectedDocuments.length > 0) {
				for (
					let i = 0;
					i < this.state.assetFilesSelectedDocuments.length;
					i++
				) {
					let obj = this.state.assetFilesSelectedDocuments[i];
					delete obj.multiTouch;
					delete obj.assetName_touch;
					delete obj.assetTimestamp_touch;
					assetFilesSelectedDocumentsTemp.push(obj);
				}
			}

			// for asset links table
			let assetLinksTemp = [];
			for (let i = 0; i < this.state.assetLinks.length; i++) {
				let obj = this.state.assetLinks[i];
				delete obj.multiTouch;
				delete obj.assetName_touch;
				delete obj.assetTimestamp_touch;
				assetLinksTemp.push(obj);
			}

			this.setState({
				multiTouchChk: "No",
				assetFilesSelectedDocuments: assetFilesSelectedDocumentsTemp,
				assetLinks: assetLinksTemp,
				isDataEdited: true,
			});
			// Bug - 3399 - Karan Jagtap - End - precautionary change
		}
	};
	handleLeadPerAssetCheckbox = (e) => {
		if (e.target.checked) {
			let leadPerAssetChk = "Yes";

			// for asset files table
			let assetFilesSelectedDocumentsTemp = [];
			if (this.state.assetFilesSelectedDocuments.length > 0) {
				for (
					let i = 0;
					i < this.state.assetFilesSelectedDocuments.length;
					i++
				) {
					assetFilesSelectedDocumentsTemp.push({
						...this.state.assetFilesSelectedDocuments[i],
						leadPercentage: 0,
						leadPerAsset: 0,
					});
				}
			}

			// for asset links table
			let assetLinksTemp = [];
			for (let i = 0; i < this.state.assetLinks.length; i++) {
				assetLinksTemp.push({
					...this.state.assetLinks[i],
					leadPercentage: 0,
					leadPerAsset: 0,
				});
			}

			this.setState({
				leadPerAssetChk: leadPerAssetChk,
				assetFilesSelectedDocuments: assetFilesSelectedDocumentsTemp,
				assetLinks: assetLinksTemp,
				isDataEdited: true,
			});
		} else {
			this.setState({ leadPerAssetChk: "No", isDataEdited: true });
		}
	};
	// LN QA switch handler
	switchHandlechange = (value) => {
		value === true
			? this.setState({ switchValue: "ON" })
			: this.setState({ switchValue: "OFF" });
	};
	// this is called when the modal is opened to view the customized mapping data
	customizeMappingFormatHandleChange = (e) => {
		let customizeMappingFormatTemp =
			this.state.editDeliveryFormat.customizeMappingFormat;
		if (
			customizeMappingFormatTemp === "" ||
			customizeMappingFormatTemp === null ||
			customizeMappingFormatTemp === undefined
		) {
			customizeMappingFormatTemp = "Format 1";
		}
		if (this.state.agencyCustomizeMapping.length > 0) {
			// filtering out which format is selected by user from dropdown and then display it in modal
			let customMapping = this.state.agencyCustomizeMapping.filter(
				(a) => a.format == customizeMappingFormatTemp
			);
			this.setState({
				agencyCustomizeMappingModal: customMapping,
				editDeliveryFormat: {
					...this.state.editDeliveryFormat,
					customizeMappingFormat: customizeMappingFormatTemp,
				},
			});
		}
	};
	// handles the dropdown select menu value changes
	handleFormatChange = (e) => {
		e.preventDefault();
		const { value } = e.target;
		let editDeliveryFormat = { ...this.state.editDeliveryFormat };
		editDeliveryFormat = {
			...editDeliveryFormat,
			["customizeMappingFormat"]: value,
		};
		editDeliveryFormat = { ...editDeliveryFormat };
		this.setState({ editDeliveryFormat: editDeliveryFormat }, () => {
			// karan-task-3059-fired when dropdown option changes and changes are reflected immediately
			this.customizeMappingFormatHandleChange();
		});
	};
	// manages all delivery format checboxes
	handleChecked = (e) => {
		const { name, checked } = e.target;
		let editDeliveryFormat = { ...this.state.editDeliveryFormat };
		if (checked === true) {
			editDeliveryFormat = { ...editDeliveryFormat, [name]: "Yes" };
		} else {
			editDeliveryFormat = { ...editDeliveryFormat, [name]: "No" };
		}
		this.setState({ editDeliveryFormat }, () => {
			// karan-task-3059-when customize mapping checbox is checked/unchecked
			if (name === "customizeMapping") {
				this.customizeMappingFormatHandleChange();
			}
		});
	};
	// end - checkbox and switch changes

	 //rutja task-4687 added handle check for country abbreviation
	handleCheckedCountryAbbreviation = (e) => {
		
		const { name, checked } = e.target;
		let editDeliveryFormat = { ...this.state.editDeliveryFormat };
		if (checked === true) {
			editDeliveryFormat = { ...editDeliveryFormat, [name]: "Yes" };
		} else {
			editDeliveryFormat = { ...editDeliveryFormat, [name]: "No" };
		}
		this.setState({ editDeliveryFormat })
		
	};

	/**
	 * @author : Karan Jagtap
	 * @description : all asset files related functions
	 */
	// start - asset files
	// all handleChangeMultipleFile functions logic is same, hence only commenting at one place to reduce code length
	handleChangeMultipleFile = (e) => {
		e.preventDefault();
		// gets all the selected files from explorer
		const { files } = e.target;
		if (files.length === 0) {
			return;
		}
		let assetFilesSelectedDocumentsTemp = [
			...this.state.assetFilesSelectedDocuments,
		];
		let duplicateAssetFilesTemp = [];
		let dupliacateAssetFilesCount = 0;
		let onlyDuplicateToDB = files.length;
		let { user } = this.props.auth;
		let data = new FormData();
		data.append("campID", this.state.campID);
		data.append("campaignStatus", this.state.campaignStatus);
		for (let i = 0; i < files.length; i++) {
			let file = files[i];
			// checking for duplicate file names
			let found = assetFilesSelectedDocumentsTemp.findIndex(
				(item) => item.suppDocName === file.name && item.status!='Removed'
			);
			//kiran-4742-added item.status to check status
			// if file is new and have unique name
			if (found === -1) {
				let obj = {};
				// setting default values for lead per asset fields
				if (this.state.leadPerAssetChk === "Yes") {
					obj.leadPercentage = 0;
					obj.leadPerAsset = 0;
				}
				// setting default values for multitouch field
				if (this.state.multiTouchChk === "Yes") {
					obj.multiTouch = "1st Touch";
				}
				obj.suppDocName = file.name;
				obj.assetFileName = file.name;
				// obj.loading = true;
				obj.success = true;
				obj.typeOfSuppDoc = "Asset";
				data.append("file", file);
				assetFilesSelectedDocumentsTemp.push(obj);
			} else {
				dupliacateAssetFilesCount++;
				duplicateAssetFilesTemp.push(file.name);
			}
		}
		e.target.value = null; // Bug - 3400 - Karan Jagtap - this is to clear the event object with already selected files
		this.setState(
			{
				assetFilesSelectedDocuments: assetFilesSelectedDocumentsTemp,
				duplicateAssetFiles: duplicateAssetFilesTemp,
			},
			() => {
				if (dupliacateAssetFilesCount !== onlyDuplicateToDB) {
					this.uploadAssetFileToDB(data);
				}
			}
		);
	};
	// all removefiles functions logic is same, hence only commenting at one place to reduce code length
	removeAssetFileSelectedDocuments = (e, item) => {
		e.preventDefault();
		const { status, leadStatus } = item;
		if (status === "Removed") {
			// do nothing
			// this should probably not get called because onClick event is disabled
		} else if (
			leadStatus === "Client Accepted" ||
			leadStatus === "Not Accepted" ||
			leadStatus === true
		) {
			// main thing goes here
			let message = "";
			let flag ="No"; //Aman task 5319 set flag to NO
			if (leadStatus === "Client Accepted") {
				message =
					"Leads are accepted by client, Are you sure you want Delete this Asset?";
			} else if (leadStatus === "Not Accepted") {
				message =
					"Leads are submitted, are you sure you want to delete this Asset ? if deleted then the leads will also be rejected .";
					flag="Yes";  //Aman task 5319 set flag to Yes if leadstatus is not accpeted	
			} else {
				message = "Are you sure you want to Delete this asset?";
			}
			Swal.fire({
				heightAuto: false,
				title: message,
				type: "question",
				showCancelButton: true,
				cancelButtonColor: "#3085d6",
				confirmButtonColor: "#d33",
				allowOutsideClick: false,
				confirmButtonText: "Yes, Remove it!",
			}).then((result) => {
				if (result.value) {
					const { user } = this.props.auth;
					let data = {
						supportDocID: item.supportDocID,
						campID: this.state.campID,
						names: item.suppDocName,
						typeOfSuppDoc: item.typeOfSuppDoc,
						campaignStatus: this.state.campaignStatus, //Sonali-3257-need these details at BE to insert into campaignLog
					}; // karan-task-3933-vapt header and query params
					fetch("campaign/supportingDocumentDeleteAsset", {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(data),
					})
						.then((res) => res.json())
						.then((response) => {
							if (leadStatus !== "") {
								this.fetchSupportingDocumentForEdit(true);
							}
						})
						.catch((err) => {});
						//Aman -5319 if flag is yes  then set lead status of review leads to rejected
						if(flag ==="Yes"){
						
							fetch("/campaign/deleteAssetUploadLeads",
							{
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(data),
							})
						}
				}
			});
		} else {
			this.deleteSupportingDocumentFileFromDB(item);
			let assetFilesUploadedSuccessCountTemp =
				this.state.assetFilesUploadedSuccessCount;
			let assetFilesUploadedFailedCountTemp =
				this.state.assetFilesUploadedFailedCount;
			let duplicateAssetFilesTemp = []; // Bug - 3400 - Karan Jagtap - duplicate file upload issue
			if (item.success) {
				assetFilesUploadedSuccessCountTemp--;
			} else {
				assetFilesUploadedFailedCountTemp--;
			}
			// Bug - 3400 - Karan Jagtap - duplicate file upload issue
			if (this.state.duplicateAssetFiles.length > 0) {
				duplicateAssetFilesTemp = this.state.duplicateAssetFiles.filter(
					(obj) => obj !== item.suppDocName
				);
			}
			this.setState(
				{
					assetFilesSelectedDocuments:
						this.state.assetFilesSelectedDocuments.filter(
							(obj) => obj.suppDocName !== item.suppDocName
						),
					assetFilesUploadedSuccessCount: assetFilesUploadedSuccessCountTemp,
					assetFilesUploadedFailedCount: assetFilesUploadedFailedCountTemp,
					duplicateAssetFiles: duplicateAssetFilesTemp, // Bug - 3400 - Karan Jagtap - duplicate file upload issue
					isDataEdited: true,
				},
				() => {
					if (this.state.assetFilesSelectedDocuments.length === 0) {
						this.setState({ displayAssetFilesTable: "none" });
					}
				}
			);
		}
	};
	// all uploadfiles functions logic is same, hence only commenting at one place to reduce code length
	uploadAssetFileToDB = (data) => {
		fetch("campaign/editSupportingDocumentAsset", {
			method: "POST",
			body: data,
		})
			.then((res) => res.json())
			.then((res) => {
				let assetFilesSelectedDocumentsTemp = [
					...this.state.assetFilesSelectedDocuments,
				];
				let assetFilesUploadedSuccessCountTemp =
					this.state.assetFilesUploadedSuccessCount;
				let assetFilesUploadedFailedCountTemp =
					this.state.assetFilesUploadedFailedCount;
				for (let i = 0; i < res.length; i++) {
					let index = -1;
					if (res[i].success) {
						index = assetFilesSelectedDocumentsTemp.findIndex(
							(item) => item.suppDocName === res[i].successList
						);
					} else {
						index = assetFilesSelectedDocumentsTemp.findIndex(
							(item) => item.suppDocName === res[i].rejectedFile
						);
					}
					let obj = assetFilesSelectedDocumentsTemp[index];
					obj.success = res[i].success;
					obj.loading = false;
					if (res[i].success) {
						assetFilesUploadedSuccessCountTemp++;
					} else {
						assetFilesUploadedFailedCountTemp++;
					}
					assetFilesSelectedDocumentsTemp[index] = obj;
				}
				let displayAssetFilesTable = false;
				if (assetFilesSelectedDocumentsTemp.length > 0) {
					displayAssetFilesTable = true;
				}
				this.setState({
					assetFilesSelectedDocuments: assetFilesSelectedDocumentsTemp,
					assetFilesUploadedSuccessCount: assetFilesUploadedSuccessCountTemp,
					assetFilesUploadedFailedCount: assetFilesUploadedFailedCountTemp,
					displayAssetFilesTable,
					isDataEdited: true,
				});
			})
			.catch((err) => {});
	};
	// end - asset files

	/**
	 * @author : Karan Jagtap
	 * @description : all abm files related functions
	 */
	// start - abm files
	handleChangeMultipleFileOfABM = (e) => {
		let self = this;
		e = document.createElement("input"); //creating a input element but not binding it to body added by Raunak
		e.setAttribute("type", "file"); //setting a attribute for type file added by Raunak
		e.setAttribute("multiple", true); //setting a attribute for multiple added by Raunak
		e.click(); //to open file dialougeadded by Raunak
		e.addEventListener("change", function () {
			const { files } = e;
			if (files.length === 0) {
				return;
			}
			self.setState(
				{
					abmFilesExtensionError: false,
				},
				() => {
					if (self.isFileExtensionCorrect(files, "abm")) {
						let abmFilesSelectedDocumentsTemp = [
							...self.state.abmFilesSelectedDocuments,
						];
						let duplicateAbmFilesTemp = [];
						let dupliacateAbmFilesCount = 0;
						let onlyDuplicateToDB = files.length;
						let { user } = self.props.auth;
						let data = new FormData();
						data.append("campID", self.state.campID);
						data.append("campaignStatus", self.state.campaignStatus);
						for (let i = 0; i < files.length; i++) {
							let file = files[i];
							let found = abmFilesSelectedDocumentsTemp.findIndex(
								(item) => item.suppDocName === file.name
							);
							if (found === -1) {
								let obj = {};
								obj.suppDocName = file.name;
								obj.loading = true;
								obj.success = true;
								obj.typeOfSuppDoc = "ABM";
								data.append("file", file);
								abmFilesSelectedDocumentsTemp.push(obj);
							} else {
								dupliacateAbmFilesCount++;
								duplicateAbmFilesTemp.push(file.name);
							}
						}
						self.setState(
							{
								abmFilesSelectedDocuments: abmFilesSelectedDocumentsTemp,
								duplicateAbmFiles: duplicateAbmFilesTemp,
							},
							() => {
								if (dupliacateAbmFilesCount !== onlyDuplicateToDB) {
									self.uploadAbmFileToDB(data);
								}
							}
						);
					}
				}
			);
		});
	};
	removeABMFileSelectedDocuments = (e, item) => {
		e.preventDefault();
		this.deleteSupportingDocumentFileFromDB(item);
		this.setState(
			{
				abmFilesSelectedDocuments: this.state.abmFilesSelectedDocuments.filter(
					(obj) => obj.suppDocName !== item.suppDocName
				),
				isDataEdited: true,
			},
			() => {
				if (this.state.abmFilesSelectedDocuments.length === 0) {
					this.setState({
						abmValidHeaderError: false,
						abmFilesExtensionError: false,
					});
				}
				this.checkErrorStatus(this.state.abmFilesSelectedDocuments, "abm");
			}
		);
	};
	uploadAbmFileToDB = (data) => {
		fetch("campaign/editSupportingDocumentABM", {
			method: "POST",
			body: data,
		})
			.then((res) => res.json())
			.then((res) => {
				let abmFilesSelectedDocumentsTemp = [
					...this.state.abmFilesSelectedDocuments,
				];
				for (let i = 0; i < res.length; i++) {
					let index = -1;
					if (res[i].success) {
						index = abmFilesSelectedDocumentsTemp.findIndex(
							(item) => item.suppDocName === res[i].successList
						);
					} else {
						index = abmFilesSelectedDocumentsTemp.findIndex(
							(item) => item.suppDocName === res[i].rejectedFile
						);
						this.setState({
							abmValidHeaderError: true,
						});
					}
					let obj = abmFilesSelectedDocumentsTemp[index];
					obj.success = res[i].success;
					obj.loading = false;
					obj.fileRecordCount = res[i].abmCount;
					abmFilesSelectedDocumentsTemp[index] = obj;
				}
				this.setState({
					abmFilesSelectedDocuments: abmFilesSelectedDocumentsTemp,
					isDataEdited: true,
				});
			})
			.catch((err) => {});
	};
	// end - abm files

	/**
	 * @author : Karan Jagtap
	 * @description : all suppression files related functions
	 */
	// start - suppression files
	handleChangeMultipleFileOfSuppression = (e) => {
		let self = this;
		e = document.createElement("input"); //creating a input element but not binding it to body added by Raunak
		e.setAttribute("type", "file"); //setting a attribute for type file added by Raunak
		e.setAttribute("multiple", true); //setting a attribute for multiple added by Raunak
		e.click(); //to open file dialougeadded by Raunak
		e.addEventListener("change", function () {
			const { files } = e;
			if (files.length === 0) {
				return;
			}
			self.setState(
				{
					suppressionFilesExtensionError: false,
				},
				() => {
					if (self.isFileExtensionCorrect(files, "suppression")) {
						let suppressionFilesSelectedDocumentsTemp = [
							...self.state.suppressionFilesSelectedDocuments,
						];
						let duplicateSuppressionFilesTemp = [];
						let dupliacateSuppressionFilesCount = 0;
						let onlyDuplicateToDB = files.length;
						let { user } = self.props.auth;
						let data = new FormData();
						data.append("campID", self.state.campID);
						data.append("parentCampID", self.state.parentCampID);
						data.append("reallocationID", self.state.reallocationID); // even though this is undefined, it is passed inside data here.
						data.append("campaignStatus", self.state.campaignStatus);
						for (let i = 0; i < files.length; i++) {
							let file = files[i];
							let found = suppressionFilesSelectedDocumentsTemp.findIndex(
								(item) => item.suppDocName === file.name
							);
							if (found === -1) {
								let obj = {};
								obj.suppDocName = file.name;
								obj.loading = true;
								obj.success = true;
								obj.typeOfSuppDoc = "Suppression";
								data.append("file", file);
								suppressionFilesSelectedDocumentsTemp.push(obj);
							} else {
								dupliacateSuppressionFilesCount++;
								duplicateSuppressionFilesTemp.push(file.name);
							}
						}
						self.setState(
							{
								suppressionFilesSelectedDocuments:
									suppressionFilesSelectedDocumentsTemp,
								duplicateSuppressionFiles: duplicateSuppressionFilesTemp,
							},
							() => {
								if (dupliacateSuppressionFilesCount !== onlyDuplicateToDB) {
									self.uploadSuppressionFileToDB(data);
								}
							}
						);
					}
				}
			);
		});
	};
	removeSuppressionFileSelectedDocuments = (e, item) => {
		e.preventDefault();
		this.deleteSupportingDocumentFileFromDB(item);
		this.setState(
			{
				suppressionFilesSelectedDocuments:
					this.state.suppressionFilesSelectedDocuments.filter(
						(obj) => obj.suppDocName !== item.suppDocName
					),
				isDataEdited: true,
			},
			() => {
				if (this.state.suppressionFilesSelectedDocuments.length === 0) {
					this.setState({
						suppressionValidHeaderError: false,
						suppressionFilesExtensionError: false,
					});
				}
				this.checkErrorStatus(
					this.state.suppressionFilesSelectedDocuments,
					"suppression"
				);
			}
		);
	};
	uploadSuppressionFileToDB = (data) => {
		fetch("campaign/editSupportingDocumentSuppression", {
			method: "POST",
			body: data,
		})
			.then((res) => res.json())
			.then((res) => {
				let suppressionFilesSelectedDocumentsTemp = [
					...this.state.suppressionFilesSelectedDocuments,
				];
				for (let i = 0; i < res.length; i++) {
					let index = -1;
					if (res[i].success) {
						index = suppressionFilesSelectedDocumentsTemp.findIndex(
							(item) => item.suppDocName === res[i].successList
						);
					} else {
						index = suppressionFilesSelectedDocumentsTemp.findIndex(
							(item) => item.suppDocName === res[i].rejectedFile
						);
						this.setState({
							suppressionValidHeaderError: true,
						});
					}
					let obj = suppressionFilesSelectedDocumentsTemp[index];
					obj.success = res[i].success;
					obj.loading = false;
					suppressionFilesSelectedDocumentsTemp[index] = obj;
				}
				this.setState({
					suppressionFilesSelectedDocuments:
						suppressionFilesSelectedDocumentsTemp,
					isDataEdited: true,
				});
			})
			.catch((err) => {});
	};
	// end - suppression files

	/**
	 * @author : Karan Jagtap
	 * @description : all exclusion files related functions
	 */
	// start - exclusion files
	handleChangeMultipleFileOfExclusion = (e) => {
		let self = this;
		e = document.createElement("input"); //creating a input element but not binding it to body added by Raunak
		e.setAttribute("type", "file"); //setting a attribute for type file added by Raunak
		e.setAttribute("multiple", true); //setting a attribute for multiple added by Raunak
		e.click(); //to open file dialougeadded by Raunak
		e.addEventListener("change", function () {
			const { files } = e;
			if (files.length === 0) {
				return;
			}
			self.setState(
				{
					exclusionFilesExtensionError: false,
				},
				() => {
					if (self.isFileExtensionCorrect(files, "exclusion")) {
						let exclusionFilesSelectedDocumentsTemp = [
							...self.state.exclusionFilesSelectedDocuments,
						];
						let duplicateExclusionFilesTemp = [];
						let duplicateExclusionFilesCount = 0;
						let onlyDuplicateToDB = files.length;
						let { user } = self.props.auth;
						let data = new FormData();
						data.append("campID", self.state.campID);
						data.append("campaignStatus", self.state.campaignStatus);
						for (let i = 0; i < files.length; i++) {
							let file = files[i];
							let found = exclusionFilesSelectedDocumentsTemp.findIndex(
								(item) => item.suppDocName === file.name
							);
							if (found === -1) {
								let obj = {};
								obj.suppDocName = file.name;
								obj.loading = true;
								obj.success = true;
								obj.typeOfSuppDoc = "Exclusion";
								data.append("file", file);
								exclusionFilesSelectedDocumentsTemp.push(obj);
							} else {
								duplicateExclusionFilesCount++;
								duplicateExclusionFilesTemp.push(file.name);
							}
						}
						self.setState(
							{
								exclusionFilesSelectedDocuments:
									exclusionFilesSelectedDocumentsTemp,
								duplicateExclusionFiles: duplicateExclusionFilesTemp,
							},
							() => {
								if (duplicateExclusionFilesCount !== onlyDuplicateToDB) {
									self.uploadExclusionFileToDB(data);
								}
							}
						);
					}
				}
			);
		});
	};
	removeExclusionFileSelectedDocuments = (e, item) => {
		e.preventDefault();
		this.deleteSupportingDocumentFileFromDB(item);
		this.setState(
			{
				exclusionFilesSelectedDocuments:
					this.state.exclusionFilesSelectedDocuments.filter(
						(obj) => obj.suppDocName !== item.suppDocName
					),
				isDataEdited: true,
			},
			() => {
				if (this.state.exclusionFilesSelectedDocuments.length === 0) {
					this.setState({
						exclusionValidHeaderError: false,
						exclusionFilesExtensionError: false,
					});
				}
				this.checkErrorStatus(
					this.state.exclusionFilesSelectedDocuments,
					"exclusion"
				);
			}
		);
	};
	uploadExclusionFileToDB = (data) => {
		fetch("campaign/editSupportingDocumentExclusion", {
			method: "POST",
			body: data,
		})
			.then((res) => res.json())
			.then((res) => {
				let exclusionFilesSelectedDocumentsTemp = [
					...this.state.exclusionFilesSelectedDocuments,
				];
				for (let i = 0; i < res.length; i++) {
					let index = -1;
					if (res[i].success) {
						index = exclusionFilesSelectedDocumentsTemp.findIndex(
							(item) => item.suppDocName === res[i].successList
						);
					} else {
						index = exclusionFilesSelectedDocumentsTemp.findIndex(
							(item) => item.suppDocName === res[i].rejectedFile
						);
						this.setState({
							exclusionValidHeaderError: true,
						});
					}
					let obj = exclusionFilesSelectedDocumentsTemp[index];
					obj.success = res[i].success;
					obj.loading = false;
					exclusionFilesSelectedDocumentsTemp[index] = obj;
				}
				this.setState({
					exclusionFilesSelectedDocuments: exclusionFilesSelectedDocumentsTemp,
					isDataEdited: true,
				});
			})
			.catch((err) => {});
	};
	// end - exclusion files

	/**
	 * @author : Karan Jagtap
	 * @description : all other files related functions
	 */
	// start - other files
	handleChangeMultipleFileOfOther = (e) => {
		let self = this;
		e = document.createElement("input"); //creating a input element but not binding it to body added by Raunak
		e.setAttribute("type", "file"); //setting a attribute for type file added by Raunak
		e.setAttribute("multiple", true); //setting a attribute for multiple added by Raunak
		e.click(); //to open file dialougeadded by Raunak
		e.addEventListener("change", function () {
			const { files } = e;
			if (files.length === 0) {
				return;
			}
			let otherFilesSelectedDocumentsTemp = [
				...self.state.otherFilesSelectedDocuments,
			];
			let duplicateOtherFilesTemp = [];
			let duplicateOtherFilesCount = 0;
			let onlyDuplicateToDB = files.length;
			let { user } = self.props.auth;
			let data = new FormData();
			data.append("campID", self.state.campID);
			data.append("campaignStatus", self.state.campaignStatus);
			for (let i = 0; i < files.length; i++) {
				let file = files[i];
				let found = otherFilesSelectedDocumentsTemp.findIndex(
					(item) => item.suppDocName === file.name
				);
				if (found === -1) {
					let obj = {};
					obj.suppDocName = file.name;
					obj.loading = true;
					obj.success = true;
					obj.typeOfSuppDoc = "Other";
					data.append("file", file);
					otherFilesSelectedDocumentsTemp.push(obj);
				} else {
					duplicateOtherFilesCount++;
					duplicateOtherFilesTemp.push(file.name);
				}
			}
			self.setState(
				{
					otherFilesSelectedDocuments: otherFilesSelectedDocumentsTemp,
					duplicateOtherFiles: duplicateOtherFilesTemp,
				},
				() => {
					if (duplicateOtherFilesCount !== onlyDuplicateToDB) {
						self.uploadOtherFileToDB(data);
					}
				}
			);
		});
	};
	removeOtherFileSelectedDocuments = (e, item) => {
		e.preventDefault();
		this.deleteSupportingDocumentFileFromDB(item);
		this.setState({
			otherFilesSelectedDocuments:
				this.state.otherFilesSelectedDocuments.filter(
					(obj) => obj.suppDocName !== item.suppDocName
				),
			isDataEdited: true,
		});
	};
	uploadOtherFileToDB = (data) => {
		fetch("campaign/editSupportingDocumentOther", {
			method: "POST",
			body: data,
		})
			.then((res) => res.json())
			.then((res) => {
				let otherFilesSelectedDocumentsTemp = [
					...this.state.otherFilesSelectedDocuments,
				];
				for (let i = 0; i < res.length; i++) {
					let index = -1;
					if (res[i].success) {
						index = otherFilesSelectedDocumentsTemp.findIndex(
							(item) => item.suppDocName === res[i].successList
						);
					} else {
						index = otherFilesSelectedDocumentsTemp.findIndex(
							(item) => item.suppDocName === res[i].rejectedFile
						);
						this.setState({
							otherValidHeaderError: true,
						});
					}
					let obj = otherFilesSelectedDocumentsTemp[index];
					obj.success = res[i].success;
					obj.loading = false;
					otherFilesSelectedDocumentsTemp[index] = obj;
				}
				this.setState({
					otherFilesSelectedDocuments: otherFilesSelectedDocumentsTemp,
					isDataEdited: true,
				});
			})
			.catch((err) => {});
	};
	// end - other files

	/**
	 * @author : Karan Jagtap
	 * @description : gievn below
	 */
	// this function handles changes for
	// 1. asset file name
	// 2. lead percentage
	// 3. lead per asset
	// 4. Multitouch
	handleChangeAssetFileName = (e, suppDocName) => {
		const { name, value } = e.target;
		if (name === "assetFileName") {
			let assetFilesSelectedDocumentsTemp = [
				...this.state.assetFilesSelectedDocuments,
			];
			for (let i = 0; i < this.state.assetFilesSelectedDocuments.length; i++) {
				let obj = { ...this.state.assetFilesSelectedDocuments[i] };
				if (obj.suppDocName === suppDocName) {
					obj.assetFileName = value;
					assetFilesSelectedDocumentsTemp[i] = obj;
				}
			}
			this.setState({
				assetFilesSelectedDocuments: assetFilesSelectedDocumentsTemp,
				isDataEdited: true,
			});
		} else if (name === "leadPercentage") {
			let assetLead1 = (value * this.state.totalLeadAllocation) / 100;
			assetLead1 = Math.round(assetLead1);
			let assetFilesSelectedDocumentsTemp = [
				...this.state.assetFilesSelectedDocuments,
			];
			for (let i = 0; i < this.state.assetFilesSelectedDocuments.length; i++) {
				let obj = { ...this.state.assetFilesSelectedDocuments[i] };
				if (obj.suppDocName === suppDocName) {
					obj.leadPercentage = value;
					obj.leadPerAsset = assetLead1;
					assetFilesSelectedDocumentsTemp[i] = obj;
				}
			}
			this.setState({
				assetFilesSelectedDocuments: assetFilesSelectedDocumentsTemp,
				isDataEdited: true,
			});
		} else {
			// this is for multiTouch
			let assetFilesSelectedDocumentsTemp = [
				...this.state.assetFilesSelectedDocuments,
			];
			for (let i = 0; i < this.state.assetFilesSelectedDocuments.length; i++) {
				let obj = { ...this.state.assetFilesSelectedDocuments[i] };
				if (obj.suppDocName === suppDocName) {
					obj.multiTouch = value;
					obj.assetTimestamp_touch = "Yes";
					obj.assetName_touch = value;
					assetFilesSelectedDocumentsTemp[i] = obj;
				}
			}
			this.setState({
				assetFilesSelectedDocuments: assetFilesSelectedDocumentsTemp,
				isDataEdited: true,
			});
		}
	};

	/**
	 * @author : Karan Jagtap
	 * @description : gievn below
	 */
	// start - asset links
	// this function handles changes for
	// 1. link asset file name
	// 2. asset link
	// 3. lead percentage
	// 4. lead per asset
	// 5. Multitouch
	handleAssetLink = (e, i) => {
		const { name, value } = e.target;

		if (name === "linkAssetFileName") {
			let assetLinksTemp = [...this.state.assetLinks];
			let obj = assetLinksTemp[i];
			obj.linkAssetFileName = value;
			assetLinksTemp[i] = obj;
			this.setState({
				assetLinks: assetLinksTemp,
				isDataEdited: true,
			});
		} else if (name === "assetLink") {
			let assetLinksTemp = [...this.state.assetLinks];
			let obj = assetLinksTemp[i];
			obj.assetLink = value;
			assetLinksTemp[i] = obj;
			this.setState({
				assetLinks: assetLinksTemp,
				isDataEdited: true,
			});
		} else if (name === "leadPercentage") {
			let assetLinksTemp = [...this.state.assetLinks];
			let assetLead1 = (value * this.state.totalLeadAllocation) / 100;
			assetLead1 = Math.round(assetLead1);
			let obj = assetLinksTemp[i];
			obj.leadPercentage = value;
			obj.leadPerAsset = assetLead1;
			assetLinksTemp[i] = obj;
			this.setState({
				assetLinks: assetLinksTemp,
				isDataEdited: true,
			});
		} else {
			// this is for multiTouch
			let assetLinksTemp = [...this.state.assetLinks];
			let obj = assetLinksTemp[i];
			obj.multiTouch = value;
			obj.assetTimestamp_touch = "Yes";
			obj.assetName_touch = value;
			assetLinksTemp[i] = obj;
			this.setState({
				assetLinks: assetLinksTemp,
				isDataEdited: true,
			});
		}
	};

	/**
	 * @author : Karan Jagtap
	 * @description : adds a new empty row in asset links table
	 */
	addLink = (e) => {
		let count = this.state.assetLinks.length + 1;
		let obj = {
			linkAssetFileName: "",
			assetLink: "",
			status: "New",
			leadStatus: "",
			count: count,
		};
		if (this.state.multiTouchChk === "Yes") {
			obj.multiTouch = "1st Touch";
			obj.assetTimestamp_touch = "Yes"; // Bug - 3399 - property was missing
			obj.assetName_touch = "1st Touch"; // Bug - 3399 - property was missing
		}
		if (this.state.leadPerAssetChk === "Yes") {
			obj.leadPerAsset = 0;
			obj.leadPercentage = 0;
		}
		this.setState({
			assetLinks: [...this.state.assetLinks, obj],
		});
	};
	/**
	 * @author : Karan Jagtap
	 * @description : removes the asset link
	 */
	removeAssetLink = (i, item) => {
		const { leadStatus, status } = item;
		if (item.status === "Removed") {
		} else if (
			(leadStatus === "Client Accepted" ||
				leadStatus === "Not Accepted" ||
				leadStatus === true) &&
			status !== "New"
		) {
			// removeLink
			let message = "";
			let flag = "No"//Rutuja 5312 declared flag = "Yes"
			if (leadStatus === "Client Accepted") {
				message =
					"Leads are accepted by client, Are you sure you want Delete this Asset?";
			} else if (leadStatus === "Not Accepted") {
				message =
					"Leads are submitted, are you sure you want to delete this Asset ? if deleted then the leads will also be rejected .";
					flag = "Yes"; //Rutuja 5312 set flag = "Yes"
			} else {
				message = "Are you sure you want to remove this asset?";
			}

			Swal.fire({
				title: message,
				type: "question",
				showCancelButton: true,
				cancelButtonColor: "#3085d6",
				confirmButtonColor: "#d33",
				allowOutsideClick: false,
				confirmButtonText: "Yes, Remove it!",
				heightAuto: false,
			}).then((result) => {
				if (result.value) {
					const { user } = this.props.auth;
					let data = {
						supportDocID: item.supportDocID,
						campID: this.state.campID,
						fileName: item.linkAssetFileName, //Rutuja Task 4866 changed linkFileName to linkAssetFileName
						campaignStatus: this.state.campaignStatus,
					}; // karan-task-3719-vapt header and query params
					fetch("campaign/deleteAssetLink", {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(data),
					})
						.then((res) => res.json())
						.then((response) => {
							let assetLinksNew = this.state.assetLinks.filter(
								(obj) => obj.status === "New"
							);
							this.fetchAssetLink(assetLinksNew);
							let message = "This asset Link is no longer used";
							Swal.fire({
								text: message,
								type: "success",
								confirmButtonText: "Ok",
								allowOutsideClick: false,
								heightAuto: false,
							});
							this.setState({ isDataEdited: true });
						});
						//Rutuja -5312 if flag is yes  then set lead status of review leads to rejected
						if(flag ==="Yes"){
							fetch("/campaign/deleteAssetUploadLeads",
							{
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(data),
							})
						}
				}
			});
		} else {
			// removeLinkNew
			if (
				item.supportDocID !== null &&
				item.supportDocID !== undefined &&
				item.supportDocID !== "null" &&
				item.supportDocID !== "undefined" &&
				item.supportDocID !== ""
			) {
				const { user } = this.props.auth;
				let data = {
					supportDocID: item.supportDocID,
					campID: this.state.campID,
					fileName: item.linkAssetFileName, //Rutuja Task 4866 changed linkFileName to linkAssetFileName
					campaignStatus: this.state.campaignStatus,
					user: user,
				};
				fetch("campaign/deleteAssetLink", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((response) => {
						if (item.leadStatus !== false) {
							let assetLinksNew = this.state.assetLinks.filter(
								(obj) => obj.status === "New"
							);
							this.fetchAssetLink(assetLinksNew);
							let message = "This asset Link is no longer used";
							Swal.fire({
								text: message,
								type: "success",
								confirmButtonText: "Ok",
								allowOutsideClick: false,
								heightAuto: false,
							});
							this.setState({ isDataEdited: true });
						} else {
							let assetLinksTemp = [...this.state.assetLinks];
							assetLinksTemp.splice(i, 1);
							this.setState({
								assetLinks: assetLinksTemp,
								isDataEdited: true,
							});
						}
					});
			} else {
				let assetLinksTemp = [...this.state.assetLinks];
				assetLinksTemp.splice(i, 1);
				this.setState({
					assetLinks: assetLinksTemp,
				});
			}
		}
	};
	// end - asset links

	/**
	 * @author : Karan Jagtap
	 * @description : all utility functions here
	 */
	// start - utility functions
	// to check the file extensions of given files array
	isFileExtensionCorrect = (files, from) => {
		let allowedFiles = [".xls", ".xlsx", ".csv"];
		let regex = new RegExp(
			"([a-zA-Z0-9s_\\.()-:])+(" + allowedFiles.join("|") + ")$"
		);
		for (let i = 0; i < files.length; i++) {
			let fileName = files[i].name;
			if (!regex.test(fileName.toLowerCase())) {
				if (from === "abm") {
					this.setState({ abmFilesExtensionError: true });
				} else if (from === "suppression") {
					this.setState({ suppressionFilesExtensionError: true });
				} else if (from === "exclusion") {
					this.setState({ exclusionFilesExtensionError: true });
				}
				return false;
			}
		}
		return true;
	};
	// delete any supporting document file
	deleteSupportingDocumentFileFromDB = (item) => {
		let data = "";
		let apiEndpoint = "";
		if (item.hasOwnProperty("supportDocID")) {
			data = {
				supportDocID: item.supportDocID,
				supportDocType: item.typeOfSuppDoc,//Rutuja Task 4837 changed supportDocType to typeOfSuppDoc 
				campID: this.state.campID,
				names: item.suppDocName,
				typeOfSuppDoc: item.typeOfSuppDoc,//Rutuja Task 4837 changed supportDocType to typeOfSuppDoc 
				campaignStatus: this.state.campaignStatus,
				fromEdit: true,
			}; // karan-task-3719-vapt header and query params
			apiEndpoint = "campaign/deleteSupportingDocFile";
		} else {
			data = {
				campID: this.state.campID,
				names: item.suppDocName,
				typeOfSuppDoc: item.typeOfSuppDoc,
			};
			apiEndpoint = "campaign/supportingDocumentDeleteFile";
		}
		fetch(apiEndpoint, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		});
	};
	// check if any error is already being displayed then save and next should not proceed
	checkErrorStatus = (arr, from) => {
		let found = false;
		for (let i = 0; i < arr.length; i++) {
			let obj = arr[i];
			if (!obj.success) {
				found = true;
				break;
			}
		}
		if (!found) {
			if (from === "abm") {
				this.setState({
					abmValidHeaderError: false,
					abmFilesExtensionError: false,
				});
			} else if (from === "suppression") {
				this.setState({
					suppressionValidHeaderError: false,
					suppressionFilesExtensionError: false,
				});
			} else if (from === "exclusion") {
				this.setState({
					exclusionValidHeaderError: false,
					exclusionFilesExtensionError: false,
				});
			}
		}
	};
	// main validation on step1
	validationStep1 = () => {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;

		let anyError = false;
		let assetFileAndAssetLinkEmpty = false;
		let abmFilesEmpty = false;
		let abmFilesInvalid = false;
		let assetFileFailedPresent = false;
		let assetFileNameEmpty = false;
		let assetLinkAndNameEmpty = false;
		let assetNameEmpty = false;
		let assetLinkEmpty = false;
		let leadPercentageEmpty = false;
		let leadPercentageNaN = false;
		let leadPercentageTotal = false;
		let addMoreAsset = false;
		let assetSameTouch = false;
		let asset3rdTouch = false;
		let asset3rdTouchOnly = false;
		let duplicateAssetFileName = false; // 3126-karan-duplicate asset name
		let excludeTextValidate = false; //snehal-task3620-Add exclude domains
		let validAssetFileName = false ;//kiran-4686-added for asset link name

		// Bug - 3404 - Karan Jagtap - all removed files validation issue
		let assetFilesSelectedDocumentsTemp =
			this.state.assetFilesSelectedDocuments.filter(
				(obj) => obj.status !== "Removed"
			);
		// Bug - 3404 - Karan Jagtap - all removed files validation issue
		let assetLinksTemp = this.state.assetLinks.filter(
			(obj) => obj.status !== "Removed"
		);

		//1. Either asset file or link should be uploaded
		if (
			assetFilesSelectedDocumentsTemp.length === 0 &&
			assetLinksTemp.length === 0
		) {
			anyError = true;
			assetFileAndAssetLinkEmpty = true;
		}

		// if campaign type is ABM
		if (this.state.campaignType == "ABM") {
			// 2. abm is empty - no file is uploaded
			if (this.state.abmFilesSelectedDocuments.length === 0) {
				abmFilesEmpty = true;
				anyError = true;
			}

			// 3. abm success files are empty - only files with error are present
			let abms = this.state.abmFilesSelectedDocuments.filter(
				(obj) => obj.success === false
			);
			if (abms.length > 0) {
				abmFilesInvalid = true;
				anyError = true;
			}
		}

		// 4. asset files validation
		let assets = assetFilesSelectedDocumentsTemp.filter(
			(obj) => obj.success === false
		);
		if (assets.length > 0) {
			assetFileFailedPresent = true;
			anyError = true;
		}

		// 5. Asset File name Validation
		for (let i = 0; i < assetFilesSelectedDocumentsTemp.length; i++) {
			let obj = assetFilesSelectedDocumentsTemp[i];
			if (
				obj.assetFileName === "" ||
				obj.assetFileName === undefined ||
				obj.assetFileName === null
			) {
				assetFileNameEmpty = true;
				anyError = true;
				break;
			}
		}

		// 6. asset link name and link value validation
		// 7. asset link name validation
		// 8. asset link value validation
		for (let i = 0; i < assetLinksTemp.length; i++) {
			let obj = assetLinksTemp[i];
			if (
				(obj.linkAssetFileName === "" ||
					obj.linkAssetFileName === null ||
					obj.linkAssetFileName === undefined) &&
				(obj.assetLink === "" ||
					obj.assetLink === null ||
					obj.assetLink === undefined)
			) {
				assetLinkAndNameEmpty = true;
				anyError = true;
				break;
			} else if (
				obj.linkAssetFileName === "" ||
				obj.linkAssetFileName === null ||
				obj.linkAssetFileName === undefined
			) {
				assetNameEmpty = true;
				anyError = true;
				break;
			} else if (
				obj.assetLink === "" ||
				obj.assetLink === null ||
				obj.assetLink === undefined
			) {
				assetLinkEmpty = true;
				anyError = true;
				break;
			}
		}

		// if leadperasset checkbox is checked
		if (this.state.leadPerAssetChk === "Yes") {
			let arr = [...assetFilesSelectedDocumentsTemp, ...assetLinksTemp];
			let totalLeadPercentage = 0;
			for (let i = 0; i < arr.length; i++) {
				let obj = arr[i];
				if (obj.status !== "Removed") {
					// 7. if no input is given
					if (
						obj.leadPercentage === "" ||
						obj.leadPercentage === undefined ||
						obj.leadPercentage === null
					) {
						leadPercentageEmpty = true;
						anyError = true;
					} else if (
						isNaN(obj.leadPercentage) == false &&
						parseFloat(obj.leadPercentage) == parseInt(obj.leadPercentage)
					) {
						totalLeadPercentage += parseInt(obj.leadPercentage);
					}
					// 8. if non numeric value is given
					else {
						leadPercentageNaN = true;
						anyError = true;
					}
				}
			}
			// 9. if lead percentage is not equal to 100
			if (totalLeadPercentage !== 100) {
				leadPercentageTotal = true;
				anyError = true;
			}
		}

		// if mutitouch is checked
		if (this.state.multiTouchChk === "Yes") {
			let commonTouchArray = [];
			let multiTouch1stCount = 0;
			let multiTouch2ndCount = 0;
			let multiTouch3rdCount = 0;
			//following the top-down approach
			for (let i = 0; i < assetFilesSelectedDocumentsTemp.length; i++) {
				let obj = assetFilesSelectedDocumentsTemp[i];
				commonTouchArray.push(obj.multiTouch);
				if (obj.multiTouch == "1st Touch") {
					multiTouch1stCount += 1;
				}
				if (obj.multiTouch == "2nd Touch") {
					multiTouch2ndCount += 1;
				}
				if (obj.multiTouch == "3rd Touch") {
					multiTouch3rdCount += 1;
				}
			}

			for (let i = 0; i < assetLinksTemp.length; i++) {
				let obj = assetLinksTemp[i];
				commonTouchArray.push(obj.multiTouch);
				if (obj.multiTouch == "1st Touch") {
					multiTouch1stCount += 1;
				}
				if (obj.multiTouch == "2nd Touch") {
					multiTouch2ndCount += 1;
				}
				if (obj.multiTouch == "3rd Touch") {
					multiTouch3rdCount += 1;
				}
			}

			if (commonTouchArray.length == 1) {
				// 10. add more assets
				addMoreAsset = true;
				anyError = true;
			} else if (commonTouchArray.length == 2) {
				if (
					commonTouchArray.includes("1st Touch") &&
					commonTouchArray.includes("2nd Touch")
				) {
				} else {
					// 11. all assets of same touch found
					assetSameTouch = true;
					if (commonTouchArray.includes("3rd Touch")) {
						assetSameTouch = false;
						// 12. assets of 3rd touch which are not enough
						asset3rdTouch = true;
					}
					anyError = true;
				}
			} else if (commonTouchArray.length >= 3) {
				if (
					multiTouch3rdCount === commonTouchArray.length ||
					multiTouch1stCount === commonTouchArray.length ||
					multiTouch2ndCount === commonTouchArray.length
				) {
					// 13. all assets of same touch found
					assetSameTouch = true;
					anyError = true;
				} else {
					if (
						!commonTouchArray.includes("1st Touch") ||
						!commonTouchArray.includes("2nd Touch")
					) {
						// 14. all assets of same 3rd touch found
						asset3rdTouchOnly = true;
						anyError = true;
					}
				}
			} else if (commonTouchArray.length === 0) {
				assetSameTouch = false;
				asset3rdTouchOnly = false;
				asset3rdTouch = false;
			}
		}

		// 3126 -karan - duplicate asset name not allowed - start
		// 15. if asset files or asset links have any duplicate names
		let duplicateCheckFlag = false;
		let validAssetNameFlag= false //kiran-4686-added flag
		// for asset files
		let temp=this.state.assetFilesSelectedDocuments;
		const result=temp.filter((el)=>{
			return el.status!=="Removed";
		})
		//kiran-4742-declared temp and added filter
		for (let i = 0; i < result.length; i++) {
			let obj = result[i];
			if (
				obj.assetFileName !== null &&
				obj.assetFileName !== undefined &&
				obj.assetFileName !== "" &&
				obj.assetFileName !== "undefined" &&
				obj.assetFileName !== "null"
			) {
				let changedName = obj.assetFileName;
				for (
					let j = 0;
					j < result.length;
					j++
				) {
					if (j !== i) {
						let obj2 = result[j];
						if (obj2.suppDocName === changedName) {
							duplicateCheckFlag = true;
							break;
						}
						if (obj2.assetFileName === changedName) {
							duplicateCheckFlag = true;
							break;
						}
						if (obj2.dataFileID === changedName) {
							duplicateCheckFlag = true;
							break;
						}
					}
				}
			}
		}
		// for asset links
		let tempLinks=this.state.assetLinks;
		const result1=tempLinks.filter((el)=>{
			return el.status!=="Removed"
		});
		//kiran-4742-declared tempLinks for assetlinks and added filter
		for (let i = 0; i < result1.length; i++) {
			let obj = result1[i];
			if (
				obj.linkAssetFileName !== null &&
				obj.linkAssetFileName !== undefined &&
				obj.linkAssetFileName !== "" &&
				obj.linkAssetFileName !== "undefined" &&
				obj.linkAssetFileName !== "null"
			) {
				let changedName = obj.linkAssetFileName;
				for (let j = 0; j < result1.length; j++) {
					if (j !== i) {
						let obj2 = result1[j];
						if (obj2.linkAssetFileName === changedName) {
							duplicateCheckFlag = true;
							break;
						}
					}
					//kiran-4686-added regex for validating Asset link name
					//kiran-4715-changed regex -validation is only for link
					   var urlRegex = new RegExp(
						// /^(?=[^\S\s]*[@#%^]+$)|(?=^\d+$)|(?=.*[@^*()!$%#<>?\\~;])(?=[a-zA-Z0-9 ])|(?=.*\.(com|co|org|net|gov|edu|mil|int|us|in|info|me|io|uk)$)|(?=.*(\/)$)|(ftp|http|https|www|smtp|ssh|telnet|dns|sip|nntp):\/\/[^ "]+$/
						/^(?=.*\.(com|co|org|net|gov|edu|mil|int|us|in|info|me|io|uk)$)|(?=.*(\/)$)|(ftp|http|https|www|smtp|ssh|telnet|dns|sip|nntp):\/\/[^ "]+$/
						);

						//Chaitanya-4762-added RegExp for validating Asset link name
						var urlRegex2 =new RegExp(  /^[!@#$%^&*()_ +{}\[\]:;<>,.?/~`|\-=\\'"!]+$/ );
						
					if (!urlRegex.test(changedName) && !urlRegex2.test(changedName)) {
					}
					else{
						validAssetNameFlag = true;
						break;
					}
				}
			}
		}
		if (duplicateCheckFlag) {
			duplicateAssetFileName = true;
			anyError = true;
		}
		//kiran-4686-check condition if validAssetNameFlag true 
		if(validAssetNameFlag){
			validAssetFileName =true;
			anyError = true;
		}
		// 3126 -karan - duplicate asset name not allowed - end
		//snehal-task3620-Add exclude domains(validations for exclude doamin)
		if (this.state.excludeText.length > 0) {
			var excludeText = this.state.excludeText;
			var excludeText_array = excludeText.split(/[\n | ,]+/);
			let excludeTextArray = [];
			for (var i = 0; i < excludeText_array.length; i++) {
				excludeTextArray.push({
					id: excludeText_array[i],
					name: excludeText_array[i],
				});
				var temp_array = excludeText_array[i];
				var pattern = new RegExp(/^[0-9a-zA-Z.@, |\n]*$/);
				var pattern1 = new RegExp(/^(|[0-9]\d*)$/);
				if (!pattern.test(temp_array) || pattern1.test(temp_array)) {
					excludeTextValidate = true;
					anyError = true;
				} else {
					excludeTextValidate = false;
					anyError = false;
				}
			}
		}
		this.setState({
			showErrorBlock: anyError,
			assetFileAndAssetLinkEmpty,
			abmFilesEmpty,
			abmFilesEmpty,
			abmFilesInvalid,
			assetFileFailedPresent,
			assetFileNameEmpty,
			assetLinkAndNameEmpty,
			assetNameEmpty,
			assetLinkEmpty,
			leadPercentageEmpty,
			leadPercentageNaN,
			leadPercentageTotal,
			addMoreAsset,
			assetSameTouch,
			asset3rdTouch,
			asset3rdTouchOnly,
			duplicateAssetFileName,
			excludeTextValidate,
			validAssetFileName, //kiran-4686-added to setState
		});
		//snehal-task3620-Add exclude domains(exclude domain)
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;

		// 15. check for any valid header errors
		if (
			this.state.abmValidHeaderError ||
			this.state.suppressionValidHeaderError ||
			this.state.exclusionValidHeaderError
		) {
			if (!anyError) {
				return false;
			}
		}

		// return true is validation successful
		return !anyError;
	};
	// just an alert to display message to user
	showSwalErrorMessage = () => {
		Swal.fire({
			text: "Error Occured Please Try Again",
			type: "error",
			heightAuto: false,
		});
	};
	// end - utility functions
	
	//Aman task-4765 added handle check for City/State Validation 
	handleCheckedCityStateValidation = (e) => {
		const { name, checked } = e.target;
		
		let editDeliveryFormat = { ...this.state.editDeliveryFormat };
		if (checked === true) {
			editDeliveryFormat = { ...editDeliveryFormat, [name]: "ON" };
		} else {
			editDeliveryFormat = { ...editDeliveryFormat, [name]: "OFF" };
		}
		this.setState({ editDeliveryFormat })
	};

	
	/**
   * @author Nilesh Zinzurao - 5355
   * @param  Description fetch column sequence check value
   * @return Description set column sequence check value
   */
	checkColumnSequence(campID){
		let data = {campID: campID};
		fetch("/columnMapping/getColumnSequenceCheck", {
		  method: "POST",
		  headers: { "Content-Type": "application/json" },
		  body: JSON.stringify(data),
		})
		  .then((res) => res.json())
		  .then((existingColumnsSequence) => {
			if (existingColumnsSequence && existingColumnsSequence.length > 0 && existingColumnsSequence[0].columnSequenceCheck) {
				if (existingColumnsSequence[0].columnSequenceCheck.toString() === 'Yes') {
				  this.setState({
					columnSequenceCheck: existingColumnsSequence[0].columnSequenceCheck
				  });
				}
			  } 
		});
	  }

	render() {
		return (
			<div style={{ paddingLeft: 0, paddingRight: 0 }}>
				{/* Start - Loader Component */}
				{/* Bug - 3405 - Karan Jagtap - removed default loader and implemented custom loader */}
				<Loader
					loadingRef={this.loadingRef}
					closeLoadingRef={this.closeLoadingRef}
					message="Please wait. Saving the data...."
				/>
				{/* End - Loader Component */}

				{/* Start - Navigation */}
				<Navigation />
				{/* End - Navigation */}

				{/* Start - Main page heading */}
				<div className="main-heading">
					<label className="main-heading-title">
						Step 2 : Supporting Documents(Campaign ID:{this.state.campID})
						{/* // karan-task-3933-vapt header and query params */}
					</label>
				</div>
				{/* End - Main page heading */}

				{/* Start - Steps */}
				<div className="underline">
					<Steps
						type="navigation"
						current={this.state.currentStep}
						onChange={this.onHandleStepChangeFromStepper}>
						{StepTabs.map((item, index) => (
							<Steps.Step
								title={item.title}
								icon={item.icon}
								status={
									index === this.state.currentStep
										? "process"
										: index > this.state.currentStep
										? "wait"
										: "finish"
								}
							/>
						))}
					</Steps>
				</div>
				{/* End - Steps */}

				<div className="row">
					<div className="col">
						{/* Start - Step 1 - Supporting Documents */}
						<Card
							className="card-addon"
							bordered={false}
							style={{ display: this.state.displayStep1 }}>
							{/* Start - Navigation */}
							<div className="card-navigation">
								<a style={{ color: "#056eb8" }}>
									<FaArrowAltCircleLeft
										size={25}
										style={{
											float: "left",
											marginRight: "5px",
											marginBottom: "5px",
										}}
										onClick={this.handleBackButton}
									/>
								</a>
								<button
									disabled={this.state.submitStep1ButtonDisabled}
									className="btn-save-and-next"
									onClick={this.submitStep1}>
									Save and Next
								</button>
							</div>
							{/* End - Navigation */}

							{/* Start - Error Block */}
							{this.state.showErrorBlock && (
								<div
									class="row row-margin-fix mt-3"
									style={{ border: "1px solid red" }}>
									<div
										id="error-list"
										className="errorMessage"
										style={{
											padding: "8px 15px",
										}}>
										{this.state.assetFileAndAssetLinkEmpty && (
											<li>
												<b>Please upload either asset file or asset link</b>
											</li>
										)}
										{this.state.abmFilesEmpty && (
											<li>
												<b>Please upload ABM file</b>
											</li>
										)}
										{this.state.abmFilesInvalid && (
											<li>
												<b>Please upload valid ABM file</b>
											</li>
										)}
										{this.state.assetFileFailedPresent && (
											<li>
												<b>
													There are few asset upload failed please remove failed
													asset or upload the missing asset
												</b>
											</li>
										)}
										{this.state.assetFileNameEmpty && (
											<li>
												<b>Please enter asset name for uploaded asset file</b>
											</li>
										)}
										{this.state.assetLinkAndNameEmpty && (
											<li>
												<b>Please enter asset link and asset link name</b>
											</li>
										)}
										{this.state.assetNameEmpty && (
											<li>
												<b>
													Please enter asset link name for uploaded asset link
												</b>
											</li>
										)}
										{this.state.assetLinkEmpty && (
											<li>
												<b>Please enter asset link for uploaded asset name</b>
											</li>
										)}
										{this.state.leadPercentageEmpty && (
											<li>
												<b>
													Please enter lead percentage for individual uploaded
													asset
												</b>
											</li>
										)}
										{this.state.leadPercentageNaN && (
											<li>
												<b>
													Please enter only numerical (0-9) values for lead
													percentage
												</b>
											</li>
										)}
										{this.state.leadPercentageTotal && (
											<li>
												<b>
													Leads percentage per asset can't be greater or less
													than hundred percentage
												</b>
											</li>
										)}
										{this.state.addMoreAsset && (
											<li>
												<b>Please add more assets</b>
											</li>
										)}
										{this.state.assetSameTouch && (
											<li>
												<b>
													Please change multi-touch options as all assets can't
													be of same touch
												</b>
											</li>
										)}
										{this.state.asset3rdTouch && (
											<li>
												<b>Assets are not enough for 3rd touch</b>
											</li>
										)}
										{this.state.asset3rdTouchOnly && (
											<li>
												<b>Either of 1st touch or 2nd touch not present</b>
											</li>
										)}
										{/* 3126-karan-duplicate asset name error */}
										{this.state.duplicateAssetFileName && (
											<li>
												<b>Please enter unique asset name</b>
											</li>
										)}
										{/*kiran- 4686 showing valid asset link name error */}
                                        {this.state.validAssetFileName && (
											<li>
												<b>Please enter valid asset link name</b>
											</li>
										)}
										{this.state.excludeTextValidate && (
											<li>
												<b>Please enter valid exclude domain </b>
											</li>
										)}
										{/* //snehal-task3620-Add exclude domains(for exclude domain) */}
									</div>
								</div>
							)}
							{/* End - Error Block */}

							{/* Start - Upload Supporting Asset */}
							{/* Start - Heading - Upload Supporting Documents */}
							<div className="row sub-content">
								<div className="col-sm-12 col-md-5 pl-0">
									<div className="sub-content-heading">
										<label className="sub-content-title">
											Upload Supporting Asset
										</label>
										<label className="asterisk">*</label>
									</div>
								</div>
								<div className="col-sm-12 col-md-7 align-self-center mt-2 mt-md-0 px-0">
									<div className="float-md-right">
										<Checkbox
											id="multitouchChk"
											name="multitouchChk"
											checked={
												this.state.multiTouchChk === "Yes" ? true : false
											}
											value={this.state.multiTouchChk}
											onChange={this.handleMultiTouchCheckbox}>
											Multitouch Required
										</Checkbox>
										<Checkbox
											id="leadPerAssetChk"
											name="leadPerAssetChk"
											checked={
												this.state.leadPerAssetChk === "Yes" ? true : false
											}
											value={this.state.leadPerAssetChk}
											onChange={this.handleLeadPerAssetCheckbox}>
											Asset wise Lead Required
										</Checkbox>
									</div>
								</div>
							</div>
							{/* End - Heading - Upload Supporting Documents */}

							{/* Start - Heading - Asstes */}
							<div className="row mt-3 mb-2 row-margin-fix">
								<div className="col pl-0">
									<label className="sub-content-subtitle">Assets :</label>
								</div>
								<div className="col pr-0">
									<label className="float-right sub-content-subtitle">
										Total Leads : {this.state.totalLeadAllocation}
									</label>
								</div>
							</div>
							{/* End - Heading - Asstes */}

							{/* Start - Asset Drag and drop */}
							<div className="row row-margin-fix">
								<div className="col px-0">
									<div className="drag-and-drop-container">
										<div className="d-flex flex-column justify-content-center p-5 drag-and-drop-ui ">
											<img
												style={{ height: 35, width: 35 }}
												src="upload_file.png"
											/>
											<label className="files-message">
												Drag and drop or browse files to upload
											</label>
											<button className="btn-save-and-next btn-small mx-auto">
												Choose Files
											</button>
										</div>
										<input
											className="d-flex flex-column justify-content-center p-5 drag-and-drop-files "
											type="file"
											multiple
											required
											onChange={this.handleChangeMultipleFile}
										/>
									</div>
									<div id="noassetList" style={{ display: "none" }}>
										0 Asset Files Selected
									</div>
								</div>
							</div>
							{/* End - Asset Drag and drop */}

							{/* Start - Uploaded Assets Files Table */}
							<div className="row row-margin-fix">
								<div
									className="col"
									style={{ display: this.state.displayAssetFilesTable }}>
									<div className="row">
										<div className="col px-0">
											<div
												className="table-responsive mt-3"
												style={{
													overflowY: "auto",
													maxHeight: 400,
												}}>
												<table className="table table-bordered">
													<thead style={{ backgroundColor: "#F5F7F8" }}>
														<tr>
															<th scope="col">Selected Files</th>
															<th scope="col">Asset Name</th>
															{this.state.leadPerAssetChk === "Yes" && (
																<th scope="col">Lead %</th>
															)}
															{this.state.leadPerAssetChk === "Yes" && (
																<th scope="col">Lead / Asset</th>
															)}
															{this.state.multiTouchChk === "Yes" && (
																<th scope="col">Multitouch</th>
															)}
														</tr>
													</thead>
													<tbody>
														{this.state.assetFilesSelectedDocuments.length >
															0 &&
															this.state.assetFilesSelectedDocuments.map(
																(item, i) => {
																	return (
																		<tr>
																			<td style={{ verticalAlign: "middle" }}>
																				<div
																					className="row align-items-center"
																					style={{
																						marginLeft: 10,
																						marginRight: 10,
																						marginTop: 5,
																						marginBottom: 5,
																						backgroundColor: item.success
																							? "#DAECDA"
																							: "#FFEEF0",
																						paddingLeft: 12,
																						paddingTop: 12,
																						paddingBottom: 12,
																						paddingRight: 12,
																						borderRadius: 2,
																					}}>
																					<div
																						style={{ padding: 0, margin: 0 }}
																						className="col-xs-2 col-md-auto">
																						{item.loading ? (
																							<CircularProgress
																								size={26}
																								style={{
																									height: 26,
																									width: 26,
																									display: "flex",
																									margin: "auto",
																								}}
																							/>
																						) : (
																							<img
																								style={{
																									height: 26,
																									width: 26,
																								}}
																								src={
																									item.success
																										? "success.png"
																										: "failed.png"
																								}
																							/>
																						)}
																					</div>
																					<div
																						style={{ padding: 0, margin: 0 }}
																						className="col overflow-hidden">
																						<label
																							id="fileName"
																							className="mb-0 ml-2 overflow-hidden align-middle"
																							style={{
																								fontWeight: "normal",
																								fontSize: 13,
																								minWidth: "150px",
																								textDecoration:
																									item.status === "Removed"
																										? "line-through red"
																										: "none",
																							}}>
																							{item.dataFileID ||
																								item.suppDocName}
																						</label>
																					</div>
																					<div
																						style={{ padding: 0, margin: 0 }}
																						className="col-xs-2 col-md-auto">
																						<a
																							supportDocID={item.supportDocID}
																							data-fileid={
																								item.dataFileID ||
																								item.suppDocName
																							}
																							style={{
																								float: "right",
																								fontFamily: "roboto",
																								fontSize: "12px",
																								fontWeight: "500",
																							}}
																							// Bug - 3395 - Karan Jagtap - added disable functionality while file uploading
																							disabled={
																								item.status === "Removed" ||
																								item.loading
																									? true
																									: false
																							}
																							onClick={(e) => {
																								this.removeAssetFileSelectedDocuments(
																									e,
																									item
																								);
																							}}
																							className="mb-0 mx-2">
																							<img
																								style={{
																									height: 18,
																									width: 18,
																								}}
																								src="file_upload_2_blue.png" // User Story - 3427 - Karan Jagtap - changed remove icon
																							/>
																						</a>
																					</div>
																				</div>
																			</td>
																			<td style={{ verticalAlign: "middle" }}>
																				<span
																					key={
																						item.suppDocName || item.dataFileID
																					}>
																					<input
																						value={item.assetFileName} // Bug - 3401 - Karan Jagtap - Asset file name was not changing
																						defaultValue={
																							item.assetFileName ||
																							item.suppDocName ||
																							item.dataFileID
																						}
																						disabled={
																							item.status === "Removed"
																								? true
																								: false
																						}
																						onChange={(e) =>
																							this.handleChangeAssetFileName(
																								e,
																								item.suppDocName ||
																									item.dataFileID
																							)
																						}
																						type="text"
																						//id={this.state.inputType[i].key}
																						class="form-control"
																						style={{
																							minWidth: "150px",
																							textDecoration:
																								item.status === "Removed"
																									? "line-through red"
																									: "none",
																						}}
																						placeholder="Asset Name"
																						name="assetFileName"
																						required
																					/>
																				</span>
																			</td>
																			{this.state.leadPerAssetChk === "Yes" && (
																				<td style={{ verticalAlign: "middle" }}>
																					<span
																						key={
																							item.suppDocName ||
																							item.dataFileID
																						}>
																						<input
																							value={item.leadPercentage}
																							placeholder="Lead %"
																							disabled={
																								item.status === "Removed"
																									? true
																									: false
																							}
																							onChange={(e) =>
																								this.handleChangeAssetFileName(
																									e,
																									item.suppDocName ||
																										item.dataFileID
																								)
																							}
																							type="text"
																							//id={this.state.inputType[i].key}
																							class="form-control"
																							name="leadPercentage"
																							style={{
																								textDecoration:
																									item.status === "Removed"
																										? "line-through red"
																										: "none",
																							}}
																							required
																						/>
																					</span>
																				</td>
																			)}
																			{this.state.leadPerAssetChk === "Yes" && (
																				<td
																					style={{
																						verticalAlign: "middle",
																					}}>
																					<span
																						key={
																							item.suppDocName ||
																							item.dataFileID
																						}>
																						<input
																							value={item.leadPerAsset}
																							defaultValue={item.leadPerAsset}
																							type="text"
																							//id={this.state.inputType[i].key}
																							class="form-control"
																							name="leadPerAsset"
																							style={{
																								borderRadius: "1px",
																								margin: "1.5px",
																								height: 42,
																								backgroundColor: "#F5F5F5",
																								textDecoration:
																									item.status === "Removed"
																										? "line-through red"
																										: "none",
																							}}
																							required
																							disabled
																						/>
																					</span>
																				</td>
																			)}
																			{this.state.multiTouchChk === "Yes" && (
																				<td
																					style={{
																						verticalAlign: "middle",
																					}}>
																					<select
																						//id={this.state.inputType[i].key}
																						value={item.multiTouch}
																						name="multiTouch"
																						disabled={
																							item.status === "Removed"
																								? true
																								: false
																						}
																						onChange={(e) =>
																							this.handleChangeAssetFileName(
																								e,
																								item.suppDocName ||
																									item.dataFileID
																							)
																						}
																						style={{
																							borderRadius: "0.5px",
																							width: "100%",
																							border: "0.5px solid #d9d9d9",
																							paddingTop: 5,
																							paddingBottom: 5,
																							paddingLeft: 3,
																							paddingRight: 3,
																							minWidth: "90px",
																							textDecoration:
																								item.status === "Removed"
																									? "line-through red"
																									: "none",
																						}}>
																						<option
																							value="1st Touch"
																							selected="selected">
																							1st Touch
																						</option>
																						<option value="2nd Touch">
																							2nd Touch
																						</option>
																						<option value="3rd Touch">
																							3rd Touch
																						</option>
																					</select>
																				</td>
																			)}
																		</tr>
																	);
																}
															)}
														<span
															id="assetList"
															style={{ display: "none" }}></span>
													</tbody>
												</table>
											</div>
										</div>
									</div>
									{this.state.duplicateAssetFiles.length > 0 &&
										this.state.duplicateAssetFiles.map((item) => (
											<div style={{ color: "red" }}>
												Please select file with another name. {item} already
												exists.
											</div>
										))}
									{/* Start - Message - Total Files  */}
									<div className="row mt-3">
										<div className="col-md-auto pl-0">
											<label className="total-uploaded-message">
												Total Uploaded Asset Files :{" "}
												{this.state.assetFilesUploadedSuccessCount +
													this.state.assetFilesUploadedFailedCount}
											</label>
										</div>
										<div className="col pl-0">
											<label style={{ fontWeight: "normal" }}>
												(&nbsp;
												<span style={{ color: "green" }}>
													Successfully Uploaded :{" "}
													{this.state.assetFilesUploadedSuccessCount}
												</span>
												,&nbsp;
												<span style={{ color: "red" }}>
													Upload Failed :{" "}
													{this.state.assetFilesUploadedFailedCount}
												</span>
												&nbsp;)
											</label>
										</div>
									</div>
									{/* End - Message - Total Files  */}
								</div>
							</div>
							{/* End - Uploaded Assets Files Table */}

							{/* Start - Divider */}
							<hr
								style={{
									height: 2,
									backgroundColor: "#f5f5f5",
									marginLeft: 15,
									marginRight: 15,
									borderWidth: 0,
								}}
							/>
							{/* End - Divider */}

							{/* Start - Assets Link */}
							<div className="row row-margin-fix mt-3 mb-2">
								<div className="d-flex">
									<img
										style={{
											height: 20,
											width: 20,
											marginRight: 10,
											alignSelf: "center",
										}}
										src="attached.png"
									/>
									<label className="sub-content-subtitle">
										Assets Links :&nbsp;
									</label>
									<a
										style={{ alignSelf: "center" }}
										data-toggle="tooltip"
										onClick={(e) => this.addLink(e)}
										title="Add one more link">
										<img style={{ height: 20, width: 20 }} src="add.png" />
									</a>
								</div>
							</div>
							{/* End - Assets Link */}

							{/* Start - Uploaded Assets Links Table */}
							<div
								className="row"
								style={{
									paddingLeft: 30,
									paddingRight: 30,
								}}>
								<div className="col px-0">
									<div
										className="table-responsive mt-3"
										style={{
											overflowY: "auto",
											maxHeight: 200,
										}}>
										<table className="table table-bordered">
											<thead style={{ backgroundColor: "#F5F7F8" }}>
												<tr>
													{this.state.leadPerAssetChk === "Yes" &&
													this.state.multiTouchChk === "No" ? (
														<th scope="col">
															<div>Asset Link Name</div>
														</th>
													) : this.state.multiTouchChk === "Yes" &&
													  this.state.leadPerAssetChk === "No" ? (
														<th scope="col">
															<div>Asset Link Name</div>
														</th>
													) : this.state.multiTouchChk === "Yes" &&
													  this.state.leadPerAssetChk === "Yes" ? (
														<th scope="col">
															<div>Asset Link Name </div>
														</th>
													) : (
														// no one is checked
														<th scope="col">
															<div>Asset Link Name</div>
														</th>
													)}
													{/* end of Selected Files */}

													{this.state.leadPerAssetChk === "Yes" &&
													this.state.multiTouchChk === "No" ? (
														<th scope="col">
															<div>Asset Link</div>
														</th>
													) : this.state.multiTouchChk === "Yes" &&
													  this.state.leadPerAssetChk === "No" ? (
														<th scope="col">
															<div>Asset Link</div>
														</th>
													) : this.state.multiTouchChk === "Yes" &&
													  this.state.leadPerAssetChk === "Yes" ? (
														<th scope="col">
															<div>Asset Link</div>{" "}
														</th>
													) : (
														//no one is checked
														<th scope="col">
															<div>Asset Link</div>{" "}
														</th>
													)}

													{/* end of Asset Name */}

													{this.state.leadPerAssetChk === "Yes" &&
													this.state.multiTouchChk === "No" ? (
														<th scope="col">
															<div>Lead %</div>
														</th>
													) : this.state.multiTouchChk === "Yes" &&
													  this.state.leadPerAssetChk === "Yes" ? (
														<th scope="col">
															<div>Lead %</div>
														</th>
													) : (
														""
													)}
													{/* end of Lead % */}

													{this.state.leadPerAssetChk === "Yes" &&
													this.state.multiTouchChk === "No" ? (
														<th scope="col">
															<div>Lead/Asset</div>
														</th>
													) : this.state.multiTouchChk === "Yes" &&
													  this.state.leadPerAssetChk === "Yes" ? (
														<th scope="col">
															<div>Lead/Asset</div>
														</th>
													) : (
														""
													)}

													{/* end of Lead/Asset */}

													{this.state.multiTouchChk === "Yes" &&
													this.state.leadPerAssetChk === "No" ? (
														<th scope="col">
															<div>Multitouch</div>
														</th>
													) : this.state.multiTouchChk === "Yes" &&
													  this.state.leadPerAssetChk === "Yes" ? (
														<th scope="col">
															<div>Multitouch</div>
														</th>
													) : (
														""
													)}
													{/* end of Multitouch */}

													{this.state.leadPerAssetChk === "Yes" &&
													this.state.multiTouchChk === "No" ? (
														<th scope="col">
															<div>Remove</div>
														</th>
													) : this.state.multiTouchChk === "Yes" &&
													  this.state.leadPerAssetChk === "No" ? (
														<th scope="col">
															<div>Remove</div>
														</th>
													) : this.state.multiTouchChk === "Yes" &&
													  this.state.leadPerAssetChk === "Yes" ? (
														<th scope="col">
															<div>Remove</div>
														</th>
													) : (
														//no one is clicked
														<th scope="col">
															<div>Remove</div>
														</th>
													)}
												</tr>
											</thead>
											<tbody>
												{this.state.assetLinks.map((assetLink, i) => {
													return (
														<tr>
															{/* START - Column 1 - Asset Link File Name */}
															<td style={{ verticalAlign: "middle" }}>
																<div
																// key={assetLink.count}
																>
																	<Input
																		id="linkAssetFileName"
																		name="linkAssetFileName"
																		className={{}}
																		placeholder="Enter Asset Link Name"
																		disabled={
																			assetLink.status === "Removed"
																				? true
																				: false
																		}
																		onChange={(e) => {
																			this.handleAssetLink(e, i);
																		}}
																		style={{
																			//width: "180px",
																			borderRadius: "1px",
																			minWidth: "150px",
																			textDecoration:
																				assetLink.status === "Removed"
																					? "line-through red"
																					: "none",
																		}}
																		value={assetLink.linkAssetFileName}
																	/>
																</div>
															</td>
															{/* END - Column 1 - Asset Link File Name */}

															{/* START - Column 2 - Asset Link */}
															<td style={{ verticalAlign: "middle" }}>
																<div
																	// key={assetLink.count}
																	style={{
																		textAlign: "center",
																	}}>
																	<Input
																		id="assetLink"
																		name="assetLink"
																		placeholder="Enter Asset Link"
																		disabled={
																			assetLink.status === "Removed"
																				? true
																				: false
																		}
																		onChange={(e) => {
																			this.handleAssetLink(e, i);
																		}}
																		style={{
																			//width: "520px",
																			borderRadius: "1px",
																			minWidth: "150px",
																			textDecoration:
																				assetLink.status === "Removed"
																					? "line-through red"
																					: "none",
																		}}
																		value={assetLink.assetLink}
																	/>
																</div>
															</td>
															{/* END - Column 2 - Asset Link */}

															{/* START - Column 3 - Lead % */}
															{this.state.leadPerAssetChk === "Yes" && (
																<td style={{ verticalAlign: "middle" }}>
																	<div
																	// key={assetLink.count}
																	>
																		<Input
																			id="leadPercentage"
																			name="leadPercentage"
																			value={assetLink.leadPercentage}
																			placeholder="Enter Lead %"
																			disabled={
																				assetLink.status === "Removed"
																					? true
																					: false
																			}
																			onChange={(e) => {
																				this.handleAssetLink(e, i);
																			}}
																			style={{
																				//width: "180px",
																				borderRadius: "1px",
																				textDecoration:
																					assetLink.status === "Removed"
																						? "line-through red"
																						: "none",
																			}}
																		/>
																	</div>
																</td>
															)}
															{/* END - Column 3 - Lead % */}

															{/* START - Column 4 - Lead/Asset */}
															{this.state.leadPerAssetChk === "Yes" && (
																<td style={{ verticalAlign: "middle" }}>
																	<div
																	// key={assetLink.count}
																	>
																		<Input
																			id="leadPerAsset"
																			name="leadPerAsset"
																			value={assetLink.leadPerAsset}
																			disabled
																			style={{
																				//width: "180px",
																				borderRadius: "1px",
																				textDecoration:
																					assetLink.status === "Removed"
																						? "line-through red"
																						: "none",
																			}}
																		/>
																	</div>
																</td>
															)}
															{/* END - Column 4 - Lead/Asset */}

															{/* START - Column 5 - Multitouch */}
															{this.state.multiTouchChk === "Yes" && (
																<td style={{ verticalAlign: "middle" }}>
																	<div
																	// key={assetLink.count}
																	>
																		<select
																			value={assetLink.multiTouch}
																			disabled={
																				assetLink.status === "Removed"
																					? true
																					: false
																			}
																			style={{
																				borderRadius: "1px",
																				margin: "1.5px",
																				paddingTop: 5,
																				paddingBottom: 5,
																				paddingLeft: 3,
																				paddingRight: 3,
																				border: "1px solid #d9d9d9",
																				//width: "120px",
																				minWidth: "90px",
																				width: "100%",
																				textDecoration:
																					assetLink.status === "Removed"
																						? "line-through red"
																						: "none",
																			}}
																			name="multiTouch"
																			onChange={(e) => {
																				this.handleAssetLink(e, i);
																			}}>
																			<option value="1st Touch">
																				1st Touch
																			</option>
																			<option value="2nd Touch">
																				2nd Touch
																			</option>
																			<option value="3rd Touch">
																				3rd Touch
																			</option>
																		</select>
																	</div>
																</td>
															)}
															{/* END - Column 5 - Multitouch */}

															{/* START - Column 6 - Remove Icon */}
															<td style={{ verticalAlign: "middle" }}>
																<div
																	//key={assetLink.count}
																	style={{
																		textAlign: "center",
																	}}>
																	<a>
																		<span
																			class="glyphicon glyphicon-minus-sign"
																			id={i}
																			disabled={
																				assetLink.status === "Removed"
																					? true
																					: false
																			}
																			onClick={() =>
																				this.removeAssetLink(i, assetLink)
																			}
																			style={{
																				color: "Red",
																				fontWeight: "bold",
																				fontSize: "22px",
																				border: "5px solid white",
																			}}></span>
																	</a>
																</div>
															</td>
															{/* END - Column 6 - Remove Icon */}
														</tr>
													);
												})}
											</tbody>
										</table>
									</div>
								</div>
							</div>
							{/* End - Uploaded Assets Link Table */}
							{/* End - Upload Supporting Asset */}

							{/* Start - Divider */}
							<hr
								style={{
									height: 2,
									backgroundColor: "#f5f5f5",
									marginLeft: 15,
									marginRight: 15,
									borderWidth: 0,
								}}
							/>
							{/* End - Divider */}
							{/* //snehal-task3620-Add exclude domains(for exclude domain) */}
							{/* Start - ABM and Suppression */}
							{/* Start - Heading - ABM and Suppression */}
							<div className="row row-margin-fix ">
								<label className="sub-content-title">ABM and Suppression</label>
							</div>
							{/* End - Heading - ABM, Suppression and Exclusion */}

							<div className="row row-margin-fix mt-3">
								{/* Start - ABM */}
								<div className="col-md-4 col-sm-12 mr-md-2 px-0">
									<div className="files-box">
										<div className="d-flex justify-content-between align-items-center">
											<label className="mb-0 files-box-header">
												ABM
												{this.state.campaignType == "ABM" && (
													<span style={{ color: "red" }}>&nbsp;*</span>
												)}
												&nbsp;:&nbsp;
												<Tooltip
													placement="right"
													title="The ABM file must have one column with header name as “Account Name” OR “Domain Name” ">
													<Icon type="question-circle" />
												</Tooltip>
											</label>
											<img
												className="float-right mx-1"
												style={{ height: 18, width: 18, cursor: "pointer" }}
												src="upload.png"
												onClick={this.handleChangeMultipleFileOfABM}
											/>
										</div>
										<div id="noabmList" style={{ display: "none" }}>
											0 ABM Files Selected
										</div>
										{this.state.abmFilesExtensionError && (
											<div className="mt-2 mb-0" style={{ color: "red" }}>
												Please upload files having extensions: .xls, .xlsx, .csv
												only
											</div>
										)}
										{this.state.abmValidHeaderError && (
											<div className="mt-2 mb-0" style={{ color: "red" }}>
												Please Upload Valid Header File
											</div>
										)}
										<div
											className="mt-2"
											style={
												this.state.abmFilesSelectedDocuments.length > 5
													? {
															maxHeight: 280,
															overflowY: "auto",
													  }
													: {}
											}>
											{this.state.abmFilesSelectedDocuments &&
												this.state.abmFilesSelectedDocuments.map((item) => {
													return (
														<div
															className="row align-items-center mb-2"
															supportDocID={item.supportDocID}
															style={{
																backgroundColor: item.success
																	? "#DAECDA"
																	: "#FFEEF0",
																paddingLeft: 12,
																paddingTop: 12,
																paddingBottom: 12,
																paddingRight: 12,
																marginLeft: 0,
																marginRight: 0,
																borderRadius: 2,
															}}>
															<div
																style={{ padding: 0, margin: 0 }}
																className="col-xs-2 col-md-auto">
																{item.loading ? (
																	<CircularProgress
																		size={26}
																		style={{
																			height: 26,
																			width: 26,
																			display: "flex",
																			margin: "auto",
																		}}
																	/>
																) : (
																	<img
																		style={{ height: 26, width: 26 }}
																		src={
																			item.success
																				? "success.png"
																				: "failed.png"
																		}
																	/>
																)}
															</div>
															<div
																style={{ padding: 0, margin: 0 }}
																className="col overflow-hidden align-middle">
																<div className="row align-middle m-0 p-0">
																	<label
																		className="mb-0 ml-2 overflow-hidden align-middle"
																		style={{
																			fontWeight: "normal",
																			fontSize: 13,
																		}}>
																		{item.dataFileID || item.suppDocName}
																	</label>
																</div>
																<div className="row align-middle m-0 p-0">
																	<label
																		className="mb-0 ml-2 overflow-hidden align-middle"
																		style={{
																			fontWeight: "normal",
																			fontSize: 11,
																		}}>
																		ABM Count : {item.fileRecordCount}
																	</label>
																</div>
															</div>
															<a
																supportDocID={item.supportDocID}
																data-fileid={
																	item.dataFileID || item.suppDocName
																}
																className="col-xs-2 col-md-auto justify-content-center"
																style={{
																	padding: 0,
																	margin: 0,
																	width: "auto",
																}}
																// Bug - 3395 - Karan Jagtap - added disable functionality while file uploading
																disabled={item.loading ? true : false}
																onClick={(e) => {
																	this.removeABMFileSelectedDocuments(e, item);
																}}>
																<img
																	className="mb-0 mx-2"
																	style={{
																		height: 18,
																		width: 18,
																	}}
																	src="file_upload_2_blue.png" // User Story - 3427 - Karan Jagtap - changed remove icon
																/>
															</a>
														</div>
													);
												})}
										</div>
									</div>
								</div>
								{/* End - ABM */}

								{/* Start - Suppression */}
								<div className="offset-md-1 offset-sm-0 col-md-4 col-sm-12 px-0 mt-md-0 mt-3">
									<div className="files-box">
										<div className="d-flex justify-content-between align-items-center">
											<label className="mb-0 files-box-header">
												Suppression :&nbsp;
												<Tooltip
													placement="right"
													title="Suppersion file can contain 1 column Email">
													<Icon type="question-circle" />
												</Tooltip>
											</label>
											<img
												className="float-right mx-1"
												style={{ height: 18, width: 18, cursor: "pointer" }}
												src="upload.png"
												onClick={this.handleChangeMultipleFileOfSuppression}
											/>
										</div>
										<div id="nosuppressionList" style={{ display: "none" }}>
											0 Suppression Files Selected
										</div>
										{this.state.suppressionFilesExtensionError && (
											<div className="mt-2 mb-0" style={{ color: "red" }}>
												Please upload files having extensions: .xls, .xlsx, .csv
												only
											</div>
										)}
										{this.state.suppressionValidHeaderError && (
											<div className="mt-2 mb-0" style={{ color: "red" }}>
												Please Upload Valid Header File
											</div>
										)}
										<div
											className="mt-2"
											style={
												this.state.suppressionFilesSelectedDocuments.length > 5
													? {
															maxHeight: 280,
															overflowY: "auto",
													  }
													: {}
											}>
											{this.state.suppressionFilesSelectedDocuments &&
												this.state.suppressionFilesSelectedDocuments.map(
													(item) => {
														return (
															<div
																className="row align-items-center mb-2"
																style={{
																	backgroundColor: item.success
																		? "#DAECDA"
																		: "#FFEEF0",
																	paddingLeft: 12,
																	paddingTop: 12,
																	paddingBottom: 12,
																	paddingRight: 12,
																	marginLeft: 0,
																	marginRight: 0,
																	borderRadius: 2,
																}}>
																<div
																	style={{ padding: 0, margin: 0 }}
																	className="col-xs-2 col-md-auto">
																	{item.loading ? (
																		<CircularProgress
																			size={26}
																			style={{
																				height: 26,
																				width: 26,
																				display: "flex",
																				margin: "auto",
																			}}
																		/>
																	) : (
																		<img
																			style={{
																				height: 26,
																				width: 26,
																			}}
																			src={
																				item.success
																					? "success.png"
																					: "failed.png"
																			}
																		/>
																	)}
																</div>
																<div
																	style={{ padding: 0, margin: 0 }}
																	className="col overflow-hidden">
																	<label
																		className="mb-0 ml-2 overflow-hidden align-middle"
																		style={{
																			fontWeight: "normal",
																			fontSize: 13,
																		}}>
																		{item.dataFileID || item.suppDocName}
																	</label>
																</div>
																<a
																	supportDocID={item.supportDocID}
																	data-fileid={
																		item.dataFileID || item.suppDocName
																	}
																	className="col-xs-2 col-md-auto justify-content-center"
																	style={{
																		padding: 0,
																		margin: 0,
																		width: "auto",
																	}}
																	// Bug - 3395 - Karan Jagtap - added disable functionality while file uploading
																	disabled={item.loading ? true : false}
																	onClick={(e) => {
																		this.removeSuppressionFileSelectedDocuments(
																			e,
																			item
																		);
																	}}>
																	<img
																		className="mb-0 mx-2"
																		style={{
																			height: 18,
																			width: 18,
																		}}
																		src="file_upload_2_blue.png" // User Story - 3427 - Karan Jagtap - changed remove icon
																	/>
																</a>
															</div>
														);
													}
												)}
										</div>
									</div>
								</div>
								{/* End - Suppression */}
								{/* //snehal-task3620-Add exclude domains(for exclude domain) */}
							</div>
							<br />
							<hr
								style={{
									height: 2,
									backgroundColor: "#f5f5f5",
									marginLeft: 15,
									marginRight: 15,
									borderWidth: 0,
								}}
							/>
							{/* End - Divider */}

							{/* Start - Exclusion and Exclude Domain Extension */}
							{/* Start - Heading - Exclusion and Exclude Domain Extension */}
							<div className="row row-margin-fix ">
								<label className="sub-content-title">
									Exclusion and Exclude Domain Extension
								</label>
							</div>
							<div className="row row-margin-fix mt-3">
								{/* Start - Exclusion */}
								{/* sonali-3467-added company name in exclusion file tooltip */}
								<div className="col-md-4 col-sm-12 ml-md-2 px-0 mt-md-0 mt-3">
									<div className="files-box">
										<div className="d-flex justify-content-between align-items-center">
											<label className="mb-0 files-box-header">
												Exclusion :&nbsp;
												<Tooltip
													placement="right"
													title='Exclusion file must have one column with header name "Domain" or "Company Name"'>
													<Icon type="question-circle" />
												</Tooltip>
											</label>
											<img
												className="float-right mx-1"
												style={{ height: 18, width: 18, cursor: "pointer" }}
												src="upload.png"
												onClick={this.handleChangeMultipleFileOfExclusion}
											/>
										</div>
										{this.state.exclusionFilesExtensionError && (
											<div className="mt-2 mb-0" style={{ color: "red" }}>
												Please upload files having extensions: .xls, .xlsx, .csv
												only
											</div>
										)}
										{this.state.exclusionValidHeaderError && (
											<div className="mt-2 mb-0" style={{ color: "red" }}>
												Please Upload Valid Header File
											</div>
										)}
										<div
											className="mt-2"
											style={
												this.state.exclusionFilesSelectedDocuments.length > 5
													? {
															maxHeight: 280,
															overflowY: "auto",
													  }
													: {}
											}>
											{this.state.exclusionFilesSelectedDocuments &&
												this.state.exclusionFilesSelectedDocuments.map(
													(item) => {
														return (
															<div
																className="row align-items-center mb-2"
																style={{
																	backgroundColor: item.success
																		? "#DAECDA"
																		: "#FFEEF0",
																	paddingLeft: 12,
																	paddingTop: 12,
																	paddingBottom: 12,
																	paddingRight: 12,
																	marginLeft: 0,
																	marginRight: 0,
																	borderRadius: 2,
																}}>
																<div
																	style={{ padding: 0, margin: 0 }}
																	className="col-xs-2 col-md-auto">
																	{item.loading ? (
																		<CircularProgress
																			size={26}
																			style={{
																				height: 26,
																				width: 26,
																				display: "flex",
																				margin: "auto",
																			}}
																		/>
																	) : (
																		<img
																			style={{
																				height: 26,
																				width: 26,
																			}}
																			src={
																				item.success
																					? "success.png"
																					: "failed.png"
																			}
																		/>
																	)}
																</div>
																<div
																	style={{ padding: 0, margin: 0 }}
																	className="col overflow-hidden">
																	<label
																		className="mb-0 ml-2 overflow-hidden align-middle"
																		style={{
																			fontWeight: "normal",
																			fontSize: 13,
																		}}>
																		{item.dataFileID || item.suppDocName}
																	</label>
																</div>
																<a
																	supportDocID={item.supportDocID}
																	data-fileid={
																		item.dataFileID || item.suppDocName
																	}
																	style={{
																		padding: 0,
																		margin: 0,
																		width: "auto",
																	}}
																	className="col-xs-2 col-md-auto justify-content-center"
																	// Bug - 3395 - Karan Jagtap - added disable functionality while file uploading
																	disabled={item.loading ? true : false}
																	onClick={(e) => {
																		this.removeExclusionFileSelectedDocuments(
																			e,
																			item
																		);
																	}}>
																	<img
																		className="mb-0 mx-2"
																		style={{
																			height: 18,
																			width: 18,
																		}}
																		src="file_upload_2_blue.png" // User Story - 3427 - Karan Jagtap - changed remove icon
																	/>
																</a>
															</div>
														);
													}
												)}
										</div>
									</div>
								</div>
								{/* End - Exclusion */}
								{/* //snehal-task3620-Add exclude domains(for exclude domain) */}
								<div className="row row-margin-fix mt-3">
									{/* Start - Exclusion */}
									<div className="offset-md-2 offset-sm-0 col-md-4 col-sm-12 px-0 mt-md-0 mt-3">
										<div className="files-box" id="excludeTextcard">
											<div className="d-flex justify-content-between align-items-center">
												<label className="mb-12 files-box-header">
													Exclude Domain Extension :&nbsp;
													<Tooltip
														placement="right"
														title="Leads with domain extension as below will be rejected.">
														<Icon type="question-circle" />
													</Tooltip>
												</label>
											</div>
											<textarea
												className=""
												style={{
													height: "175px",
													resize: "none",
													border: "1px solid #cccccc",
												}}
												id="excludeText"
												name="excludeText"
												onChange={this.excludeTextChange}
												value={this.state.excludeText}></textarea>
										</div>
									</div>
								</div>
							</div>
							<br />
							<hr
								style={{
									height: 2,
									backgroundColor: "#f5f5f5",
									marginLeft: 15,
									marginRight: 15,
									borderWidth: 0,
								}}
							/>
							{/* End - Divider */}

							{/* End - ABM, Suppression and Exclusion */}

							{/* Start - Other Documents */}
							<div className="col px-0">
								{/* Start - Heading - Other Documents */}
								<div className="row row-margin-fix ">
									<label className="sub-content-title">Other Documents</label>
								</div>
								{/* End - Heading - Other Documents */}

								{/* Start - Other Documents files */}
								<div className="row row-margin-fix mt-3">
									<div className="col-md-4 col-sm-12 mr-md-2 px-0">
										<div className="files-box">
											<div className="d-flex justify-content-between align-items-center">
												<label className="mb-0 files-box-header">
													Other :&nbsp;
												</label>
												<img
													className="float-right mx-1"
													style={{ height: 18, width: 18, cursor: "pointer" }}
													src="upload.png"
													onClick={this.handleChangeMultipleFileOfOther}
												/>
											</div>
											<div
												className="mt-2"
												style={
													this.state.otherFilesSelectedDocuments.length > 5
														? {
																maxHeight: 280,
																overflowY: "auto",
														  }
														: {}
												}>
												{this.state.otherFilesSelectedDocuments &&
													this.state.otherFilesSelectedDocuments.map((item) => {
														return (
															<div
																className="row align-items-center mb-2"
																style={{
																	backgroundColor: item.success
																		? "#DAECDA"
																		: "#FFEEF0",
																	paddingLeft: 12,
																	paddingTop: 12,
																	paddingBottom: 12,
																	paddingRight: 12,
																	marginLeft: 0,
																	marginRight: 0,
																	borderRadius: 2,
																}}>
																<div
																	style={{ padding: 0, margin: 0 }}
																	className="col-xs-2 col-md-auto">
																	{item.loading ? (
																		<CircularProgress
																			size={26}
																			style={{
																				height: 26,
																				width: 26,
																				display: "flex",
																				margin: "auto",
																			}}
																		/>
																	) : (
																		<img
																			style={{
																				height: 26,
																				width: 26,
																			}}
																			src={
																				item.success
																					? "success.png"
																					: "failed.png"
																			}
																		/>
																	)}
																</div>
																<div
																	style={{ padding: 0, margin: 0 }}
																	className="col overflow-hidden">
																	<label
																		className="mb-0 ml-2 overflow-hidden align-middle"
																		style={{
																			fontWeight: "normal",
																			fontSize: 13,
																		}}>
																		{item.dataFileID || item.suppDocName}
																	</label>
																</div>
																<a
																	supportDocID={item.supportDocID}
																	data-fileid={
																		item.dataFileID || item.suppDocName
																	}
																	style={{
																		padding: 0,
																		margin: 0,
																		width: "auto",
																	}}
																	className="col-xs-2 col-md-auto justify-content-center"
																	// Bug - 3395 - Karan Jagtap - added disable functionality while file uploading
																	disabled={item.loading ? true : false}
																	onClick={(e) => {
																		this.removeOtherFileSelectedDocuments(
																			e,
																			item
																		);
																	}}>
																	<img
																		className="mb-0 mx-2"
																		style={{
																			height: 18,
																			width: 18,
																		}}
																		src="file_upload_2_blue.png" // User Story - 3427 - Karan Jagtap - changed remove icon
																	/>
																</a>
															</div>
														);
													})}
											</div>
										</div>
									</div>
								</div>
								{/* End - Other Documents files */}
							</div>
							{/* End - Other Documents */}

							{/* Start - Button Save and Next */}
							<div className="row mt-5 mt-md-0">
								<div className="col px-0">
									<button
										disabled={this.state.submitStep1ButtonDisabled}
										className="btn-save-and-next float-right mr-5"
										onClick={this.submitStep1}>
										Save and Next
									</button>
								</div>
							</div>
							{/* End - Button Save and Next */}
							<Footer />
						</Card>
						{/* End - Step 1 - Supporting Documents */}

						{/* Start - Step 2 - LN QA & Delivery Format */}
						<Card
							className="card-addon"
							bordered={false}
							style={{ display: this.state.displayStep2 }}>
							{/* Start - Navigation */}
							<div className="card-navigation">
								<a style={{ color: "#056eb8" }} onClick={this.handleBackButton}>
									<FaArrowAltCircleLeft
										size={25}
										style={{
											float: "left",
											marginRight: "5px",
											marginBottom: "5px",
										}}
									/>
								</a>
								<button
									disabled={this.state.submitStep2ButtonDisabled}
									className="btn-save-and-next"
									onClick={this.submitStep2}>
									Save and Finish
								</button>
							</div>
							{/* End - Navigation */}
							{/* //Aman-5161-Changed tooltip and Title Leads-Nexus-Edit Campaign-Replace to LNQA */}
							{/* Start - Heading - Quality Assurance */}
							<div className="row sub-content align-items-baseline">
								<div className="sub-content-heading">
									<label className="sub-content-title">
										Quality Assurance&nbsp;
									</label>
									<Tooltip
										placement="right"
										title="This option will divert all the leads to LN-QA for review after submission from Publisher. All the leads will be visible to Agency only after LN-QA review completion.">
										<Icon type="question-circle" />
									</Tooltip>
								</div>
								<div className="ml-3">
									<label
										className="mb-0 mr-2 ml-lg-3"
										style={{
											fontSize: 15,
											fontWeight: "normal",
											color: "black",
										}}>
										LN QA
									</label>
									<Switch
										size="small"
										onChange={this.switchHandlechange}
										checked={this.state.switchValue == "ON" ? true : false}
									/>
								</div>
							</div>
							{/* End - Heading - Quality Assurance */}

							{/* Start - Delivery Format */}
							{/* Start - Heading - Delivery Format */}
							<div className="row row-margin-fix mt-3 mb-2">
								<label className="sub-content-title">Delivery Format</label>
							</div>
							{/* End - Heading - Delivery Format */}

							{/* Start - Delivery Format - sub details */}
							<div className="justify-content mt-2 justify-content-between flex-wrap">{/* Aman task-4765 (Done some layout changes) City-state validation checkbox Div*/}
								<br /> 
								<div className="checkbox-grid row-margin-fix mt-1 mb-sm-3">
									{/* 	 //rutja task-4687 added div for country abbreviation */}
									{/* <div className="pr-0"> */}
										<Checkbox
											name="countryAbbreviation"
											value={this.state.editDeliveryFormat.countryAbbreviation}
											checked={
												this.state.editDeliveryFormat.countryAbbreviation === "Yes"
													? true
													: false
											}
											onChange={this.handleCheckedCountryAbbreviation}>
											{/* Aman task-4765 (added Tooltip) City-state validation checkbox Div*/}
											<span style={{ color: "#11509F" }}>
												Country Abbreviation&nbsp;
												<Tooltip
													placement="right"
													title="Select checkbox for country abbreviation in lead template.">
													<Icon type="question-circle" />
												</Tooltip>
											</span>
										</Checkbox>
									{/* </div> */}
									{/* end 4687 */}
									{/* Aman task-4765 added City-state validation checkbox Div*/}
										<Checkbox
												disabled //Aman-4765- disabled this check box
												 name="cityStateValidationCheck"
												value={this.state.editDeliveryFormat.cityStateValidationCheck}
												// checked={this.state.editDeliveryFormat.cityStateValidationCheck === "ON"}
												checked={
												this.state.editDeliveryFormat.cityStateValidationCheck === "ON"
													? true
													: false
											}
												onChange={this.handleCheckedCityStateValidation}
												 >
												 {/* Aman task-4765 (added Tooltip) City-state validation checkbox Div*/}
												 <span style={{ color: "#11509F" }}>
												 City/State Validation&nbsp;
												 <Tooltip
													placement="right"
													title="Select checkbox for city-state validation for lead upload.">
													<Icon type="question-circle" />
												</Tooltip>
												 </span>
										</Checkbox>
									<div className="d-flex flex-wrap">
										{/* <div className="pr-0"> */}
											<Checkbox
												disabled={
													this.state.agencyCustomizeMapping.length > 0
														? false
														: true
												}
												name="customizeMapping"
												value={this.state.editDeliveryFormat.customizeMapping}
												checked={
													this.state.editDeliveryFormat.customizeMapping === "Yes"
														? true
														: false
												}
												onChange={this.handleChecked}>
												<span style={{ color: "#11509F" }}>
													Customize Mapping:
												</span>
											</Checkbox>
										{/* </div> */}
										{this.state.agencyCustomizeMapping.length > 0 && (
											<div className="d-flex align-items-baseline">
												<div className="d-flex mr-md-2 align-items-center">
													<label
														style={{
															marginBottom: 0,
															color: "#11509F",
															fontWeight: "normal",
														}}>
														Format:&nbsp;
													</label>
													<img
														style={{ height: 18, width: 18 }}
														src="incremental.jpg"
														data-toggle="modal"
														data-target="#customizeMappingAdd"
														onClick={this.customizeMappingFormatHandleChange}
													/>
												</div>

												{/* Start - Modal - when clicked on plus icon */}
												<AgencyCustomizeMappingModal
													agencyCustomizeMapping={
														this.state.agencyCustomizeMappingModal
													}
													customizeMappingCancleHandleChange={
														this.customizeMappingCancleHandleChange
													}
												/>
												{/* End - Modal - when clicked on plus icon */}

												<select
						                            value={
												        this.state.editDeliveryFormat.customizeMappingFormat
											        }
													onChange={this.handleFormatChange}>
													{this.state.agencyCustomizeMapping.map(
														(agencyCustomizeMapping) => {
															return (
																<option value={agencyCustomizeMapping.format}>
																	{/* Nilesh-5001- Add non english sentence when nonEnglishCheck is Yes indelivery_format_mapping_agency table */}
																	{agencyCustomizeMapping.format} {agencyCustomizeMapping.nonEnglishCheck == "Yes" ? "- Non English" : ""}
																</option>
															);
														}
													)}
												</select>
											</div>
										)}
									</div>
								</div>
								{/* //Aman-task-4765 added (change the layout as per the given UI)City-state validation checkbox */}
								<div className="d-flex justify-content mt-2 row-margin-fix justify-content-between flex-wrap">
									<label className="total-uploaded-message">
										(Following Checked Fields Will Be Mandatory For Lead
										Delivery Format)
									</label>
								</div>
							</div>
							{/* End - Delivery Format - sub details */}
							{/* End - Delivery Format */}

							{/* Start - Checkbox Grid */}
							<div className="checkbox-grid row-margin-fix mt-1">
								{/* Start - 1st row - Publisher Id, Campaign Id, Campaign Name, Allocation Id */}
								<Checkbox
									className="single-checkbox"
									name="pID"
									defaultChecked
									disabled
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].pID
										: "Publisher ID"}
								</Checkbox>
								<Checkbox
									className="single-checkbox"
									name="campaignID"
									defaultChecked
									disabled
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].campID
										: "Campaign ID"}
								</Checkbox>
								<Checkbox
									className="single-checkbox"
									name="campaignName"
									disabled //kiran-4740-disable checkbox
									checked={
										this.state.editDeliveryFormat.campaignName === "Yes"
											? true
											: false
									}
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].campaignName
										: "Campaign name"}
								</Checkbox>
								<Checkbox
									className="single-checkbox"
									name="reAllocationID"
									checked={
										this.state.editDeliveryFormat.reAllocationID === "Yes"
											? true
											: false
									}
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].reAllocationID
										: "Allocation ID"}
								</Checkbox>
								{/* End - 1st row - Publisher Id, Campaign Id, Campaign Name, Allocation Id */}

								{/* Start - 2nd row - Lead Interaction date, first name, last name, email */}
								<Checkbox
									className="single-checkbox"
									name="leadInteractionDate"
									defaultChecked
									disabled
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0]
												.leadInteractionDate
										: "Lead interaction date"}
								</Checkbox>
								<Checkbox
									className="single-checkbox"
									name="firstName"
									defaultChecked
									disabled
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].firstName
										: "First name"}
								</Checkbox>
								<Checkbox
									className="single-checkbox"
									name="lastName"
									value={this.state.lastName}
									defaultChecked
									disabled
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].lastName
										: "Last name"}
								</Checkbox>
								<Checkbox
									className="single-checkbox"
									name="email"
									defaultChecked
									disabled
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].email
										: "Email"}
								</Checkbox>
								{/* End - 2nd row - Lead Interaction date, first name, last name, email */}

								{/* Start - 3rd row - work phone, alternate phone no, company name, linkedIn company name */}
								<Checkbox
									className="single-checkbox"
									name="workPhone"
									defaultChecked
									disabled
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].workPhone
										: "Work phone"}
								</Checkbox>
								<Checkbox
									className="single-checkbox"
									name="alternatePhoneNo"
									checked={
										this.state.editDeliveryFormat.alternatePhoneNo === "Yes"
											? true
											: false
									}
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].alternatePhoneNo
										: "Alternate phone no"}
								</Checkbox>
								<Checkbox
									className="single-checkbox"
									name="companyName"
									defaultChecked
									disabled
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].companyName
										: "Company name"}
								</Checkbox>
								<Checkbox
									className="single-checkbox"
									name="linkedInCompanyName"
									defaultChecked
									disabled
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0]
												.linkedInCompanyName
										: "LinkedIn company name"}
								</Checkbox>
								{/* End - 3rd row - work phone, alternate phone no, company name, linkedIn company name */}

								{/* Start - 4th row - industry, company employee size, company revenue, job title */}
								<Checkbox
									className="single-checkbox"
									name="industry"
									checked={
										this.state.editDeliveryFormat.industry === "Yes"
											? true
											: false
									}
									disabled
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].industry
										: "Industry"}
								</Checkbox>
								<Checkbox
									className="single-checkbox"
									name="companyEmployeeSize"
									checked={
										this.state.editDeliveryFormat.companyEmployeeSize === "Yes"
											? true
											: false
									}
									disabled
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0]
												.companyEmployeeSize
										: "Company employee size"}
								</Checkbox>
								<Checkbox
									className="single-checkbox"
									name="companyRevenue"
									checked={
										this.state.editDeliveryFormat.companyRevenue === "Yes"
											? true
											: false
									}
									disabled
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].companyRevenue
										: "Company revenue"}
								</Checkbox>
								<Checkbox
									className="single-checkbox"
									name="jobTitleDelivery"
									defaultChecked
									disabled
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].jobTitle
										: "Job title"}
								</Checkbox>
								{/* End - 4th row - industry, company employee size, company revenue, job title */}

								{/* Start - 5th row - linkedIn job title, job title validation, job level, job function */}
								<Checkbox
									className="single-checkbox"
									name="linkedInJobTitle"
									defaultChecked
									disabled
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].linkedInJobTitle
										: "LinkedIn job title"}
								</Checkbox>
								<Checkbox
									className="single-checkbox"
									name="jobTitleValidation"
									checked={
										this.state.editDeliveryFormat.jobTitleValidation === "Yes"
											? true
											: false
									}
									disabled
									onChange={this.handleChecked}>
									Job title validation
								</Checkbox>
								<Checkbox
									className="single-checkbox"
									name="jobLevelDelivery"
									checked={
										this.state.editDeliveryFormat.jobLevel==="Yes"  //kiran-4877-changed  variable name
										// this.state.editDeliveryFormat.jobLevelDelivery === "Yes"
											? true
											: false
									}
									disabled
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].jobLevel
										: "Job level"}
								</Checkbox>
								<Checkbox
									className="single-checkbox"
									name="jobFunction"
									checked={
										this.state.editDeliveryFormat.jobFunction==="Yes"  ////kiran-4877-changed  variable name
										// this.state.editDeliveryFormat.jobFunctionDelivery === "Yes"
											? true
											: false
									}
									disabled 
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].jobFunction
										: "Job function"}
								</Checkbox>
								{/* End - 5th row - linkedIn job title, job title validation, job level, job function */}

								{/* Start - 6th row - Country, state, city, zipcode */}
								<Checkbox
									className="single-checkbox"
									name="countryDelivery"
									defaultChecked
									disabled
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].country
										: "Country"}
								</Checkbox>
								<Checkbox
									className="single-checkbox"
									name="state"
									defaultChecked
									disabled
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].state
										: "State"}
								</Checkbox>
								<Checkbox
									className="single-checkbox"
									name="city"
									defaultChecked
									disabled
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].city
										: "City"}
								</Checkbox>
								<Checkbox
									className="single-checkbox"
									name="zipCode"
									defaultChecked
									disabled
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].zipCode
										: "Zip code"}
								</Checkbox>
								{/* End - 6th row - Country, state, city, zipcode */}

								{/* Start - 7th row - address, street, asset name, asset id */}
								<Checkbox
									className="single-checkbox"
									name="address"
									defaultChecked
									disabled
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].address
										: "Address"}
								</Checkbox>
								<Checkbox
									className="single-checkbox"
									name="street"
									checked={
										this.state.editDeliveryFormat.street === "Yes"
											? true
											: false
									}
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].street
										: "Street"}
								</Checkbox>
								<Checkbox
									className="single-checkbox"
									name="assetName"
									defaultChecked
									disabled
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].assetName
										: "Asset name"}
								</Checkbox>
								<Checkbox
									className="single-checkbox"
									name="supportDocID" //assetId
									checked={
										this.state.editDeliveryFormat.supportDocID === "Yes"
											? true
											: false
									}
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].supportDocID
										: "Asset ID"}
								</Checkbox>
								{/* End - 7th row - address, street, asset name, asset id */}

								{/* Start - 8th row - linkedIn, domain, ip, comments */}
								<Checkbox
									className="single-checkbox"
									name="linkedIn"
									checked={
										this.state.editDeliveryFormat.linkedIn === "Yes"
											? true
											: false
									}
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].linkedIn
										: "LinkedIn"}
								</Checkbox>
								<Checkbox
									className="single-checkbox"
									name="domain"
									checked={
										this.state.editDeliveryFormat.domain === "Yes"
											? true
											: false
									}
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].domain
										: "Domain"}
								</Checkbox>
								<Checkbox
									className="single-checkbox"
									name="ip"
									checked={
										this.state.editDeliveryFormat.ip === "Yes" ? true : false
									}
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].ip
										: "IP"}
								</Checkbox>
								<Checkbox
									className="single-checkbox"
									name="comments"
									checked={
										this.state.editDeliveryFormat.comments === "Yes"
											? true
											: false
									}
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].comments
										: "Comments"}
								</Checkbox>
								{/* End - 8th row - linkedIn, domain, ip, comments */}

								{/* Start - rest of the rows for Extra fields */}
								<Checkbox
									className="single-checkbox"
									name="extra1"
									checked={
										this.state.editDeliveryFormat.extra1 === "Yes"
											? true
											: false
									}
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].extra1
										: "Extra 1"}
								</Checkbox>

								<Checkbox
									className="single-checkbox"
									name="extra2"
									checked={
										this.state.editDeliveryFormat.extra2 === "Yes"
											? true
											: false
									}
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].extra2
										: "Extra 2"}
								</Checkbox>

								<Checkbox
									className="single-checkbox"
									name="extra3"
									checked={
										this.state.editDeliveryFormat.extra3 === "Yes"
											? true
											: false
									}
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].extra3
										: "Extra 3"}
								</Checkbox>

								<Checkbox
									className="single-checkbox"
									name="extra4"
									checked={
										this.state.editDeliveryFormat.extra4 === "Yes"
											? true
											: false
									}
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].extra4
										: "Extra 4"}
								</Checkbox>

								<Checkbox
									className="single-checkbox"
									name="extra5"
									checked={
										this.state.editDeliveryFormat.extra5 === "Yes"
											? true
											: false
									}
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].extra5
										: "Extra 5"}
								</Checkbox>

								<Checkbox
									className="single-checkbox"
									name="extra6"
									checked={
										this.state.editDeliveryFormat.extra6 === "Yes"
											? true
											: false
									}
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].extra6
										: "Extra 6"}
								</Checkbox>

								<Checkbox
									className="single-checkbox"
									name="extra7"
									checked={
										this.state.editDeliveryFormat.extra7 === "Yes"
											? true
											: false
									}
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].extra7
										: "Extra 7"}
								</Checkbox>

								<Checkbox
									className="single-checkbox"
									name="extra8"
									checked={
										this.state.editDeliveryFormat.extra8 === "Yes"
											? true
											: false
									}
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].extra8
										: "Extra 8"}
								</Checkbox>
								<Checkbox
									className="single-checkbox"
									name="extra9"
									checked={
										this.state.editDeliveryFormat.extra9 === "Yes"
											? true
											: false
									}
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].extra9
										: "Extra 9"}
								</Checkbox>

								<Checkbox
									className="single-checkbox"
									name="extra10"
									checked={
										this.state.editDeliveryFormat.extra10 === "Yes"
											? true
											: false
									}
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].extra10
										: "Extra 10"}
								</Checkbox>

								<Checkbox
									className="single-checkbox"
									name="extra11"
									checked={
										this.state.editDeliveryFormat.extra11 === "Yes"
											? true
											: false
									}
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].extra11
										: "Extra 11"}
								</Checkbox>

								<Checkbox
									className="single-checkbox"
									name="extra12"
									checked={
										this.state.editDeliveryFormat.extra12 === "Yes"
											? true
											: false
									}
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].extra12
										: "Extra 12"}
								</Checkbox>

								<Checkbox
									className="single-checkbox"
									name="extra13"
									checked={
										this.state.editDeliveryFormat.extra13 === "Yes"
											? true
											: false
									}
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].extra13
										: "Extra 13"}
								</Checkbox>
								<Checkbox
									className="single-checkbox"
									name="extra14"
									checked={
										this.state.editDeliveryFormat.extra14 === "Yes"
											? true
											: false
									}
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].extra14
										: "Extra 14"}
								</Checkbox>
								<Checkbox
									className="single-checkbox"
									name="extra15"
									checked={
										this.state.editDeliveryFormat.extra15 === "Yes"
											? true
											: false
									}
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].extra15
										: "Extra 15"}
								</Checkbox>
								<Checkbox
									className="single-checkbox"
									name="extra16"
									checked={
										this.state.editDeliveryFormat.extra16 === "Yes"
											? true
											: false
									}
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].extra16
										: "Extra 16"}
								</Checkbox>
								<Checkbox
									className="single-checkbox"
									name="extra17"
									checked={
										this.state.editDeliveryFormat.extra17 === "Yes"
											? true
											: false
									}
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].extra17
										: "Extra 17"}
								</Checkbox>
								<Checkbox
									className="single-checkbox"
									name="extra18"
									checked={
										this.state.editDeliveryFormat.extra18 === "Yes"
											? true
											: false
									}
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].extra18
										: "Extra 18"}
								</Checkbox>

								<Checkbox
									className="single-checkbox"
									name="extra19"
									checked={
										this.state.editDeliveryFormat.extra19 === "Yes"
											? true
											: false
									}
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].extra19
										: "Extra 19"}
								</Checkbox>
								<Checkbox
									className="single-checkbox"
									name="extra20"
									checked={
										this.state.editDeliveryFormat.extra20 === "Yes"
											? true
											: false
									}
									onChange={this.handleChecked}>
									{/* karan-task-3059-if customize mapping checbox is checked then show the Alias, otherwise show the default label */}
									{/* Priyanka-4532-Added hutk label */}
									{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
									this.state.agencyCustomizeMappingModal.length === 1
										? this.state.agencyCustomizeMappingModal[0].extra20
										: "Extra 20 (hutk)"}
								</Checkbox>

								{/* Nilesh-5279-set the other extra filed 21 to 30 value */}

								{this.state.agencyCustomizeMappingModal[0] && (
									this.state.agencyCustomizeMappingModal[0].extra21 && (
										this.state.agencyCustomizeMappingModal[0].extra21 !== "" || this.state.agencyCustomizeMappingModal[0].extra21 !== null ? (
											<Checkbox
												className="single-checkbox"
												name="extra21"
												checked={
													this.state.editDeliveryFormat.extra21 === "Yes"
														? true
														: false
												}
												onChange={this.handleChecked}>
												{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
												this.state.agencyCustomizeMappingModal.length === 1
													? this.state.agencyCustomizeMappingModal[0].extra21
													: "Extra 21"}
											</Checkbox>
										) : null
									)
								)}

								{this.state.agencyCustomizeMappingModal[0] && (
									this.state.agencyCustomizeMappingModal[0].extra22 && (
										this.state.agencyCustomizeMappingModal[0].extra22 !== "" || this.state.agencyCustomizeMappingModal[0].extra22 !== null ? (
											<Checkbox
												className="single-checkbox"
												name="extra22"
												checked={
													this.state.editDeliveryFormat.extra22 === "Yes"
														? true
														: false
												}
												onChange={this.handleChecked}>
												{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
												this.state.agencyCustomizeMappingModal.length === 1
													? this.state.agencyCustomizeMappingModal[0].extra22
													: "Extra 22"}
											</Checkbox>
										) : null
									)
								)}

								{this.state.agencyCustomizeMappingModal[0] && (
									this.state.agencyCustomizeMappingModal[0].extra23 && (
										this.state.agencyCustomizeMappingModal[0].extra23 !== "" || this.state.agencyCustomizeMappingModal[0].extra23 !== null ? (
											<Checkbox
												className="single-checkbox"
												name="extra23"
												checked={
													this.state.editDeliveryFormat.extra23 === "Yes"
														? true
														: false
												}
												onChange={this.handleChecked}>
												{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
												this.state.agencyCustomizeMappingModal.length === 1
													? this.state.agencyCustomizeMappingModal[0].extra23
													: "Extra 23"}
											</Checkbox>
										) : null
									)
								)}

								{this.state.agencyCustomizeMappingModal[0] && (
									this.state.agencyCustomizeMappingModal[0].extra24 && (
										this.state.agencyCustomizeMappingModal[0].extra24 !== "" || this.state.agencyCustomizeMappingModal[0].extra24 !== null ? (
											<Checkbox
												className="single-checkbox"
												name="extra24"
												checked={
													this.state.editDeliveryFormat.extra24 === "Yes"
														? true
														: false
												}
												onChange={this.handleChecked}>
												{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
												this.state.agencyCustomizeMappingModal.length === 1
													? this.state.agencyCustomizeMappingModal[0].extra24
													: "Extra 24"}
											</Checkbox>
										) : null
									)
								)}

								{this.state.agencyCustomizeMappingModal[0] && (
									this.state.agencyCustomizeMappingModal[0].extra25 && (
										this.state.agencyCustomizeMappingModal[0].extra25 !== "" || this.state.agencyCustomizeMappingModal[0].extra25 !== null ? (
											<Checkbox
												className="single-checkbox"
												name="extra25"
												checked={
													this.state.editDeliveryFormat.extra25 === "Yes"
														? true
														: false
												}
												onChange={this.handleChecked}>
												{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
												this.state.agencyCustomizeMappingModal.length === 1
													? this.state.agencyCustomizeMappingModal[0].extra25
													: "Extra 25"}
											</Checkbox>
										) : null
									)
								)}

								{this.state.agencyCustomizeMappingModal[0] && (
									this.state.agencyCustomizeMappingModal[0].extra26 && (
										this.state.agencyCustomizeMappingModal[0].extra26 !== "" || this.state.agencyCustomizeMappingModal[0].extra26 !== null ? (
											<Checkbox
												className="single-checkbox"
												name="extra26"
												checked={
													this.state.editDeliveryFormat.extra26 === "Yes"
														? true
														: false
												}
												onChange={this.handleChecked}>
												{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
												this.state.agencyCustomizeMappingModal.length === 1
													? this.state.agencyCustomizeMappingModal[0].extra26
													: "Extra 26"}
											</Checkbox>
										) : null
									)
								)}
						
								{this.state.agencyCustomizeMappingModal[0] && (
									this.state.agencyCustomizeMappingModal[0].extra27 && (
										this.state.agencyCustomizeMappingModal[0].extra27 !== "" || this.state.agencyCustomizeMappingModal[0].extra27 !== null ? (
											<Checkbox
												className="single-checkbox"
												name="extra27"
												checked={
													this.state.editDeliveryFormat.extra27 === "Yes"
														? true
														: false
												}
												onChange={this.handleChecked}>
												{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
												this.state.agencyCustomizeMappingModal.length === 1
													? this.state.agencyCustomizeMappingModal[0].extra27
													: "Extra 27"}
											</Checkbox>
										) : null
									)
								)}

								{this.state.agencyCustomizeMappingModal[0] && (
									this.state.agencyCustomizeMappingModal[0].extra28 && (
										this.state.agencyCustomizeMappingModal[0].extra28 !== "" || this.state.agencyCustomizeMappingModal[0].extra28 !== null ? (
											<Checkbox
												className="single-checkbox"
												name="extra28"
												checked={
													this.state.editDeliveryFormat.extra28 === "Yes"
														? true
														: false
												}
												onChange={this.handleChecked}>
												{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
												this.state.agencyCustomizeMappingModal.length === 1
													? this.state.agencyCustomizeMappingModal[0].extra28
													: "Extra 28"}
											</Checkbox>
										) : null
									)
								)}

								{this.state.agencyCustomizeMappingModal[0] && (
									this.state.agencyCustomizeMappingModal[0].extra29 && (
										this.state.agencyCustomizeMappingModal[0].extra29 !== "" || this.state.agencyCustomizeMappingModal[0].extra29 !== null ? (
											<Checkbox
												className="single-checkbox"
												name="extra29"
												checked={
													this.state.editDeliveryFormat.extra29 === "Yes"
														? true
														: false
												}
												onChange={this.handleChecked}>
												{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
												this.state.agencyCustomizeMappingModal.length === 1
													? this.state.agencyCustomizeMappingModal[0].extra29
													: "Extra 29"}
											</Checkbox>
										) : null
									)
								)}

								{this.state.agencyCustomizeMappingModal[0] && (
									this.state.agencyCustomizeMappingModal[0].extra30 && (
										this.state.agencyCustomizeMappingModal[0].extra30 !== "" || this.state.agencyCustomizeMappingModal[0].extra30 !== null ? (
											<Checkbox
												className="single-checkbox"
												name="extra30"
												checked={
													this.state.editDeliveryFormat.extra30 === "Yes"
														? true
														: false
												}
												onChange={this.handleChecked}>
												{this.state.editDeliveryFormat.customizeMapping === "Yes" &&
												this.state.agencyCustomizeMappingModal.length === 1
													? this.state.agencyCustomizeMappingModal[0].extra30
													: "Extra 30"}
											</Checkbox>
										) : null
									)
								)}
								{/* End - rest of the rows for Extra fields */}
							</div>
							{/* End - Checkbox Grid */}

							{/* Start - Button Save and Finish */}
							<div className="row mt-5">
								<div className="col px-0">
									<button
										disabled={this.state.submitStep2ButtonDisabled}
										className="btn-save-and-next float-right mr-5"
										onClick={this.submitStep2}>
										Save and Finish
									</button>
								</div>
							</div>
							{/* End - Button Save and Finish */}
							<Footer />
						</Card>
						{/* End - Step 2 - LN-QA & Delivery Format */}
					</div>
				</div>
			</div>
		);
	}
}

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
EditSupportingDocument.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
	withRouter(EditSupportingDocument)
);
